import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { removeCommasBeforeSubmitForm } from '@/layouts/application/utils/handleMoneyField';
import { formatMoneyToNumber } from '@/layouts/application/utils/formats';

export default class ExistingAssetForm {
  constructor(dreamChartInstance) {
    this.retirementAssetValueInputSelector = '.retirement-asset-value-input';
    this.existingAssetsSectionSelector = '#existing-assets';
    this.existingAssetContainerSelector = '#exsiting-asset-container';
    this.existingAssetActivateCheckboxSelector = '.retirement-asset-activated-checkbox';
    this.groupAssetCheckboxSelector = '.group-asset-checkbox';
    this.assetCurrentValueSelector = '.asset-current-value-input';
    this.assetValueErrorsSelector = '.asset-value-errors';
    this.groupExistingAsset = '.group-existing-asset';
    this.dreamChartInstance = dreamChartInstance;
  }

  setup() {
    this.setSaveTriggerForExistingAssetForm();
    handleMoneyField();
  }

  setSaveTriggerForExistingAssetForm() {
    $(this.existingAssetsSectionSelector).on('change',
      `${this.retirementAssetValueInputSelector}, ${this.existingAssetActivateCheckboxSelector}
    `, (inputElement) => {
      const $form = $(inputElement.target).parents('form');
      const collapseIdFocus = $(inputElement.target).parents('.sub-input-group').attr('id');
      const $groupExistingAsset = $(inputElement.target).parents(this.groupExistingAsset);

      const currentAssetValue = parseFloat(formatMoneyToNumber($groupExistingAsset.find(this.assetCurrentValueSelector).val()));
      const currentValue = parseFloat(formatMoneyToNumber($groupExistingAsset.find(this.retirementAssetValueInputSelector).val()));

      if ($(inputElement.target).hasClass('retirement-asset-activated-checkbox')) {
        const grouMasterCheckboxes = $form.find(this.groupAssetCheckboxSelector);

        grouMasterCheckboxes.toArray().forEach((e) => {
          const subCheckboxGroup = $(e).closest('.card-asset').find('.sub-input-group')[0];

          if (e.checked) {
            const allSubCheckboxes = $(subCheckboxGroup).find('.retirement-asset-activated-checkbox').toArray();

            if ($(inputElement.target).hasClass('group-asset-checkbox')) {
              allSubCheckboxes.forEach((checkbox) => {
                $(checkbox).attr('checked', 'checked')
              })
            }

          } else {
            const checkedSubCheckboxes = $(subCheckboxGroup).find('.retirement-asset-activated-checkbox:checked').toArray();

            checkedSubCheckboxes.forEach((checkbox) => {
              $(checkbox).removeAttr('checked')
            })
          }
        })
      }

      if (currentValue <= currentAssetValue) {
        $groupExistingAsset.find(this.assetValueErrorsSelector).addClass('d-none');
        $groupExistingAsset.find(this.assetValueErrorsSelector).addClass('valid-value');
      } else { 
        $groupExistingAsset.find(this.assetValueErrorsSelector).removeClass('d-none');
        $groupExistingAsset.find(this.assetValueErrorsSelector).removeClass('valid-value');
      }

      const validValue = this.checkvalidValue();
      if (validValue) {
        removeCommasBeforeSubmitForm($form);

        $.ajax({
          url: $form.attr('action') + `?should_collapse=false&collapse_focus=${collapseIdFocus}`,
          type: 'PUT',
          data: $form.serialize(),
          success: (data) => {
            if (data.success) {
              $(this.existingAssetContainerSelector).replaceWith(data.existing_assets);
              this.dreamChartInstance.presentDataToChart();
              this.setSaveTriggerForExistingAssetForm();
              handleMoneyField();
            }
          }
        });
      }
    }
    )
  }

  checkvalidValue() {
    let valid = true;

    $(this.groupExistingAsset).toArray().forEach(element => {
      const $group = $(element);
      const validError = $group.find(this.assetValueErrorsSelector).hasClass('valid-value');

      if (!validError) {
        valid = false;
      }
    });

    return valid;
  }
}
