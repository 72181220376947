import initMultipleLinesChart from '@/layouts/application/utils/charts/initMultipleLinesChart';
import DreamChart from './dreamChart';
import Chart from 'chart.js/auto';
import { formatCurrency } from '@/layouts/application/utils/formats';
import SimulatedInvestmentForm from '../simulatedInvestmentForm';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';

import {
  SHARED_DREAM_AMOUNT_SAVED_LINE,
  SHARED_DREAM_CLIENT_CONTRIBUTION_LINE,
  SHARED_DREAM_MEMBER_CONTRIBUTION_LINE,
  RETIREMENT_SIMULATED_BODER_COLOR
} from '@/layouts/application/utils/charts/chartColors';
const images = require.context('@/images', true)
const imagePath = (name) => images(name, true)

export default class DreamSharedChart extends DreamChart {
  constructor(...args) {
    super(...args);
    this.rangeBackYearSelector = '.dream-shared-year-range .back-year-icon';
    this.rangeForwardYearSelector = '.dream-shared-year-range .forward-year-icon';
    this.rangeStartYearSelector = '.dream-shared-year-range .range-start-year';
    this.rangeEndYearSelector = '.dream-shared-year-range .range-end-year';
  }

  chart = undefined;
  validChart = true;
  initialSaving = undefined;
  currentTotalSaving = undefined;
  chartInitialData = undefined;
  chartLinesData = [];
  sharedMemberName = '';
  clientName = '';
  isLifeTimeView = false;

  setup() {
    this.setupChartRangeYear();
    this.setOnChangeViewType();
    this.setupChartValue();
    this.addEventForPresentDreamCheckbox();
    this.addEventForActivateSimulatedInvestmentCheckbox();
    this.setupSimulatedInvestments();
  }

  setupChartValue() {
    const chartInitialData = this.chartElementContainer.data('chart-data');

    this.sharedMemberName = chartInitialData.member_name;
    this.clientName = chartInitialData.client_name;
    this.sharedMemberId = chartInitialData.member_id;

    this.buildXAxisData();
    this.presentDataToChart([]);
  }

  setupSimulatedInvestments() {
    new SimulatedInvestmentForm(this).setup();
  }

  presentDataToChart() {
    const data = this.buildDataForChartRequest();
    $.post(
      Routes.present_dreams_to_shared_saving_chart_consultant_client_dreams_path({ client_id: this.clientId, type: 'share-dream', member_id: this.sharedMemberId }),
      { data: data },
      data => {
        this.chartLinesData = data.chart_data;
        this.presentIconsToChart(data.icons_data);
        this.drawDreamSavingChart();
      },
    );
  }

  presentIconsToChart(data) {
    this.dataForIcons = data;
    this.datasetIconLength = 0;
    this.dataForIcons.forEach((e) => {
      if (e == null) return;
      this.datasetIconLength = e.length > this.datasetIconLength ? e.length : this.datasetIconLength;
    });
  }

  initFirstDatasetIcons() {
    let icons = [];
    let values = [];
    let dataIcons = [];
    this.dataForIcons.map((e) => {
      if (e == null) {
        icons.push(null);
        values.push(null);
        dataIcons.push(null);
      } else {
        icons.push(e[0]['icon']);
        values.push(0);
        dataIcons.push(0);
      }
    })

    return {
      type: 'line',
      data: dataIcons,
      fill: false,
      pointStyle: function (context) {
        let tmp = new Image(25, 25);
        if (icons[context.dataIndex] === null || typeof icons[context.dataIndex] === undefined) return;
        tmp.src = imagePath(`./icons/retirement/${icons[context.dataIndex]}.svg`);

        return tmp;
      },
      pointRadius: 22,
      pointHoverRadius: 22,
      values: values
    };
  }

  drawDreamSavingChart() {
    const datasets = this.buildChartDatasets();
    const _this = this;
    let isRenderedIcon = false;

    const options = {
      scales: {
        y: {
          grid: {
            color: function (context) {
              if (context.tick.value == 0) {
                return '#000000';
              } else {
                return Chart.defaults.borderColor
              }
            }
          },
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          borderRadius: 25,
          labels: {
            filter: function (item) {
              if (typeof item.text === 'undefined' || item.text === 'icons 2') return false;

              return true;
            },
            generateLabels: (chart) => {
              const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

              for (const item of items) {
                if (typeof item.text === 'undefined') continue;
                if (!item.text.startsWith('With Simulated')) {
                  item.borderRadius = 5;
                } else {
                  item.lineWidth = 0
                  item.borderRadius = 5;
                }
              }

              return items;
            }
          }
        },
        tooltip: {
          mode: 'index',
          position: 'tooltipPositionCustom',
          backgroundColor: '#EEEEEE',
          padding: 15,
          titleColor: '#303030',
          titleFont: { weight: 'bold', size: 14 },
          bodyColor: '#303030',
          cornerRadius: 10,
          usePointStyle: true,
          filter: function (item) {
            if (typeof item.dataset.pointStyle === "function") return false;
            return true;
          },
          callbacks: {
            label: (tooltipItems) => {
              const label = tooltipItems.dataset.label;
              return ` ${label}: ${formatCurrency(tooltipItems.raw)}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: 'circle',
                borderWidth: 10
              }
            },
            labelColor: function (context) {
              const color = context.dataset.backgroundColor;

              return {
                borderColor: color,
                backgroundColor: color,
                borderWidth: 10,
                borderDash: [2, 2],
                borderRadius: 2,
              };
            }
          }
        }
      }
    };

    if (this.chart) {
      this.chart.data.datasets = datasets;

      this.chart.update();
    } else {
      let maxYaxis = 0;
      this.chart = initMultipleLinesChart(this.chartElementContainer, datasets, this.xAxisData, options, [{
        afterUpdate: chart => {
          const ctx = chart.ctx;
          const { chartArea: { top, right, bottom, left, width, height }, scales: { x, y } } = chart;
          const yAxisIndexZero = y.ticks.findIndex((e) => e.value == 0)
          const yAxisMaxVal = y.ticks[yAxisIndexZero + 1].value;
          const datasets = chart.data.datasets;
          if (isRenderedIcon && maxYaxis === yAxisMaxVal) return;

          if (isRenderedIcon && maxYaxis !== yAxisMaxVal) {
            let countIcons = datasets.filter((e) => e.label === 'icons 2').length;

            if (countIcons <= 0) {
              this.triggerCreateIcons(datasets, yAxisMaxVal);
            }

            maxYaxis = yAxisMaxVal;
            return;
          }
          this.triggerCreateIcons(datasets, yAxisMaxVal);
          maxYaxis = yAxisMaxVal;
          isRenderedIcon = true;
          chart.update();
        }
      }]);
    }
  }

  triggerCreateIcons(datasets, yAxisMaxVal) {
    const data = [];
    this.triggerRenderIcons(data, yAxisMaxVal);
    data.forEach((d) => {
      datasets.push(d);
    })
  }

  triggerRenderIcons(datasets, maxYaxis) {
    for (let i = 1; i < this.datasetIconLength; i++) {
      let data = Array(this.dataForIcons.length).fill(null);
      let values = Array(this.dataForIcons.length).fill(null);
      const icon = this.dataForIcons.map((e, idx) => {
        if (e == null || e[i] == null) return null;
        data[idx] = maxYaxis * i;
        values[idx] = 0;
        return e[i]['icon'];
      })

      datasets.push(
        {
          label: 'icons 2',
          type: 'line',
          data: data,
          fill: false,
          order: 1,
          pointStyle: function (context) {
            let tmp = new Image(25, 25);
            if (icon[context.dataIndex] === null) return;
            tmp.src = imagePath(`./icons/retirement/${icon[context.dataIndex]}.svg`);

            return tmp;
          },
          pointRadius: 22,
          pointHoverRadius: 22,
          values: values
        }
      );
    }
  }

  buildChartDatasets() {
    let datasets = [];
    const {
      data_for_amount_saved: dataForMountSavedLine,
      data_for_member_contribution: dataForMemberContrinutionLine,
      data_for_client_contribution: dataForClientContrinutionLine,
      data_for_simulated_investment: dataForSimulatedInvestmentLine
    } = this.chartLinesData;

    const pointStyle = this.isLifeTimeView ? null : this.pointImageSource();
    const pointRadius = this.isLifeTimeView ? 0 : 5;

    datasets.push(this.initFirstDatasetIcons());

    if (dataForSimulatedInvestmentLine) {
      datasets.push({
        label: `With Simulated Investment`,
        data: dataForSimulatedInvestmentLine,
        borderColor: RETIREMENT_SIMULATED_BODER_COLOR[1],
        backgroundColor: RETIREMENT_SIMULATED_BODER_COLOR[1],
        borderDash: [10, 10],
        borderWidth: 4,
        borderCapStyle: 'round',
        pointRadius: pointRadius,
        order: 2,
      })
    }

    datasets.push({
      label: 'Amount Saved',
      data: dataForMountSavedLine.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      backgroundColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      order: 2,
    })

    datasets.push({
      label: `${this.clientName}'s contribution`,
      data: dataForClientContrinutionLine.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: SHARED_DREAM_CLIENT_CONTRIBUTION_LINE,
      backgroundColor: SHARED_DREAM_CLIENT_CONTRIBUTION_LINE,
      order: 2,
    })

    datasets.push({
      label: `${this.sharedMemberName}'s contribution`,
      data: dataForMemberContrinutionLine.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: SHARED_DREAM_MEMBER_CONTRIBUTION_LINE,
      backgroundColor: SHARED_DREAM_MEMBER_CONTRIBUTION_LINE,
      order: 2,
    })

    return datasets;
  }

  buildDataForChartRequest() {
    return {
      range_start_age: this.currentRangeStartAge,
      presenting_dream_ids: this.getAllPresentingDreamIds(),
      is_lifetime_view: this.isLifeTimeView,
      member_id: this.sharedMemberId
    }
  }
}
