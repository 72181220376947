export default class EkycForm {
  constructor() {
    this.ekcyTypeSelector = '#ekyc-type';
    this.selectAllBtn = '.select-all-button';
    this.deSelectAllBtn = '.deselect-all-button';
    this.ekycDataGroup = '.ekyc-data-group';
    this.applicantDataCheckBox = '.applicant-data-checkbox';
    this.mainApplicantWrapper = '.main-applicant-wrapper';
    this.mainApplicantDataField = '#main-applicant-data';
    this.mainApplicantDataForm = '.main-applicant-data-form';
    this.coApplicantDataForm = '.co-applicant-data-form';
    this.coApplicantFields = '.co-applicant-fields';
    this.coApplicantDataField = '#co-applicant-data';

    this.coApplicantSelector = '#co-applicant';
    this.coApplicantLabel = '.co-applicant-label';
    this.coApplicantInput = '.co-applicant-input';
    this.coApplicantWrapper = '.co-applicant-wrapper';
    this.dependantsWrapper = '.dependants-wrapper';
    this.addCoApplicantSelector = '#add-new-co-applicant';
    this.removeCoApplicantSelector = '#remove-co-applicant';

    this.coApplicantInfo = '.co-applicant-info';
    this.coApplicantId = '#co-applicant-id';
    this.removeDependantSelector = '.remove-dependant';

    this.applicantTypeSelector = '.applicant-type';
    this.applicantOptionsSelector = '.applicant-options';

    this.nricInputCoApplicant = '.nric-input-co-applicant';

    this.peopleListDataSelector = '.people-list-options';
    this.mainFamlyMembersDataSelector = '.main-family-members-options';
    this.famlyMembersDataSelector = '.family-members-options';
    this.peopleListDataNricSelect = '.people-list-nric-options';
    this.mainFamlyMembersNricSelector = '.main-family-members-nric-options';

    this.setupEkycForm();
  }

  handleEkycTypeChange() {
    this.toggleDataWithEkycType($(this.ekcyTypeSelector).val());

    $(this.ekcyTypeSelector).on('change', (e) => {
      this.toggleDataWithEkycType($(e.target).val());
    });
  }

  toggleDataWithEkycType(ekycType) {
    this.showCoApplicant();
    $(this.dependantsWrapper).show();
    if (ekycType == 'full_ekyc') {
      $(this.mainApplicantWrapper).show();
      $(this.coApplicantDataForm).show();
    } else {
      $(this.mainApplicantWrapper).hide();
      $(this.coApplicantDataForm).hide();

      if (ekycType == 'csl_isp') {
        this.hideCoApplicant();
        $(this.coApplicantSelector).prop('checked', false);
        $(this.removeCoApplicantSelector).trigger('click');
      }

      if (ekycType == 'cka_risk_profile' || ekycType == 'client_transfer') {
        $(this.dependantsWrapper).hide();
        this.removeAllDependants();
      }
    }
  }

  toggleAllCheckBox(selector, isChecked) {
    const parentGroup = $(selector).closest(this.ekycDataGroup);
    parentGroup.find(this.applicantDataCheckBox).prop('checked', isChecked);
  }

  toggleCoApplicant() {
    $(this.coApplicantSelector).on('change', () => {
      $(this.applicantTypeSelector).trigger('change');
      if ($(this.coApplicantSelector).is(':checked')) {
        $(this.addCoApplicantSelector).trigger('click');
        $(this.coApplicantWrapper).removeClass('d-none');
      } else {
        $(this.removeCoApplicantSelector).trigger('click');
        $(this.coApplicantWrapper).addClass('d-none');
      }
    });
  }

  removeAllDependants() {
    $(this.removeDependantSelector).trigger('click');
  }

  showCoApplicant() {
    $(this.coApplicantFields).show();
    $(this.coApplicantLabel).show();
    $(this.coApplicantInput).show();
  }

  hideCoApplicant() {
    $(this.coApplicantFields).hide();
    $(this.coApplicantLabel).hide();
    $(this.coApplicantInput).hide();
  }

  updateCoApplicantDataGroup(groupSelector, data) {
    const count = data.length;
    $(groupSelector).find('.row').remove();
    $(groupSelector).closest('.ekyc-group').find('.group-count').text(`(${count})`);
    data.forEach((row) => {
      let recordInfo = `${row['class']}-${row['id']}`;
      let recordName = row['name'];
      let recordValue = row['value'];
      let html_row = `<div class="row mt-3">
                        <div class="col-12">
                          <div class="custom-col-ekyc">
                            <div class="input-value">
                              <input checked="true" class="form-check-input boolean optional checkbox-main applicant-data-checkbox" data-record-info=${recordInfo} type="checkbox">
                              <label class="form-check-label boolean optional font-weight-400-text font-size-16-text" for="main-applicant-1-0">${recordName}</label>
                              </div><p class="font-weight-400-text font-size-16-text">${recordValue}</p></div>
                            </div>
                          </div>
                        </div>
                      </div>`;
      $(groupSelector).append(html_row);
    });
    this.buildApplicantData(this.coApplicantDataForm, this.coApplicantDataField);
  }

  handleCheckBoxAddedByJquery() {
    $('.input-value').on('click', (e) => {
      if ($(e.target).closest(this.coApplicantWrapper).length > 0) {
        const checkbox = $(e.target).closest('.input-value').find('input');
        if ($(checkbox).is(':checked')) {
          $(checkbox).prop('checked', false);
        } else {
          $(checkbox).prop('checked', true);
        }
      }
    });
  }

  reloadCoApplicantData() {
    const coApplicantId = $(this.coApplicantId).val();
    const mainApplicantId = $(this.mainApplicantDataForm).data('main-applicant-id');
    let reloadUrl = Routes.reload_co_applicant_consultant_ekycs_path({ client_id: mainApplicantId });
    if ($('#co-applicant:input:checkbox').is(':checked')) {
      reloadUrl = reloadUrl + `&co_applicant_id=${coApplicantId}`;
    }
    const mainFamilyMembers = $(this.mainFamlyMembersDataSelector).data('family-members');

    $.ajax({
      url: reloadUrl,
      type: 'get',
      success: (data) => {
        this.updateCoApplicantDataGroup('#co-applicant-1', data['assets_and_liabilities']);
        this.updateCoApplicantDataGroup('#co-applicant-2', data['incomes_and_expenses']);
        this.updateCoApplicantDataGroup('#co-applicant-3', data['policies']);
        this.handleCheckBoxAddedByJquery();

        $(this.famlyMembersDataSelector).data('family-members', data['family_members'].concat(mainFamilyMembers));
      },
    });
  }

  handleOnChangeCoApplicant() {
    $(this.coApplicantFields).on('cocoon:after-insert', () => {
      this.handleCoApplicantIsFamilyMember();

      $(this.coApplicantInfo).on('change', () => {
        this.removeAllDependants();
        this.reloadCoApplicantData();
        this.buildApplicantData(this.coApplicantDataForm, this.coApplicantDataField);
        this.handleDataForHiddenFields();
        this.setFullNameAsNric();
      });
    });

    $(this.coApplicantFields).on('cocoon:after-remove', () => {
      this.removeAllDependants();
    });
  }

  buildApplicantData(dataSelector, formFieldSelector, forced_build = false) {
    if ($(dataSelector).is(':hidden') && !forced_build) {
      return;
    }

    let result = [];
    const dataGroup = $(dataSelector).find(this.applicantDataCheckBox);
    dataGroup.toArray().forEach((e) => {
      if ($(e).is(':checked')) {
        result.push($(e).data('record-info'));
      }
    });

    $(formFieldSelector).val(result);
  }

  handleDataForHiddenFields() {
    this.buildApplicantData(this.mainApplicantDataForm, this.mainApplicantDataField, true);

    $(this.applicantDataCheckBox).on('change', () => {
      this.buildApplicantData(this.mainApplicantDataForm, this.mainApplicantDataField);
      this.buildApplicantData(this.coApplicantDataForm, this.coApplicantDataField);
    });
  }

  setNewOptionsDropdown(dropdownSelector, options) {
    $(dropdownSelector).find('option').remove();
    options.forEach((option) => {
      $(dropdownSelector).append(`<option value=${option[1]}>${option[0]}</option>`);
    });

    const firstElement = $(dropdownSelector).find('option')[0];
    $(firstElement).attr('selected', 'selected');
  }

  handleApplicantTypeChange() {
    $(this.applicantTypeSelector).on('change', (e) => {
      const applicantType = $(e.target).val();
      const groupParent = $(e.target).closest('.nested-fields');
      const applicantOptionsSelector = $(groupParent).find(this.applicantOptionsSelector);
      let options = [];

      if (applicantType == 'FamilyMember') {
        options = $(this.mainFamlyMembersDataSelector).data('family-members');
      } else {
        options = $(this.peopleListDataSelector).data('people-list');
      }

      if ($(applicantOptionsSelector).closest(this.coApplicantFields).length > 0) {
        this.setNewOptionsDropdown(applicantOptionsSelector, options);
        $(this.coApplicantInfo).trigger('change');
      } else if ($(applicantOptionsSelector).closest(this.dependantsWrapper).length > 0) {
        this.setNewOptionsDropdown(applicantOptionsSelector, options);
      }
    });
  }

  toggleCoApplicantData(coApplicantType) {
    if (coApplicantType == 'FamilyMember') {
      $(this.coApplicantWrapper).hide();
    } else {
      $(this.coApplicantWrapper).show();
    }
  }

  handleCoApplicantIsFamilyMember() {
    this.toggleCoApplicantData($(`${this.coApplicantFields} ${this.applicantTypeSelector}`).val());

    $(`${this.coApplicantFields} ${this.applicantTypeSelector}`).on('change', (e) => {
      this.toggleCoApplicantData($(e.target).val());
    });
  }

  handleDependantAppended() {
    $(this.dependantsWrapper).on('cocoon:after-insert', () => {
      const theLastDependant = $(`${this.dependantsWrapper} .nested-fields ${this.applicantOptionsSelector}`).last();
      const famliyMemberOptions = $(this.famlyMembersDataSelector).data('family-members');

      this.setNewOptionsDropdown(theLastDependant, famliyMemberOptions);
    });
  }

  setupEkycForm() {
    $(this.selectAllBtn).on('click', (e) => {
      this.toggleAllCheckBox(e.target, true);
    });

    $(this.deSelectAllBtn).on('click', (e) => {
      this.toggleAllCheckBox(e.target, false);
    });

    this.handleEkycTypeChange();
    this.toggleCoApplicant();
    this.handleDataForHiddenFields();
    this.handleApplicantTypeChange();
    $('body').on('cocoon:after-insert', () => {
      this.handleApplicantTypeChange();
    });

    this.handleOnChangeCoApplicant();
    this.handleDependantAppended();
  }

  setFullNameAsNric() {
    const coApplicantId = $(this.coApplicantId).val();
    const coApplicantType = $(`${this.coApplicantFields} ${this.applicantTypeSelector}`).val();
    const listPeopleNric = $(this.peopleListDataNricSelect).data('people-list');
    const listFamilyNric = $(this.mainFamlyMembersNricSelector).data('family-members');
    let fullnameAsNric = '';

    if (coApplicantType === 'FamilyMember') {
      listFamilyNric.forEach((coAppID) => {
        if (coAppID[0] == coApplicantId) {
          fullnameAsNric = coAppID[1];
        }
      });
    } else {
      listPeopleNric.forEach((coAppID) => {
        if (coAppID[0] == coApplicantId) {
          fullnameAsNric = coAppID[1];
        }
      });
    }

    $(this.nricInputCoApplicant).val(fullnameAsNric);
  }
}
