import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import changeParamenterByTab from "@/layouts/application/utils/changeParameterByTab";

class ClientsNotificationsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
    changeParamenterByTab('', 'active_tab');
  }

  message() {
    handleSideMenu();
  }

  show() {
    handleSideMenu();
  }
}

Raicon.register('clients/notifications', ClientsNotificationsController);
