import calculateTartgetYearBaseOnStartDate from '../shared/calculateTargetYearBaseOnStartDate';
import { formatCurrency } from '@/layouts/application/utils/formats';

import { calculateHouseAssetMortgageTotalMonthlyRepayment } from './assetCalculator';
import { DEFAULT_CURRENCY } from '@/constants';

export default class LoanForm {
  constructor() {
    this.outstandingDurationInputSelector = '#outstanding-duration-input';
    this.monthlyOutstandingDurationInputSelector = '#monthly-outstanding-duration-input';
    this.outstandingAmountInputSelector = '#outstanding-amount';
    this.outstandingRateInputSelector = '#interest-rate';
    this.startYearSelector = '#start-date';
    this.monthlyRepaymentAmountSelector = '#liability-monthly-repayment-amount';
    this.targetYearValueSelector = '#target-year-value';
    this.formSelector = '#loan-liability-form';
    this.totalCpfRepayment = '#total-cpf-repayment-input';
    this.totalCashPayment = '#liability-cpf-monthly-repayment-amount';
    this.paymentMethod = 'input[name="liability[payment_method]"]:checked';
    this.recurringInputForm = '.recurring-payment-input';
    this.cpfRepaymentText = '#cpf-repayment-text';
    this.includedThisMonth = '#included-this-month-input';
  }

  setup() {
    this.calculateMonthlyRepayment();
    this.changePaymentMethod();
    this.toggleIncludedThisMonth();

    calculateTartgetYearBaseOnStartDate(
      this.startYearSelector,
      this.outstandingDurationInputSelector,
      this.targetYearValueSelector,
      this.monthlyOutstandingDurationInputSelector,
    );
  }

  calculateMonthlyRepayment() {
    this.syncMortgageRepaymentAmounts();

    $(this.formSelector).on(
      'input',
      `${this.outstandingAmountInputSelector}, ${this.outstandingDurationInputSelector}, ${this.monthlyOutstandingDurationInputSelector}, ${this.startYearSelector}, ${this.outstandingRateInputSelector}, ${this.totalCpfRepayment}`,
      () => {
        this.syncMortgageRepaymentAmounts();
      },
    );
  }

  changePaymentMethod() {
    this.changeFormPaymentMethod();

    $(this.formSelector).on('change', `${this.paymentMethod}`, () => {
      this.changeFormPaymentMethod();
    });
  }

  changeFormPaymentMethod() {
    const paymentMethod = $(this.paymentMethod).val();

    if (paymentMethod === 'lump_sum_payment') {
      $(this.recurringInputForm).addClass('d-none');
      $(this.cpfRepaymentText).text('Your cash payment is');
    } else {
      $(this.recurringInputForm).removeClass('d-none');
      $(this.cpfRepaymentText).text('Your cash monthly repayment is');
    }

    this.syncMortgageRepaymentAmounts();
  }

  toggleIncludedThisMonth() {
    const currentDate = new Date();
    let date = new Date($(this.startYearSelector).val());
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if ((month < currentDate.getMonth() + 1 && year == currentDate.getFullYear()) || year < currentDate.getFullYear()) {
      $(this.includedThisMonth).removeClass('d-none');
    } else {
      $(this.includedThisMonth).addClass('d-none');
    }

    $(document).on('change', `${this.startYearSelector}`, (e) => {
      date = new Date($(this.startYearSelector).val());
      month = date.getMonth() + 1;
      year = date.getFullYear();

      if (
        (month < currentDate.getMonth() + 1 && year == currentDate.getFullYear()) ||
        year < currentDate.getFullYear()
      ) {
        $(this.includedThisMonth).removeClass('d-none');
      } else {
        $(this.includedThisMonth).addClass('d-none');
      }
    });
  }

  syncMortgageRepaymentAmounts() {
    const loanAmount = this.getLoanAmount();
    const loanRate = this.getLoanRate();
    const mortgageDuration = this.getMortgageDuration();
    const extraMonth = this.getMonthlyOutStandingDuration();
    const paymentMethod = $(this.paymentMethod).val();
    const cpfRepayment = this.getTotalCpfRepayment();
    let totalCashPayment = 0;

    const mortgageTotalMonthlyRepaymentAmount =
      mortgageDuration !== 0 || extraMonth !== 0
        ? calculateHouseAssetMortgageTotalMonthlyRepayment(loanAmount, loanRate, mortgageDuration, extraMonth)
        : 0.0;

    if (paymentMethod === 'lump_sum_payment') {
      totalCashPayment = loanAmount - cpfRepayment;
    } else {
      totalCashPayment = mortgageTotalMonthlyRepaymentAmount - cpfRepayment;
    }
    let currency = $('#currency-select').val() || DEFAULT_CURRENCY;
    $(this.totalCashPayment).text(formatCurrency(totalCashPayment, currency));
    $(this.monthlyRepaymentAmountSelector).text(formatCurrency(mortgageTotalMonthlyRepaymentAmount, currency));
  }

  getLoanRate() {
    return (parseFloat($(this.outstandingRateInputSelector).val()) || 0) / 100;
  }

  getMortgageDuration() {
    return parseInt($(this.outstandingDurationInputSelector).val()) || 0;
  }

  getMonthlyOutStandingDuration() {
    return parseInt($(this.monthlyOutstandingDurationInputSelector).val()) || 0;
  }

  getLoanAmount() {
    return parseFloat($(this.outstandingAmountInputSelector).val().replaceAll(',', '')) || 0;
  }

  getTotalCpfRepayment() {
    return parseFloat($(this.totalCpfRepayment).val()) || 0;
  }

  getTotalCpfRepayment() {
    return parseFloat($(this.totalCpfRepayment).val()) || 0;
  }
}
