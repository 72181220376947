import AppointmentForm from '../../planners/appointmentForm';

export default class AppointmentActivities {
  constructor() {
    this.appointmentConferenceLink = '.conference-link';
  }

  setup() {
    this.addConferenceLinkOnClickEvent();
    this.setupAppointmentForm();
  }

  addConferenceLinkOnClickEvent() {
    $(this.appointmentConferenceLink).on('click', ({ target }) => {
      const conferenceLink = $(target).data('conference-link')

      window.open(conferenceLink);

      return false;
    })
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#open-edit-appointment, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }
}
