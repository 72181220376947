import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import { initSelect2 } from '@/layouts/application/packages/select2';

class AdminChallengesController {
  index() {
    this.initDropZone();
  }

  new() {
    this.setupCoocon();
    initSelect2();
  }

  create() {
    this.initSelectCustom();
    this.callbackCoocon();
    initSelect2();
  }

  edit() {
    this.callbackCoocon();
    this.initSelectCustom();
    initSelect2();
  }

  update() {
    this.initSelectCustom();
    this.callbackCoocon();
    initSelect2();
  }

  setupCoocon() {
    this.callbackCoocon();
    for (let i = 0; i < 3; i++) {
      $('#add-challenge-option').click();
    }
  }

  initSelectCustom() {
    $('.target-field').each(function (index, el) {
      const value = $(el).val();

      if (value == 'custom_target') {
        $(el).parents('.form-content').find('.custom-name-field').removeClass('d-none');
      } else {
        $(el).parents('.form-content').find('.custom-name-field').addClass('d-none');
      }

      $(el).on('change', (e) => {
        const value = e.target.value;

        if (value == 'custom_target') {
          $(el).parents('.form-content').find('.custom-name-field').removeClass('d-none');
        } else {
          $(el).parents('.form-content').find('.custom-name-field').addClass('d-none');
        }
      });
    });
  }

  callbackCoocon() {
    $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      $(insertedItem).find('.target-field').on('change', (e) => {
        const value = e.target.value;

        if (value == 'custom_target') {
          $(insertedItem).find('.custom-name-field').removeClass('d-none');
        } else {
          $(insertedItem).find('.custom-name-field').addClass('d-none');
        }
      });
    });
  }

  initDropZone() {
    new DropZoneForm({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-process-challenge-form',
      submitBtnSelector: '#submit-import-process-challenge',
      fileParamName: 'challenge_process[process_challenge_file]',
      importResultFormSelector: '#import-process-challenge-result-form',
      importBtnSelector: [
        '#import-process-challenge-form',
        '#import-process-challenge-button'
      ],
    }).setup();
  }
}

Raicon.register('admin/challenges', AdminChallengesController);
