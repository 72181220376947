// ======= Show errors if  password is not correct
// ======= open PDF new window
import { initFlatPickr } from '@/layouts/application/packages/flatpickr';

$(document).on('turbolinks:load', function () {
  setupFinancialReport();
});

const setupFinancialReport = () => {
  $('body').on('modal:afterSetContent', '#open-list-report', () => {
    openNewWindowReportDownload();
    addSortedTableToViewFile();
    // $('.copy-public-link-icon').on('click', ({ target }) => {
    //   const $tempInput = $("<textarea>");
    //   $("body").append($tempInput);
    //   $tempInput.val($(target).siblings('input.public-link-input').val()).trigger('select');
    //   document.execCommand("copy");
    //   $tempInput.remove();
    //   alert('Copied to clipboard!');
    // })
  });

  $('body')
    .off('click', '.copy-public-link-icon')
    .on('click', '.copy-public-link-icon', ({ target }) => {
      const $tempInput = $('<textarea>');
      $('body').append($tempInput);
      $tempInput.val($(target).siblings('input.public-link-input').val()).trigger('select');
      document.execCommand('copy');
      $tempInput.remove();
      alert('Copied to clipboard!');
    });

  $('body').on('click', '.copy-public-link-icon', ({ target }) => {
    const $tempInput = $('<textarea>');
    $('body').append($tempInput);
    $tempInput.val($(target).siblings('input.public-link-input').val()).trigger('select');
    document.execCommand('copy');
    $tempInput.remove();
    alert('Copied to clipboard!');
  });

  $('body').on('click', '.copy-public-link-icon', ({ target }) => {
    const $tempInput = $('<textarea>');
    $('body').append($tempInput);
    $tempInput.val($(target).siblings('input.public-link-input').val()).trigger('select');
    document.execCommand('copy');
    $tempInput.remove();
    alert('Copied to clipboard!');
  });

  $('body').on('modal:afterSetContent', '#open-new-report, #create-financial-report', () => {
    openNewWindowReportNew();
    addSortedTableToViewFile();
  });

  $('body').on('click', '.display-button', ({ target }) => {
    let reportID = $(target).data('report-id');
    let clientID = $('body').data('client-id');
    $.ajax({
      type: 'PUT',
      url: Routes.update_display_to_client_consultant_financial_reports_path({ client_id: clientID }),
      data: {
        id: reportID,
      },
      success: (data) => {
        $('#report-list-modal').html(data.html);
      },
    });
  });
};

const openNewWindowReportDownload = () => {
  $('#new_financial_report').on('ajax:complete', (event) => {
    const [xhr, status] = event.detail;
    if (status === 'OK') {
      window.open(JSON.parse(xhr.response).url);
    } else {
      $('#errorsReport').text(JSON.parse(xhr.response).message);
    }
  });
};

const openNewWindowReportNew = () => {
  createReportRequestHandler();
  addEventForToggleCheckboxes();
};

const addSortedTableToViewFile = () => {
  const inputSortedTable = $('#sorted-table-input');
  let sortedTable = {};
  let search = '_table';
  Object.keys(localStorage)
    .filter((key) => key.endsWith(search))
    .map((key) => {
      sortedTable[key] = localStorage.getItem(key);
    });

  inputSortedTable.val(JSON.stringify(sortedTable));
};

const createReportRequestHandler = () => {
  $('#create-financial-report').on('ajax:complete', (event) => {
    const [xhr] = event.detail;
    if (xhr.status == 200) {
      window.open(JSON.parse(xhr.response).url);
      $('#main-modal').modal('hide');
      let html_flash = '';
      html_flash += "<div class='alert alert-dismissible fade show alert-success'>";
      html_flash += " <span class='alert-inner--text'>";
      html_flash += JSON.parse(xhr.response).flash;
      html_flash += ' </span>';
      html_flash += " <button aria-label='Close' class='close' data-dismiss='alert' type='button'>";
      html_flash += "   <span aria-hidden='true'>&times;</span>";
      html_flash += ' </button>';
      html_flash += '</div>';
      $('.flash-customize-report').html(html_flash);
    } else {
      $('#modal-custom-financial-report').html(xhr.responseText);
      initFlatPickr('.datepicker');
      addEventForToggleCheckboxes();
    }
  });
};

const addEventForToggleCheckboxes = () => {
  const appointmentTypeCheckbox = '#appointment-type-checkbox';
  const appointmentTypeInputSelector = '#appointment-type-input';
  const focusTypeCheckboxSelector = '#focus-type-checkbox';
  const focusTypeInputSelector = '#areas-focus-input';
  const recommendationTypeCheckboxSelector = '#recommendation-type-checkbox';
  const recommendationTypeInputSelector = '#recommendation-input';
  const passwordTypeCheckboxSelector = '#password-type-checkbox';
  const passwordTypeInputSelector = '#password-input';

  handleToggleEvent(appointmentTypeCheckbox, appointmentTypeInputSelector);
  handleToggleEvent(focusTypeCheckboxSelector, focusTypeInputSelector);
  handleToggleEvent(recommendationTypeCheckboxSelector, recommendationTypeInputSelector);
  handleToggleEvent(passwordTypeCheckboxSelector, passwordTypeInputSelector);
};

const handleToggleEvent = (checkbox, inputSection) => {
  const $checkbox = $(checkbox);
  const $inputSection = $(inputSection);

  if ($checkbox.is(':checked')) {
    $inputSection.show();
  } else {
    $inputSection.hide();
  }

  $checkbox.on('change', ({ target }) => {
    if (target.checked) {
      $inputSection.show();
    } else {
      $inputSection.hide();
    }
  });
};
