import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:load', () => {
  $('body').on('click', '[data-role="link"]', (e) => {
    e.preventDefault();

    const $this = $(e.currentTarget);
    const location = $this.data('href');
    Turbolinks.visit(location);
  });
});
