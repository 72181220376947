import Raicon from 'raicon';
import { setupEditor } from '../shared/campaignTemplateUtil';
import { youtube_link_parser } from '@/layouts/application/utils/youtubeLink';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';

const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);

$(document).on('click', '.toggle-password', ({ target }) => {
  const $passwordInput = $(target).parent().find(':input');
  if ($passwordInput.attr('type') === 'password') {
    $passwordInput.attr('type', 'text');
    $(target).addClass('show-pass');
    $(target).removeClass('hidden-pass');
  } else {
    $passwordInput.attr('type', 'password');
    $(target).addClass('hidden-pass');
    $(target).removeClass('show-pass');
  }
});

class ConsultantSettingsController {
  constructor() {
    this.imageSelector = '.image-file-input';
    this.imagePreviewSelector = '.preview-input-file';
    this.selectBookmarksSelector = '#bookmarks-input';
  }

  edit() {
    changeParamenterByTab('', 'tab');
    this.checkCorrectPasswordLength();
    this.eventForRegenerateMerlinApiKey();
    this.eventForSubscribeWhatsappNumber();
    this.eventForAddSignature();
    this.eventForFeaturedVideoFcBrand();
    this.eventForUploadFileFcBrand();
    this.eventForUpdateContentCategory();
    this.handleImageChange();
    this.eventForTestimonialFcBrand();
    this.handleRemoveTestimonial();
    this.handleChangeYoutubeVideo();
    this.handleRemoveAttachement();
    this.updateContentCategoryModal();
  }

  updatePassword() {
    this.checkCorrectPasswordLength();
  }

  updateContentCategoryModal() {
    $('body').on('modal:afterSetContent', '#btn-update-content-category, #audience-category-modal', () => {
      this.handleGroupSummaryChecked();
      this.handleAudienceToggle();
      this.fillDataSelectAudience();
      this.handleAudienceSubmitForm();
    });
  }

  handleRemoveAttachement() {
    $('.remove-attachment').on('click', ({ target }) => {
      const targetSelector = $(target);

      if (confirm("It's will delete your resource intermediate without submit. Are you sure?") == true) {
        if (typeof targetSelector.data('attachment-id') == 'undefined') {
          targetSelector.closest('.file-input-wrapper').find(this.imagePreviewSelector).addClass('d-none');
          targetSelector.closest('.file-input-wrapper').find(this.imageSelector).val('');
          return;
        }

        const blobId = targetSelector.data('attachment-id');
        $.ajax({
          url: Routes.destroy_signed_upload_file_path(blobId),
          type: 'DELETE',
          dataType: 'json',
          success: function () {
            window.location.reload();
          },
        });
      }
    });
  }

  eventForFeaturedVideoFcBrand() {
    $('input[type="radio"][name="fc_brand[featured_video_custom]"]').on('change', ({ target }) => {
      const value = target.value;

      if (value == 'upload_a_new_video') {
        $('#upload-video').removeClass('d-none');
        $('#custom-video-url').addClass('d-none');
        $('.ytb-iframe').addClass('d-none');
        $('.video-preview').removeClass('d-none');
      } else {
        $('#upload-video').addClass('d-none');
        $('#custom-video-url').removeClass('d-none');
        $('.ytb-iframe').removeClass('d-none');
        $('.video-preview').addClass('d-none');
      }
    });
  }

  handleChangeYoutubeVideo() {
    $('#fc_brand_video_url').on('change', () => {
      $('.ytb-iframe')
        .find('iframe')
        .attr('src', youtube_link_parser($('#fc_brand_video_url').val()));
    });
  }

  eventForUploadFileFcBrand() {
    $('.upload-file-btn').on('click', ({ target }) => {
      $(target).closest('.mt-2').find('input[type="file"]').trigger('click');
    });
  }

  handleRemoveTestimonial() {
    Array.from($('.remove-testimonial')).forEach((item) => {
      $(item).on('click', ({ target }) => {
        const testimonialId = $(target).data('testimonial-id');

        if (confirm('Are you sure?') == true) {
          $.ajax({
            url: Routes.consultant_testimonial_path(testimonialId),
            type: 'DELETE',
            dataType: 'json',
            success: function () {
              $(`tr[data-testimonial-id=${testimonialId}]`).remove();
            },
          });
        }
      });
    });
  }

  handleImageChange() {
    $(this.imageSelector).on('change', ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).removeClass('d-none');
      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).attr('src', URL.createObjectURL(file));
    });
  }

  eventForTestimonialFcBrand() {
    Array.from($('.bookmark-btn')).forEach((item) => {
      $(item).on('click', ({ target }) => {
        const bookmarkSelector = $(target);
        const sourceableId = bookmarkSelector.data('bookmark-id');

        if (bookmarkSelector.hasClass('bookmarked')) {
          bookmarkSelector.attr('src', imagePath('./icons/bookmark.svg'));
          bookmarkSelector.removeClass('bookmarked');
          $(`${this.selectBookmarksSelector} option[value=${sourceableId}]`).removeAttr('selected');
          $(`tr[data-testimonial-id=${sourceableId}]`).appendTo('#testimonial-table');
        } else {
          bookmarkSelector.attr('src', imagePath('./icons/bookmark-blue.svg'));
          bookmarkSelector.addClass('bookmarked');
          $(`${this.selectBookmarksSelector} option[value=${sourceableId}]`).attr('selected', 'selected');
          $(`tr[data-testimonial-id=${sourceableId}]`).prependTo('#testimonial-table');
        }
      });
    });
  }

  checkCorrectPasswordLength() {
    const atLeastUpperCaseRegex = /.*?[A-Z]/;
    const atLeastNumRegex = /.*?[0-9]/;
    const atLeastSpecialRegex = /.*?[#?!@$%^&*-._\(\)]/;

    $('#user_password').on('keyup', function () {
      const $this = $(this);
      $('#leastCharacter').toggleClass('checked', $this.val().length >= 8);
      $('#leastCapital').toggleClass('checked', $this.val().match(atLeastUpperCaseRegex) !== null);
      $('#leastNumber').toggleClass('checked', $this.val().match(atLeastNumRegex) !== null);
      $('#leastSymbol').toggleClass('checked', $this.val().match(atLeastSpecialRegex) !== null);
    });
  }

  eventForAddSignature() {
    $('body').on('modal:afterSetContent', '#add-new-signature, #btn-edit-signature', () => {
      setupEditor();
    });
  }

  eventForUpdateContentCategory() {
    $('body').on('modal:afterSetContent', '#btn-update-content-category', () => {
      this.handleSelectAllBtn();
      this.handleDeselectAllBtn();
    });
  }

  eventForRegenerateMerlinApiKey() {
    $('#regenerate-merlin-api-key-button').on('click', () => {
      $.post(Routes.regenerate_merlin_api_key_consultant_settings_path(), (data) => {
        const apiKey = data.api_key;
        $('#merlin-api-key-input').val(apiKey);
      });
    });

    $('#copy-merlin-api-key-button').on('click', ({ target }) => {
      const $tempInput = $('<textarea>');
      $('body').append($tempInput);
      $tempInput.val($('#merlin-api-key-input').val()).trigger('select');
      document.execCommand('copy');
      $tempInput.remove();
      alert('Copied to clipboard!');
    });
  }

  eventForSubscribeWhatsappNumber() {
    $('#subscribe-whatsapp-information-button').on('click', () => {
      const phoneNumber = $('#phone-number-input').val();
      const countryCode = $('#country-code-input').val();
      const agreeReceiveNotifications = $('#agree-receive-notifications-input').is(':checked') ? '1' : '0';

      $.post(Routes.subscribe_whatsapp_number_consultant_settings_path(), {
        phone_number: phoneNumber,
        country_code: countryCode,
        agree_receive_notifications: agreeReceiveNotifications,
      });
    });
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $('.content-category-list input').prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $('.content-category-list input').prop('checked', false);
    });
  }

  handleGroupSummaryChecked() {
    $('.group-summary input').each(function() {
      let $parentDiv = $(this).closest('.group-summary');
      let $toggleBtn = $('.toggle-audience-btn', $parentDiv);

      if ($(this).prop('checked')) {
        $toggleBtn.show();
      } else {
        $toggleBtn.hide();
      }

      $(this).on('click', function() {
        if ($(this).prop('checked')) {
          $toggleBtn.show();
          if (!$toggleBtn.hasClass('collapsed') || $parentDiv.next('.audience-options').hasClass('show')) {
            $parentDiv.next('.audience-options').show();
          }
        } else {
          $toggleBtn.hide();
          $parentDiv.next('.audience-options').hide();
        }
      });
    });

    $('.group-summary button.toggle-audience-btn').each(function() {
      let $parentDiv = $(this).closest('.group-summary');
      $(this).on('click', function() {
        if ($(this).hasClass('collapsed')) {
          $parentDiv.next('.audience-options').show();
        } else {
          $parentDiv.next('.audience-options').hide();
        }
      });
    });
  }

  handleAudienceToggle() {
    $(".content-category-list .checkbox-audience").each(function() {
      $(this).find('.select2').on('change', function() {
        let selectedValue = $(this).val();
        let parentDiv = $(this).closest('.audience-options');
        let parentDivId = parentDiv.attr('id');

        if ($(this).closest('.content_category_audience_type_custom').length > 0) {
          $(`#${parentDivId} .contacts-type-options`).toggle(selectedValue === 'by_type');
          $(`#${parentDivId} .category-options`).toggle(selectedValue === 'by_rating');
          $(`#${parentDivId} .tags-options`).toggle(selectedValue === 'by_tags');
        }
      });
    });
  }

  fillDataSelectAudience() {
    $.ajax({
      url: Routes.get_audien_type_custom_consultant_settings_path(),
      type: 'get',
      success: (data) => {
        let consultantData = data['data'];
        $('.group-summary input:checked').each(function() {
          let categoryChecked = this;
          let checkboxAudienceDiv = $(categoryChecked).closest('.checkbox-audience');
          let selectedValue = $(checkboxAudienceDiv).find('.select2').val();
          let categoryName = $(checkboxAudienceDiv).find('.audience-options').attr('id');
          let audienceOptionDiv = $(checkboxAudienceDiv).find('.audience-options');

          switch (selectedValue) {
            case 'by_type':
              $(audienceOptionDiv).find('.contacts-type-options').show();

              consultantData.forEach(audience_type_data => {
                if (categoryName == audience_type_data['category']) {
                  audience_type_data['audience_type_data'].forEach(checked_data => {
                    $(`input#${checked_data}-${categoryName}`).prop('checked', true);
                    let style = $(`<style>label[for="${checked_data}-${categoryName}"]::after {}</style>`);
                    $('head').append(style);
                  });
                }
              });
              break;
            case 'by_rating':
              $(audienceOptionDiv).find('.category-options').show();

              consultantData.forEach(audience_type_data => {
                if (categoryName == audience_type_data['category']) {
                  audience_type_data['audience_type_data'].forEach(checked_data => {
                    $(`input#${checked_data}-star-${categoryName}`).prop('checked', true);
                    let style = $(`<style>label[for="${checked_data}-star-${categoryName}"]::after {}</style>`);
                    $('head').append(style);
                  });
                }
              });
              break;
            case 'by_tags':
              $(audienceOptionDiv).find('.tags-options').show();
            default:
              break;
          }
        });
      },
    });
  }

  handleAudienceSubmitForm() {
    $('body form.content_category').on('submit', (e) => {
      e.preventDefault();
      let categoryFormSubmit = { content_category: { categories: {} } };
      let categoryList = categoryFormSubmit['content_category']['categories'];

      $('.group-summary input:checked').each(function() {
        let categoryChecked = this;
        let checkboxAudienceDiv = $(categoryChecked).closest('.checkbox-audience');
        let selectedValue = $(checkboxAudienceDiv).find('.select2').val();
        let categoryName = $(checkboxAudienceDiv).find('.audience-options').attr('id');

        categoryList[categoryName] = {}
        categoryList[categoryName]['audience_type_data'] = []
        let categoryData = categoryList[categoryName]['audience_type_data']

        switch (selectedValue) {
          case 'by_type':
            let selectCategoryValue = $(checkboxAudienceDiv).find('.contacts-type-options input:checked');
            selectCategoryValue.each(function() {
              let typeId = $(this).attr('id').replace(`-${categoryName}`, '');
              categoryList[categoryName]['select_audience_type'] = selectedValue;
              categoryData.push(typeId);
            });
            break;
          case 'by_rating':
            let selectContactValue = $(checkboxAudienceDiv).find('.category-options input:checked');
            selectContactValue.each(function() {
              let ratingId = $(this).attr('id').replace(`-star-${categoryName}`, '');
              categoryList[categoryName]['select_audience_type'] = selectedValue;
              categoryData.push(ratingId);
            });
            break;
          case 'by_tags':
            categoryList[categoryName]['select_audience_type'] = selectedValue;
            $(`#${categoryName}`).find('.tags-options li').each(function() {
              let valueOption = $(this).text().replace('×', '');
              categoryData.push(valueOption);
            });
            break;
          default:
            categoryList[categoryName]['select_audience_type'] = selectedValue;
            categoryData.push(selectedValue);
            break;
        }
      });

      $.ajax({
        url: Routes.update_content_category_consultant_settings_path(),
        type: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(categoryFormSubmit),
        success: function(data) {
          if (data.success) {
            location.reload();
          } else {
            console.error('Update failed');
          }
        },
        error: function(xhr, status, error) {
          console.error('Error:', error);
        }
      });
    });
  }
}

Raicon.register('consultant/settings', ConsultantSettingsController);
