import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminShieldPremiumsController {
  index() {
    this.initDropZone();
    this.toggleSelectBox();
    this.triggerDestroyMultiple();
    this.updateShieldAndRiderOrder();
  }

  initDropZone() {
    const params = {
      formSelector: '#import-premium-table-form',
      submitBtnSelector: '#submit-import-premium-table',
      fileParamName: 'premium_table[import_premium_file]',
    };

    new DropZoneForm({ ...params, uploadMultiple: true, importBtnSelector: '#import-premium-table-button' }).setup();
    new DropZoneForm({ ...params, importBtnSelector: '#update-premium-table-button' }).setup();
  }

  toggleSelectBox() {
    $('.select-box').on('change', ({ target }) => {
      if ($(target).is(':checked')) {
        $('#destroy-multiple').removeClass('d-none');
      } else {
        if ($('.select-box:checked').length == 0) {
          $('#destroy-multiple').addClass('d-none');
        }
      }
    });
  }

  triggerDestroyMultiple() {
    $('#destroy-multiple').on('click', ({ target }) => {
      const url = $(target).attr('href');
      const ids = $.map($('.select-box:checked'), (item) => $(item).data('value'));
      $(target).attr('href', `${url}?ids=${ids.join(',')}`);
    });
  }

  updateShieldAndRiderOrder() {
    $('.order-input').on('change', ({ target }) => {
      const order = target.value;
      const id = $(target).data('premium-id');
      $.ajax({
        url: Routes.update_premium_order_admin_shield_premium_path(id),
        type: 'PUT',
        data: {
          order,
        },
      });
    });
  }
}

Raicon.register('admin/shieldPremiums', AdminShieldPremiumsController);
