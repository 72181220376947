import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class OverviewUploadDocumentForm extends DropZoneForm {
  constructor() {
    super({
      uploadMultiple: true,
      formSelector: '#uploaded-document-form',
      submitBtnSelector: '#submit-uploaded-documents',
      fileParamName: 'document[uploaded_documents]',
      acceptedFiles: ['image/png', '.jpg', 'application/pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.ppt', '.pptx']
    })

    this.invalidFeedbackSelector = '.invalid-feedback';
  }

  setup = () => {
    this.addPinAndUnpinEvent();

    $('body').on('modal:afterSetContent', '#btn-open-document-upload, #uploaded-document-form', () => {
      this.setupDropZone();
      this.handleSubmit();

      this.handleAddFilesError();
      this.handleAddFilesSuccess();
    });
  }

  addPinAndUnpinEvent = () => {
    $('body').on('ajax:success', '.btn-document-stick, .btn-document-unstick, .uploaded-document-remove', (event) => {
      $('#overview-uploaded-document-list').replaceWith(event.detail[2].responseText);
    });
  }

  handleAddFilesError = () => {
    this.dropzoneObject.on('error', (file, message) => {
      if (message.includes('File is too big')) {
        message = "Document size is too big. Try one that's 20MB and below"
      }

      if ($(this.invalidFeedbackSelector).length < 1) {
        $(this.uploaderAreaSelector).after("<div class='invalid-feedback'>" + message + "</div>");
        $(this.invalidFeedbackSelector).show();
      }

      this.dropzoneObject.removeFile(file);
    });
  }

  handleAddFilesSuccess = () => {
    this.dropzoneObject.on("addedfile", () => {
      $(this.invalidFeedbackSelector).remove();
    });
  }
}
