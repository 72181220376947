import { initDropzone } from '@/layouts/application/packages/dropzone';

export default class DropZoneForm {
  constructor({
    formSelector,
    fileParamName,
    overrideInput,
    importBtnSelector,
    submitBtnSelector,
    importResultFormSelector,
    isLoading = false,
    uploadMultiple = false,
    isValidateUpload = true,
    isFailedRecordsLink = false,
    acceptedFiles = '.csv',
    templateSelector = '#tpl',
    loadingBtnSelector = '#loading-button',
    previewsContainerSelector = '#file-preview',
    uploaderAreaSelector = '.file-uploader-area',
    failedRecordLinkSelector = '#download-failed-records-link',
  }) {
    this.isLoading = isLoading;
    this.dropzoneObject = undefined;
    this.formSelector = formSelector;
    this.overrideInput = overrideInput;
    this.fileParamName = fileParamName;
    this.uploadMultiple = uploadMultiple;
    this.isValidateUpload = isValidateUpload;
    this.templateSelector = templateSelector;
    this.submitBtnSelector = submitBtnSelector;
    this.loadingBtnSelector = loadingBtnSelector;
    this.isFailedRecordsLink = isFailedRecordsLink;
    this.uploaderAreaSelector = uploaderAreaSelector;
    this.failedRecordLinkSelector = failedRecordLinkSelector;
    this.importResultFormSelector = importResultFormSelector;
    this.importBtnSelector = this.convertArrayToString(importBtnSelector);
    this.acceptedFiles = this.convertArrayToString(acceptedFiles);
    this.previewsContainerSelector = previewsContainerSelector;
  }

  setup = () => {
    $('body').on('modal:afterSetContent', this.importBtnSelector, () => {
      this.setupDropZone();
      this.setupEventDropZone();
    })
  }

  setupDropZone = () => {
    if ($(this.formSelector).length < 1) return;

    const options = {
      formSelector: this.formSelector,
      acceptedFiles: this.acceptedFiles,
      uploadMultiple: this.uploadMultiple,
      templateSelector: this.templateSelector,
      previewsContainerSelector: this.previewsContainerSelector,
    };

    this.dropzoneObject = initDropzone(options);
    this.setupUploadMultiple();
  }

  setupEventDropZone = () => {
    this.handleSubmit();
    this.handleDownloadFailedRecords();
  }

  handleSubmit = () => {
    $(this.submitBtnSelector).on('click', () => {
      const $form = $(this.formSelector);
      const files = this.dropzoneObject.files;

      if (this.handleFileSelectError(files)) return false;

      const importFieldName = this.uploadMultiple ? `${this.fileParamName}[]` : this.fileParamName;
      const fileInput = $(`<input type="file" multiple="multiple" name="${importFieldName}" />`);

      fileInput[0].files = this.fileListItems(files);
      $(fileInput).hide();
      $form.append(fileInput);

      this.handleLoading(this.isLoading);
    });
  }

  handleDownloadFailedRecords = () => {
    if (!this.isFailedRecordsLink) return;

    $('body').on('click', this.failedRecordLinkSelector, () => {
      const $form = $(this.importResultFormSelector);
      $form.trigger('submit');
    });
  }

  handleFileSelectError = (files) => {
    if (files.length === 0 && this.isValidateUpload) {
      alert('Please select a file to upload');
      return true;
    }

    return false;
  }

  handleLoading = (isLoading) => {
    if (!isLoading) return;

    $(this.submitBtnSelector).addClass('d-none');
    $(this.loadingBtnSelector).removeClass('d-none');
  }

  fileListItems = (files) => {
    const clipboard = new ClipboardEvent('').clipboardData || new DataTransfer();
    files.forEach((file) => clipboard.items.add(file));

    return clipboard.files;
  }

  setupUploadMultiple = () => {
    if (this.uploadMultiple) return;

    const fileUploaderArea = $(this.uploaderAreaSelector);
    const overrideInput = $(this.overrideInput);

    this.dropzoneObject.on("addedfile", () => {
      fileUploaderArea.attr('style', 'display: none !important');
      overrideInput.removeClass('d-none');
    });

    this.dropzoneObject.on("removedfile", () => {
      fileUploaderArea.attr('style', '');
      overrideInput.addClass('d-none');
    })
  };

  convertArrayToString = (item) => {
    return Array.isArray(item) ? item.join(', ') : item;
  }

  redirectTo = (selector, path) => {
    $(selector).on('click', () => {
      Turbolinks.visit(path);
    })
  }
}
