import Raicon from 'raicon';
import AppointmentForm from './appointmentForm';
import CalendarDisplay from './calendarDisplay';
import InsurancePolicyForm from '../insurances/insurancePolicyForm';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { handleOverlayGuides } from '../overlayGuides';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';

import '../tasks/taskPage';
class ConsultantPlannersController {
  index() {
    this.setupAppointmentForm();
    this.setupInsurancePolicyForm();
    this.setupCalendar();
    this.setupCalculateAgeByBirthday();
    handleOverlayGuides();
    handleTrackClickBanner();
  }

  setupCalendar() {
    if ($('.consultant-calendar-page').length > 0) {
      new CalendarDisplay().setup();
    }
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form, #send-confirmation-email-form, #send-cancellation-email-form', () => {
      new AppointmentForm().setup();
    });
  }

  setupInsurancePolicyForm() {
    new InsurancePolicyForm().setup();
  }

  setupCalculateAgeByBirthday() {
    $('body').on('modal:afterSetContent', '#edit-referee-form', () => {
      calculateAgeByInput('#dob', '.input-age');
    });
  }
}

Raicon.register('consultant/planners', ConsultantPlannersController);
