import Chart from 'chart.js/auto';
import initMultipleLinesChart from '@/layouts/application/utils/charts/initMultipleLinesChart';
import { formatCurrency } from '@/layouts/application/utils/formats';
import SimulatedInvestmentForm from '../simulatedInvestmentForm';

import DreamChart from './dreamChart';
import {
  RETIREMENT_SIMULATED_BODER_COLOR,
  SOLO_DREAM_LINE
} from '@/layouts/application/utils/charts/chartColors';

export default class DreamSoloChart extends DreamChart {
  constructor(...args) {
    super(...args);
    this.rangeBackYearSelector = '.dream-solo-year-range .back-year-icon';
    this.rangeForwardYearSelector = '.dream-solo-year-range .forward-year-icon';
    this.rangeStartYearSelector = '.dream-solo-year-range .range-start-year';
    this.rangeEndYearSelector = '.dream-solo-year-range .range-end-year';
  }

  chart = undefined;
  validChart = true;
  initialSaving = undefined;
  currentTotalSaving = undefined;
  chartData = undefined;
  isLifeTimeView = false;

  setup() {
    this.setupChartRangeYear();
    this.setOnChangeViewType();
    this.setupChartValue();
    this.addEventForPresentDreamCheckbox();
    this.addEventForActivateSimulatedInvestmentCheckbox();
    this.setupSimulatedInvestments();
  }

  setupChartValue() {
    const chartData = this.chartElementContainer.data('chart-data');

    this.initialSaving = chartData.initial_saving;
    this.currentTotalSaving = chartData.current_total_saving;
    this.validChart = chartData.should_show_chart;

    this.buildXAxisData();
    this.presentDataToChart([]);
  }

  setupSimulatedInvestments() {
    new SimulatedInvestmentForm(this).setup();
  }

  presentDataToChart() {
    const data = {
      presenting_dream_ids: this.getAllPresentingDreamIds(),
      range_start_age: this.currentRangeStartAge,
      is_lifetime_view: this.isLifeTimeView
    };

    $.post(
      Routes.present_dreams_to_solo_saving_chart_consultant_client_dreams_path({ client_id: this.clientId }),
      { data: data },
      data => {
        this.chartData = data.chart_data;
        this.drawDreamSavingChart();
      },
    );
  }

  drawDreamSavingChart() {
    const datasets = this.buildChartDatasets();

    const options = {
      scales: {
        y: {
          grid: {
            color: function (context) {
              if (context.tick.value == 0) {
                return '#000000';
              } else {
                return Chart.defaults.borderColor
              }
            },
          },
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          borderRadius: 25,
          labels: {
            generateLabels: (chart) => {
              const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

              for (const item of items) {
                item.lineWidth = 0
                item.borderRadius = 5;
              }

              return items;
            }
          }
        },
        tooltip: {
          mode: 'index',
          position: 'nearest',
          backgroundColor: '#EEEEEE',
          padding: 15,
          titleColor: '#303030',
          titleFont: { weight: 'bold', size: 14 },
          bodyColor: '#303030',
          usePointStyle: true,
          callbacks: {
            label: (tooltipItems) => {
              const label = tooltipItems.dataset.label;
              return ` ${label}: ${formatCurrency(tooltipItems.raw)}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: 'circle',
                borderWidth: 10
              }
            },
            labelColor: function (context) {
              const color = context.dataset.backgroundColor;

              return {
                borderColor: color,
                backgroundColor: color,
                borderWidth: 10,
                borderDash: [2, 2],
                borderRadius: 2,
              };
            }
          }
        }
      }
    };

    if (this.chart) {
      this.chart.data.datasets = datasets;
      this.chart.data.datasets.forEach(dataset => {
        if (this.isLifeTimeView) {
          dataset.pointRadius = 2;
        }
      });

      this.chart.update();
    } else {
      this.chart = initMultipleLinesChart(this.chartElementContainer, datasets, this.xAxisData, options);
    }
  }

  buildChartDatasets() {
    const pointStyle = this.isLifeTimeView ? null : this.pointImageSource();

    const {
      solo_dream_line: dataForSoloDreamLine,
      simulated_investment_line: dataForSimulatedInvestmentLine
    } = this.chartData;

    const datasets = []

    datasets.push(
      {
        label: 'Total allocation and progress',
        data: dataForSoloDreamLine.map(e => parseFloat(e)),
        fill: false,
        borderColor: SOLO_DREAM_LINE,
        tension: 0.1,
        pointBackgroundColor: '#E8B012',
        backgroundColor: SOLO_DREAM_LINE,
        pointBorderColor: '#E8B012',
        pointBorderWidth: 4,
        pointStyle: pointStyle,
        pointRadius: 2,
        cubicInterpolationMode: 'monotone',
        responsive: true
      }
    )

    if (dataForSimulatedInvestmentLine) {
      const pointRadius = this.isLifeTimeView ? 0 : 5;

      datasets.push(
        {
          label: `With Simulated Investment`,
          data: dataForSimulatedInvestmentLine,
          borderColor: RETIREMENT_SIMULATED_BODER_COLOR[1],
          backgroundColor: RETIREMENT_SIMULATED_BODER_COLOR[1],
          borderDash: [10, 10],
          pointRadius: pointRadius,
          borderWidth: 4,
          borderCapStyle: 'round'
        }
      )
    }

    return datasets;
  }
}
