export default class formAutomationContent {
  setup() {
    $("a.add_fields").
      data("association-insertion-node", function(link) {
        return $('#step-block');
      });

    this.triggerTab();
    this.toggleStartProcess();
    this.toggleSelectTargetOptions();
    this.triggerChangeContentType();
    this.initStep();
  }

  triggerChangeContentType(){
    $('.step-block').on('change', 'input[type="radio"]', (e) => {
      const value = e.target.value;
      const content = $(e.target).parent().closest('.form-step').find('.tab-content');

      if(value === 'todo'){
        content.find('.task-block').addClass('active').addClass('show');
        content.find('.task-name').removeClass('d-none')
        content.find('.email-block').removeClass('active').removeClass('show');
      } else if ( value === 'to_call' ) {
        content.find('.task-block').addClass('active').addClass('show');
        content.find('.task-name').addClass('d-none')
        content.find('.email-block').removeClass('active').removeClass('show');
      } else {
        content.find('.email-block').addClass('active').addClass('show');
        content.find('.task-block').removeClass('active').removeClass('show')
      }
    })
  }

  initStep(){
    const stepCount = $('#add-step').data('step-count');

    $('.step-block').each((index, e) => {
      const _this = $(e);
      const value = _this.find('input[type="radio"]:checked').val();
      const content = _this;

      if(value === 'todo'){
        content.find('.task-block').addClass('active').addClass('show');
        content.find('.task-name').removeClass('d-none')
        content.find('.email-block').removeClass('active').removeClass('show');
      } else if ( value === 'to_call' ) {
        content.find('.task-block').addClass('active').addClass('show');
        content.find('.task-name').addClass('d-none')
        content.find('.email-block').removeClass('active').removeClass('show');
      } else {
        content.find('.email-block').addClass('active').addClass('show');
        content.find('.task-block').removeClass('active').removeClass('show')
      }
    })

    for(let i = 0; i < stepCount; i++){
      $('#add-step li:last').before(
        `<li class='nav-item'>
          <a class="btn-tab nav-link ${ i==0 ? 'active' : ''}" data-tab="step-${i}" data-toggle="pill" href="#step-${i}">
            Step ${i + 1}
          </a>
        </li>`
      )
    }
  }

  triggerTab() {
    $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      let count = $('#add-step').data('step-count');
      $('#add-step li:last').before(
        `<li class='nav-item'>
          <a class="btn-tab nav-link" data-tab="step-${count}" data-toggle="pill" href="#step-${count}">
            Step ${count + 1}
          </a>
        </li>`
      );

      $(insertedItem).closest('.tab-pane').attr('id', `step-${count}`)
      $(insertedItem).closest('.position-step').find('input').val(count);
      $(insertedItem).find('.task-todo-nav').attr('href', `#automation-target-model-task-todo-${count}`)
      $(insertedItem).find('.task-call-nav').attr('href', `#automation-target-model-task-call-${count}`)
      $(insertedItem).find('.email-nav').attr('href', `#automation-target-model-email-${count}`)
      $(insertedItem).find('#automation-target-model-task-todo-').attr('id', `automation-target-model-task-todo-${count}`)
      $(insertedItem).find('#automation-target-model-task-call-').attr('id', `automation-target-model-task-call-${count}`)
      $(insertedItem).find('#automation-target-model-email-').attr('id', `automation-target-model-email-${count}`)
      $(insertedItem).find('input[type="hidden"]:not([name*="_destroy"])').val(count);

      this.triggerChangeContentType();
      $('#add-step').data('step-count', count + 1);
    }).on('cocoon:before-remove', function(e, deletedElement) {
      const idStep = $(deletedElement).attr('id');

      $(`a[href="#${idStep}"]`).parent().remove();
      $(`a[href="#step-0"]`).click();
    }).on('cocoon:after-remove', () => {
      let count = $('#add-step').data('step-count');
      $('.btn-tab:not(:last)').each((index, e) => {
        const _this = $(e);
        const idStepBlock = _this.attr('href');
        _this.text(`Step ${index + 1}`)
        $(idStepBlock).find('input[type="hidden"]:not([name*="_destroy"])').val(index);
      })
      $('#add-step').data('step-count', count - 1);
    });
  }

  toggleStartProcess() {
    const checkBox = $('#only-newly-contact');
    const form = $('#start-process');
    this.toggleElementByCheckboxEvent(checkBox, form);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  toggleSelectTargetOptions() {
    const selectedTargetSelector = '#select-target';
    const customOptionsSelector = '#custom-option-block';
    const categoryOptionsSelector = '#category-option-block';
    const clientSelect = '#automation_client_ids';
    const newlyCheckbox = '#newly-check-box';
    const categorySelect = '.category-select';


    if($('input[name="automation[target]"]:checked').val() == 'category') {
      $(customOptionsSelector).hide();
      $(categoryOptionsSelector).show();
      $(clientSelect).val([]);
      $(clientSelect).trigger("change");
      $(newlyCheckbox).prop('checked', false);
    }
    else {
      $(customOptionsSelector).show();
      $(categoryOptionsSelector).hide();
      $(categorySelect).prop('checked', false);
    }

    $(selectedTargetSelector).on('change', (e) => {
      if(e.target.value == 'category') {
        $(customOptionsSelector).hide();
        $(categoryOptionsSelector).show();
        $(clientSelect).val([]);
        $(clientSelect).trigger("change");
        $(newlyCheckbox).prop('checked', false);
      }
      else {
        $(customOptionsSelector).show();
        $(categoryOptionsSelector).hide();
        $(categorySelect).prop('checked', false);
      }
    })
  }
}