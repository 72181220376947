import Chart from 'chart.js/auto';


const initDoughnutChart = (element, rawData = [], colors = [], cutout = 50) => {
  const data = {
    datasets: [
      {
        data: rawData,
        backgroundColor: colors
      },
    ],
  };
  return new Chart(element, {
    type: 'doughnut',
    data,
    options: {
      cutout
    }
  });
}

export default initDoughnutChart;
