import Raicon from 'raicon';
import FamilyMemberForm from './familyMemberForm';
import { add10Referees } from '../add10Referees';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';

class ConsultantFamilyController {
  index() {
    this.bioSetup();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    bulkDeleteItem('#bulk-delete-family-member', '.family-member-checkbox');
  }

  bioSetup() {
    new FamilyMemberForm().setup();
  }
}

Raicon.register('consultant/family', ConsultantFamilyController);
