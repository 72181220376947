export default function changeParamenterByTab(page = 'task', tabParams = 'tab', skipBlock = false) {
  $('.btn-tab').click(function (e) {
    if (skipBlock && $(this).data('page') !== page) {
      return;
    }

    const data = $(this).data('tab');
    let firstParameter = '';
    let secondParameter = '';
    let thirdParameter = '';
    let refresh = '';

    if (page == 'task') {
      firstParameter = decodeURIComponent(window.location.search.match(/(\?|&)status\=([^&]*)/)).split(',')[0];
      secondParameter = decodeURIComponent(window.location.search.match(/(\?|&)tab\=([^&]*)/)).split(',')[0];
    } else if (page == 'overview') {
      firstParameter = decodeURIComponent(window.location.search.match(/(\?|&)case_status\=([^&]*)/)).split(',')[0];
    } else if (page == 'dashboardInsight') {
      firstParameter = decodeURIComponent(window.location.search.match(/(^\?|&)insight_tab\=([^&]*)/)).split(',')[0];
      secondParameter = decodeURIComponent(window.location.search.match(/(^\?|&)focus_tab\=([^&]*)/)).split(',')[0];
      thirdParameter = decodeURIComponent(window.location.search.match(/(^\?|&)opportunity_tab\=([^&]*)/)).split(
        ',',
      )[0];
    } else if (page == 'dashboardFocus') {
      firstParameter = decodeURIComponent(window.location.search.match(/(^\?|&)insight_tab\=([^&]*)/)).split(',')[0];
      secondParameter = decodeURIComponent(window.location.search.match(/(^\?|&)focus_tab\=([^&]*)/)).split(',')[0];
      thirdParameter = decodeURIComponent(window.location.search.match(/(^\?|&)opportunity_tab\=([^&]*)/)).split(
        ',',
      )[0];
    } else if (page == 'dashboardOpportunity') {
      firstParameter = decodeURIComponent(window.location.search.match(/(^\?|&)insight_tab\=([^&]*)/)).split(',')[0];
      secondParameter = decodeURIComponent(window.location.search.match(/(^\?|&)focus_tab\=([^&]*)/)).split(',')[0];
      thirdParameter = decodeURIComponent(window.location.search.match(/(^\?|&)opportunity_tab\=([^&]*)/)).split(
        ',',
      )[0];
    } else if (page == 'people') {
      firstParameter = decodeURIComponent(window.location.search.match(/(^\?|&)people_tab\=([^&]*)/)).split(',')[0];
    }

    refresh = window.location.protocol + '//' + window.location.host + window.location.pathname + '?';
    refresh += `${tabParams}=${data}`;

    if (firstParameter !== 'null' && firstParameter !== '' && !firstParameter.includes(tabParams)) {
      refresh += `&${firstParameter.slice(1, firstParameter.length)}`;
    }

    if (secondParameter != 'null' && secondParameter !== '' && !secondParameter.includes(tabParams)) {
      refresh += `&${secondParameter.slice(1, secondParameter.length)}`;
    }

    if (thirdParameter !== 'null' && thirdParameter !== '' && !thirdParameter.includes(tabParams)) {
      refresh += `&${thirdParameter.slice(1, thirdParameter.length)}`;
    }

    if (page === 'people') {
      window.location.href = refresh;
      return;
    }

    window.history.pushState({ turbolinks: { path: refresh } }, '', refresh);
  });
}
