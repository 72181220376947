import Rails from '@rails/ujs';
import { initSelect2 } from './select2';
import { initFlatPickr } from './flatpickr';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';
import makeReferrerSelectClearable from '../../../controllers/consultant/shared/makeReferrerSelectClearable';
import handleMoneyField from '../utils/handleMoneyField';

const MODAL_ID = '#main-modal';
const MODAL_OPEN_BUTTON_ID = '#open-main-modal';
const PLATPICKR_WITHOUT_YEAR = '.flatpickr-without-year';
const MODAL_CLOSE_BUTTON_ID = '#close-main-modal';
const LOADING_ID = '#loading-page';

const AFTER_OPEN_TRIGGER_NAME = 'modal:afterOpen';
const AFTER_CLOSE_TRIGGER_NAME = 'modal:afterClose';
const AFTER_SET_CONTENT_TRIGGER_NAME = 'modal:afterSetContent';

class ActAsModal {
  eventSelector = 'a[data-modal="true"], form[data-modal="true"]';
  reinitSelector = '.reinit-container';

  event = null;
  eventListeners = {};

  constructor(modalId, openModalButtonId, closeModalButtonId) {
    this.modalId = modalId;
    this.openModalButtonId = openModalButtonId;
    this.closeModalButtonId = closeModalButtonId;

    this.attachModal();
  }

  setEvent(event) {
    this.event = event;
    this.collectEventListeners();
  }

  getEvent() {
    return this.event;
  }

  collectEventListeners() {
    this.eventListeners = [AFTER_OPEN_TRIGGER_NAME, AFTER_CLOSE_TRIGGER_NAME, AFTER_SET_CONTENT_TRIGGER_NAME].reduce(
      (memo, eventName) => ({
        ...memo,
        [eventName]: this.collectElementEventListeners(this.event.target, eventName),
      }),
      {},
    );
  }

  clearEventListeners() {
    this.eventListeners = {};
  }

  attachModal() {
    this.modal = $(this.modalId);
    this.modal.on('hidden.bs.modal', () => {
      this.clearModalContent();
      this.invokeTriggerEvent(AFTER_CLOSE_TRIGGER_NAME);
      this.clearEventListeners();
    });

    this.openModalButton = $(this.openModalButtonId);
    this.closeModalButton = $(this.closeModalButtonId);
  }

  isModalValid() {
    return !!this.modal.length && !!this.openModalButton.length && !!this.closeModalButton.length;
  }

  setModalContent(contentHtml) {
    this.modal.html(contentHtml);
    this.reinitModalContentElement();
    this.invokeTriggerEvent(AFTER_SET_CONTENT_TRIGGER_NAME);
  }

  clearModalContent() {
    this.modal.html('');
  }

  openModal() {
    if (this.modal.hasClass('show')) {
      return;
    }
    // Keep modal open when click outside
    this.modal.modal({
      backdrop: 'static',
      keyboard: false,
    });

    this.openModalButton[0].click();
    this.invokeTriggerEvent(AFTER_OPEN_TRIGGER_NAME);
    console.log('open');
    this.hidingLoading();
    makeReferrerSelectClearable();
  }

  closeModal() {
    if (!this.modal.hasClass('show')) {
      return;
    }
    this.closeModalButton[0].click();
    this.invokeTriggerEvent(AFTER_CLOSE_TRIGGER_NAME);
    this.clearEventListeners();
    this.hidingLoading();
  }

  hidingLoading() {
    $(LOADING_ID).addClass('d-none');
  }

  reinitModalContentElement() {
    const inModalContainer = `${this.modalId} ${this.reinitSelector}`;

    if (!$(inModalContainer).length) {
      return;
    }

    if (!this.onReinitModalContent && typeof this.onReinitModalContent !== 'function') {
      return;
    }
    this.hidingLoading();
    this.onReinitModalContent(inModalContainer);
  }

  invokeTriggerEvent(eventName) {
    const listeners = this.eventListeners[eventName] || [];

    for (const listener of listeners) {
      if (!listener.handler || typeof listener.handler !== 'function') {
        return;
      }
      listener.handler(this.event, this);
    }
  }

  collectElementEventListeners(target, eventName) {
    const selector = target.dataset.modalEventSelector || (target.id ? `#${target.id}` : null);
    const relatedElements = [
      { target: target, direct: true },
      { target: document, selector: selector, direct: false },
      { target: $('body')[0], selector: selector, direct: false },
    ];
    const eventListeners = [];

    for (const relatedElement of relatedElements) {
      if (!relatedElement.direct && !relatedElement.selector) {
        continue;
      }

      const events = $._data(relatedElement.target, 'events') || {};
      let listeners = events[eventName] || [];

      if (!relatedElement.direct) {
        listeners = listeners.filter(({ selector }) =>
          selector
            .split(',')
            .map((slt) => slt.trim())
            .includes(relatedElement.selector),
        );
      }
      eventListeners.push(...listeners);
    }
    return eventListeners;
  }
}

document.addEventListener('turbolinks:load', () => {
  const aam = new ActAsModal(MODAL_ID, MODAL_OPEN_BUTTON_ID, MODAL_CLOSE_BUTTON_ID);
  aam.onReinitModalContent = (containerSelector) => {
    const withoutYearOption = $(PLATPICKR_WITHOUT_YEAR).length > 0;
    initFlatPickr(`${containerSelector} .datepicker`, `${containerSelector} .datetimepicker`, withoutYearOption);

    initSelect2(`${containerSelector} .select2`, `${containerSelector} .select2-with-tags`);
    handleMoneyField();
    clearInputNumberOnFocus();

    if ($('[data-toggle="tooltip"]').length > 0) {
      $('[data-toggle="tooltip"]').tooltip();
    }
  };

  $('body')
    .on('ajax:success', aam.eventSelector, (event) => {
      aam.setEvent(event);
      const [_data, _status, xhr] = event.detail;

      if (!aam.isModalValid()) {
        console.log('[ActAsModal]: Can not find attached modal.');
        return;
      }

      if (xhr.responseURL.includes('sign_in')) {
        Turbolinks.visit(xhr.responseURL);
      }

      if (xhr.responseText.includes('Turbolinks.visit')) {
        Rails.disableElement(event.target);
        aam.closeModal();
        return;
      }

      aam.setModalContent(xhr.responseText);
      aam.openModal();
    })
    .on('ajax:error', aam.eventSelector, () => {
      console.log('[ActAsModal]: Unable to process the request.');
    });
});
