export default function calculateAgeByInput(inputDate, inputAge) {
  const ageSelector = $(inputAge);
  const inputDateSelector = $(inputDate);
  ageSelector.html(getAge(inputDateSelector.val()));
  $(inputDate).on('change', () => {
    ageSelector.html(getAge(inputDateSelector.val()));
  })
}

export const calculateYearByAgeInput = (inputAge, birthdayDateString) => {
  const today = new Date();
  return today.getFullYear() + (inputAge - getAge(birthdayDateString));
}

export const getAge = (birthdayDateString, today = new Date()) => {
  let birthdayDateStringSanitize = '';
  if (birthdayDateString?.toString()?.includes('/')) {
    birthdayDateStringSanitize = birthdayDateString.replace(/\//g, '-').split('-').reverse().join('-');
  } else {
    birthdayDateStringSanitize = birthdayDateString;
  }

  const birthDate = new Date(birthdayDateStringSanitize);
  const gapMonths = gapMonthsBetweenTodayAndDob(today, birthDate);

  let age = parseInt(gapMonths / 12);

  if (gapMonths % 12 >= 6) {
    age += 1;
  }

  return age;
};

export const convertYearByAge = (birthdayDateString, withMonth = true) => {
  const today = new Date();
  const birthDate = new Date(birthdayDateString);

  const gapMonths = gapMonthsBetweenTodayAndDob(today, birthDate);

  let year = birthDate.getFullYear();
  if (gapMonths % 12 >= 6 && withMonth) {
    year -= 1;
  }

  return year;
};

const gapMonthsBetweenTodayAndDob = (today, birthDate) => {
  const todayMonth = today.getMonth() + 1;
  const birthDateMonth = birthDate.getMonth() + 1;

  return (today.getFullYear() - birthDate.getFullYear()) * 12 + todayMonth - birthDateMonth - (today.getDate() >= birthDate.getDate() ? 0 : 1);
}
