import Raicon from 'raicon';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';

class AdminCompanyEventsController {
  index() {
    changeParamenterByTab('company_events', 'current_status')
  }
}

Raicon.register('admin/companyEvents', AdminCompanyEventsController);
