import { calculateYearByAgeInput } from '@/layouts/application/utils/calculateAgeByInput';

export default function updateYearByInput(inputAge, yearShow) {
  const yearShowSelector = $(yearShow);

  yearShowSelector.html(calculateYear(inputAge));
  $(inputAge).on('change', () => {
    yearShowSelector.html(calculateYear(inputAge));
  });
}

export const calculateYear = (inputAge) => {
  const ageInput = Number($(inputAge).val());
  const dateOfBirth = new Date($('#date-of-birth-data').data('dob-data'));

  return calculateYearByAgeInput(ageInput, dateOfBirth);
}
