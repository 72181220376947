export const securityText = (selector, type = '', showQuantity = 4) => {
  $(selector).each(function (_, e) {
    const currentInput = $(e);
    const currentText = currentInput.text();
    let replaceText = '';

    if (type === 'email') {
      const atIndex = currentText.lastIndexOf('@');
      const emailPart = currentText.substring(0, atIndex);
      replaceText = emailPart.length <= showQuantity
        ? '*'.repeat(emailPart.length)
        : '*'.repeat(emailPart.length - showQuantity) + currentText.substring(emailPart.length - showQuantity);
    } else {
      const inputTextLength = currentText.length;
      replaceText = inputTextLength <= showQuantity
        ? '*'.repeat(inputTextLength)
        : '*'.repeat(inputTextLength - showQuantity) + currentText.substring(inputTextLength - showQuantity);
    }

    currentInput.text(replaceText);
  });
}
