export const  toggleElementByCheckboxEvent = ($checkbox, $form) => {
  if ($checkbox.is(':checked')) {
    $form.show();
  } else {
    $form.hide();
  }

  $checkbox.on('change', (e) => {
    if ($(e.target).is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }
  });
}

export const toggleElementByCheckboxEventReverse = ($checkbox, $form) => {
  if ($checkbox.is(':checked')) {
    $form.hide();
  } else {
    $form.show();
  }

  $checkbox.on('change', (e) => {
    if ($checkbox.is(':checked')) {
      $form.hide();
    } else {
      $form.show();
    }  
  });
}
