import Raicon from 'raicon';

class DashboardController {
  index() {}

  unsubscribeEmail() {
    this.handleReasonChange();
    this.handleShowThankYouModal();
  }

  errorPage() {}

  createReason() {}

  handleReasonChange() {
    $('.reason-selection').on('change', (e) => {
      if (e.target.value == 'Other (fill in reason below)') {
        $('#reason-input').parent().removeClass('d-none');
        $('#reason-input').val('');
      } else {
        $('#reason-input').parent().addClass('d-none');
        $('#reason-input').val(e.target.value);
      }
    });
    if (!$('.reason-selection').is(':checked') && $('#thank-you-modal').data('is-success')) {
      $('.reason-selection').prop('checked', true);
      $('#reason-input').parent().removeClass('d-none');
    }
  }

  handleShowThankYouModal() {
    const thankyouModal = $('#thank-you-modal');
    const isSuccess = thankyouModal.data('is-success');
    if (isSuccess) {
      thankyouModal.modal('show');
    }
  }
}

Raicon.register('client/dashboard', DashboardController);
