// House Dream
const monthsOfYear = 12

export function calculateHouseDreamPaymentAmountWithCashPayment(value, fee) {
  return value + fee;
}

export function calculateHouseDreamPaymentAmountWithLoanPayment(downPayment, cpfDownPayment, fee) {
  const result = downPayment - cpfDownPayment + fee;

  return result > 0 ? result : 0
}

export function calculateHouseDreamMortgageOutstandingAmount(value, downPayment) {
  const result = value - downPayment;

  return result > 0 ? result : 0
}

export function calculateHouseDreamMortgageTotalMonthlyRepayment(principalAmount, yearlyRate, yearlyDuration, monthlyDuration = 0) {
  const totalMonthlyDuration = yearlyDuration * monthsOfYear + monthlyDuration;

  if (yearlyRate == 0) {
    return principalAmount / totalMonthlyDuration
  }

  const monthlyRate = yearlyRate / monthsOfYear;

  return (
    (principalAmount * (monthlyRate * Math.pow(1 + monthlyRate, totalMonthlyDuration))) /
    (Math.pow(1 + monthlyRate, totalMonthlyDuration) - 1)
  );
}

export function calculateHouseDreamMortgageCashMonthlyRepaymentAmount(totalMonthlyRepayment, cpfMonthlyRepayment) {
  const result = totalMonthlyRepayment - cpfMonthlyRepayment;

  return result > 0 ? result : 0
}

// Car Dream

export function calculateCarDreamLoanOutstandingAmount(value, downPayment) {
  const result = value - downPayment;

  return result > 0 ? result : 0
}

export function calculateCarDreamLoanTotalMonthlyRepayment(loanOutstandingAmount, yearlyRate, yearlyDuration, monthlyDuration = 0) {
  const totalMonthlyDuration = yearlyDuration * monthsOfYear + monthlyDuration;

  if (yearlyRate == 0) {
    return Math.round((loanOutstandingAmount / totalMonthlyDuration))
  }

  return Math.ceil(((loanOutstandingAmount * yearlyRate * totalMonthlyDuration / monthsOfYear) + loanOutstandingAmount) / totalMonthlyDuration);
}
