export default class events {
  constructor() {
    this.refWrappers = '.related-contact-selected-with-ref-link-wrapper';
  }

  setup() {
    /***
     * handle toggle show link: `Click here to see leads from related contact`
     */
    $.each($(this.refWrappers), (index, elm) => {
      const $select = $(elm).find('.related-contact-select').first();
      if ($select.hasClass('rendered')) {
        return;
      }
      $select
        .on('change', this.handleShowRelatedContactLink)
        .addClass('rendered')
        .change();
    })
  }

  handleShowRelatedContactLink = (e) => {
    const $this = $(e.currentTarget)
    const $refLink = $this.closest(this.refWrappers).find('.ref-link').first();
    const referrer_id = $this.val();
    if (referrer_id) {
      $refLink.attr('href', Routes.consultant_referees_path({filter: {referrer_id}}));
      this.deepShow($refLink);
    } else {
      $refLink.attr('href', '');
      this.deepHide($refLink);
    }
  }

  deepShow($refLink) {
    $refLink
      .removeClass('d-none')
      .addClass('d-block')
      .show();
  }

  deepHide($refLink) {
    $refLink
      .addClass('d-none')
      .removeClass('d-block')
      .hide();
  }
}