const googleBtnSelector = '#google-login-btn';
const facebookBtnSelector = '#facebook-login-btn';
const appleBtnSelector = '#apple-login-btn';
const socialLoginBtnSelector = '.social-login-btn';

const setupLoginWithSocialProviders = () => {
  const isWeb = navigator.userAgent.indexOf("median") < 0;

  if(isWeb) {
    $(googleBtnSelector).remove();
    $(facebookBtnSelector).remove();
  } else {
    $(googleBtnSelector).on('click', () => {
      median.socialLogin.google.login({
        'redirectUri' : socialLoginCallback('google'), 'scope' : 'public_profile, email'
      });
    });

    $(facebookBtnSelector).on('click', () => {
      median.socialLogin.facebook.login({
        'redirectUri' : socialLoginCallback('facebook'), 'scope' : 'public_profile, email'
      });
    });
  
    $(appleBtnSelector).on('click', () => {
      median.socialLogin.apple.login({
        'redirectUri' : socialLoginCallback('apple'), 'scope' : 'full_name, email'
      });
    });
  }
}

const socialLoginCallback = (provider) => {
  let callbackUrl = '';
  if(provider === 'google') {
    callbackUrl = `${window.location.origin}/users/auth/google_oauth2/callback`;
  } else if(provider === 'facebook') {
    callbackUrl = `${window.location.origin}/users/auth/facebook/callback`;
  } else if(provider === 'apple') {
    callbackUrl = `${window.location.origin}/users/auth/apple/callback`;
  }

  const username = $(socialLoginBtnSelector).data('username');
  if(username) {
    callbackUrl += `?username=${username}`
  }

  return callbackUrl
}

export default setupLoginWithSocialProviders;
