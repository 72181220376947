const images = require.context('@/images', true)
const imagePath = (name) => images(name, true);
const moment = require('moment');

export default class Instagram {
  constructor() {
    this.$igFeed = $('.ig-feed');
    this.$feedContent = $('.ig-feed .content');
    this.$loadMoreBtn = $('.ig-feed #ig-load-more-btn');
    this.$galleryImg = $('.gallery-img');
    this.$nextElm = undefined;
    this.$NewCurrent = undefined;
    this.$prevElm = undefined;
    this.$Current = undefined
    this.$productPopup = undefined;
  }

  setup() {
    if (this.$igFeed.length === 0) return;

    window.ig_medias = {};
    this.getFeeds().then(data => {
      this.bindFeeds(data, this.$feedContent, true);
      this.$loadMoreBtn.removeClass('d-none').show();
      this.initIGLightBox();
    });
  }

  initIGLightBox() {
    $(`
            <div class="product-gallery-popup">
                <a href="javascript:void(0);" class="cross"><img src="${imagePath(`./icons/ig/close.svg`)}"></a>
                <div class="popup-overlay"></div>
                <div class="gallery-nav-btns">
                    <a id="nav-btn-next" class="nav-btn next"></a>
                    <a id="nav-btn-prev" class="nav-btn prev"></a>
                </div>
                <div class="product-popup-content row">
                    <div class="product-image col-7">
                        <div class="player" id="IMAGE">
                            <img id="gallery-img" src="" alt="" />
                        </div>
                        <div class="player" id="CAROUSEL_ALBUM"></div>
                        <div class="gallery-nav-btns">
                            <a id="nav-btn-next-inner" class="nav-btn next"></a>
                            <a id="nav-btn-prev-inner" class="nav-btn prev"></a>
                        </div>
                        <div class="player embed-responsive embed-responsive-1by1" id="VIDEO"></div>
                    </div>
                    <div class="product-information col-5">
                        <div class="popup-header mt-3"></div>
                        <div class="product-desc-wrapper">
                            <p class="product-desc text-tertiary-color"></p>
                        </div>
                    </div>
                    <div class="clear"></div>

                </div>
            </div>`).appendTo('body');
    this.$productPopup = $('.product-gallery-popup');

    //on image click
    this.$igFeed.on('click', '.gallery-img', (e) => {
      const $this = $(e.currentTarget);
      const media_id = $this.attr('id');
      this.getMedia(media_id).then(data => {
        this.bindMedia(media_id);
        this.$productPopup.fadeIn(500);
        $('body').css({ 'overflow': 'hidden' });
        // find next and prev element
        this.$Current = $this;
        this.$prevElm = $this.prev();
        this.$nextElm = $this.next();
        this.handleNav(this.$prevElm, this.$nextElm);
        this.fixPopupUI();
      });
    });

    //on Next click
    this.$productPopup.on('click', '#nav-btn-next', (e) => {
      const media_id = this.$nextElm.attr('id');
      this.getMedia(media_id).then(data => {
        this.nextProduct();
      });
    });

    //on Prev click
    this.$productPopup.on('click', '#nav-btn-prev', (e) => {
      const media_id = this.$prevElm.attr('id');
      this.getMedia(media_id).then(data => {
        this.prevProduct();
      });
    });

    this.$productPopup.on('click', '#nav-btn-next-inner', (e) => {
      const $carouselAlbum = $('#CAROUSEL_ALBUM'),
        activeIndex = parseInt($carouselAlbum.data('active')),
        nextImgIndex = activeIndex + 1;
      $carouselAlbum.find('img').hide();
      $carouselAlbum.find('video').hide();
      $carouselAlbum.find('video').prop('muted', true)
      $carouselAlbum.find(`img:nth-child(${nextImgIndex + 1})`).show();
      $carouselAlbum.find(`video:nth-child(${nextImgIndex + 1})`).show();
      $carouselAlbum.find(`video:nth-child(${nextImgIndex + 1})`).prop('muted', false);
      $carouselAlbum.data('active', nextImgIndex);
      this.handleInnerNav();
    });
    this.$productPopup.on('click', '#nav-btn-prev-inner', (e) => {
      const $carouselAlbum = $('#CAROUSEL_ALBUM'),
        activeIndex = parseInt($carouselAlbum.data('active')),
        prevIndex = activeIndex - 1;
      $carouselAlbum.find('img').hide();
      $carouselAlbum.find('video').hide();
      $carouselAlbum.find('video').prop('muted', true)
      $carouselAlbum.find(`img:nth-child(${prevIndex + 1})`).show(); // nth-child index is starting at 1
      $carouselAlbum.find(`video:nth-child(${prevIndex + 1})`).show();
      $carouselAlbum.find(`video:nth-child(${prevIndex + 1})`).prop('muted', false);
      $carouselAlbum.data('active', prevIndex);
      this.handleInnerNav();
    });

    //Close Popup
    this.$productPopup.on('click', '.cross,.popup-overlay', (e) => {
      this.closePopup();
    });

    this.$loadMoreBtn.on('click', e => {
      this.getFeeds(true).then(data => this.bindFeeds(data, this.$feedContent, false));
    });

    //Key Events
    $(document).on('keyup', (e) => {
      e.preventDefault();
      //Close popup on esc
      if (e.keyCode === 27) {
        $('.product-gallery-popup').fadeOut(500);
        $('body').css({ 'overflow': 'initial' });
      }
      //Next Img On Right Arrow Click
      if (e.keyCode === 39) this.nextProduct();

      //Prev Img on Left Arrow Click
      if (e.keyCode === 37) this.prevProduct();
    });
  }

  closePopup() {
    $('.product-gallery-popup').fadeOut(500);
    $('video').prop('muted', true);
    $('body').css({ 'overflow': 'initial' });
  }

  handleInnerNav() {
    const $carouselAlbum = $('#CAROUSEL_ALBUM');
    const $prev = this.$productPopup.find('#nav-btn-prev-inner');
    const $next = this.$productPopup.find('#nav-btn-next-inner');
    const count = parseInt($carouselAlbum.data('count')),
      activeIndex = parseInt($carouselAlbum.data('active')),
      prevIndex = activeIndex - 1,
      nextIndex = activeIndex + 1;

    $prev.show();
    $next.show();
    if (prevIndex <= -1) $prev.hide();
    if (nextIndex >= count) $next.hide();
  }

  nextProduct() {
    if (this.$nextElm.length === 1) {
      this.$NewCurrent = this.$nextElm;
      this.$prevElm = this.$NewCurrent.prev();
      this.$nextElm = this.$NewCurrent.next();
      const media_id = this.$NewCurrent.attr('id');
      this.bindMedia(media_id);
      this.handleNav(this.$prevElm, this.$nextElm);
      this.fixPopupUI();
    }
  }


  prevProduct() {
    if (this.$prevElm.length === 1) {
      this.$NewCurrent = this.$prevElm;
      this.$prevElm = this.$NewCurrent.prev();
      this.$nextElm = this.$NewCurrent.next();
      const media_id = this.$NewCurrent.attr('id');
      this.bindMedia(media_id);
      this.handleNav(this.$prevElm, this.$nextElm);
      this.fixPopupUI();
    }
  }

  bindMedia(media_id) {
    const media = window.ig_medias[media_id].data;
    const $image = $('.product-popup-content .product-image #IMAGE'),
      $video = $('.product-popup-content .product-image #VIDEO'),
      $carouselAlbum = $('.product-popup-content .product-image #CAROUSEL_ALBUM'),
      $galleryNavInnerBtns = $('.product-popup-content .product-image .gallery-nav-btns');

    $galleryNavInnerBtns.hide();
    $('.product-popup-content .product-image .player').hide();
    $('.product-popup-content .product-image').find(`#${media.media_type}`).show();
    if (media.media_type === 'VIDEO') {
      $video.find('video').remove();
      $video.append(`<video poster="${media.thumbnail_url}" preload="auto" src="${media.media_url}" autoPlay="" controlsList="" class="embed-responsive-item"></video>`);
    } else if (media.media_type === 'CAROUSEL_ALBUM') {
      $galleryNavInnerBtns.show();
      $carouselAlbum.data('active', 0).data('count', media.children.data.length).empty();
      media.children.data.forEach((childImg, index) => {
        let isVideo = childImg?.thumbnail_url
        if (isVideo !== undefined) {
          $(`<video poster="${childImg.thumbnail_url}" preload="auto" src="${childImg.media_url}" autoPlay="" controlsList="" class="embed-responsive-item" style="${index > 0 ? "display: none;" : ""}"></video>`).appendTo($carouselAlbum);
        } else {
          $(`<img src="${childImg.media_url}" class="img-fluid" style="${index > 0 ? "display: none;" : ""}" />`).appendTo($carouselAlbum);
        }
      });
      this.handleInnerNav();
    } else {
      $('.product-popup-content .product-image img')
        .show()
        .clearQueue()
        .animate({ opacity: '0' }, 0)
        .attr('src', media.media_url)
        .animate({ opacity: '1' }, 500);
    }

    this.$productPopup.find('.popup-header').html(`
             <div><div class="merlin-small-logo"></div></div>
             <div><span class="f16">${media.username}</span><span class="text-tertiary-color f14">${moment(media.timestamp).format('MMMM DD')}</span></div>
             <div><a href="${media.permalink}" target="_blank"><span class="ig-icon"></span></a></div>
        `);

    const caption = media?.caption?.replace(/(?:\r\n|\r|\n)/g, '<br>')
    this.$productPopup.find('.product-information p').html(`
             <span class="text-root-color">${media.username}</span> ${caption === undefined ? '' : caption}
        `);
  }

  handleNav($prevElm, $nextElm) {
    const $next = this.$productPopup.find('#nav-btn-next'), $prev = this.$productPopup.find('#nav-btn-prev');
    if ($prevElm.length === 0) $prev.css({ 'display': 'none' }); else $prev.css({ 'display': 'block' });
    if ($nextElm.length === 0) $next.css({ 'display': 'none' }); else $next.css({ 'display': 'block' });
  }

  bindFeeds(response, $feedsContent, clear = true) {
    if (clear) {
      $feedsContent.empty();
    }
    response.data.forEach(feed => {
      const template = `<div class="gallery-img grid-item" id="${feed.id}">
                <img class="thumbnail" alt="img01" src="${feed.media_type === 'VIDEO' ? feed.thumbnail_url : feed.media_url}">
                ${this.getMediaTypeIcon(feed.media_type)}
            </div>`;
      $feedsContent.append(template);
    });
  }

  getMediaTypeIcon(media_type) {
    switch (media_type) {
      case 'CAROUSEL_ALBUM':
        return `<div class="icon ${media_type}"><img src="${imagePath(`./icons/ig/ig-carousel-album.svg`)}"></div>`;
      case 'VIDEO':
        return `<div class="icon ${media_type}"><img src="${imagePath(`./icons/ig/ig-video.svg`)}"></div>`;
      default:
        return '';
    }
  }

  getFeeds(isLoadMore = false) {
    const path = isLoadMore ? this.$loadMoreBtn.data('next') : Routes.instagram_feed_index_path();
    return new Promise((resolve, reject) => $.ajax({
      "url": path, "method": "GET"
    }).done(response => {
      response.paging.hasOwnProperty('next') ? this.$loadMoreBtn.data('next', response.paging.next) : this.$loadMoreBtn.remove();
      this.$loadMoreBtn.removeAttr('disabled');
      resolve(response);
    }));
  }

  getMedia(media_id) {
    return new Promise((resolve, reject) => {
      if (!!!window.ig_medias.hasOwnProperty(media_id)) {
        const path = Routes.instagram_feed_path(media_id);
        $.ajax({
          "url": path, "method": "GET",
          beforeSend: () => {
            $('.product-image').addClass('loading');
            $('#' + media_id).addClass('loading').append(`<div class="loading"></div>`);
            this.$productPopup.find('.product-image').addClass('loading').append(`<div class="loading"></div>`);
          },
          complete: () => {
            $('.product-image').removeClass('loading');
            $('#' + media_id).removeClass('loading').find('.loading').remove();
            this.$productPopup.find('.product-image').removeClass('loading').find('.loading').remove();
          },
        }).done(response => {
          window.ig_medias[media_id] = response;
          resolve(response)
        });
      } else {
        resolve(window.ig_medias[media_id]);
      }
    });
  }
  fixPopupUI() {
    const productDescWrapperHeight = this.$productPopup.find('.product-popup-content').outerHeight() - this.$productPopup.find('.popup-header').outerHeight();
    this.$productPopup.find('.product-desc-wrapper').css({
      height: productDescWrapperHeight + 'px'
    });
  }
}
