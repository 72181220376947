import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import { handleOpenPreviewModal } from '../shared/campaignTemplateUtil';
import {
  copyDynamicTagName,
  handleAttachFileChange,
  setupEditor,
  syncAttachFiles,
  handleDeleteAttachFile,
  setAttachedFiles,
  handleShowClientNameToPreviewCampaign,
  resetCssBeforeSave,
  handlePreviewMedia,
} from '../../consultant/shared/campaignTemplateUtil';

export default class EmailForm {
  constructor() {
    this.destroyMultipleSelector = '#btn-destroy-campaigns';
    this.broadcastType = '#broadcast-type-selection';
    this.chanelCheckbox = '.channel-checkbox';
    this.senderNameInput = '#sender-name-input';
    this.emailSubjectInput = '#email-subject-input';
    this.shortDescriptionInput = '#short-description-input';
    this.campaignContent = '#campaign-tab .nav-link';
    this.emailAudienceTab = '#email-audience-tab .nav-link';
    this.allPeopleFromWrapper = '.all-people-from-wrapper';
    this.editorWrapper = '.editor-wrapper';
    this.saveAsTemplateWrapper = '.save-as-template-wrapper';
    this.saveAsTemplate = '.save-as-template';
    this.saveAsTemplateDescription = '.save-as-template-description';
    this.scheduleCampaign = '.schedule-campaign';
    this.emailDateTimeInputWrapper = '.email-date-time-input-wrapper';
    this.scheduleCampaignDescription = '.schedule-campaign-description';
    this.editTemplate = '.edit-template';
    this.previewEmailTemplate = '#preview-email-template .text-container';
    this.emailTemplateSelection = '#email-template-selection';
    this.bulkScheduleCampaign = '#bulk-schedule-campaign';
    this.bulkCancelScheduleCampaign = '#bulk-cancel-schedule-campaign';
    this.blastCampaignContent = '#blast-campaign-tab .nav-link';
    this.signatureSelection = '#signature-selection';
    this.templateHtml = '';
    this.files = [];
    this.editor = null;
    this.role = '';
    this.showPath = '';
    this.sendTestEmailPath = '';
  }

  index() {
    toggleTdTable();
    checkboxSelected(this.destroyMultipleSelector, 'campaign');
    checkboxSelected(this.bulkScheduleCampaign, 'campaign');
    checkboxSelected(this.bulkCancelScheduleCampaign, 'campaign');
    handleOpenPreviewModal(this.showPath, this.sendTestEmailPath);
    this.handleBlastCampaignContentChange();
    this.fetchDataWhenClickPaginationItem();
  }

  new() {
    this.setup();
  }

  create() {
    this.setup();
    this.showEditorOrListTemplate();
  }

  edit() {
    this.setup();
    this.showEditorOrListTemplate();
    handleOpenPreviewModal(this.showPath, this.sendTestEmailPath);
  }

  update() {
    this.setup();
    this.showEditorOrListTemplate();
    handleOpenPreviewModal(this.showPath, this.sendTestEmailPath);
  }

  show() {
    handlePreviewMedia();
  }

  setup() {
    setupEditor().then((editor) => (this.editor = editor));
    this.handleOnScheduleCampaignChange();
    this.hanldeOnScheduleAutomationEmailChange();
    this.handleOnChangeScheduleType();
    this.handleChangeEmailTemplate();
    this.handleChangeSignature();
    this.handelChanelCheckbox();
    this.handleBroadcastTypeChange();
    this.handleShowCampaignContent();
    this.handleShowSaveAsTemplateDescription();
    this.handleClickEditTemplate();
    this.showChannelsError();
    handleShowClientNameToPreviewCampaign(this.role);
    copyDynamicTagName();
    handleAttachFileChange();
    handleDeleteAttachFile();
    resetCssBeforeSave();
  }

  handelChanelCheckbox() {
    $($(this.chanelCheckbox)[0]).on('change', () => {
      $(this.senderNameInput).parent().toggleClass('d-none');
      $(this.emailSubjectInput).parent().toggleClass('d-none');
    });
    if ($($(this.chanelCheckbox)[0]).is(':checked')) {
      $(this.senderNameInput).parent().removeClass('d-none');
      $(this.emailSubjectInput).parent().removeClass('d-none');
    }
    $($(this.chanelCheckbox)[1]).on('change', () => {
      $(this.shortDescriptionInput).parent().toggleClass('d-none');
    });
    if ($($(this.chanelCheckbox)[1]).is(':checked')) {
      $(this.shortDescriptionInput).parent().removeClass('d-none');
    }
  }

  handleBroadcastTypeChange() {
    $(this.broadcastType).on('change', ({ target }) => {
      this.broadcastTypeOptions(target.value);
    });

    this.broadcastTypeOptions($(this.broadcastType).val());
  }

  broadcastTypeOptions(broadcastType) {
    const allAudienceTabs = [
      '#all-people-tab',
      '#all-contact-tab',
      '#all-lead-tab',
      '#all-subscriber-tab',
      '#email-category-tab',
      '#email-client-type-tab-admin',
      '#email-client-type-tab-consultant',
      '#email-label-tab',
    ];

    switch (broadcastType) {
      case 'broadcast':
        $('.zip-attachment-with-password').addClass('d-none');
        $('.unsubscribe-container').removeClass('d-none');
        $('#automation-email').addClass('d-none');
        $('#email-custom-tab').parent().show();
        $('#all-people-tab').parent().show();
        $('#all-lead-tab').parent().show();
        $('#all-subscriber-tab').parent().show();
        $('#email-client-type-tab-admin').parent().show();
        $('#email-client-type-tab-consultant').parent().show();
        $('#campaign-schedule').show();
        allAudienceTabs.forEach((audience) => {
          $(audience).parent().css(this.enableClickObject());
        });
        if (!this.getTemlateId()) {
          $('#all-subscriber-tab').parent().css(this.disableClickObject());
          $('#email-client-type-tab-admin').parent().css(this.disableClickObject());
        }
        break;
      case 'automation':
        $('.zip-attachment-with-password').addClass('d-none');
        $('.unsubscribe-container').addClass('d-none');
        $('#automation-email').removeClass('d-none');
        $('#email-custom-tab').parent().hide();
        $('#all-people-tab').parent().show();
        $('#all-lead-tab').parent().hide();
        $('#all-subscriber-tab').parent().hide();
        $('#email-client-type-tab-admin').parent().show();
        $('#email-client-type-tab-consultant').parent().show();
        $('#campaign-schedule').hide();
        if (location.pathname.includes('new')) $('#all-contact-tab').click();
        allAudienceTabs.forEach((audience) => {
          $(audience).parent().css(this.enableClickObject());
        });
        break;
      case 'personal_messsage':
        $('.zip-attachment-with-password').removeClass('d-none');
        $('.unsubscribe-container').addClass('d-none');
        $('#automation-email').addClass('d-none');
        $('#email-custom-tab').parent().show();
        $('#all-people-tab').parent().show();
        $('#all-lead-tab').parent().show();
        $('#all-subscriber-tab').parent().show();
        $('#email-client-type-tab-admin').parent().show();
        $('#email-client-type-tab-consultant').parent().show();
        $('#campaign-schedule').show();
        if (location.pathname.includes('new')) $('#email-custom-tab').click();
        allAudienceTabs.forEach((audience) => {
          $(audience).parent().css(this.disableClickObject());
        });
        break;
    }
  }

  handleShowCampaignContent() {
    $('#email-client-type-tab-admin').parent().css(this.disableClickObject());
    Array.from($(this.campaignContent)).forEach((campaign) => {
      $(campaign).on('click', (e) => {
        if ($(campaign).data('value') == 'add-new-draft') {
          $(this.editorWrapper).removeClass('d-none');
          $(this.saveAsTemplateWrapper).removeClass('d-none');
          const activeAudienceTab = $('#email-audience-tab-content li.active')[0];
          const fcOptedIn = activeAudienceTab?.id == 'all-subscriber';
          if (fcOptedIn) $('#all-people-tab').click();
          $('#all-subscriber-tab').parent().css(this.disableClickObject());
        } else {
          $(this.editorWrapper).addClass('d-none');
          $(this.saveAsTemplateWrapper).addClass('d-none');
          $(this.saveAsTemplate).prop('checked', false);
          $(this.saveAsTemplateDescription).addClass('d-none');
          $('#all-subscriber-tab').parent().css(this.enableClickObject());
          $('#email-client-type-tab-admin').parent().css(this.enableClickObject());
        }
        // check real user click or trigger click by js
        if (e.originalEvent !== undefined) {
          $(this.emailTemplateSelection).prop('selectedIndex', -1).trigger('change');
          this.editor.setData('');
          syncAttachFiles([], 'replace');
        }
      });
    });
  }

  showEditorOrListTemplate() {
    const id = $(this.emailTemplateSelection).val() || $('#existed-template').data('email-template-id');
    if (id !== '') {
      $($(this.campaignContent)[1]).click();
      this.getTemplateHTmlFromServer(id);
    } else {
      $($(this.campaignContent)[0]).click();
    }
  }

  handleShowSaveAsTemplateDescription() {
    $(this.saveAsTemplate).on('change', () => {
      $(this.saveAsTemplateDescription).toggleClass('d-none');
    });
  }

  handleOnScheduleCampaignChange() {
    $(this.scheduleCampaign).on('change', () => {
      $(this.scheduleCampaignDescription).toggleClass('d-none');
    });
    if ($(this.scheduleCampaign).is(':checked')) {
      $(this.scheduleCampaignDescription).removeClass('d-none');
    }
  }

  hanldeOnScheduleAutomationEmailChange() {
    $('.campaign-date-itself').on('change', () => {
      $('.period-wrapper').toggleClass('d-none');
    });
    if ($('.campaign-date-itself').is(':checked')) {
      $('.period-wrapper').addClass('d-none');
    } else {
      $('.period-wrapper').removeClass('d-none');
    }
  }

  handleOnChangeScheduleType() {
    $('#email-schedule-type-selection').on('change', ({ target }) => {
      this.toggleScheduleTimeInput(target.value);
    });
    this.toggleScheduleTimeInput($('#email-schedule-type-selection').val());
  }

  toggleScheduleTimeInput(value) {
    if (value == 'now') {
      $('.email-date-time-input-wrapper').hide();
    } else {
      $('.email-date-time-input-wrapper').show();
    }
  }

  handleClickEditTemplate() {
    $(this.editTemplate).on('click', (e) => {
      e.preventDefault();
      this.editor.setData(this.templateHtml);
      syncAttachFiles(this.files, 'replace');
      setAttachedFiles(this.files.map((file) => file.blob));
      $($(this.campaignContent)[0]).click();
    });
  }

  handleChangeEmailTemplate() {
    $(this.emailTemplateSelection).on('change', () => {
      const id = $(this.emailTemplateSelection).val();
      if (id) {
        this.getTemplateHTmlFromServer(id);
      }
    });
  }

  handleChangeSignature() {
    $(this.signatureSelection).on('change', () => {
      const id = $(this.signatureSelection).val();
      if (id) {
        this.getSignatureHtmlFromServer(id);
      }
    });
  }

  getTemplateHTmlFromServer(id) {
    $.get(Routes[`show_template_html_${this.role}_email_template_path`](id)).done((data) => {
      const fcName = $(this.previewEmailTemplate).data('fc-name');
      this.templateHtml = data.template_html.replace('{{fc name}}', fcName);
      this.files = data.files;
      $(this.previewEmailTemplate).html(this.templateHtml);
      $('#image-links').attr('data-value', JSON.stringify(data.image_links));
      syncAttachFiles(this.files, 'replace');
      handlePreviewMedia();
    });
  }

  getSignatureHtmlFromServer(id) {
    $.get(Routes[`show_signature_html_${this.role}_signature_path`](id)).done((data) => {
      const htmlContent = this.editor.getData();
      this.editor.setData(`${htmlContent}${data.signature_html}`);
    });
  }

  fetchDataWhenClickPaginationItem() {
    $('body').on('modal:afterSetContent', '#preview-recipient', () => {
      this.getListRecipients();
      $('.recipient-pagination').on('DOMSubtreeModified', () => {
        this.getListRecipients();
      });
    });
  }

  getListRecipients() {
    Array.from($('.recipient-pagination a')).forEach((item) => {
      $(item).on('click', (e) => {
        e.preventDefault();
        const url = $(item).attr('href');
        $.ajax({
          url: url,
          dataType: 'json',
          success: function (data) {
            $('.recipient-table-wrapper').html(data.entries);
            $('.recipient-pagination').html(data.pagination);
          },
        });
      });
    });
  }

  showChannelsError() {
    const channelErrorField = $('.channel-wrapper .invalid-feedback');
    if ($('.channel-wrapper .invalid-feedback').length > 0) {
      $(`<div class='invalid-feedback d-block'>${channelErrorField.text()}</div>`).appendTo('#channel-field');
      channelErrorField.remove();
    }
  }

  handleBlastCampaignContentChange() {
    $('#blast-date-time-input').on('change', (e) => {
      const currentUrl = $(this.bulkScheduleCampaign).prop('href');
      const url = new URL(currentUrl);
      url.searchParams.set('send_date_time', e.target.value);
      $(this.bulkScheduleCampaign).prop('href', url.href);
    });

    Array.from($(this.blastCampaignContent)).forEach((campaign) => {
      $(campaign).on('click', () => {
        const currentUrl = $(this.bulkScheduleCampaign).prop('href');
        const url = new URL(currentUrl);
        const scheduleType = $(campaign).data('value');
        url.searchParams.set('schedule_type', scheduleType);
        if (scheduleType == 'on_a_specific_date') {
          url.searchParams.set('send_date_time', $('#blast-date-time-input').val());
        }
        $(this.bulkScheduleCampaign).prop('href', url.href);
      });
    });
  }

  getTemlateId() {
    return $(this.emailTemplateSelection).val() || $('#existed-template').data('email-template-id');
  }

  enableClickObject() {
    return { pointerEvents: 'auto', opacity: 1 };
  }

  disableClickObject() {
    return { pointerEvents: 'none', opacity: 0.7 };
  }
}
