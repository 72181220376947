export default class IpreciateMailingForm {
  constructor() {
    this.changeIpreciateMailingCheckboxSelector = '#ipreciate-mailing-checkbox';
    this.clientId = $('body').data('client-id');
    this.ipreciateSectionSelector = '#ipreciate-mailing';
  }

  setup() {
    $(this.changeIpreciateMailingCheckboxSelector).on('change', ({ target }) => {
      const $target = $(target);
      let confirmMessage = '';
      let ipreciateMailing = false;

      if ($target.is(':checked')) {
        confirmMessage = 'Please ensure that this client has given consent. Proceed to save?';
        ipreciateMailing = true;
      } else {
        confirmMessage = 'Client will now be opted out of I’PRECIATE mailing list. Proceed to save?';
        ipreciateMailing = false;
      }

      if (confirm(confirmMessage)) {
        $.post(Routes.change_ipreciate_mailing_information_consultant_client_path({ id: this.clientId }, { ipreciate_mailing: ipreciateMailing }))
          .done(({ html }) => {
            $(this.ipreciateSectionSelector).replaceWith(html);
            this.setup();
          })
      } else {
        if ($target.is(':checked')) {
          $target.prop("checked", false);
        } else {
          $target.prop("checked", true);
        }
      }
    })
  }
}
