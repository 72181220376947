export default function setupReferralCodeDisclaimer() {
  let userReferralCode = $('#user_referral_code');
  let referralCodeDisclaimer = $('.referral-code-disclaimer');

  if (userReferralCode.val() !== '') {
    referralCodeDisclaimer.removeClass('d-none');
  }

  userReferralCode.on('keyup', (e) => {
    referralCodeDisclaimer.removeClass('d-none');
  })
}
