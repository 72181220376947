import Raicon from 'raicon';

class AdminUsersController {
  index() {
    this.toggleBulkOtp2FA();
    this.eventModifyOTP();
  }

  toggleBulkOtp2FA(){
    $('.select-box').on('change', (e) => {
      const _this = $(e.target);
      if(_this.is(':checked')){
        $('#option-otp').removeClass('d-none');
      } else {
        if($('.select-box:checked').length == 0){
          $('#option-otp').addClass('d-none');
        }
      }
    })
  }

  eventModifyOTP(){
    $('#enable-link, #disable-link, #reset-link').on('click', (e) => {
      const _this = $(e.target)
      const oldUrl =  _this.attr("href")

      let ids = $.map($('.select-box:checked'), (item) => {
        return $(item).data('value');
      })

      let newUrl = `${oldUrl}&ids=${ids.join(', ')}`
      if(e.target.id == 'reset-link') {
        newUrl = `${oldUrl}?ids=${ids.join(', ')}`
      }
      _this.attr("href", newUrl);
    })
  }
}

Raicon.register('admin/users', AdminUsersController);
