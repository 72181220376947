import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';

export default class GoalsChart {
  constructor() {
    this.appointmentsMetChartselector = '#appointments-met-chart'
    this.favorableIntroductionsChartselector = '#favorable-introductions-chart'
    this.newCasesWonChartselector = '#new-cases-won-chart'
  }

  setup(){
    this.initGoalsChart();
  }

  initGoalsChart() {
    const $appointmentsMetChart = $(this.appointmentsMetChartselector);
    const appointmentsMetChartData = $appointmentsMetChart.data('chart-data');
    const appointmentsMetChartColor = $appointmentsMetChart.data('chart-color');
    initDoughnutChart($appointmentsMetChart, appointmentsMetChartData, appointmentsMetChartColor, 40);

    const $favorableIntroductionsChart = $(this.favorableIntroductionsChartselector);
    const favorableIntroductionsChartData = $favorableIntroductionsChart.data('chart-data');
    const favorableIntroductionsChartColor = $favorableIntroductionsChart.data('chart-color');
    initDoughnutChart($favorableIntroductionsChart, favorableIntroductionsChartData, favorableIntroductionsChartColor, 40);

    const $newCasesWonChart = $(this.newCasesWonChartselector);
    const newCasesWonChartData = $newCasesWonChart.data('chart-data');
    const newCasesWonChartColor = $newCasesWonChart.data('chart-color');
    initDoughnutChart($newCasesWonChart, newCasesWonChartData, newCasesWonChartColor, 40);
  }
}
