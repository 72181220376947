import Raicon from 'raicon';
import LoanForm from '../../consultant/netwealths/loanForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';

class ClientsLiabilitiesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    handleMoneyField();
    handleHideSignSgd('#currency-select', '.property-currency');
    new LoanForm().setup();
  }

  edit() {
    handleMoneyField();
    new LoanForm().setup();
    handleLockForm(['#loan-liability-form']);
    handleHideSignSgd('#currency-select', '.property-currency');
    $('#currency-select').trigger('change');
  }

  create() {
    handleMoneyField();
    new LoanForm().setup();
  }

  newLiabilitySelection() {}
}

Raicon.register('clients/liabilities', ClientsLiabilitiesController);
