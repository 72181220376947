import Raicon from 'raicon';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleSideMenu from '../shared/handleSideMenu';

class ClientEventsController {
  edit() {
    handleMoneyField();
    handleSideMenu();
  }

  update() {
    handleMoneyField();
    handleSideMenu();
  }
}

Raicon.register('clients/events', ClientEventsController);
