import Raicon from 'raicon';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleImportDuplicationPolicies from '../../consultant/shared/handleImportDuplicationPolicies';
import ImportContactForm from '@/layouts/application/utils/dropzone/importContactForm';
import ImportInsurancePoliciesForm from '@/layouts/application/utils/dropzone/importInsurancePoliciesForm';

class AdminUserManagementsController {
  index() {
    this.initDropZone();
    clearInputNumberOnFocus();
    handleMoneyField();
    handleImportDuplicationPolicies();
  }

  initDropZone() {
    new ImportContactForm().setup();
    new ImportInsurancePoliciesForm().setup();
  }

  hiddenSearchDropSelect2(id) {
    $(id).select2({
      tags: true,
      allowClear: false,
      tokenSeparators: [';', '	', ' ']
    }).on('select2:open', function (e) {
      $('.select2-container--open .select2-dropdown--below').css('display', 'none');
    });
  }
}

Raicon.register('admin/userManagements', AdminUserManagementsController);
