import Raicon from 'raicon';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import GoalsChart from './goalsChart';
import TaskForm from '../tasks/formTask';
import AppointmentForm from '../planners/appointmentForm';
import InsurancePolicyForm from '../insurances/insurancePolicyForm';
import { fillDataForAddContact } from '../shared/fillDataForAddContact';
import { triggerCompletedTask } from '../shared/triggerCompletedTask';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';
import { getUrlParamValueByName } from '@/helpers';
import ProductForm from '../cases/productForm';
import { handleOverlayGuides } from '../overlayGuides';
import { initSelect2 } from '@/layouts/application/packages/select2';
import { formatMoneyToNumber } from '@/layouts/application/utils/formats';
import { fillDataForAddContactOfInsight } from '../shared/fillDataForAddContact';

const moment = require('moment');

class ConsultantDashboardsController {
  constructor() {
    this.targetProduction = '#target-production';
    this.turnUpRatio = '#turn-up-ratio';
    this.closingRatio = '#closing-ratio';
    this.callingRatio = '#calling-ratio';
    this.averageCaseSize = '#average-case-size';
    this.appointmentMeet = '#appointment-meet';
    this.recommendation = '#recommendation';
    this.opportunitiesWon = '#opportunities-won';
    this.applyAssumption = '#is-apply-assumption';
    this.goalConsultantStartDateSelector = '#goal-start-date';
    this.goalConsultantEndDateSelector = '#goal-end-date';
    this.goalConsultantFormSelector = '#edit-target-form';
    this.preffTableSelector = '#preff-show';
    this.deletePreffButtonSelector = '.delete-preff-button';
    this.overflowError = '#overflow-error';
  }

  index() {
    handleOverlayGuides();
    changeParamenterByTab('dashboardInsight', 'insight_tab', true);
    changeParamenterByTab('dashboardFocus', 'focus_tab', true);
    changeParamenterByTab('dashboardOpportunity', 'opportunity_tab', true);
    this.setupGoalsChart();
    this.setupTaskForm();
    this.setupAppoinmentForm();
    this.setupAddContactForm();
    this.setupEditTargetForm();
    this.setupInsurances();
    this.setupForGetCurrent();
    this.setupFilterActionForShortfallTabel();
    fillDataForAddContact();
    this.setupProgressBar();
    triggerCompletedTask();
    handleTrackClickBanner();
    this.setupProductForm();
    this.autoCalculationTargetGoal();
    this.validateForCustomMilestonesInput();
    this.handleDeletePreff();
    this.ajaxLoadDashboardTabs();
    this.loadSelect2();
    this.setupFocusDay();
    this.showVideoModal();
    fillDataForAddContactOfInsight();
  }

  setupFocusDay() {
    $.get(Routes.consultant_focus_day_path(), function (data) {
      $('.focus-today').html(data['raw_html']);
    });
  }

  ajaxLoadDashboardTabs() {
    const tabShowIds = [
      '#open-cases-show',
      '#favorable-recommendations-show',
      '#preff-show',
      '#post-fna-show',
      '#short-fall-show',
      '#turning-30-show',
      '#turning-55-show',
      '#policy-anniversary-show',
      '#client-review-date-show',
    ];
    tabShowIds.forEach((tabId) => {
      $.ajax({
        url: this.urlForTabId(tabId),
        type: 'GET',
        success: (data) => {
          $(`${tabId}`).html(data);
          initSelect2();
          this.addEventTooltip();
        },
      });
    });
  }

  urlForTabId(tabId, params = {}) {
    switch (tabId) {
      case '#open-cases-show':
        return Routes.open_opportunities_consultant_dashboards_path(params);
      case '#favorable-recommendations-show':
        return Routes.fav_recommendations_consultant_dashboards_path(params);
      case '#preff-show':
        return Routes.pre_fact_finds_consultant_dashboards_path(params);
      case '#post-fna-show':
        return Routes.post_fnas_consultant_dashboards_path(params);
      case '#short-fall-show':
        return Routes.shortfall_consultant_dashboards_path(params);
      case '#turning-30-show':
        return Routes.turning_30_consultant_dashboards_path(params);
      case '#turning-55-show':
        return Routes.turning_55_consultant_dashboards_path(params);
      case '#policy-anniversary-show':
        return Routes.policy_anniversary_consultant_dashboards_path(params);
      case '#client-review-date-show':
        return Routes.client_review_date_consultant_dashboards_path(params);
    }
  }

  loadSelect2() {
    $('body').on(
      'change',
      '#open-cases-show, #short-fall-show, #turning-30-show, #turning-55-show, #policy-anniversary-show',
      (target) => {
        initSelect2();
      },
    );
  }

  setupFilterActionForShortfallTabel() {
    $('body').on(
      'change',
      '#short-fall-show-filter, #turning-30-show-filter, #turning-55-show-filter, #policy-anniversary-show-filter, #open-cases-show-filter',
      ({ target }) => {
        const filterValue = target.value;
        const insightTab = getUrlParamValueByName('insight_tab');
        let hashTag = 'merlin-insights-content';
        const targetId = '#' + target.id.replace('-filter', '');
        if (targetId == '#open-cases-show') {
          hashTag = 'dashboard-opportunities-content';
        }
        const params = { insight_tab: insightTab, filter: filterValue, anchor: hashTag };
        const filterRoute = this.urlForTabId(targetId, params);

        $.ajax({
          url: filterRoute,
          type: 'get',
          success: (data) => {
            $(`${targetId}`).html(data);
            initSelect2();
            this.removeRedundantTooltip();
            this.addEventTooltip();
          },
        });
      },
    );
  }

  addEventTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }

  removeRedundantTooltip() {
    $('body > .tooltip').remove();
  }

  setupGoalsChart() {
    new GoalsChart().setup();
  }

  setupTaskForm() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task', () => {
      new TaskForm().setup();
    });
  }

  setupAppoinmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form, #new_task', () => {
      new AppointmentForm().setup();
    });
  }

  setupAddContactForm() {
    $('body').on('modal:afterSetContent', '#new-referee-to-contact-button, #new-contact-form', () => {
      calculateAgeByInput('#dob', '.input-age');
    });
  }

  setupEditTargetForm() {
    $('body').on('modal:afterSetContent', '#open-edit-target-form, #edit-target-form', () => {
      let applyAssumption = $('#is-apply-assumption').is(':checked');

      this.showOrHide(applyAssumption);

      $('#is-apply-assumption').on('change', () => {
        applyAssumption = $('#is-apply-assumption').is(':checked');
        this.showOrHide(applyAssumption);
      });
    });
  }

  showOrHide(isShow) {
    if (isShow) {
      $('#apply-assumption').show();
      $('#not-apply-assumption').hide();
    } else {
      $('#apply-assumption').hide();
      $('#not-apply-assumption').show();
    }
  }

  setupInsurances() {
    new InsurancePolicyForm().setup();
  }

  setupForGetCurrent() {
    const getCurrentRecommendation = '#get-current-favorable-recommendations';
    const getCurrentOpportunity = '#get-current-opportunitys-won';
    const inputRecommendation = '#input-new-recommendation';
    const inputOpportunity = '#input-new-opportunity-won';
    const getProcessOne = '#get-current-process-one';
    const getProcessTwo = '#get-current-process-two';
    const getProcessThree = '#get-current-process-three';
    const inputProcessOne = '#input-process-one';
    const inputProcessTwo = '#input-process-two';
    const inputProcessThree = '#input-process-three';

    $('body').on(
      'modal:afterSetContent',
      '#open-activity-log-form, #new-activity-log-form, #edit-activity-log-form',
      () => {
        $(getCurrentRecommendation).on('click', () => {
          const recommendationCount = $(getCurrentRecommendation).attr('data-new-recommendation');
          $(inputRecommendation).val(recommendationCount);
        });

        $(getCurrentOpportunity).on('click', () => {
          const opportunityCount = $(getCurrentOpportunity).attr('data-new-opportunity-won');
          $(inputOpportunity).val(opportunityCount);
        });
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#form-edit-challenge, #form-create-challenge, #edit-form-challenge-process, #new-challenge-form, .edit-challenge-modal',
      () => {
        $(getProcessOne).on('click', () => {
          const processOne = $(getProcessOne).attr('data-process-one');
          $(inputProcessOne).val(processOne);
          $(inputProcessOne).trigger('input');
        });

        $(getProcessTwo).on('click', () => {
          const processTwo = $(getProcessTwo).attr('data-process-two');
          $(inputProcessTwo).val(processTwo);
          $(inputProcessTwo).trigger('input');
        });

        $(getProcessThree).on('click', () => {
          const processThree = $(getProcessThree).attr('data-process-three');
          $(inputProcessThree).val(processThree);
          $(inputProcessThree).trigger('input');
        });
      },
    );
  }

  setupProgressBar() {
    const mdrtProgressBar = $('#mdrt-progress');
    const persistencyProgressBar = $('#persistency-progress');
    setTimeout(() => {
      this.loadProgressBar(mdrtProgressBar);
      this.loadProgressBar(persistencyProgressBar);
    }, 250);
  }

  loadProgressBar(barSelector) {
    const percent = Number($(barSelector).attr('data-percent'));
    $(barSelector).width(percent + '%');

    if (barSelector.attr('id') == 'persistency-progress' && percent < 85) {
      $(barSelector).removeClass('green-bar');
      $(barSelector).addClass('red-bar');
    }
  }

  setupProductForm() {
    $('body').on('modal:afterSetContent', '#edit-case-link, #edit-case-form', () => {
      new ProductForm();
    });
  }

  autoCalculationTargetGoal() {
    $('body').on('modal:afterSetContent', '#open-edit-target-form, #edit-target-form', () => {
      this.handleCalculation();
      $('#is-apply-assumption').on('change', () => {
        this.handleCalculation();
      });
      this.calculationOnChange(this.applyAssumption);
    });
  }

  handleCalculation() {
    this.calculationOnChange(this.targetProduction);
    this.calculationOnChange(this.turnUpRatio);
    this.calculationOnChange(this.closingRatio);
    this.calculationOnChange(this.averageCaseSize);
    this.calculationOnChange(this.callingRatio);

    $(this.goalConsultantFormSelector).on(
      'change',
      `${this.targetProduction}, ${this.goalConsultantStartDateSelector}, ${this.goalConsultantEndDateSelector}, ${this.turnUpRatio}, ${this.closingRatio}, ${this.averageCaseSize}, ${this.callingRatio}`,
      () => {
        this.calculationOnChange();
      },
    );
  }

  calculationOnChange() {
    const startDate = $(this.goalConsultantStartDateSelector).val();
    const endDate = $(this.goalConsultantEndDateSelector).val();

    if (!startDate || !endDate) {
      return false;
    }

    const currentYear = new Date().getFullYear();

    const startDateWithYear = moment(`${currentYear}${startDate}`, 'YYYY-MM-DD');
    const endDateWithYear = moment(`${currentYear}${endDate}`, 'YYYY-MM-DD');

    const gapDaysBetweenStartAndEndDate = moment.duration(endDateWithYear.diff(startDateWithYear)).asDays();

    let gapWeeksBetweenStartAndEndDate = 1;

    if (gapDaysBetweenStartAndEndDate >= 7) {
      gapWeeksBetweenStartAndEndDate = Math.floor(gapDaysBetweenStartAndEndDate / 7);
    }

    const production = Number(formatMoneyToNumber($(this.targetProduction).val()));
    const defaultClosingRatio = 30;
    const defaultTurnUpRatio = 50;
    const defaultCallingRatio = 30;

    let averageCase = Number(formatMoneyToNumber($(this.averageCaseSize).val()));
    if (averageCase == 0) {
      averageCase = 1;
    }

    let closingRatio = (Number($(this.closingRatio).val()) || defaultClosingRatio) / 100;
    if (closingRatio == 0) {
      closingRatio = 1;
    }

    let turnUpRatio = (Number($(this.turnUpRatio).val()) || defaultTurnUpRatio) / 100;
    if (turnUpRatio == 0) {
      turnUpRatio = 1;
    }

    let callingRatio = (Number($(this.callingRatio).val()) || defaultCallingRatio) / 100;
    if (callingRatio == 0) {
      callingRatio = 1;
    }

    const opportunitiesWon = production / gapWeeksBetweenStartAndEndDate;
    const appointmentMeet = Math.ceil(opportunitiesWon / averageCase / closingRatio);
    const recommendation = Math.ceil(appointmentMeet / turnUpRatio / callingRatio);

    if ($(this.overflowError).length == 0) {
      $(this.opportunitiesWon).text(Number(opportunitiesWon.toFixed(0)).toLocaleString());
      $(this.appointmentMeet).text(appointmentMeet);
      $(this.recommendation).text(recommendation);
    }
  }

  validateForCustomMilestonesInput() {
    $('body').on('modal:afterSetContent', '#open-edit-target-form, #edit-target-form', () => {
      $('#custom-milestones-input').select2({
        tags: true,
        maximumSelectionLength: 4,
        createTag: (milestone) => {
          const position_of_invalid_char = milestone.term.search(/[^0-9]/);
          if (position_of_invalid_char != -1) return null;

          return {
            id: milestone.term,
            text: milestone.term,
          };
        },
        language: {
          noResults: () => {
            return 'Milestones can only be positive numbers';
          },
        },
      });
    });
  }

  handleDeletePreff() {
    $('body').on('click', '.delete-preff-button', (element) => {
      const status = confirm('Are you sure?');
      if (status) {
        const preffId = $(element.target).attr('preff_id');
        $.ajax({
          url: Routes.pre_fact_find_path(preffId),
          type: 'DELETE',
          success: (data) => {
            $(this.preffTableSelector).html(data.html);
          },
        });
      }
    });
  }

  showVideoModal() {
    const unreadNotificationsElement = $('#unread-notifications');
    const videoModal = $('#enhancement-modal');
    const ytPlayer = $('.ytplayer');

    $('.open-modal').click(function () {
      ytPlayer.removeAttr('style');
      const $this = $(this);
      $.ajax({
        url: Routes.mark_read_consultant_notification_path($this.data('id')),
        type: 'PUT',
        dataType: 'json',
        success: function (data) {
          $(`#merlin-enhance-${$this.data('id')}`)
            .find('.video-new')
            .remove();
          const is_read = data.read_all;
          if (is_read) {
            unreadNotificationsElement.addClass('d-none');
          }
        },
      });
      ytPlayer.attr('src', $this.data('url'));
      videoModal.modal('show');
      videoModal.removeClass('d-none');
      setTimeout(() => {
        ytPlayer.removeAttr('style');
        setTimeout(() => {
          ytPlayer.removeAttr('style');
        }, 1000);
      }, 1000);
    });

    $('#enhancement-modal .btn-close-video').click(function () {
      ytPlayer.attr('src', '');
      videoModal.addClass('d-none');
      videoModal.modal('hide');
    });
  }
}

Raicon.register('consultant/dashboards', ConsultantDashboardsController);
