export default class CpfBonus {
  constructor() {
    this.hasBonusCheckBox = '#income_has_bonus';
    this.addNewBonusButton = '#add-new-income-bonuses';
    this.bonusType = '#bonus-type';
  }

  setup() {
    this.logicBonusCheckbox();
    this.changeFieldBaseOnBonusType();
    this.initFieldBaseOnBonusType();
  }

  showFieldBaseOnBonusType(e) {
    let monthQuantity = $(e).parents(this.bonusType).next().find('#month-quantity');
    let personalInput = $(e).parents(this.bonusType).next().find('#personal-input');
    if ($(e).val() == 'monthly') {
      monthQuantity.css('display', 'block');
      personalInput.css('display', 'none');
    } else {
      monthQuantity.css('display', 'none');
      personalInput.css('display', 'block');
    }
  }

  changeFieldBaseOnBonusType() {
    $('#bonus-select-wrapper select').on('change', (e) => {
      this.showFieldBaseOnBonusType(e.target);
    });
  }

  initFieldBaseOnBonusType() {
    $('.nested-fields.income-bonus-fields #bonus-select-wrapper select').each((i, e) => {
      this.showFieldBaseOnBonusType(e);
    });
  }

  logicBonusCheckbox() {
    if ($(this.hasBonusCheckBox).is(':checked')) {
      $('#for-has-bonus').css('display', 'block');
      $('#for-has-bonus').prop('disabled', false);
    } else {
      $('#for-has-bonus').css('display', 'none');
      $('#for-has-bonus').prop('disabled', true);
    }

    $(document).on('cocoon:after-remove', () => {
      if (!$('.nested-fields.income-bonus-fields').is(':visible')) {
        $(this.hasBonusCheckBox).prop('checked', false);
        $('#for-has-bonus').css('display', 'none');
      }
    });

    if ($(this.hasBonusCheckBox).is(':checked') && $('.nested-fields.income-bonus-fields').length < 1) {
      $(this.addNewBonusButton).trigger('click');
    }

    $(document).on('cocoon:after-insert', () => {
      this.changeFieldBaseOnBonusType();
    });
  }
}
