export default function clearInputNumberOnFocus() {
  $('input[type="number"], input[type="float"]').focus(function() {
    if($(this).val() == '0' || $(this).val() == '0.0' || $(this).val() == '0.00'){
      $(this).val('');
    }
  })

  $('input[type="number"]').bind('keydown paste', function(event) {
    if (event.shiftKey==1) {
      return false;
    }
    // Allow only backspace and delete
    if ([46, 8, 9, 37, 38, 39, 40, 110, 190].includes(event.keyCode)) {
       // let it happen, don't do anything
    } else {
       // Ensure that it is a number and stop the keypress
      if ((event.keyCode < 48 || event.keyCode > 57 ) && ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105].includes(event.keyCode) ){
        event.preventDefault();
      }
    }
  })
;}
