const moment = require('moment');

export const consultantAndAdminEmailValidator = (value) => {
  if (!presentValidator(value)) {
    return false;
  }

  if (!emailFormatValidator(value)) {
    return false;
  }

  let result = false;

  $.ajax({
    url: Routes.consultant_and_admin_email_validator_quizzes_path(),
    type: 'get',
    data: { email: value },
    async: false,
    success: function (data) {
      result = data.isConsultantOrAdminEmail !== true;
    },
    error: function () {
      result = false;
    },
  });

  return result;
};

export const emailFormatValidator = (value) => {
  if (!presentValidator(value)) {
    return false;
  }

  return /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(value);
};

export const presentValidator = (value) => {
  return value.length > 0;
};

export const phoneNumberFormatValidator = (value) => {
  if (!presentValidator(value)) {
    return false;
  }

  return /^\d{8}$/.test(value);
};

export const otherPhoneNumberFormatValidator = (value) => {
  if (!presentValidator(value)) {
    return false;
  }

  return /^\d{4,16}$/.test(value);
};

export const dobInThePastValidator = (value) => {
  if (!presentValidator(value)) {
    return false;
  }

  const inputDate = moment(value);
  const currentDate = moment(new Date());

  return currentDate.isAfter(inputDate);
};

export const setupValidatorBaseOnCountryCode = (phoneNumberSelector, countryCodeSelector) => {
  if ($(countryCodeSelector).val() == '+65') {
    phoneNumberSelector.validators = [presentValidator, phoneNumberFormatValidator];
  } else {
    phoneNumberSelector.validators = [presentValidator, otherPhoneNumberFormatValidator];
  }
};
