import { getAge } from '@/layouts/application/utils/calculateAgeByInput';

export default function updateAgeByInput(inputDate, ageShow) {
  setDobOnSyncToSelectedClientChange(inputDate);
  const ageShowSelector = $(ageShow);
  ageShowSelector.html(calculateAge(inputDate));
  $(inputDate).on('change', () => {
    ageShowSelector.html(calculateAge(inputDate));
  });
}

export const calculateAge = (inputDate) => {
  const birthDate = new Date($('#date-of-birth-data').data('dob-data'));
  const expectedDate = new Date($(inputDate).val());

  const age = getAge(birthDate, expectedDate);

  return age;
};

const setDobOnSyncToSelectedClientChange = (inputDate) => {
  const jointDob = $('#date-of-birth-data').data('dob-data');
  $('.sync-to-client-radio').on('change', ({ target }) => {
    if (!target.value) {
      $('#date-of-birth-data').data('dob-data', jointDob);
      $(inputDate).trigger('change');
      return;
    }

    const individualContacts = $('#merged-contact-ids').data('value');
    const contact = individualContacts.find((contact) => contact[0] == target.value);
    if (contact) {
      $('#date-of-birth-data').data('dob-data', contact[1]);
      $(inputDate).trigger('change');
    }
  });
};
