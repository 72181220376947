import Raicon from 'raicon';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';
import { CurrencyHandler } from '../shared/currencyHandle';

class ClientsExpensesController {
  constructor() {
    this.currencyHandler = new CurrencyHandler(
      '#currency-select',
      '#currency-select-business-salary',
      '#property-currency',
      '#property-currency-business-salary',
    );
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    handleMoneyField();
    this.handleLogicShowAge();
    handleHideSignSgd('#currency-select', '.property-currency');
    this.currencyHandler.currencyChangeHandler();
  }

  edit() {
    handleMoneyField();
    this.handleLogicShowAge();
    handleLockForm(['#edit_expense']);
    handleHideSignSgd('#currency-select', '.property-currency');
    this.currencyHandler.currencyChangeHandler();
    $('#currency-select').trigger('change');
  }

  create() {}

  newExpensesSelection() {}

  handleLogicShowAge() {
    let initialValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));

    $('#age-value').html(initialValue);

    $('#expense_end_date').on('change', () => {
      let ageValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
      $('#age-value').html(ageValue);
    });
  }
}

Raicon.register('clients/expenses', ClientsExpensesController);
