export default class ServiceMatrix {
  constructor() {
    this.activeButtonSelector = '#service-matrix-active-btn';
  }

  setup() {
    this.addActiveEvent();
  }

  addActiveEvent() {
    $(this.activeButtonSelector).on('click', (e) => {
      e.preventDefault();
      const status = e.target.textContent;
      const activated = status === 'Active' ? false : true;
      const data = { activated: activated }

      $.post(
        Routes.update_service_matrix_active_consultant_automations_path(),
        {
          data: data
        },
        data => {
          if (data.activated) {
            $(this.activeButtonSelector).text('Active');
            $(this.activeButtonSelector).addClass('active-btn');
            $(this.activeButtonSelector).removeClass('inactive-btn');
          } else {
            $(this.activeButtonSelector).text('Inactive');
            $(this.activeButtonSelector).addClass('inactive-btn');
            $(this.activeButtonSelector).removeClass('active-btn');
          }
        }
      )
    });
  }
}
