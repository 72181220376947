import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';

document.addEventListener('turbolinks:load', () => {
  $('body').on('modal:afterSetContent', '#merge-contact-form', () => {
    const showTabByMergingPurpose = (purpose) => {
      if (purpose == 'joint_planning') {
        $('#joint-planning-wrapper').removeClass('d-none');
        $('#merge-contact-wrapper').addClass('d-none');
      } else {
        $('#merge-contact-wrapper').removeClass('d-none');
        $('#joint-planning-wrapper').addClass('d-none');
      }
    };

    const toggleTab = () => {
      $('.merging-purpose-selection').on('change', ({ target }) => {
        showTabByMergingPurpose(target.value);
      });
      const mergingPurpose = $('input[name="merge_contacts[merging_purpose]"]:checked').val();
      if (mergingPurpose) showTabByMergingPurpose(mergingPurpose);
    };

    const handleListClient = () => {
      let previousSelectedValue1;
      let previousSelectedValue2;

      $('.list-contact1').on('change', ({ target }) => {
        $(`.list-contact2 option[value="${previousSelectedValue2}"]`).prop('disabled', false);
        const selectedValue = target.value;
        previousSelectedValue2 = selectedValue;
        $(`.list-contact2 option[value="${selectedValue}"]`).prop('disabled', true);
      });
      const baseClientId1 = $('.list-contact1').data('client-id');
      if (baseClientId1) $('.list-contact1').val(baseClientId1).trigger('change');

      $('.list-contact2').on('change', ({ target }) => {
        $(`.list-contact1 option[value="${previousSelectedValue1}"]`).prop('disabled', false);
        const selectedValue = target.value;
        previousSelectedValue1 = selectedValue;
        $(`.list-contact1 option[value="${selectedValue}"]`).prop('disabled', true);
      });
      const baseClientId2 = $('.list-contact2').data('client-id');
      if (baseClientId2) $('.list-contact2').val(baseClientId2).trigger('change');
    };

    const handlePreviewDataAndFillDataForSelection = () => {
      $('.list-contact1, .list-contact2').on('select2:select', () => {
        fillPreviewData();
      });
    };

    const fillPreviewData = () => {
      const contact1Id = $('.list-contact1').val();
      const contact2Id = $('.list-contact2').val();
      if (!(contact1Id || contact2Id)) return;

      $.get(
        Routes.get_info_merge_contacts_consultant_clients_path({ contact1_id: contact1Id, contact2_id: contact2Id }),
      ).done((data) => {
        const { data: contact1 } = data.contact1;
        const { data: contact2 } = data.contact2;

        const contact1Dob = new Date(contact1.attributes.dob);
        const contact2Dob = new Date(contact2.attributes.dob);

        if (contact1Dob != 'Invalid Date' && contact2Dob != 'Invalid Date') {
          let dob = contact1Dob < contact2Dob ? contact1Dob : contact2Dob;
          const dobString = `${dob.getDate()}/${dob.getMonth() + 1}/${dob.getFullYear()}`;
          const dobText = $('.datepicker[placeholder="Date of Birth"][type="text"]');

          $("input[name='merge_contacts[dob]']").val(dobString);
          $(dobText[0]).val(dobString);
          calculateAgeByInput('#dob', '.input-age');
        }

        [contact1, contact2].forEach(({ attributes }, index) => {
          const contactNumber = index + 1;
          $(`.email-contact-${contactNumber}`).text(attributes.email || '--');
          $(`.mobile-contact-${contactNumber}`).text(attributes.phone_number || '--');
          $(`.dob-contact-${contactNumber}`).text(attributes.dob || '--');
          $(`.gender-contact-${contactNumber}`).text(attributes.gender || '--');
        });

        const baseContactSelection = [
          {
            id: contact1.id,
            text: contact1.attributes.full_name,
          },
          {
            id: contact2.id,
            text: contact2.attributes.full_name,
          },
        ];
        $('.list-base-contact').empty();
        $('.list-base-contact').select2({
          placeholder: 'Choose a base contact',
          data: baseContactSelection,
        });

        const usernameContactSelection = [
          {
            id: contact1.attributes.username,
            text: `${contact1.attributes.username} (from ${contact1.attributes.full_name})`,
          },
          {
            id: contact2.attributes.username,
            text: `${contact2.attributes.username} (from ${contact2.attributes.full_name})`,
          },
        ];
        $('.list-username-contact').empty();
        $('.list-username-contact').select2({
          placeholder: 'Choose a username to use',
          data: usernameContactSelection,
        });
      });
    };

    const triggerValidation = () => {
      const fields = [
        '.list-contact1',
        '.list-contact2',
        '.list-base-contact',
        '.action-for-other-account',
        '.account-name-input',
        '.dob-input',
        '.email-input',
        '.mobile-number-input',
        '.username-input',
      ];
      fields.forEach((field) => {
        $(field).on('change', () => {
          $(field).parent().parent().find('.invalid-feedback').hide();
        });
      });
    };

    const handleMergeContacts = () => {
      toggleTab();
      handlePreviewDataAndFillDataForSelection();
      handleListClient();
      fillPreviewData();
      triggerValidation();
    };

    handleMergeContacts();

    $('body').on('modal:afterSetContent', '#joint-merge-contact-form', () => {
      handleMergeContacts();
      $('.invalid-feedback.show').show();
    });
  });
});
