import Raicon from 'raicon';
import SignaturePad from 'signature_pad'


class NonDisclosureAgreements {
  new(){
    this.initSignaturePad();
  }

  create(){}

  initSignaturePad(){
    const btnSubmit = $('#btn-submit-form');
    const canvas = document.querySelector("canvas");
    const clearSignaturePadButton = $('.signature_pad_clear');
    const signaturePad = new SignaturePad(canvas);
    const signatureInput = $('.signature_pad_input');

    clearSignaturePadButton.on('click', () => {
      signaturePad.clear();
      btnSubmit.prop('disabled', 'disabled')
    });

    signaturePad.addEventListener("endStroke", () => {
      signatureInput.val(signaturePad.toDataURL());
      btnSubmit.prop('disabled', '')
    });
  }
}

Raicon.register('users/sessions/nonDisclosureAgreements', NonDisclosureAgreements);
