const moment = require("moment");
export const changeStatusTask = (btnTrigger, outerId , completedId, openedOverdueId, openedTodayId, uncheckClass, checkedClass) => {
    $(document).on('change', btnTrigger, function(e) {
        const $this = e.target;
        const taskId = $($this).data('id');
        const duedate = $($this).data('duedate');
        const is_complete = $($this).is(":checked");
        const is_call = $($this).data('call');
        const btnLog = $('#log-call');
        const isRecurring = $($this).data('recurring');
        let data = {};
        let status_task = 'completed';

        if(is_complete){
            data = {
                task: {status: status_task},
                recurring_status: { recurring_status: status_task, recurring_duedate: duedate }
            };
        } else {
            status_task = duedate === '' || duedate === null ? 'backlog' : 'open'
            data = {
                task: {status: status_task},
                recurring_status: { recurring_status: status_task, recurring_duedate: duedate }
            };
        }

        $.ajax({
            url: Routes.mark_completed_consultant_task_path(taskId),
            type: 'PUT',
            dataType: 'json',
            data: data,
            success: function(res) {
                if(res.status === 200){
                    const now = moment().format("YYYY-MM-DD");
                    const date = res.task.date;
                    const completedAt = res.task.completed_at;
                    const overdueTask = $(outerId).find(openedOverdueId);
                    const openTask = $(outerId).find(openedTodayId);
                    const futureTask = $(outerId).find(`#${res.task.date}`);
                    const containerTask = $(outerId).find(`#task-${taskId}-${duedate}`);
                    if(is_complete){
                        if(is_call){
                            btnLog.attr("href", Routes.new_log_call_consultant_task_path(taskId, { recurring_duedate: duedate}));
                            btnLog[0].click();
                            btnLog.attr("href", '#');
                        }

                        if(isRecurring) {
                          containerTask.remove();
                          return;
                        }
                        containerTask.find(`.${uncheckClass}`).removeClass(uncheckClass).addClass(checkedClass);
                        containerTask.find('.text-danger').removeClass('text-danger').addClass('text-dark');
                        containerTask.find('.align-self-start').remove();
                        containerTask.find('.task-description').addClass('d-none');

                        const completedHTML = `<div class="align-self-start text-dark completed_at">${moment(completedAt).format("DD MMM YYYY")}</div>`;
                        containerTask.append(completedHTML);

                        const html = containerTask[0].outerHTML;
                        $(completedId).append(html);
                        containerTask.remove();
                        if (now > date && overdueTask.find('.d-flex').length == 0){
                            overdueTask.addClass('d-none');
                        } else if (now === date && openTask.find('.d-flex').length == 0) {
                            openTask.addClass('d-none');
                        } else {
                          if($(outerId).find(`[id^="task-"][id$="${res.task.duedate}"]`).length == 0){
                            futureTask.addClass('d-none');
                          }
                        }
                        $(`.${checkedClass}`).prop('checked', true);
                    } else {
                        containerTask.find(`.${checkedClass}`).removeClass(checkedClass).addClass(uncheckClass);
                        if(status_task === 'open'){
                            if(now > date){
                                containerTask.find('#open-edit-task').removeClass('text-dark').addClass('text-danger');
                                containerTask.find('.align-self-start').removeClass('text-dark').addClass('text-danger');
                            }
                            containerTask.find('.blog').addClass('d-none');
                            containerTask.find('.completed_at').addClass('d-none');
                            containerTask.find('.task-description').removeClass('d-none');
                            if(is_call) {
                              containerTask.find('.log-call').addClass('d-none');
                            }

                            let html = containerTask[0].outerHTML;
                            if (now > date){
                                overdueTask.append(html);
                                overdueTask.removeClass('d-none');
                            } else if (now === date) {
                                openTask.append(html);
                                openTask.removeClass('d-none');
                            } else {
                                futureTask.append(html);
                                futureTask.removeClass(('d-none'));
                            }
                        } else {
                            containerTask.find('.completed_at').addClass('d-none');
                            const html = containerTask[0].outerHTML;
                            $('#task-backlogs').append(html);
                        }
                        containerTask.remove();
                        $(`.${uncheckClass}`).prop('checked', false);
                    }
                } else {
                    alert("Something wrong. Please try again.");
                }
            }
        })
    })
}
