import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import {
  emailFormatValidator,
  presentValidator,
  phoneNumberFormatValidator
} from '@/layouts/application/utils/validators';

class ClientsMarketplacesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index(){}

  getRedeemMarketplace() {
    $(document).ready(() => {
      let submitButton = $('input[type="submit"]');
      let acknowledgeButton = $('#consent_given');
      if (submitButton.val() !== 'Redeemed') {
        this.checkAcknowledgeButton();

        $('.client-marketplace .simple_form').on('click keyup', (e) => {
          let currentStepValidationPassed = true;
          const requiredFields = [
            {
              selector: '#user_email',
              validators: [presentValidator, emailFormatValidator],
            },
            {
              selector: '#user_phone_number',
              validators: [presentValidator, phoneNumberFormatValidator],
            },
            {
              selector: '#user_first_name',
              validators: [presentValidator],
            },
            {
              selector: '#user_last_name',
              validators: [presentValidator],
            },
          ];

          requiredFields.forEach(function(fieldInfor) {
            const fieldInput = $(fieldInfor.selector);
            let isValidField = true;

            fieldInfor.validators.forEach(function(validatorMethod) {
              isValidField = validatorMethod(fieldInput.val());

              if (!isValidField) {
                currentStepValidationPassed = false;
                fieldInput.addClass('is-invalid').removeClass('is-valid');
                fieldInput.parent().parent().find('.invalid-feedback').show();
              } else {
                fieldInput.addClass('is-valid').removeClass('is-invalid');
                fieldInput.parent().parent().find('.invalid-feedback').hide();
              }
            });
          });

          if (currentStepValidationPassed && (acknowledgeButton.is(':checked') || acknowledgeButton.length == 0 )) {
            submitButton.prop('disabled', false);
          } else {
            submitButton.prop('disabled', true);
          }
        });
      }
    });
  }

  checkAcknowledgeButton() {
    let submitButton = $('input[type="submit"]');
    let acknowledgeButton = $('#consent_given');
    if (acknowledgeButton.is(':checked') || acknowledgeButton.length == 0) {
      submitButton.prop('disabled', false);
    } else {
      submitButton.prop('disabled', true);
    }
  }
}

Raicon.register('clients/marketplaces', ClientsMarketplacesController)
