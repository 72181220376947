import Raicon from 'raicon';

class RequestAccessesController {
  create() {
    this.closeModal();
    const isSubmitSuccess = $('#modal-message').data('submit-success')

    if (isSubmitSuccess) {
      $('#modal-message').modal('show');
    }
  }

  closeModal() {
    $('#modal-message').on('hide.bs.modal', (e) => {
      window.location.replace("/");
    })

    // Using when redirect after render error
    $('body').on('click', '#btn-close', (e) => {
      window.location.replace("/");
    })
  }
}

Raicon.register('requestAccess/requestAccesses', RequestAccessesController);
