import Raicon from 'raicon';
import InvestmentIllustratorChart from './investmentIllustratorChart';

class ConsultantInvestmentIllustratorsController {
  constructor() {
    this.investmentIllustratorChartContentSelector = '#investment-illustrator-chart-container'
  }

  index() {
    new InvestmentIllustratorChart($(this.investmentIllustratorChartContentSelector)).setup();
  }
}

Raicon.register('consultant/investmentIllustrators', ConsultantInvestmentIllustratorsController);
