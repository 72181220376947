import { autoFormatMoneyFields } from "@/layouts/application/utils/handleMoneyField";
import handleMoneyField from "@/layouts/application/utils/handleMoneyField";

export default class ProductForm {
  constructor() {
    this.addNewProductSelector = '#add-new-product';
    this.removeProductionSelector = '.remove-product';
    this.productTypeSelector = '.product-type';
    this.statusRatio = 'input[name="case[status]"]';
    this.inputCompleted = '#date-completed';

    this.setUpCaseForm();
  }

  setUpCaseForm() {
    this.autoRemoveZero();
    this.autoAddProduct();
    this.handleProductLabel();
    this.handleRemoveProductButtonOnOpen();
    this.handleOnAddProduct();
    this.handleOnRemoveProduct();
    this.showHideCompletedDate();
    handleMoneyField();
  }

  showHideCompletedDate() {
    let oldStatus = $(`${this.statusRatio}:checked`).val();
    const currentStatus = oldStatus;
    const currentDateCompleted = this.getCurrentDateCompleted()

    if (!oldStatus) {
      return false;
    }

    oldStatus = oldStatus.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    $(this.inputCompleted).find('input').attr('placeholder', `Date ${oldStatus}`)
    $(this.inputCompleted).find('label').html(`Date ${oldStatus}<abbr title="required">*</abbr>`)

    $(this.statusRatio).on('change', (e) => {
      let value = e.target.value;

      value = value.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
      $(this.inputCompleted).find('label').html(`Date ${value}<abbr title="required">*</abbr>`)
      $(this.inputCompleted).find('input').attr('placeholder', `Date ${value}`)

      if (value.toLowerCase() != currentStatus) {
        $(this.inputCompleted).find('input').val('');
      } else {
        $(this.inputCompleted).find('input').val(currentDateCompleted);
      }
    })
  }

  getCurrentDateCompleted() {
    const currentDateCompleted = $('#date-completed input').val();
    if (!currentDateCompleted) return '';

    const year = currentDateCompleted.slice(0, 4);
    const month = currentDateCompleted.slice(5, 7);
    const day = currentDateCompleted.slice(8, 10);

    return day + '/' + month + '/' + year
  }

  autoRemoveZero() {
    $(this.addNewProductSelector).on('click', function () {
      $(document).on("cocoon:after-insert", function () {
        $('.input-wapi').on('focus', (e) => {
          if (Number(e.target.value) == 0) {
            e.target.value = '';
          }
        });
      });
    });
  }

  autoAddProduct() {
    if ($('.nested-fields.product-fields').length < 1) {
      $(this.addNewProductSelector).trigger('click');
    }
    else {
      const $lastCase = $('.case-fields').last();
      if ($($lastCase).find('.product-fields').length < 1) {
        $lastCase.find('#add-new-product').trigger('click');
      }
    }
  }

  handleOnAddProduct() {
    $(this.addNewProductSelector).on('click', () => {
      $(document).on("cocoon:after-insert", (e) => {
        $('form.simple_form input.money').on('keyup', (e) => {
          autoFormatMoneyFields(e.target)
        })
      
        let $cur_case = e.target.closest('.case-fields');
        if ($($cur_case).length < 1) {
          $cur_case = $('#what-next-field .case-fields').last();
        }
        if ($($cur_case).length > 0) {
          this.handleRemoveProductButtonOnChangeMultiCases($cur_case);
        }
        else {
          this.handleOnChangeProduct();
        }
      });
    });
  }

  handleOnRemoveProduct() {
    $(this.removeProductionSelector).on('click', () => {
      $(document).on("cocoon:after-remove", (e) => {
        const $cur_case = e.target.closest('.case-fields');
        if ($($cur_case).length > 0) {
          this.handleRemoveProductButtonOnChangeMultiCases($cur_case);
        }
        else {
          this.handleOnChangeProduct();
        }
      });
    });
  }

  handleRemoveProductButtonOnOpen() {
    const $cases = $('.case-fields');
    if ($cases.length > 0) {
      for (let i = 0; i < $($cases).length; i++) {
        let productsCount = $($cases[i]).find('.product-fields').length;
        if (productsCount < 2) {
          $($cases[i]).find('.remove-product').hide();
        }
        else {
          $($cases[i]).find('.remove-product').show();
        }
      }
    }
    else {
      const productsCount = $('.product-fields').length;

      if (productsCount < 2) {
        $(this.removeProductionSelector).hide();
      }
      else {
        $(this.removeProductionSelector).show();
      }
    }
  }

  handleOnChangeProduct() {
    this.handleRemoveProductButtonOnChange();
    this.handleProductLabel();
  }

  handleRemoveProductButtonOnChange() {
    const productsCount = $('.product-fields:visible').length;
    if (productsCount < 2) {
      $(this.removeProductionSelector).hide();
    }
    else {
      $(this.removeProductionSelector).show();
    }
  }

  handleProductLabel() {
    $(this.productTypeSelector).on('change', (e) => {
      const typeSelected = $(e.target).val();
      const productTarget = e.target.closest('.product-fields');
      const $productLabel = $(productTarget).find('.product-label');
      const displayLabel = this.convertTypeName(typeSelected);

      $productLabel.html(displayLabel);
    });
  }

  convertTypeName(type) {
    const nameDict = {
      health: 'Health',
      whole_life: 'Whole Life',
      accident: 'Accident',
      endowment: 'Endowment',
      ilp: 'ILP',
      term: 'Term'
    }
    return nameDict[type] || '';
  }

  handleRemoveProductButtonOnChangeMultiCases($cur_case) {
    const productsCount = $($cur_case).find('.product-fields:visible').length;
    if (productsCount < 2) {
      $($cur_case).find('.remove-product').hide();
    }
    else {
      $($cur_case).find('.remove-product').show();
    }
  }

}
