import 'dropzone';

export const initDropzone = (options) => {
  Dropzone.autoDiscover = false;
  const maxFilesAllowed = 40;
  const { formSelector, templateSelector, previewsContainerSelector, uploadMultiple, acceptedFiles } = options;

  const dropzoneOptions = {
    maxFiles: uploadMultiple ? maxFilesAllowed : 1,
    acceptedFiles: acceptedFiles ? acceptedFiles : null,
    autoProcessQueue: false,
    previewsContainer: previewsContainerSelector,
    addRemoveLinks: true,
    dictRemoveFile: '',
    maxFilesize: 20, // MB
  };

  if (document.querySelector(templateSelector)) {
    dropzoneOptions.previewTemplate = document.querySelector(templateSelector).innerHTML;
  }

  const dropzoneObject = new Dropzone(formSelector, dropzoneOptions);

  dropzoneObject.on('error', (file) => {
    dropzoneObject.removeFile(file);
  });

  dropzoneObject.on('addedfile', (file) => {
    const $file = $(file.previewElement);
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length);

    $file.find('.dz-file-logo').addClass(`default ${fileType}`);
  });

  return dropzoneObject;
};
