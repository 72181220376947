import { getAge } from '@/layouts/application/utils/calculateAgeByInput';

export default class RetirementTargetForm {
  constructor() {
    this.clientDob = new Date($('.main-content').data('client-dob'));
    this.retirementAgeInput = '.retirement-age-input';
    this.yearOfRetirementInput = '.year-of-retirement-input';
    this.retirementYearSelector = '.retirement-year-selector';
    this.yearOfRetirementAgeSelector = '.years-of-retirement-age-selector';
  }

  setup() {
    this.setAgeYearCalculateEvent();
  }

  setAgeYearCalculateEvent() {
    const currentYear = new Date().getFullYear();
    const clientAge = getAge(this.clientDob);

    $(this.retirementAgeInput).on('input', (event) => {
      const retirementYear = parseInt(event.target.value);
      const year = currentYear + retirementYear - clientAge;

      $(this.retirementYearSelector).html(year);
    });

    $(this.yearOfRetirementInput).on('input', (event) => {
      const yearOfRetirement = parseInt(event.target.value);
      const year = currentYear + yearOfRetirement - clientAge;

      $(this.yearOfRetirementAgeSelector).html(year);
    });
  }
}
