import { formatCurrencyToNumber, formatCurrency } from '@/layouts/application/utils/formats';

export default class AssetCpfForm {
  constructor() {
    this.formSelector = '#asset-cpf-account-form';
    this.ordinaryAccountInputSelector = '#ordinary-account-input';
    this.specialAccountInputSelector = '#special-account-input';
    this.mediSaveAccountInputSelector = '#medisave-account-input';
    this.retirementAccountInputSelector = '#retirement-account-input';
    this.totalCpfSelector = '#total-cpf-value';
  }

  setup() {
    this.addOnchangeForInputs();
  }

  addOnchangeForInputs() {
    $(this.formSelector).on(
      'input',
      `${this.ordinaryAccountInputSelector}, ${this.specialAccountInputSelector}, ${this.mediSaveAccountInputSelector}, ${this.retirementAccountInputSelector}`,
      () => {
        const ordinaryValue = formatCurrencyToNumber($(this.ordinaryAccountInputSelector).val());
        const specialValue = formatCurrencyToNumber($(this.specialAccountInputSelector).val());
        const medisaveValue = formatCurrencyToNumber($(this.mediSaveAccountInputSelector).val());
        const retirementValue = formatCurrencyToNumber($(this.retirementAccountInputSelector).val());
        const totalCpfValue = formatCurrency(ordinaryValue + specialValue + medisaveValue + retirementValue);

        $(this.totalCpfSelector).html(totalCpfValue);
      },
    );
  }
}
