const handleDeletePolicyBenefitItem = () => {
  Array.from($('.btn-delete-benefit')).forEach((btnDelete) => {
    $(btnDelete).on('click', () => {
      $(btnDelete).parent().parent().remove();
    });
  });
};

const handleAddMorePolicyBenefit = () => {
  $('.add-more-policy-benefit').on('mouseout', () => {
    handleDeletePolicyBenefitItem();
  });
};

const handlePolicyBenefit = () => {
  handleDeletePolicyBenefitItem();
  handleAddMorePolicyBenefit();
};

export { handlePolicyBenefit };
