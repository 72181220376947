import Raicon from 'raicon';
import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import {
  handleOpenPreviewModal,
  copyDynamicTagName,
  handleAttachFileChange,
  setupEditor,
  handleShowRealClientName,
  handleDeleteAttachFile,
  resetCssBeforeSave,
  handlePreviewMedia,
} from '../../consultant/shared/campaignTemplateUtil';
class EmailTemplatesController {
  constructor() {
    this.imageSelector = '#editor-image';
    this.attachFileSelector = '#attach-file';
    this.fileContainer = '#file-container';
    this.editorSelector = '.editor';
    this.destroyMultipleSelector = '#btn-destroy-email-templates';
    this.filterTemplateName = '.filter-template-name';
    this.previewEmailTemplate = '#preview-email-template .text-container';
    this.emailTemplateForm = '#email-template-form';
    this.addCategory = '#add-category';
    this.editor = null;
  }

  index() {
    toggleTdTable();
    checkboxSelected(this.destroyMultipleSelector, 'template');
    this.setupPreviewModal();
  }

  new() {
    this.setup();
  }

  create() {
    this.setup();
  }

  edit() {
    this.setup();
    this.setupPreviewModal();
  }

  update() {
    this.setup();
    this.setupPreviewModal();
  }

  show() {
    handlePreviewMedia();
  }

  setup() {
    setupEditor().then((editor) => (this.editor = editor));
    handleAttachFileChange();
    copyDynamicTagName();
    handleShowRealClientName();
    handleDeleteAttachFile();
    resetCssBeforeSave();
    this.setValueForCategory();
  }

  setupPreviewModal() {
    let showPath = 'admin_email_template_path';
    let sendTestEmailPath = 'send_test_email_admin_email_template_path';
    handleOpenPreviewModal(showPath, sendTestEmailPath);
  }

  setValueForCategory() {
    const existedOptions = $('#add-category')[0].innerText.split('\n');
    $('.select2').on('keyup', function (e) {
      if (e.keyCode === 13) {
        const options = $('#add-category')
          .val()
          .map((option) => option.split(/[,\s]+/))
          .flat();
        $('#add-category').empty();
        options
          .concat(existedOptions)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
          .forEach((option) => {
            let newOption = new Option(option, option, false, false);
            $('#add-category').append(newOption);
          });
        $('#add-category').val(options).trigger('change');
      }
    });
  }
}

Raicon.register('admin/emailTemplates', EmailTemplatesController);
