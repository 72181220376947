import Raicon from 'raicon';
import OttermiseChart from './ottermiseChart';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';

class ConsultantOttermiseCalculatorsController {
  constructor(){
    this.ottermiseChartContainer = '#ottermise-chart-container'
  }
  
  index(){
    clearInputNumberOnFocus();
    this.presentDataToChart();
  }

  presentDataToChart(){
    new OttermiseChart(this.ottermiseChartContainer).setup();
  }
}

Raicon.register('consultant/ottermiseCalculators', ConsultantOttermiseCalculatorsController);
