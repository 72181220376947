export function displayOtherOptionInputField(selectInput, optionInput) {
    if (selectInput.val().toLowerCase() == 'others') {
        optionInput.show();
    } else {
        optionInput.hide();
    };
}

export function handleWhenSelectOthersValue(selectInput, optionInput) {
    selectInput.on('change', function() {
        if (selectInput.val().toLowerCase() == 'others') {
        optionInput.show();
        } else {
        optionInput.hide();
        }
    });
}