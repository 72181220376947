import Raicon from 'raicon';
import { togglePassword } from '../../clients/shared/togglePassword';
class SessionsController {
  constructor() {
    this.email = '#user_email';
    this.password = '#user-password';
    this.userLogin = '#user-login';
    this.loginForm = '#login-form';
    this.alertContainer = '#alert-container';
    this.closeAlertBtn = '#close-alert-btn';
    this.loginBtn = '#login-btn';
    this.biometricBtn = '#biometric-btn'
  }

  new() {
    togglePassword();
    this.hideFooterOnClientPortal();

    if (navigator.userAgent.indexOf('median') > -1) {
      this.setUpLoginForm();
      this.setUpCloseAlertBtn();
      this.setupBiometricLogin();
    }
  }

  create() {
    this.hideFooterOnClientPortal();
  }

  hideFooterOnClientPortal() {
    if (navigator.userAgent.indexOf('median') > -1) {
      this.hideFooterEvent(this.email);
      this.hideFooterEvent(this.password);
    }
  }

  hideFooterEvent(element) {
    $(element).on('focus', () => {
      $('.footer').hide();
    });

    $(element).on('blur', () => {
      $('.footer').show();
    });
  }

  showAlert(msg) {
    $(`${this.alertContainer} .alert-msg`).html(msg);
    $(this.alertContainer).show();
  }

  setUpCloseAlertBtn() {
    $(this.closeAlertBtn).on('click', () => {
      $(this.alertContainer).hide();
    })
  }

  login() {
    const formElement = $(this.loginForm)[0];
    const formData = new FormData(formElement);
 
    $.ajax({
      type: 'POST',
      url: Routes.user_session_path(),
      data: formData,
      processData: false,
      contentType: false
    }).done((data) => {
      // redirect to client dashboard

      // save password to local storage to enable biometric login for the first time
      localStorage.setItem('secretPassword', $(this.password).val());

      const secretUsername = localStorage.getItem('secretUsername').toLowerCase();
      const username = $(this.userLogin).val().toLowerCase();
      const loginWithDifferentUser = secretUsername != username;

      if (loginWithDifferentUser) median.auth.delete({'callbackFunction': (result) => {}});
    }).fail((data) => {
      this.showAlert(data.responseText);
      median.auth.delete({'callbackFunction': (result) => {}});
    }).always(() => {
      $(this.loginBtn).prop('disabled', false);
    });
  }

  setUpLoginForm() {
    $(this.loginForm).on('submit', (e) => {
      e.preventDefault();
      this.login();
    });
  }

  setupBiometricLogin() {
    $(this.biometricBtn).on('click', () => {
      let username = $(this.userLogin).val();

      if (username == '') {
        this.showAlert('Please enter your username to use biometric login');
        return;
      }

      median.auth.status({'callbackFunction': (result) => {
        if (result && result.hasTouchId) {
          if (result.hasSecret) {
            median.auth.get({'callbackFunction': (result) => this.medianSecretCallback(result, username)});
          } else {
            this.showAlert('Please login to your account first and enable biometrics from finConnect settings');
          }
        } else {
          this.showAlert('You will need to set up your biometric from your device setting first before enabling biometric login for finConnect');
        }
      }});
    });
  }

  medianSecretCallback(data, username) {
    if (data && data.success && data.secret) {
      const credentials = JSON.parse(data.secret);

      // store secret username to check if login with different user
      localStorage.setItem('secretUsername', credentials.username);

      if (credentials.username.toLowerCase() != username.toLowerCase()) {
        this.showAlert('Please login to your account first and enable biometrics from finConnect settings');
        return;
      }

      const password = credentials.password;
      $(this.password).val(password);

      this.login();
    } else {
      this.showAlert('Please enter your username and password');
    }
  }
}
Raicon.register('users/sessions', SessionsController);
