import setupCpfLifeChart from '@/layouts/application/utils/charts/setupCpfLifeChart';
export default class CpfLife {
  constructor() {
    this.modal = $('#estimated_cpf_life_payout_modal');
  }

  setup() {
    this.setupModal();
  }

  setupModal() {
    setupCpfLifeChart();
    this.modal.on('hidden.bs.modal', () => {
      let url = new URL(location.href);
      url.search = '';
      window.history.replaceState({}, document.title, url.toString());
    });
    this.modal.modal('show');
  }
}
