const calculationForOutstandingDurationYearTarget = (formSelector, outstandingDurationInputSelector, monthlyOutstandingDurationInputSelector, targetYearvalueSelector) => {
  $(formSelector).on(
    'input',
    `${outstandingDurationInputSelector}, ${monthlyOutstandingDurationInputSelector}`,
    () => {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const yearlyDuration = parseInt($(outstandingDurationInputSelector).val()) || 0;
      const monthlyDuration = parseInt($(monthlyOutstandingDurationInputSelector).val()) || 0;
      let targetYear = currentYear + yearlyDuration;
      let month = currentMonth + monthlyDuration + 1;

      if (month > 12) {
        targetYear += 1;
      }

      if (Number.isInteger(targetYear)) {
        $(targetYearvalueSelector).html(targetYear);
      }
    }
  );
}

export default calculationForOutstandingDurationYearTarget;
