export default class CaseActivities {
  constructor() {
    this.caseFilter = '#select-case-status';
    this.caseOverviewContent = '#case-overview-content';
    this.caseTab = '#case';
  }

  setup() {
    $(this.caseFilter).on('change', () => {
      const status = $(this.caseFilter).val();
      const user_id = $(this.caseTab).attr('data-user-id');
      const params = { client_id: user_id, case_status: status };
      const routes = Routes.case_filter_consultant_client_biodata_path(params);

      $(this.caseOverviewContent).load(routes);
    });
  }
}
