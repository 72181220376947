import { waitForElement } from '@/helpers';

$(document).on('turbolinks:load', function () {
  setupNotifications();
  initNotificationAfterLogin();
});

const setupNotifications = () => {
  $('body').on('modal:afterSetContent', '#open-notifications-modal', () => {
    initHeight();
    showVideoModal();
    markReadActivityAndEnhancement();
  });
};

const initNotificationAfterLogin = () => {
  const btnOpenNotifications = document.getElementById('open-notifications-modal');
  if (!btnOpenNotifications) return;

  if (btnOpenNotifications.getAttribute('data-is-show-enhancement') === 'true') {
    $('#main-modal').css('z-index', 9999);
    btnOpenNotifications.click();

    // Set timeout for click change tab after open modal
    waitForElement('#enhancement-tab').then((val) => {
      $(val).click();
    });
    return;
  }

  if (btnOpenNotifications.getAttribute('data-is-show-filled-form') === 'true') {
    $('#main-modal').css('z-index', 9999);
    btnOpenNotifications.click();
    waitForElement('#activity-tab').then((val) => {
      $(val).click();
    });
  }
};

const markReadActivityAndEnhancement = () => {
  $('#enhancement-tab').on('click', () => {
    $.ajax({
      url: Routes.mark_read_merlin_enhancement_consultant_notifications_path(),
      type: 'PUT',
      dataType: 'json',
      success: () => {
        if ($('#enhancement-dashboard').length > 0) {
          $('#enhancement-dashboard').find('.circle-red').remove();
        }
      },
    });
  });

  $('#activity-tab').on('click', () => {
    $.ajax({
      url: Routes.mark_read_filled_form_activity_consultant_notifications_path(),
      type: 'PUT',
      dataType: 'json',
      success: (data) => {
        if (data.read_all) {
          $('#unread-notifications').addClass('d-none');
        }
      },
    });
  });
};

const initHeight = () => {
  const offset = 261;
  const windowHeight = $(window).height();
  const height = windowHeight - offset;

  $('.activities-list').css('height', `${height}px`);
  $('.enhancement-list').css('height', `${height}px`);
};

const setBaseHeight = () => {
  $('.activities-list').css('height', '475px');
  $('.enhancement-list').css('height', '475px');
};

const showVideoModal = () => {
  const unreadNotificationsElement = $('#unread-notifications');
  const videoModal = $('#enhancement-modal');
  const ytPlayer = $('.ytplayer');

  $('.open-modal').click(function () {
    ytPlayer.removeAttr('style');
    const $this = $(this);
    $.ajax({
      url: Routes.mark_read_consultant_notification_path($this.data('id')),
      type: 'PUT',
      dataType: 'json',
      success: function (data) {
        $(`#merlin-enhance-${$this.data('id')}`)
          .find('.video-new')
          .remove();
        const is_read = data.read_all;
        if (is_read) {
          unreadNotificationsElement.addClass('d-none');
        }
      },
    });
    ytPlayer.attr('src', $this.data('url'));
    videoModal.modal('show');
    videoModal.removeClass('d-none');
    setTimeout(() => {
      ytPlayer.removeAttr('style');
      setTimeout(() => {
        ytPlayer.removeAttr('style');
      }, 1000);
    }, 1000);
  });

  $('.modal-notification .close-btn').click(function (e) {
    videoModal.modal('hide');
    setBaseHeight();
    $('.modal-backdrop').remove();
  });

  $('#enhancement-modal .btn-close-video').click(function () {
    ytPlayer.attr('src', '');
    videoModal.modal('hide');
    videoModal.addClass('d-none');
  });
};
