import Raicon from 'raicon';
import setupReferralCodeDisclaimer from './setupReferralCodeDisclaimer';

class RegistrationsController {
  constructor() {
    this.alertContainer = '#alert-container';
    this.closeAlertBtn = '#close-alert-btn';
    this.newUserForm = '#new-user-form';
    this.username = '#username';
    this.firstName = '#first-name';
    this.phoneNumber = '#phone-number';
    this.referralCode = '#referral-code';
    this.nextBtn = '#next-btn';
  }

  new() {
    this.setup();
  }

  create() {
    this.setup();
  }

  setup() {
    setupReferralCodeDisclaimer();
    this.setupRegistrationForm();
    this.setUpCloseAlertBtn();
    this.setupNextBtn();
  }

  setupRegistrationForm() {
    $(this.newUserForm).on('submit', (e) => {
      let valid = true;
      let error_msg = '';

      let username = $(this.username).val();
      let regex = /^[a-zA-Z0-9_]+$/;
      if (!regex.test(username)) {
        error_msg = error_msg + 'Username must only contain alphanumeric characters and underscores. ';
        valid = false;
      }

      let firstName = $(this.firstName).val();
      regex = /^[a-zA-Z]+$/;
      if (!regex.test(firstName)) {
        error_msg = error_msg + 'First name must only contain alphabetic characters. ';
        valid = false;
      }

      let phoneNumber = $(this.phoneNumber).val();
      regex = /^\d+$/;
      if (!regex.test(phoneNumber)) {
        error_msg = error_msg + 'Phone number must only contain digits. ';
        valid = false;
      }

      let referralCode = $(this.referralCode).val();
      if (referralCode) {
        regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(referralCode)) {
          error_msg = error_msg + 'Referral code must only contain alphanumeric characters. ';
          valid = false;
        }
      }

      if (!valid) {
        e.preventDefault();
        this.showAlert(error_msg);
      }
    });
  }

  setupNextBtn() {
    $(`${this.username}, ${this.firstName}, ${this.phoneNumber}, ${this.referralCode}`).on('keyup', () => {
      $(this.nextBtn).prop('disabled', false);
    });
  }

  showAlert(msg) {
    $(`${this.alertContainer} .alert-msg`).html(msg);
    $(this.alertContainer).show();
  }

  setUpCloseAlertBtn() {
    $(this.closeAlertBtn).on('click', () => {
      $(this.alertContainer).hide();
    })
  }
}

Raicon.register('users/registrations', RegistrationsController);
