import { triggerChangeStatusTask } from "./triggerChangeStatusTask";
import changeParamenterByTab from "@/layouts/application/utils/changeParameterByTab";
const moment = require('moment');
import { triggerCallbackCocoonLog, toggleFormCocoonCollapse } from '@/layouts/application/packages/cocoon';
import TaskForm from '@/controllers/consultant/tasks/formTask';
import ProductForm from '../cases/productForm';

class TaskPage {
  constructor() {
    this.taskPageContainer = '#task-container';
    this.completedBtnSelector = '#completed-btn';
    this.openBtnSelector = '#open-btn';
    this.activeTabBtnSelector = '#active-tab';
    this.weekList = '#week-list';
    this.prevWeekBtn = '#prev-week-tmp';
    this.nextWeekBtn = '#next-week-tmp';
    this.prevWeek = '#prev-week';
    this.nextWeek = '#next-week';
    this.preBtn = '#btn-prev-week';

    document.addEventListener("turbolinks:load", () => {
      this.setup();
    });
  }

  setup() {
    this.renderWeekdays();
    this.addTriggerOpenModalEvents();
    this.renderBtnWeekdays();
    triggerChangeStatusTask('.task-complete, .task-reverse', '#task-container', '#completed-tasks', '#open-task-overdue', '#backlog-task', 'task-complete', 'task-reverse');
    this.hiddenWeek();
    this.disabledWhenAjax();
    if($(this.taskPageContainer).length) {
      changeParamenterByTab();
    }
    this.hideShowPrevWeek();
  }

  hiddenWeek() {
    $(this.completedBtnSelector).on('click', () => {
      $(this.weekList).removeClass('d-none').addClass('d-flex');
      $(this.activeTabBtnSelector).val('completed');
    });

    $(this.openBtnSelector).on('click', () => {
      $(this.weekList).removeClass('d-none').addClass('d-flex');
      $(this.activeTabBtnSelector).val('open');
    });
  }

  hideShowPrevWeek(){
    if($(this.activeTabBtnSelector).val() === 'completed') {
      if($(this.prevWeek).parent().hasClass('opacity-0')) {
        $(this.prevWeek).removeClass('disabled')
       $(this.prevWeek).parent().removeClass('opacity-0');
     }
    }

    $(this.openBtnSelector).on('click', () => {
      if($(this.prevWeekBtn).data('week') <= 0) {
        $(this.openBtnSelector).addClass('disabled')
        $(this.openBtnSelector).parent().addClass('opacity-0');
        window.location.reload();
      }
    })

    $(this.completedBtnSelector).on('click', () => {
       if($(this.prevWeek).parent().hasClass('opacity-0')) {
        $(this.prevWeek).removeClass('disabled')
        $(this.prevWeek).parent().removeClass('opacity-0');
      }
    });
  }

  disabledWhenAjax() {
    $('body').on('click', `${this.prevWeek}, ${this.nextWeek}`, () => {
      const $this = $(this);

      $this.addClass('disabled');
    })
  }

  addTriggerOpenModalEvents() {
    const _this = this;
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task, #open-edit-task, #edit-task-form, #save-task, #how-is-your-call-form', () => {
      new TaskForm().setup();
      $('#open-new-case').on('click', () => {
        $('#what-next-field').on('cocoon:after-insert', () => {
          new ProductForm();
        })
      })

      if($('.case-fields').length > 0) {
        new ProductForm();
      }
    });

    $('body').on('modal:afterSetContent', '#log-call', () => {
      triggerCallbackCocoonLog();
      toggleFormCocoonCollapse();
    })
  }

  renderBtnWeekdays() {
    const offSetWeek = 1;

    $(this.preBtn).find('a').addClass('disabled');

    $(this.nextWeekBtn).on('click', () => {
      let skipWeek = $(this.nextWeekBtn).data('week');
      this.renderWeekdays(skipWeek);

      $(this.nextWeekBtn).data('week', skipWeek + offSetWeek);
      $(this.prevWeekBtn).data('week', skipWeek);
      if (skipWeek === offSetWeek) {
        $(this.preBtn).removeClass('opacity-0').addClass('opacity-1');
        $(this.preBtn).find('a').removeClass('disabled');
      }
    });

    $(this.prevWeekBtn).on('click', () => {
      let skipWeek = $(this.prevWeekBtn).data('week');
      this.renderWeekdays(skipWeek - offSetWeek);
      $(this.prevWeekBtn).data('week', skipWeek - offSetWeek);
      $(this.nextWeekBtn).data('week', skipWeek);
      if (skipWeek === offSetWeek  && $(this.activeTabBtnSelector).val() !== 'completed') {
        $(this.preBtn).removeClass('opacity-1').addClass('opacity-0');
        $(this.preBtn).find('a').addClass('disabled');
      }
    });
  }

  renderWeekdays(numWeek = 0) {
    const arrayList = this.listWeekdays(numWeek);
    const arrayWeek = ['#monday', '#tuesday', '#wednesday', '#thurday', '#friday', '#statuday', '#sunday'];

    for (let i = 0; i <= 6; i++) {
      const day = $(arrayWeek[i]);

      day.removeClass('opacity-text');
      if (day.hasClass("today-text") && day.hasClass('position-relative')) {
        day.removeClass('today-text');
        day.removeClass('position-relative');
        $('.today-border').remove();
      }

      day.find('.week-day').html(arrayList[i][0].toUpperCase());
      day.find('.week-date').html(arrayList[i][1]);

      if (arrayList[i][2]) {
        day.addClass('today-text');
        day.addClass('position-relative');
        day.append(`
          <div class="today-border"></div>
          `);
      } else if (!arrayList[i][3]){
        day.addClass('opacity-text');
      }
    }
  }

  listWeekdays(numWeek = 0) {
    let currentDate = moment();
    let date = moment().add(numWeek, 'weeks');
    let weekStart = date.clone().startOf('isoWeek');
    let weekEnd = date.clone().endOf('isoWeek');

    let days = [];
    for (let i = 0; i <= 6; i++) {
      let tmp = moment(weekStart).add(i, 'days');
      days.push([tmp.format('ddd'), tmp.format("Do MMM"), currentDate.isSame(tmp, 'day'), tmp.isAfter(currentDate)]);
    };

    return days;
  }
}

export default new TaskPage();
