const eventForRecurringDepositType = (recurringIntervalInputSelector, remaindingPaymentTypeSelector) => {
  const recurringType = $(recurringIntervalInputSelector).val();

  if (recurringType == 'yearly') {
    $(remaindingPaymentTypeSelector).val('year').trigger('change');
    $(remaindingPaymentTypeSelector).attr('disabled', 'disabled');
  }

  $(recurringIntervalInputSelector).on('change', ({ target }) => {
    const recurringType = target.value;

    if (recurringType == 'yearly') {
      $(remaindingPaymentTypeSelector).val('year').change();
      $(remaindingPaymentTypeSelector).attr('disabled', 'disabled');
    } else {
      $(remaindingPaymentTypeSelector).removeAttr('disabled');
    }
  })
}

export default eventForRecurringDepositType;
