import '@nathanvda/cocoon';
import { initSelect2 } from './select2';
import {initFlatPickr} from "../packages/flatpickr";
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';

$(document).on("cocoon:after-insert", () => {
  initSelect2();
  initFlatPickr();
});

export const triggerCallbackCocoonLog = () => {
  $(document).on("cocoon:after-insert", function(e, insertedItem) {
    clearInputNumberOnFocus();
    if($(insertedItem).find('.nested-fields').hasClass('case-fields') || insertedItem.hasClass('case-fields')){
      if($(insertedItem).find('.product-fields').length < 1) {
        $('.add-new-product').last().click();
        // click on the last add new product btn, since the new add case will be inserted as the last
        // if there are case-fields/ product-fields above using #add-new-product will insert to the first case-fields
      }
    }
  })
}

export const toggleFormCocoonCollapse = () => {
  $(document).on("cocoon:after-insert", function(e, insertedItem) {
    const uniqueId = `cocoon-fields-${Date.now().toString()}`;

    $(insertedItem).find('.toggle-btn').attr('data-target', `#${uniqueId}`)
    $(insertedItem).find('.form-content').attr('id', uniqueId);

    // appointment/case/task field is default to not dropdown the fields content,
    // but when add new appointment/case/task is need to dropdown all the field for user to fill in
    if (!$(insertedItem).find('.form-content').hasClass('show')) {
      $(insertedItem).find('.form-content').addClass('show');
    }
  })
}
