import DreamSoloSavingGraph from './dreamCharts/dreamSoloChart';
import DreamSharedSavingGraph from './dreamCharts/dreamSharedChart';
import DreamRetirementChart from './dreamCharts/dreamRetirementChart';
import showAttentionNeededForEvents from '../shared/showEventProgressByPercentage';

export default class DreamIndexPage {
  constructor() {
    this.dreamSharedMemberSelector = '#dream-shared-member-selection';
    this.mainDreamIndexContentSelector = '.main-content';

    this.retirementDreamsChartContentSelector = '#retirement-dream-chart-container';
    this.sharedDreamsChartContentSelector = '#shared-dreams-saving-chart';
    this.soloDreamsChartContentSelector = '#solo-dreams-saving-chart';
    this.toggleEventSelector = '.toggle-event-btn';
    this.clientId = $('body').data('client-id');
  }

  setup() {
    this.setOnchangeSharedMember();
    this.initDreamSavingChart();

    $(this.toggleEventSelector).on('click', () => {
      showAttentionNeededForEvents();
    })
  }

  setOnchangeSharedMember() {
    $(this.dreamSharedMemberSelector).on('change', ({ target }) => {
      let url;

      const memberId = target.value;
      const selectedIndex = target.selectedIndex;

      if (this.isSoloDreamIndexPage(memberId, selectedIndex)) {
        url = Routes.consultant_client_dreams_path({ client_id: this.clientId })
      } else {
        url = Routes.consultant_client_dreams_path({ client_id: this.clientId, member_id: memberId })
      }

      Turbolinks.visit(url)
    })
  }

  initDreamSavingChart() {
    const $retirementDreamsChartContainer = $(this.retirementDreamsChartContentSelector);
    const $sharedDreamsChartContainer = $(this.sharedDreamsChartContentSelector);
    const $soloDreamsChartContainer = $(this.soloDreamsChartContentSelector);

    if ($retirementDreamsChartContainer.length > 0) {
      this.initRetirementDreamChart($retirementDreamsChartContainer);
    } else if ($sharedDreamsChartContainer.length > 0) {
      this.initDreamSharedSavingChart($sharedDreamsChartContainer);
    } else if ($soloDreamsChartContainer.length > 0) {
      this.initDreamSoloSavingChart($soloDreamsChartContainer);
    }
  }


  initDreamSoloSavingChart($soloDreamsChartContainer) {
    new DreamSoloSavingGraph($soloDreamsChartContainer).setup();
  }

  initDreamSharedSavingChart($sharedDreamsChartContainer) {
    new DreamSharedSavingGraph($sharedDreamsChartContainer).setup();
  }

  initRetirementDreamChart($retirementDreamsChartContainer) {
    new DreamRetirementChart($retirementDreamsChartContainer).setup();
  }

  isSoloDreamIndexPage(memberId, selectedIndex) {
    return this.clientId == memberId && selectedIndex == 0
  }
}
