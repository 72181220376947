
export default class SimulatedEventForm {
  constructor() {
    this.simulatedEventStillAfterDeathCheckBoxSelector = '#dream-still-after-death-checkbox';
    this.simulatedEventStillAfterDeathFormSelector = '.dream-still-after-death-form';
  }

  setup() {
    this.initForm();
    this.toggleForm();
  }

  initForm() {
    if($(this.simulatedEventStillAfterDeathCheckBoxSelector).is(':checked')) {
        $(this.simulatedEventStillAfterDeathFormSelector).show();
    }
    else {
      $(this.simulatedEventStillAfterDeathFormSelector).hide();
    }
  }
  
  toggleForm() {
    $(this.simulatedEventStillAfterDeathCheckBoxSelector).on('change', () => {
      this.initForm();
    })
  }
}
