import Raicon from 'raicon';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import AssetPropertyForm from '../../consultant/netwealths/assetPropertyForm';
import AssetCarForm from '../../consultant/netwealths/assetCarForm';
import AssetInvestmentForm from '../../consultant/netwealths/assetInvestmentForm';
import AssetCpfForm from '../../consultant/netwealths/assetCpfForm';
import AssetEndowmentForm from '../../consultant/netwealths/assetEndowmentForm';
import { getCurrentUrlQueryParams } from '@/layouts/application/utils/getCurrentUrlQueryParams';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import dynamicIncludedMonth from '../../consultant/dynamicIncludedMonth';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';

class ClientsAssetsController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
    new dynamicIncludedMonth().setup('#premium-interval-type');
  }

  new() {
    this.handleFormLogic();
    handleHideSignSgd('#currency-select', '.property-currency');
  }

  create() {
    this.handleFormLogic();
  }

  edit() {
    this.handleFormLogic();
    handleLockForm([
      '#asset-property-form',
      '#asset-car-form',
      '#asset-investment-form',
      '#asset-saving-form',
      '#asset-fixed-deposit-form',
      '#asset-srs-account-form',
      '#asset-cpf-account-form',
      '#asset-endowment-form',
      '#asset-other-form',
      '#asset-top-up-form',
    ]);
    handleHideSignSgd('#currency-select', '.property-currency');
    $('#currency-select').trigger('change');
  }

  newPhysicalAssetSelection() {}

  newOtherAssetSelection() {}

  togglePropertyMortgageEvent() {
    const $checkbox = $('#property-mortgage-checkbox');
    const $mortgageForm = $('.property-mortgage-form');

    this.toggleElementByCheckboxEvent($checkbox, $mortgageForm);
  }

  toggleCarLoanEvent() {
    const $checkbox = $('#car-loan-checkbox');
    const $loanForm = $('.car-loan-form');

    this.toggleElementByCheckboxEvent($checkbox, $loanForm);
  }

  toggleInvestmentRecurringEvent() {
    const $checkbox = $('#investment-recurring-checkbox');
    const $recurringForm = $('.recurring-investment-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleDreamPlanningEvent() {
    const $checkbox = $('#dream-planing-checkbox');
    const $recurringForm = $('.payout-option-form');

    this.toggleElementByCheckboxEventReverse($checkbox, $recurringForm);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  toggleElementByCheckboxEventReverse($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.hide();
    } else {
      $form.show();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.hide();
      } else {
        $form.show();
      }
    });
  }

  handleFormLogic() {
    let assetType = getCurrentUrlQueryParams()['asset_type'];
    if (assetType == 'Asset::Property') {
      new AssetPropertyForm().setup(true);
      this.togglePropertyMortgageEvent();
    } else if (assetType == 'Asset::Car') {
      this.toggleCarLoanEvent();
      new AssetCarForm().setup();
    } else if (assetType == 'Asset::CpfAccount') {
      new AssetCpfForm().setup();
    } else if (assetType == 'Asset::Investment') {
      this.toggleInvestmentRecurringEvent();
      new AssetInvestmentForm().setup(true);
    } else if (assetType == 'Asset::Endowment') {
      this.toggleDreamPlanningEvent();
      new AssetEndowmentForm().setup(true);
    }
    handleMoneyField();
  }
}

Raicon.register('clients/assets', ClientsAssetsController);
