import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  // $('[data-toggle="tooltip"]').on('click', function () {
  //   $(this).tooltip('hide')
  // })
});
