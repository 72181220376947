const moment = require("moment");

export default function checkValidDreamStartDate(startDateSelector) {
  const errorSelector = '#dream-start-date-errors';
  const startDate = new Date($(startDateSelector).val());
  const currentDate = new Date();
  const inValidDate = moment(currentDate).isAfter(startDate, 'day');
  const defaultErrorSelector = '.dream_dream_start_date .invalid-feedback:contains("Dream cannot happen in the past")';
  
  $(defaultErrorSelector).addClass('d-none');

  if (inValidDate) {
    $(errorSelector).removeClass('d-none');
  } else {
    $(errorSelector).addClass('d-none');
  }
}
