import Raicon from 'raicon';

class RedbrickController {
  index() {
    this.handleEmailValidation();
  }

  handleEmailValidation() {
    const $emailInput = $('#client-email-input');

    $('#redbrick-submit-btn').on('click', (e) => {
      if (this.validateEmail($emailInput.val()) == false) {
        e.preventDefault();
        $('#email-error').text('Please provide a valid email');
        return false;
      }
    })
  }

  validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
}

Raicon.register('redbrick', RedbrickController);
