import Raicon from 'raicon';
import EmailForm from './emailForm';

class EnhanceEmailsController extends EmailForm {
  constructor() {
    super();
    this.role = 'consultant';
    this.showPath = 'consultant_enhance_email_path';
    this.sendTestEmailPath = 'send_test_email_consultant_enhance_email_path';
  }

  setup() {
    super.setup();
    this.setDefaultValueForInputs();
  }

  setDefaultValueForInputs() {
    $('#email-submit-btn').on('click', (e) => {
      // set default value for audience field
      const inactiveTabContents = $('#email-audience-tab-content').children('li').not('.active');
      Array.from(inactiveTabContents).forEach((tab) => {
        tab.id.includes('all') ? $(`.${tab.id}-input`).val('0') : $(`.${tab.id}-input`).val([]);
      });

      // set default value for save content as template field
      if ($(this.saveAsTemplate).val() == '1' && $('a[href="#new-template"').hasClass('active')) {
        $('#override-template-input').val('');
      }
    });
    $('#override-template-input').val('');
  }
}

Raicon.register('consultant/enhanceEmails', EnhanceEmailsController);
