export default class ServiceMatrixForm {
  constructor() {
    this.triggerOnInputSelector = '#trigger-on-input-selector';
    this.serviceMatrixTriggerDate = '.service-matrix-trigger-date';
  }

  setup() {
    this.addTriggerOnEvent();
  }

  addTriggerOnEvent() {
    $(this.triggerOnInputSelector).on('change', ({ target: { value: triggerType } }) => {
      if (triggerType === 'birthday' || triggerType === 'month') {
        $(this.serviceMatrixTriggerDate).addClass('d-none');
      } else {
        $(this.serviceMatrixTriggerDate).removeClass('d-none');
      }
    });
  }
}
