import Raicon from 'raicon';

class SystemSettingsController {
  index() {}

  update() {}

  ruleOfThumbs() {}

  editRuleOfThumbs() {
    this.setup();
  }

  updateRuleOfThumbs() {
    this.setup();
  }

  setup() {
    $('.under-protected-to').on('change', ({ target }) => {
      $('.well-protected-from').val(target.value);
    });

    $('.well-protected-to').on('change', ({ target }) => {
      $('.over-protected-from').val(target.value);
    });

    $('.well-protected-from').on('change', ({ target }) => {
      $('.under-protected-to').val(target.value);
    });

    $('.over-protected-from').on('change', ({ target }) => {
      $('.well-protected-to').val(target.value);
    });
  }
}

Raicon.register('admin/systemSettings', SystemSettingsController);
