export default function handleImportDuplicationPolicies() {
  $('body').on('click', '.remove-email-row', ({target}) => {
    const emailIndex = $(target).closest('td').data('policy')
    $(`.email-row-${emailIndex}`).remove();
    $(`.email-row-index-${emailIndex}`).remove();
    checkDisableImportButton();
  })

  $('body').on('click', '.remove-policy-row', ({target}) => {
    $(target).closest('tr.policy-row').remove();
    const policyIndex = $(target).closest('td').data('policy');
    $(`.policy-row-index-${policyIndex}`).remove();
    checkDisableImportButton();
  })

  $('body').on('click', '.apply-to-all', ({target}) => {
    const emailRow = $(target).closest('tr.policy-row');
    const selectedClient = emailRow.find('.client-id-input').val();
    const emailGroupId = $(target).data('policy');
    const clientIdClass = `.client-id-input-${emailGroupId}`;
    $(clientIdClass).each((_index, element) => {
      $(element).val(selectedClient).trigger('change');
    })
  })

  
  $('body').on('ajax:success', () => {
    $('.select2.client-id-input').select2({
      templateResult: formatResult,
      templateSelection: formatSelection
    })
  })
}

const checkDisableImportButton = () => {
  if($('.policy-row').length == 0) {
    $('#submit-duplication-policies').attr('disabled', 'disabled');
  }
}

const formatResult = (item) => {
  var selectionText = item.text.split("---");
  var $returnString = $("<span class='policy-holder-result-option font-size-18-text'>" + selectionText[0] + '</span>' + "</br>" + "<span class='policy-holder-result-option font-size-14-text'>" + selectionText[1] + '</span>');

  return $returnString;
};

const formatSelection = (item) => {
  var selectionText = item.text.split("---");
  var $returnString = $("<span class='policy-holder-selection-option font-size-18-text'>" + selectionText[0] + '</span>' + '</br>' + "<span class='policy-holder-selection-option font-size-14-text'>" + selectionText[1] + '</span>');

  return $returnString;
}
