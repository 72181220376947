import Raicon from 'raicon';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import ServiceMatrixForm from '../../consultant/automations/serviceMatrixForm';

class AdminAutomationsController {
  index() {
    changeParamenterByTab('automations', 'active_tab');
    this.setupServiceMatrixForm();
  }

  setupServiceMatrixForm() {
    $('body').on('modal:afterSetContent', '#edit-service-matrix', () => {
      new ServiceMatrixForm().setup();
    });
  }
}

Raicon.register('admin/automations', AdminAutomationsController);
