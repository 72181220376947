import Raicon from 'raicon';

class AdminAssignModulesController {
  fcSelectionCheckboxSelector = '.modify-module-permission-checkbox';
  fcSelectionCheckAllSelector = '#modify-module-permission-check-all';
  bulkModifyPermissionSectionSelector = '#bulk-modify-permission-section';
  bulkModifyPermissionButtonSelector = '#bulk-modify-permission-button';
  moduleCheckboxCommonSelector = '.module-checkbox';
  automationModuleCheckboxCommonSelector = '.automation-feature-enabled-checkbox';
  serviceMatrixModuleCheckboxCommonSelector = '.service-matrix-feature-enabled-checkbox';
  taskModuleCheckboxCommonSelector = '.task-feature-enabled-checkbox';
  emailModuleCheckboxCommonSelector = '.email-feature-enabled-checkbox';
  plannerModuleCheckboxCommonSelector = '.planner-feature-enabled-checkbox';
  goalModuleCheckboxCommonSelector = '.goal-feature-enabled-checkbox';
  preFactFindModuleCheckboxCommonSelector = '.pre-fact-find-feature-enabled-checkbox';
  postFnaModuleCheckboxCommonSelector = '.post-fna-feature-enabled-checkbox';
  savePermissionButtonSelector = '#save-permission-button';
  fcPermissionRowCommonSelector = '.fc-permission-row';

  listFeaturesNeedToEnableAutomation = ['task', 'email', 'planner'];

  index() {
    $(this.fcSelectionCheckboxSelector).on('change', () => {
      this.handleShowHideModifyButton();
      $(this.fcSelectionCheckAllSelector).prop("checked", false);
    })

    $(this.bulkModifyPermissionButtonSelector).on('click', ({ target }) => {
      const fcIds = this.buildFcCheckedData();
      target.href = Routes.bulk_modify_permission_admin_assign_modules_path({ fc_ids: fcIds })
    })

    $(this.fcSelectionCheckAllSelector).on('change', ({ target }) => {
      if ($(target).is(':checked')) {
        $(this.fcSelectionCheckboxSelector).prop("checked", true);
      } else {
        $(this.fcSelectionCheckboxSelector).prop("checked", false);
      }

      this.handleShowHideModifyButton();
    })
  }

  editBulkModifyPermission() {
    $(this.moduleCheckboxCommonSelector).on('change', ({ target }) => {
      const feature = target.value;

      if (this.listFeaturesNeedToEnableAutomation.includes(feature)) {
        const fcId = target.dataset['fcId'];
        this.checkConditionNeededToEnableAutomation(fcId);
      }
    })

    $(this.savePermissionButtonSelector).on('click', () => {
      const requestData = this.buildUpdatePermissionData();
      $.post(Routes.bulk_modify_permission_admin_assign_modules_path({ data: requestData }))
    })
  }

  checkConditionNeededToEnableAutomation(fcId) {
    const fcPermissionRow = $(`#fc-permission-row-${fcId}`)
    const checkedFeaturesForFc = fcPermissionRow.find(`${this.moduleCheckboxCommonSelector}:checked`).toArray().map((e) => e.value)
    const $automationCheckbox = $(fcPermissionRow.find(`${this.automationModuleCheckboxCommonSelector}`)[0]);
    const $serviceMatrixCheckbox = $(fcPermissionRow.find(`${this.serviceMatrixModuleCheckboxCommonSelector}`)[0]);
    if (this.listFeaturesNeedToEnableAutomation.every((val) => checkedFeaturesForFc.includes(val))) {
      $automationCheckbox.removeAttr("disabled");
      $serviceMatrixCheckbox.removeAttr("disabled");
    } else {
      $automationCheckbox.attr('disabled', 'disabled');
      $automationCheckbox.prop("checked", false)
      $serviceMatrixCheckbox.attr('disabled', 'disabled');
      $serviceMatrixCheckbox.prop("checked", false)
    }
  }

  buildFcCheckedData() {
    let data = [];
    data = $(`${this.fcSelectionCheckboxSelector}:checked`).toArray().map(e => $(e).data('fc-id'));
    return data;
  }

  buildUpdatePermissionData() {
    let data = {};

    $(this.fcPermissionRowCommonSelector).toArray().forEach((fcRow) => {
      const $fcRow = $(fcRow);
      const fcId = $fcRow.data('fc-id');

      const isEnabledTask = $fcRow.find(`${this.taskModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledEmail = $fcRow.find(`${this.emailModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledPlanner = $fcRow.find(`${this.plannerModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledAutomation = $fcRow.find(`${this.automationModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledServiceMatrix = $fcRow.find(`${this.serviceMatrixModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledGoal = $fcRow.find(`${this.goalModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledPreFactFind = $fcRow.find(`${this.preFactFindModuleCheckboxCommonSelector}:checked`).length > 0
      const isEnabledPostFna = $fcRow.find(`${this.postFnaModuleCheckboxCommonSelector}:checked`).length > 0

      data[fcId] = {
        task: !isEnabledTask,
        email: !isEnabledEmail,
        planner: !isEnabledPlanner,
        automation: !isEnabledAutomation,
        service_matrix: !isEnabledServiceMatrix,
        goal: !isEnabledGoal,
        pre_fact_find: !isEnabledPreFactFind,
        post_fna: !isEnabledPostFna
      }
    })

    return data;
  }

  handleShowHideModifyButton() {
    const numberSelectedFc = $(`${this.fcSelectionCheckboxSelector}:checked`).toArray().length;
    if (numberSelectedFc > 0) {
      $(this.bulkModifyPermissionSectionSelector).removeClass('d-none');
    } else {
      $(this.bulkModifyPermissionSectionSelector).addClass('d-none');
    }
  }
}

Raicon.register('admin/assignModules', AdminAssignModulesController);
