document.addEventListener("turbolinks:load", () => {
  if (isClientPage()) {
    if (isHasMyInfo() && isHasCpfBalance()) {
      $('#open-sync-myinfo-biodata').click();
    } else {
      if (isHasMyInfo()) {
        $('#open-sync-myinfo-biodata').click();
      }

      if (isHasCpfBalance()) {
        $('#open-sync-cpf-balance').click();
      }
    }
  }
});

$(document).on('click', '.modal-close-btn', () => {
  if (isClientPage() && isHasCpfBalance() && isHasCpfBalance()) {
    $('#open-sync-cpf-balance').click();
  }
});

const isClientPage = () => {
  return $('.client-details-navbar').length > 0;
}

const isHasMyInfo = () => {
  return $('#open-sync-myinfo-biodata').length > 0;
}

const isHasCpfBalance = () => {
  return $('#open-sync-cpf-balance').length > 0;
}
