import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
const moment = require('moment');

export default class CalendarDisplay {
  constructor() {
    this.consultantCalendarElementId = 'consultant-calendar';
    this.calendarFilterListSelector = '.calendar-filter-list';
    this.filterItemSelector = '.filter-item';
    this.appointmentEventFilterSelector = '#appointment-filter';
    this.birthdayEventFilterSelector = '#birthday-filter';
    this.policyAnniversaryEventFilterSelector = '#policy-anniversary-filter';
    this.todoTaskEventFilterSelector = '#to-do-filter';
    this.toCallTaskEventFilterSelector = '#to-call-filter';
    this.companyEventFilterSelector = '#company-event-filter';
    this.reviewDateFilterSelector = '#review-date-filter';

    this.eventList = [];
    this.currentFilterList = this.getCurrentActiveFilter();
    this.calendar = undefined;
  }

  setup() {
    this.fetchEventsAndDrawCalendar();
    this.addEventForFilterButtons();
  }

  calendarSettingSetup() {
    $(document).on('turbolinks:before-cache', this.clearCalendar);

    const calendar = new Calendar(document.getElementById(this.consultantCalendarElementId), {
      plugins: [dayGridPlugin, timeGridPlugin],
      initialView: 'dayGridMonth',
      dayMaxEvents: true,
      dayMaxEvents: 5,
      contentHeight: 'auto',
      headerToolbar: {
        center: 'prev,next today',
        left: null,
        right: 'title'
      },
      eventBackgroundColor: '#38A0F2',
      views: {
        timeGridWeek: {
          dayHeaderContent: function (args) {
            const textColorClass = args.isToday ? 'blue-text' : 'light1-black-text';

            return {
              html: `
                <div class='d-flex flex-column'>
                  <div class='${textColorClass};'>
                    ${moment(args.date).format('ddd')}
                  </div>
                  <div class='font-weight-600-text font-size-24-text text-capitalize ${textColorClass}'>
                    ${moment(args.date).format('D MMM')}
                  </div>
                </div>
              `
            }
          },
          eventContent: ({ event }) => {
            if (!event.allDay) {
              return {
                html: `
                      <div>
                        <div class='font-weight-600-text edit-appointment-text white-text line-height-18'>
                          ${this.textWithLimitedLength(event.title, 28)}
                        </div>
                        <div class='font-weight-400-text edit-appointment-text white-text line-height-18'>
                          ${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}
                        </div>
                      </div>
                    `
              }
            } else {
              return this.setEventContentIdByGroupId(event);
            }
          },
          eventDidMount: (event) => {
            this.setAttrForCalendarEventElement(event);
          }
        },
        dayGridMonth: {
          dayCellContent: function (args) {
            if (args.isToday) {
              return {
                html: `
                <div class="today-number-wrapper">
                  ${args.dayNumberText}
                </div>
              `
              }
            }
          },
          eventContent: ({ event }) => {
            if (!event.allDay) {
              const textColorClass = moment(event.end).date() == moment(event.start).date() ? 'blue-text' : 'white-text';
              return {
                html: `
                  <div>
                    <span class='font-weight-600-text edit-appointment-text line-height-18 ${textColorClass}'>
                      ${moment(event.start).format('LT')}
                    </span>
                    <span class='font-weight-400-text edit-appointment-text line-height-18 ml-5px ${textColorClass}'>
                      ${this.textWithLimitedLength(event.title, 24)}
                    </span>
                  </div>
                `
              }
            } else {
              return this.setEventContentIdByGroupId(event);
            }
          },
          eventDidMount: (event) => {
            this.setAttrForCalendarEventElement(event);
          }
        }
      },
      events: this.eventList
    });

    $('#calendar-month').on('click', () => {
      calendar.changeView('dayGridMonth');
    })

    $('#calendar-week').on('click', () => {
      calendar.changeView('timeGridWeek');
    })

    calendar.render();
    this.calendar = calendar;
  }

  clearCalendar() {
    $('#consultant-calendar').html('');
  }

  setAttrForCalendarEventElement(eventObject) {
    if (eventObject.event.groupId == 'holiday') {
      return false;
    }

    if (eventObject.event.groupId == 'review_date') {
      $(eventObject.el).attr('target', '_blank');
      return false;
    }
    if (eventObject.event.groupId == 'appointment') {
      $(eventObject.el).attr('id', 'new-appointment-button');
    } else if (eventObject.event.groupId == 'task') {
      $(eventObject.el).attr('id', 'open-edit-task');
      $(eventObject.el).addClass(`${eventObject.event.extendedProps.taskId}`);
    }

    $(eventObject.el).attr('data-type', 'html');
    $(eventObject.el).attr('data-modal', 'true');
    $(eventObject.el).attr('data-remote', 'true');
  }

  setEventContentIdByGroupId(eventObject) {
    if (eventObject.groupId == 'birthday') {
      return {
        html: `${eventObject.title} (${eventObject.extendedProps.age})`
      }
    } else if (eventObject.groupId == 'policy_aniversary') {
      return {
        html: `${eventObject.title} (${eventObject.extendedProps.policy_name})`
      }
    }
  }

  addEventForFilterButtons() {
    $(this.calendarFilterListSelector).on('click', `
      ${this.appointmentEventFilterSelector},
      ${this.birthdayEventFilterSelector},
      ${this.policyAnniversaryEventFilterSelector},
      ${this.todoTaskEventFilterSelector},
      ${this.toCallTaskEventFilterSelector},
      ${this.companyEventFilterSelector},
      ${this.reviewDateFilterSelector}
    `, ({ target }) => {
      $(target).closest(this.filterItemSelector).toggleClass('active');
      this.updateCurrentFilterList(target);
    })
  }

  fetchEventsAndDrawCalendar() {
    $.get(Routes.event_list_consultant_planners_path())
      .done((data) => {
        this.addEventsToCalendar(data);
      })
  }

  updateCurrentFilterList(filterElement) {
    const filterName = $(filterElement).closest(this.filterItemSelector)[0].id.replace(/-/g, '_');
    const filterList = this.getCurrentActiveFilter();

    if (this.currentFilterList.length >= filterList.length) {
      $.post(Routes.change_calendar_filters_consultant_planners_path({ filter_name: filterName, need_load_more: false }))
        .done(() => {
          this.removeEventsFromCalendar(filterName);
        })
    } else {
      $.post(Routes.change_calendar_filters_consultant_planners_path({ filter_name: filterName, need_load_more: true }))
        .done((data) => {
          this.addEventsToCalendar(data);
        })
    }

    this.currentFilterList = filterList;
  }

  removeEventsFromCalendar(eventId) {
    eventId = eventId.replace('_filter', '');

    this.calendar.getEvents().forEach(event => {
      if (event.id == eventId) {
        event.remove();
      }
    })
  }

  getCurrentActiveFilter() {
    const $activeFilters = $(`${this.filterItemSelector}.active`);

    const filterList = $activeFilters.toArray().map((element) => {
      return element.id.replace(/-/g, '_');
    })

    return filterList;
  }

  addEventsToCalendar(data) {
    this.eventList = data.event_list;

    if (this.calendar) {
      this.eventList.forEach((event) => {
        this.calendar.addEvent(event);
      })
    } else {
      this.calendarSettingSetup();
    }
  }

  textWithLimitedLength(text, maxLength) {
    if (text.length >= maxLength) {
      return text.substr(0, maxLength - 2) + '...'
    } else {
      return text
    }
  }
}
