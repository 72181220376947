import Raicon from 'raicon';
import { setDataForSelect2 } from '@/layouts/application/utils/select2Util';
import { formatCurrencyWholeNumber, formatCurrency, formatDate } from '@/layouts/application/utils/formats';

class ShieldPremiumsController {
  specialShieldPlan = 'MediShield Life';
  defaultStartAge = 25;

  constructor() {
    this.currentYear = new Date().getFullYear();
    this.defaultYearOfDob = this.currentYear - this.defaultStartAge;
    this.isValid = true;
    this.defaultPremium = {
      total_premium: 0,
      total_cpf_medisave: 0,
      total_cash_outlay: 0,
      total_cpf_medisave_retirement_age: 0,
      total_cash_outlay_retirement_age: 0,
      total_premium_retirement_age: 0,
      monthly_saving_to_retirement_age: 0,
    };
  }

  index() {
    this.setup();
  }

  setup() {
    this.calculateDate();
    this.fetchListShieldPlansAndListRiders();
    this.calculateShieldPremiumOnInputChange();
    this.handleShowDetailBreakDown();
  }

  calculateDate() {
    $('.start-age-input').on('change', ({ target }) => {
      const startAge = Number(target.value);
      const endAge = Number($('.end-age-input').val());
      const retirementAge = Number($('.retirement-age-input').val());
      this.defaultYearOfDob = this.currentYear - startAge;

      $('.end-age-year').text(this.defaultYearOfDob + endAge);
      $('.retirement-age-year').text(this.defaultYearOfDob + retirementAge);
      $('.start-age').text(startAge);
      $('.age-from-start-to-retirement-age').text(retirementAge - startAge - 1);
      this.isValid = this.triggerValidate(target.name);
    });

    $('.end-age-input').on('change', ({ target }) => {
      const endAge = Number(target.value);
      $('.end-age-year').text(this.defaultYearOfDob + endAge);
      $('.end-age').text(endAge);
      this.isValid = this.triggerValidate(target.name);
    });

    $('.retirement-age-input').on('change', ({ target }) => {
      const startAge = Number($('.start-age-input').val());
      const retirementAge = Number(target.value);
      $('.retirement-age-year').text(this.defaultYearOfDob + retirementAge);
      $('.retirement-age').text(retirementAge);
      $('.before-retirement-age').text(retirementAge - 1);
      $('.age-from-start-to-retirement-age').text(retirementAge - startAge);
      this.isValid = this.triggerValidate(target.name);
    });

    $('.start-age-year').text(this.currentYear);
    $('.end-age-input').trigger('change');
    $('.retirement-age-input').trigger('change');
  }

  fetchListShieldPlansAndListRiders() {
    $('.insurer-selection').on('change', ({ target }) => {
      $.get(
        Routes.get_list_shield_plan_and_list_rider_by_insurer_consultant_shield_premiums_path({
          name: target.value,
        }),
      ).done((result) => {
        let shieldPlanNames = result.shield_plan_names.map((item) => ({ id: item, text: item }));
        let riderNames = result.rider_names.map((item) => ({ id: item, text: item }));
        setDataForSelect2('.shield-plan-selection', shieldPlanNames, false, 'Please select your shield plan');
        setDataForSelect2('.rider-selection', riderNames, true, 'Please select your rider');
        this.setValueToShieldItem(this.defaultPremium);
        $('.date-uploaded').text('');
      });
    });

    $('.shield-plan-selection').on('change', () => {
      $('.rider-selection').val('').trigger('change');
    });
  }

  calculateShieldPremiumOnInputChange() {
    $('#shield-premium-form').on('change', ({ target }) => {
      const shieldPlanName = $('.shield-plan-selection').val();
      if (!shieldPlanName || $(target).hasClass('insurer-selection')) return;

      const formData = $('#shield-premium-form').serializeArray();
      const isEmpty = formData.some((data) => {
        const optionalFields = ['shield_premium[rider_name]', 'shield_premium[annual_increment]'];
        return !optionalFields.includes(data.name) && !data.value;
      });

      if (!isEmpty && this.isValid) {
        $.ajax({
          type: 'POST',
          url: Routes.calculate_shield_premium_consultant_shield_premiums_path(),
          data: formData,
          success: (data) => {
            this.setValueToShieldItem(data);
            $('.date-uploaded').text(formatDate(new Date(data.date_uploaded)));
          },
        });
      }
    });
  }

  setValueToShieldItem(data) {
    $('.total-premium').text(formatCurrencyWholeNumber(data.total_premium));
    $('.total-cpf-medisave').text(formatCurrencyWholeNumber(data.total_cpf_medisave));
    $('.total-cash-outlay').text(formatCurrencyWholeNumber(data.total_cash_outlay));
    $('.total-cpf-medisave-retirement-age').text(formatCurrencyWholeNumber(data.total_cpf_medisave_retirement_age));
    $('.total-cash-outlay-retirement-age').text(formatCurrencyWholeNumber(data.total_cash_outlay_retirement_age));
    $('.total-premium-retirement-age').text(formatCurrencyWholeNumber(data.total_premium_retirement_age));
    $('.monthly-saving-to-retirement-age').text(formatCurrencyWholeNumber(data.monthly_saving_to_retirement_age));
  }

  handleShowDetailBreakDown() {
    $('.link-view-detail').on('click', () => {
      const shieldPlan = $('.shield-plan-selection').val();

      if (!shieldPlan) {
        $('.show-shield-plan-name').text('Shield Name');
        $('.show-rider-name').text('Rider Name');
        $('#shield-detail-body').html('');
        return;
      }

      const formData = $('#shield-premium-form').serializeArray();

      $.ajax({
        type: 'GET',
        url: Routes.detail_breakdown_consultant_shield_premiums_path(),
        data: formData,
        success: (data) => {
          this.setValueToDetailBreakDown(data);
        },
      });
    });
  }

  setValueToDetailBreakDown(data) {
    const shieldPlan = data.shield_plan;
    const rider = data.rider;
    let detailData;
    for (let i = data.start_age; i <= data.end_age; i++) {
      let ageIndex = i - 1;
      let cpfMedisaveShieldPlan = Number(shieldPlan.pru_shield_cpf[ageIndex]) - Number(shieldPlan.cash[ageIndex]);
      detailData += `
        <tr>
          <td class='width-100 text-center'>${i}</td>
          <td class='min-width-130 text-center'>${formatCurrency(shieldPlan.medi_shield_cpf[ageIndex])}</td>
          <td class='d-flex text-center'>
            <p class='m-0 width-50-percent'>${formatCurrency(cpfMedisaveShieldPlan)}</p>
            <p class='m-0 width-50-percent'>${formatCurrency(shieldPlan.cash[ageIndex])}</p>
          </td>
          <td class='min-width-130 text-center'>${formatCurrency(rider?.cash_rider[ageIndex] || 0)}</td>
        </tr>
      `;
    }
    $('.show-shield-plan-name').text(shieldPlan.name);
    $('.show-rider-name').text(rider?.name || 'Rider Name');
    $('#shield-detail-body').html(detailData);
    this.hideAndShowColumnBySpecialShieldPlan(shieldPlan.name);
  }

  hideAndShowColumnBySpecialShieldPlan(shieldPlan) {
    if (shieldPlan == this.specialShieldPlan) {
      $('th:eq(1)').css({ 'border-top-right-radius': '25px' });
      $('th').slice(2, 4).addClass('d-none');
      $('#table-breakdown-detail tr').each((_index, element) => {
        $(element).find('td').slice(2, 4).remove();
      });
    } else {
      $('th:eq(1)').css({ 'border-top-right-radius': '0' });
      $('th').slice(2, 4).removeClass('d-none');
    }
  }

  triggerValidate(inputName) {
    const startAge = Number($('.start-age-input').val());
    const endAge = Number($('.end-age-input').val());
    const retirementAge = Number($('.retirement-age-input').val());
    switch (inputName) {
      case 'shield_premium[start_age]':
        if (!this.isValidAgeRange(startAge)) {
          $('.start-end-age-error').text('Start age must be in the range of 1 to 100');
          return false;
        }
        if (startAge > endAge || startAge > retirementAge) {
          $('.start-end-age-error').text('Start age must be smaller than end age and retirement age');
          return false;
        } else {
          $('.retirement-age-error').text('');
        }
        $('.start-end-age-error').text('');
        break;
      case 'shield_premium[end_age]':
        if (!this.isValidAgeRange(endAge)) {
          $('.start-end-age-error').text('End age must be in the range of 1 to 100');
          return false;
        }
        if (endAge < startAge || endAge < retirementAge) {
          $('.start-end-age-error').text('End age must be larger than start age and retirement age');
          return false;
        } else {
          $('.retirement-age-error').text('');
        }
        $('.start-end-age-error').text('');
        break;
      case 'shield_premium[retirement_age]':
        if (!this.isValidAgeRange(retirementAge)) {
          $('.retirement-age-error').text('Retirement age must be in the range of 1 to 100');
          return false;
        }
        if (retirementAge < startAge || retirementAge > endAge) {
          $('.retirement-age-error').text('Retirement age must be smaller than end age and larger than start age');
          return false;
        } else {
          $('.start-end-age-error').text('');
        }
        $('.retirement-age-error').text('');
        break;
    }
    return true;
  }

  isValidAgeRange(age) {
    return age >= 1 && age <= 100;
  }
}

Raicon.register('consultant/shieldPremiums', ShieldPremiumsController);
