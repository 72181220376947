import { formatCurrencyToNumber, formatCurrency } from '@/layouts/application/utils/formats';

const calculateDepositAmountByClient = (
  recurringInputSelector,
  recurringIntervalInputSelector,
  recurringDepositByClientSelector,
  currency = 'SGD',
) => {
  let recurringDepositAmount = formatCurrencyToNumber($(recurringInputSelector).val());
  let recurringIntervalType = $(recurringIntervalInputSelector).val();

  if (recurringIntervalType == 'yearly') {
    recurringDepositAmount = recurringDepositAmount / 12.0;
  } else if (recurringIntervalType == 'quarterly') {
    recurringDepositAmount = recurringDepositAmount / 3.0;
  } else if (recurringIntervalType == 'half_yearly') {
    recurringDepositAmount = recurringDepositAmount / 6.0;
  }

  recurringDepositAmount = recurringDepositAmount ? recurringDepositAmount : 0;

  $(recurringDepositByClientSelector).html(`${formatCurrency(recurringDepositAmount, currency)} / month`);
};

export default calculateDepositAmountByClient;
