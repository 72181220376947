import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';
import { NET_WEALTH, LIABILITY } from '@/layouts/application/utils/charts/chartColors';
import { CurrencyHandler } from '../shared/currencyHandle';

class CashflowsPasswordsController {
  constructor() {
    this.currencyHandler = new CurrencyHandler(
      '#currency-select',
      '#currency-select-business-salary',
      '#property-currency',
      '#property-currency-business-salary'
    );
  }

  index() {
    handleSideMenu();
    handleUploadAvatar();
    this.drawCashflowChartMonth();
    this.drawCashflowChartYear();
    this.handleShowCashflowChart();
    this.currencyHandler.currencyChangeHandler();
  }

  loanRepaymentExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }

  savingInvestmentsExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }

  totalInsuranceExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }

  handleShowCashflowChart() {
    $('.interval-selection').on('change', ({ target }) => {
      $('.chart-wrapper').removeClass(['active', 'show']);
      $(target.value).addClass(['active', 'show']);
    });
  }

  drawCashflowChartMonth() {
    const chartElementMonth = $('#cashflow-chart-month');
    const chartDataMonth = chartElementMonth.data('calculation');

    if (chartElementMonth.length > 0) {
      initDoughnutChart(chartElementMonth, chartDataMonth, [NET_WEALTH, LIABILITY], 170);
    }
  }

  drawCashflowChartYear() {
    const chartElementYear = $('#cashflow-chart-year');

    if (chartElementYear.length > 0) {
      const nums_months_of_year = 12;
      const chartDataYear = chartElementYear.data('calculation').map((e) => e * nums_months_of_year);

      chartElementYear.addClass('rendered');
      initDoughnutChart(chartElementYear, chartDataYear, [NET_WEALTH, LIABILITY], 170);
    }
  }
}

Raicon.register('clients/cashflows', CashflowsPasswordsController);
