export const queryParamExistUrl = (param = '') => {
  if (new URLSearchParams(window.location.search).get(param) != null){
    return true
  }
  return false
}

export const getParamFromUrl = (param = '') => {
  if (new URLSearchParams(window.location.search).get(param) != null){
    return new URLSearchParams(window.location.search).get(param)
  }
  return null
}
