const eventHandlerForIsInputMonthlyRepayment = () => {
  const isInputMonthlyRepaymentCheckboxSelector = '#is-input-monthly-repayment';
  const withInputMonthlyRepaymentSelector = '.with-input-monthly-repayment';
  const withNotInputMonthlyRepaymentSelector = '.with-not-input-monthly-repayment';

  if ($(isInputMonthlyRepaymentCheckboxSelector).is(':checked')) {
    $(withInputMonthlyRepaymentSelector).show();
    $(withNotInputMonthlyRepaymentSelector).hide();
  } else {
    $(withInputMonthlyRepaymentSelector).hide();
    $(withNotInputMonthlyRepaymentSelector).show();
  }

  $(isInputMonthlyRepaymentCheckboxSelector).on('change', ({ target }) => {
    if (target.checked) {
      $(withInputMonthlyRepaymentSelector).show();
      $(withNotInputMonthlyRepaymentSelector).hide();
    } else {
      $(withInputMonthlyRepaymentSelector).hide();
      $(withNotInputMonthlyRepaymentSelector).show();
    }
  })
}

export default eventHandlerForIsInputMonthlyRepayment;
