import initMultipleLinesChart from '@/layouts/application/utils/charts/initMultipleLinesChart';
import DreamChart from '../dreams/dreamCharts/dreamChart';
import Chart from 'chart.js/auto';
import { formatCurrency } from '@/layouts/application/utils/formats';
import externalTooltipHandler from '@/layouts/application/utils/charts/modifyTooltipCashflowChart';
import { convertYearByAge } from '@/layouts/application/utils/calculateAgeByInput';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';

import {
  SHARED_DREAM_AMOUNT_SAVED_LINE,
  INCOME
} from '@/layouts/application/utils/charts/chartColors';
import { data } from 'jquery';

export default class CashflowChart extends DreamChart {
  constructor(...args) {
    super(...args);
    this.rangeBackYearSelector = '.cashflow-shared-year-range .back-year-icon';
    this.rangeForwardYearSelector = '.cashflow-shared-year-range .forward-year-icon';
    this.rangeStartYearSelector = '.cashflow-shared-year-range .range-start-year';
    this.rangeEndYearSelector = '.cashflow-shared-year-range .range-end-year';
  }

  chart = undefined;
  isLifeTimeView = false;

  setup() {
    this.buildXAxisData();
    this.setupChartRangeYear();
    this.setOnChangeViewType();
    this.setupChartValue();
  }

  setupChartValue() {
    const chartInitialData = this.chartElementContainer.data('chart-data');
    this.chartRetirementYear = this.chartElementContainer.data('label');

    this.incomes = chartInitialData.incomes;

    this.buildXAxisData();
    this.presentDataToChart([]);
  }

  presentDataToChart() {
    this.showLoadingDreamChart();
    const data = this.buildDataForChartRequest();
    $.post(
      Routes.present_cashflow_data_chart_consultant_client_cashflows_path({ client_id: this.clientId }),
      { data: data },
      data => {
        this.chartLinesData = data.chart_data;
        this.dataForLabels = data.chart_data.labels;
        this.drawCashflowChart();
        this.hideLoadingDreamChart();
      },
    );
  }

  calculateRangeAge() {
    let rangeAge = 10;

    if (this.isLifeTimeView) {
      const clientBirthdayYear = this.clientDobYear();
      rangeAge = Number(clientBirthdayYear) + this.chartRetirementYear - Number(this.currentYear) + 1;
    }

    return rangeAge
  }

  drawCashflowChart() {
    const datasets = this.buildChartDatasets();

    const options = {
      scales: {
        y: {
          grid: {
            color: function (context) {
              if (context.tick.value == 0) {
                return '#000000';
              } else {
                return Chart.defaults.borderColor
              }
            }
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          borderRadius: 25,
          labels: {
            generateLabels: (chart) => {
              const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

              for (const item of items) {
                if (item.text == 'Net Savings' || item.text == 'Total CPF Contribution') {
                  item.text = ''
                  continue;
                }
                let color;

                if (item.text == "Total Income") {
                  color = '#1156cc';
                } else if (item.text == "Total Expense") {
                  color = '#ffd966';
                }

                item.fillStyle = color;
                item.lineWidth = 0
                item.borderRadius = 5;
              }

              return items;
            }
          }
        },
        tooltip: {
          mode: 'index',
          position: 'tooltipPositionCustom',
          backgroundColor: '#EEEEEE',
          padding: 15,
          titleColor: '#303030',
          titleFont: { weight: 'bold', size: 14 },
          bodyColor: '#303030',
          cornerRadius: 10,
          usePointStyle: true,
          enabled: false,
          external: externalTooltipHandler,
          callbacks: {
            title: (context) => {
              if (this.isLifeTimeView) {
                const currentYear = this.currentRangeStartYear;
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              } else {
                let currentYear = parseInt(convertYearByAge(this.clientDob, false)) + parseInt($('span.range-start-year').text());
                if (parseInt(convertYearByAge(this.clientDob, false)) + this.clientAge != new Date().getFullYear()) {
                  currentYear = parseInt(convertYearByAge(this.clientDob, false)) + 1 + parseInt($('span.range-start-year').text());
                }
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              }
            },
            label: (tooltipItems) => {
              const label = tooltipItems.dataset.label;
              return ` ${label}: ${formatCurrency(tooltipItems.raw)}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: 'circle',
                borderWidth: 10
              }
            },
            labelColor: function (context) {
              const color = context.dataset.backgroundColor;

              return {
                borderColor: color,
                backgroundColor: color,
                borderWidth: 10,
                borderDash: [2, 2],
                borderRadius: 2,
              };
            }
          }
        }
      }
    };

    const plugins = [{
      afterUpdate: () => this.hideLoadingDreamChart()
    }]

    if (this.chart) {
      this.chart.data.datasets = datasets;
      this.chart.data.labels = this.dataForLabels;

      this.chart.update();
    } else {
      this.chart = initMultipleLinesChart(this.chartElementContainer, datasets, this.dataForLabels, options, plugins);
    }
  }

  buildChartDatasets() {
    let datasets = [];
    let incomes = this.chartLinesData.incomes
    const expenses = this.chartLinesData.expenses
    const contributions = this.chartLinesData.contributions

    const pointStyle = this.isLifeTimeView ? null : this.pointImageSource();

    if (incomes.length == 0) {
      incomes = new Array(expenses.length).fill(0);
    }

    datasets.push({
      label: 'Total Income',
      data: incomes.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: INCOME,
      backgroundColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      tension: 0.1,
      fill: false,
      pointBackgroundColor: '#E8B012',
      pointBorderColor: '#E8B012',
      pointBorderWidth: 4,
      pointRadius: 2,
      cubicInterpolationMode: 'monotone',
      responsive: true
    })

    datasets.push({
      label: 'Total CPF Contribution',
      data: contributions.map((value) => parseFloat(value)),
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    })

    datasets.push({
      label: 'Total Expense',
      data: expenses.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      backgroundColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      tension: 0.1,
      fill: false,
      pointBackgroundColor: '#E8B012',
      pointBorderColor: '#E8B012',
      pointBorderWidth: 4,
      pointRadius: 2,
      cubicInterpolationMode: 'monotone',
      responsive: true
    })

    datasets.push({
      label: 'Net Savings',
      data: incomes.map((value, index) => parseFloat(value - expenses[index] - contributions[index])),
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    })

    return datasets;
  }

  buildDataForChartRequest() {
    return {
      range_start_age: this.currentRangeStartAge,
      is_lifetime_view: this.isLifeTimeView
    }
  }
}
