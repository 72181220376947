export const togglePassword = () => {
  $('.toggle-password').on('click', ({ target }) => {
    const $passwordInput = $(target).parent().find(':input');
    if ($passwordInput.attr('type') === 'password') {
      $passwordInput.attr('type', 'text');
      $(target).addClass('show-pass');
      $(target).removeClass('hidden-pass');
    } else {
      $passwordInput.attr('type', 'password');
      $(target).addClass('hidden-pass');
      $(target).removeClass('show-pass');
    }
  });
};
