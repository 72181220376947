const moment = require("moment");
import { updateCalendarTaskColor } from '../planners/updateCalendarTaskColor';
export const triggerChangeStatusTask = (btnTrigger, outerId , completedId, openedOverdueId, backlogId, uncheckClass, checkedClass) => {
    $(outerId).on('change', btnTrigger, function(e) {
        const $this = e.target;
        const taskId = $($this).data('id');
        let duedate = $($this).data('duedate');
        const is_complete = $($this).is(":checked");
        const is_call = $($this).data('call');
        const btnLog = $('#log-call');
        const isRecurring = $($this).data('recurring');
        let data = {};
        let status_task = 'completed';

        if(is_complete){
            data = {
                task: {status: status_task},
                recurring_status: { recurring_status: status_task, recurring_duedate: duedate }
            };
        } else {
            status_task = 'open'
            data = {
                task: {status: status_task},
                recurring_status: { recurring_status: status_task, recurring_duedate: duedate }
            };
        }

        $.ajax({
            url: Routes.mark_completed_consultant_task_path(taskId),
            type: 'PUT',
            dataType: 'json',
            data: data,
            success: function(res) {
              if(res.status === 200){
                  const now = moment().format("DD-MM-YYYY");
                  const date = res.task.date;
                  const overdueTask = $(outerId).find(openedOverdueId);
                  const backlogTask = $(outerId).find(backlogId);
                  const containerTask = $(outerId).find(`#task-${taskId}-${duedate}`);
                  if(is_complete){
                      updateCalendarTaskColor(`.task-${taskId}`, res.task.taskType, status_task);
                      if(is_call){
                          btnLog.attr("href", Routes.new_log_call_consultant_task_path(taskId, { recurring_duedate: duedate}));
                          btnLog[0].click();
                          btnLog.attr("href", '#');
                      }

                      if(isRecurring) {
                        containerTask.remove();
                        return;
                      }
                      containerTask.find(`.${uncheckClass}`).removeClass(uncheckClass).addClass(checkedClass);
                      //containerTask.find('.text-danger').removeClass('text-danger').addClass('text-dark');
                      //containerTask.find('.align-self-start').remove();
                      //containerTask.find('.task-description').addClass('d-none');

                      //const completedHTML = `<div class="align-self-start text-dark completed_at">${moment(completedAt).format("DD MMM YYYY")}</div>`;
                      //containerTask.append(completedHTML);


                      if(duedate < now && duedate !== '' && duedate !== null){
                        const containerTaskTmp = $(outerId).find(`#task-${taskId}-${duedate}-overdue`);
                        containerTaskTmp.removeClass('d-none');
                        containerTaskTmp.find(`.${uncheckClass}`).removeClass(uncheckClass).addClass(checkedClass);
                        containerTaskTmp.find('input[type="checkbox"]').prop("disabled", false)
                        containerTask.removeClass('d-flex').addClass('d-none');
                        containerTask.find('input[type="checkbox"]').prop("disabled", true)
                        containerTask.attr('id', `task-${taskId}-${duedate}-overdue`);
                        containerTaskTmp.attr("id",`task-${taskId}-${duedate}`)
                        const htmlTmp = containerTaskTmp[0].outerHTML;
                        const html = containerTask[0].outerHTML;
                        $(`#container-completed-${duedate}`).append(htmlTmp);
                        $(`#container-completed-${duedate}`).append(html);
                        containerTaskTmp.remove();
                      } else {
                        if(duedate === '' || duedate === null){
                          duedate = date
                          const containerTaskTmp = $(outerId).find(`#task-${taskId}-backlog`);
                          containerTaskTmp.removeClass('d-none');
                          containerTaskTmp.find(`.${uncheckClass}`).removeClass(uncheckClass).addClass(checkedClass);
                          //containerTaskTmp.find('input[type="checkbox"]').prop("disabled", false)
                          //containerTask.addClass('d-none').removeClass('d-flex');
                          //containerTask.find('input[type="checkbox"]').prop("disabled", true)
                          containerTaskTmp.attr("id",`task-${taskId}-${duedate}`)
                          //containerTask.attr('id', `task-${taskId}-backlog`);
                          const htmlTmp = containerTaskTmp[0].outerHTML;
                          const html = containerTask[0].outerHTML;
                          $(`#container-completed-${duedate}`).append(htmlTmp);
                          containerTaskTmp.remove();
                        } else {
                          const html = containerTask[0].outerHTML;
                          $(`#container-completed-${duedate}`).append(html);
                        }
                      }
                      containerTask.remove();
                      if ($(`#container-${duedate}`).find('.task-item').length > 0 && duedate >= now){
                        $(`#container-${duedate}`).removeClass('empty-background');
                      }
                      $(`#container-completed-${duedate}`).removeClass('empty-background');
     
                      if($('#backlog-task').find('.task-other-item').length === 0){
                        $('#text-other').removeClass('d-none');
                      } else {
                        $('#text-other').addClass('d-none');
                      }
                      
                      if ($('#open-task-overdue').find('.task-overdue-item').length === 0){
                        $('#text-over').removeClass('d-none');
                      } else {
                        $('#text-over').addClass('d-none');
                      }

                      $(`.${checkedClass}`).prop('checked', true);
                  } else {
                      updateCalendarTaskColor(`.task-${taskId}`, res.task.taskType, status_task);
                      containerTask.find(`.${checkedClass}`).removeClass(checkedClass).addClass(uncheckClass);
                      if(status_task === 'open'){
                        //   if(now > date){
                        //       containerTask.find('#open-edit-task').removeClass('text-dark').addClass('text-danger');
                        //       containerTask.find('.align-self-start').removeClass('text-dark').addClass('text-danger');
                        //   }
                        //   containerTask.find('.blog').addClass('d-none');
                        //   containerTask.find('.completed_at').addClass('d-none');
                        //   containerTask.find('.task-description').removeClass('d-none');
                        //   if(is_call) {
                        //     containerTask.find('.log-call').addClass('d-none');
                        //   }

                          let html = containerTask[0].outerHTML;
                          if (date < now){
                            const containerTaskTmp = $(outerId).find(`#task-${taskId}-${duedate}-overdue`);
                            containerTaskTmp.removeClass('d-none').addClass('d-flex');
                            containerTaskTmp.find('input[type="checkbox"]').prop("disabled", false)
                            containerTaskTmp.find(`.${checkedClass}`).removeClass(checkedClass).addClass(uncheckClass);
                            containerTask.find(`.${uncheckClass}`).removeClass(uncheckClass).addClass(checkedClass);
                            containerTask.addClass('d-none').removeClass('d-flex');
                            containerTask.find('input[type="checkbox"]').prop("disabled", true)
                            containerTaskTmp.attr("id",`task-${taskId}-${duedate}`)
                            containerTask.attr('id', `task-${taskId}-${duedate}-overdue`);
                            const htmlTmp = containerTaskTmp[0].outerHTML;
                            const html = containerTask[0].outerHTML;
                            $('#text-over').addClass('d-none');
                            overdueTask.append(htmlTmp);
                            overdueTask.append(html);
                            containerTaskTmp.remove();
                          } else {
                            $(`#container-${duedate}`).append(html);
                          }
                      } else {
                          const html = containerTask[0].outerHTML;
                          $('#task-backlogs').append(html);
                      }

                      containerTask.remove();
                      if($(`#container-completed-${duedate}`).find('.task-item').length == 0 && duedate < now){
                        $(`#container-completed-${duedate}`).addClass('empty-background');
                      } else if ($(`#container-completed-${duedate}`).find('.task-item').length > 0 && duedate >= now) {
                        $(`#container-completed-${duedate}`).removeClass('empty-background');
                      }

                      if(duedate >= now){
                        $(`#container-${duedate}`).removeClass('empty-background');
                      }

                      $(`.${uncheckClass}`).prop('checked', false);
                  }
              } else {
                  alert("Something wrong. Please try again.");
              }
            }
        })
    })
}
