import AssetEvents from './assetEvents';
import calculateDepositAmountByClient from './shared/calculateDepositAmountByClient';
import eventForRecurringDepositType from './shared/eventForRecurringDepositType';
import AddFamilyMemberAction from '../dreams/addFamilyMemberAction';
import dynamicIncludedMonth from '../dynamicIncludedMonth';
import { handleInsurerOther } from '../shared/handleInsurerOther';
import { handlePolicyBenefit } from '../shared/handlePolicyBenefit';
import { handleTotalDepositOnChange, handleTotalPaidOnChange } from './shared/calculateTotalPaidAmount';
import { DEFAULT_CURRENCY } from '@/constants';

export default class AssetInvestmentForm extends AssetEvents {
  constructor(formSourceSelector='.networth-modal') {
    super();
    this.formSelector = '#asset-investment-form';
    this.recurringInputSelector = '#recurring-deposit-input';
    this.recurringIntervalInputSelector = '#recurring-interval-type';
    this.recurringDepositByClientSelector = '#recurring-deposit-by-client';
    this.remaindingPaymentTypeSelector = '#remaining-payment-type';
    this.investmentTypeSelector = '#investment-type-selector';
    this.policyDetailAddedCheckboxSelector = '#with-ilp-investment';
    this.otherInvestmentFormSelector = '.other-investment-form';
    this.otherInvestmentFormCustomSelector = '.other-investment-form-custom';
    this.ilpInvestmentFormSelector = '.ilp-investment-form';
    this.ilpInvestmentFormCustomSelector = '.ilp-investment-form-custom';
    this.inceptionDateForm = '.inception-date-form';
    this.checkboxRecurringAsset = '#investment-recurring-checkbox';
    this.includedThisMonthBlock = '#included-this-month-block';
    this.includedThisMonthPosition = '.included-this-month-position';
    this.paymentEndDate = '#payment-end-date';
    this.currencySelector = '#currency-select';
    this.formSourceSelector = formSourceSelector;
  }

  setup(forClientPortal = false) {
    this.setExistingValue();
    this.addOnchangeRecurringAmount();
    this.eventForRecurringDepositType();
    this.eventForInvestmentType();
    this.setAssetEvents();
    handleInsurerOther();
    handleTotalDepositOnChange();
    handleTotalPaidOnChange();
    handlePolicyBenefit();
    if (!forClientPortal) {
      new AddFamilyMemberAction().setup(this.formSourceSelector);
    }
    this.handleIntervalType();
    this.setupChangeIntervalType();
  }

  handleIntervalType() {
    $(this.investmentTypeSelector).on('change', ({ target }) => {
      this.setupChangeIntervalType();
    });
  }

  setupChangeIntervalType() {
    if ($('#investment-type-selector').val() == 'ilp') {
      new dynamicIncludedMonth().setup('#premium-interval-type');
    } else {
      new dynamicIncludedMonth().setup('#recurring-interval-type');
    }
  }

  setInceptionDateForm() {
    const $checkbox = $(this.checkboxRecurringAsset);
    const $form = $(this.inceptionDateForm);

    if ($checkbox.is(':checked')) {
      $form.show();
      $form.find('input').prop('disabled', true);
    } else {
      $form.hide();
      $form.find('input').prop('disabled', false);
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
        $form.find('input').prop('disabled', true);
      } else {
        $form.hide();
        $form.find('input').prop('disabled', false);
      }
    });
  }

  setExistingValue() {
    this.calculateDepositAmountByClient();
    this.togglePolicyDetailAddedCheckbox($(this.investmentTypeSelector).val());
  }

  addOnchangeRecurringAmount() {
    $(this.formSelector).on('input', `${this.recurringInputSelector}, ${this.recurringIntervalInputSelector}`, () => {
      this.calculateDepositAmountByClient();
    });
  }

  calculateDepositAmountByClient() {
    let currency = $(this.currencySelector).val() || DEFAULT_CURRENCY;

    calculateDepositAmountByClient(
      this.recurringInputSelector,
      this.recurringIntervalInputSelector,
      this.recurringDepositByClientSelector,
      currency,
    );
  }

  eventForRecurringDepositType() {
    eventForRecurringDepositType(this.recurringIntervalInputSelector, this.remaindingPaymentTypeSelector);
  }

  eventForInvestmentType() {
    $(this.investmentTypeSelector).on('change', ({ target }) => {
      const investmentType = target.value;

      this.togglePolicyDetailAddedCheckbox(investmentType);
    });
  }

  togglePolicyDetailAddedCheckbox(investmentType) {
    if (investmentType == 'ilp') {
      $(`${this.otherInvestmentFormSelector} ${this.includedThisMonthBlock}`).appendTo(
        `${this.ilpInvestmentFormSelector} ${this.includedThisMonthPosition}`,
      );
      $(`${this.otherInvestmentFormSelector} ${this.includedThisMonthBlock}`).remove();
      $(`${this.includedThisMonthBlock}`).appendTo(
        `${this.ilpInvestmentFormSelector} ${this.includedThisMonthPosition}`,
      );
      $(this.otherInvestmentFormSelector).hide();
      $(this.otherInvestmentFormSelector).find('input').prop('disabled', true);
      $(this.otherInvestmentFormCustomSelector).show();
      $(this.ilpInvestmentFormSelector).show();
      $(this.ilpInvestmentFormCustomSelector).show();
    } else {
      $(this.includedThisMonthBlock).appendTo(`${this.otherInvestmentFormSelector} ${this.includedThisMonthPosition}`);
      $(`${this.ilpInvestmentFormSelector} ${this.includedThisMonthBlock}`).remove();
      $(this.otherInvestmentFormSelector).show();
      $(this.otherInvestmentFormSelector).find('input').prop('disabled', false);
      $(this.otherInvestmentFormCustomSelector).hide();
      $(this.ilpInvestmentFormSelector).hide();
      $(this.ilpInvestmentFormCustomSelector).show();
    }
  }
}
