import { toggleFormCocoonCollapse } from '@/layouts/application/packages/cocoon';

class MerlinReferralQuestion {
  constructor() {
    this.merlinReferralQuestionContainerSelector = '.merlin-referral-question';
    this.merlinIconSectionSelector = '.icon-section';
    this.merlinQuestionSectionSelector = '.question-section';
    this.closeIconSelector = '.question-section .cancle-icon'
    this.toggleNoteBoxSelector = '#toggle-note-box';
    this.checkDropdownToggleSelector = '#note-list .show';

    this.setup();
    this.isShowing = false;
  }

  setup() {
    toggleFormCocoonCollapse();
    document.addEventListener("turbolinks:load", () => {
      $(this.merlinQuestionSectionSelector).hide();

      $(this.merlinIconSectionSelector).on('click', () => {
        this.handleNoteWhenShowReferral();
        const $questionSection = $(this.merlinQuestionSectionSelector);

        if (this.isShowing) {
          if ($(`${this.merlinQuestionSectionSelector}:hidden`).length == $questionSection.length) {
            $questionSection.fadeIn();
            this.isShowing = true;
            return true;
          } else {
            $questionSection.fadeOut();
          }
        } else {
          $questionSection.fadeIn();
        }

        this.isShowing = !this.isShowing;
      });

      $(this.closeIconSelector).on('click', ({ target }) => {
        $(target).closest(this.merlinQuestionSectionSelector).hide();
      });

    });
  }

  handleNoteWhenShowReferral() {
    if ($(this.checkDropdownToggleSelector).length > 0) {
      $(this.toggleNoteBoxSelector).trigger('click');
    }
  }
}

export default new MerlinReferralQuestion();
