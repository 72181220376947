import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class ClientsGiveawaysController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index(){

  }
}

Raicon.register('clients/giveaways', ClientsGiveawaysController)
