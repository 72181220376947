import Raicon from 'raicon';
import InsurancePolicyForm from '../../consultant/insurances/insurancePolicyForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import dynamicIncludedMonth from '../../consultant/dynamicIncludedMonth';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';

class InsurancePolicesController {
  constructor() {
    this.payoutOptionSelector = '#payout-option-selector';
    this.lumpSumPayoutOptionSelector = '#lump-sum-payout-option';
    this.recurringPayoutOption = '#recurring-payout-option';
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {}

  new() {
    this.setup();
    handleHideSignSgd('#currency-select', '.property-currency');
  }

  edit() {
    this.setup();
    handleLockForm(['#insurance-policy-form']);
    handleLockForm(['#insurance-policy-form-automatic']);
    handleHideSignSgd('#currency-select', '.property-currency');
  }

  create() {
    this.setup();
  }

  update() {
    this.setup();
  }

  show() {}

  newInsurancePolicySelection() {}

  newInsurancePolicySelectionOther() {}

  showPolicyOther() {}

  showDetailPolicyOther() {}

  needsAnalysis() {}

  setup() {
    this.setExistingValue();
    this.eventForPayoutOption();
    new InsurancePolicyForm().setupForClientPortal();
    handleMoneyField();
    this.toggleDreamPlanningEvent();
    new dynamicIncludedMonth().setup('#insurance_policy_premium_interval_type');
  }

  toggleDreamPlanningEvent() {
    const $checkbox = $('#dream-planing-checkbox');
    const $recurringForm = $('.payout-option-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  setExistingValue() {
    this.togglePayoutOption($(this.payoutOptionSelector).val());
  }

  eventForPayoutOption() {
    $(this.payoutOptionSelector).on('change', ({ target }) => {
      const payoutOptionValue = target.value;

      this.togglePayoutOption(payoutOptionValue);
    });
  }

  togglePayoutOption(payoutOption) {
    if (payoutOption == 'lump_sum_payout') {
      $(this.lumpSumPayoutOptionSelector).show();
      $(this.recurringPayoutOption).hide();
    } else if (payoutOption == 'recurring_payout') {
      $(this.lumpSumPayoutOptionSelector).hide();
      $(this.recurringPayoutOption).show();
    } else {
      $(this.lumpSumPayoutOptionSelector).hide();
      $(this.recurringPayoutOption).hide();
    }
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }
}

Raicon.register('clients/insurancePolicies', InsurancePolicesController);
