const GUIDESTEXT = {
  // consultant dashboard
  'planner-sidenav-icon': 'have your upcoming appointments, important dates of opportunities and tasks in a single consolidated view',
  'opportunity-sidenav-icon': 'keep track of cases you’ve opened or can potentially open',
  'clients-sidenav-icon': 'start your clients on their personalized dream planning experience with you',
  'referees-sidenav-icon': 'collate your leads here, so you never miss out on any potential business asset that you can reach out to',
  'setting-sidenav-icon': 'set up your chatbot and link your zoom account for seamless integration',
  'edit-target-icon': 'knowing what you want to achieve is the first step in mapping out your route to get there',
  'open-activity-log-icon': 'update your critical activities on a weekly basis for greater clarity to ensure productivity over busyness',
  'open-activity-log': 'review your weekly activities tab zoom in to the areas that need improving for you to see exponential results',
  'policy-anni-navtab': 'easily identify opportunities for you to maximize your assets',
  'focus-today-tab': 'focus not on the results, but on the actions that can bring you those results',
  'merlin-prompter': 'need a second look at these explanations later, or steps on what you can do next? click me!',
  // planners
  'appointment-filter': 'select to view the appointments, tasks and various opportunities for reaching out to your clients',
  'select-calendar-type-button': 'sync with your google calendar to have all your activities consolidated in one convenient view',
  'planners-sync-btn': 'pull the information you’ve updated in your google calendar to merlin planner, and vice versa',
  'planners-add-tasks-btn': 'add the critical tasks you need to complete as you plan ahead each week',
  'overdue-tasks-box': "don’t forget to catch up on your tasks that are supposed to be completed",
  'other-task-box': 'these are items with no due date that you can work on once you’ve completed the critical items for the day',
  'week-list': "see your tasks in order of priority, and check the box once it’s been completed!",
  // opportunities
  'lost-navtab': 'remember, reviewing your lost cases is just as important as bringing in your new cases',
  'case-list-wrapper': 'constantly revisit this list for your lowest hanging fruits!',
  'new-cases-btn': 'keep track of the cases you’ve opened or can potentially open to reduce any leakage in your processes',
  'table-open-task-btn': 'add tasks for reminders to follow up so you don’t forget',
  'table-open-appointment-btn': 'add appointments directly into your planner',
  'table-view-clients-btn': 'view client’s profile',
  // referees
  'search-text-wrapper': 'search by name, email, mobile, or remarks',
  'import-referees-btn-wrapper': "whether it’s an old acquaintance you haven’t been in touch with or a lead from a campaign you’re running, collate your leads here for systematic tracking",
  'remarks-title': 'you can use this field to indicate the campaign name (eg social media) or purpose (eg pif), which you can then search to filter thereafter',
  'table-add-tasks-btn': "add tasks for reminders to follow up so you don’t forget",
  'table-add-appointment-btn': 'add appointments directly into your planner',
  'table-add-client-btn': 'add a client profile'
}

// only add 'table' as id for elements in table

const dashboardOverlayGuidesElements = {
  'planner-sidenav-icon': 'bottom-left',
  'opportunity-sidenav-icon': 'left',
  'clients-sidenav-icon': 'left',
  'referees-sidenav-icon': 'top-left',
  'setting-sidenav-icon': 'top-left',
  'edit-target-icon': 'top-right',
  'open-activity-log-icon': 'top-right',
  'open-activity-log': 'bottom-right',
  'policy-anni-navtab': 'top-left',
  'focus-today-tab': 'top'
}

const plannersOverlayGuidesElements = {
  'appointment-filter': 'top',
  'select-calendar-type-button': 'right',
  'planners-sync-btn': 'top-right',
  'planners-add-tasks-btn': 'top-right',
  'overdue-tasks-box': 'bottom',
  'other-task-box': 'bottom',
  'week-list': 'bottom'
}

const casesOverlayGuidesElements = {
  'lost-navtab': 'left',
  'case-list-wrapper': 'bottom',
  'new-cases-btn': 'right',
  'table-open-task-btn': 'table-right',
  'table-open-appointment-btn': 'table-bottom',
  'table-view-clients-btn': 'table-top'
}

const refereeOverlayGuidesElements = {
  'search-text-wrapper': 'left',
  'import-referees-btn-wrapper': 'right',
  'remarks-title': 'top-right',
  'table-add-tasks-btn': 'table-right',
  'table-add-appointment-btn': 'table-bottom',
  'table-add-client-btn': 'table-top'
}

const closeOverlayGuides = () => {
  $('.close-overlay-btn').on('click', () => {
    $('.overlay-guides-container').hide();
    $('.guides-text-wrapper').hide();
  });
}

const showOverlayGuidesText = () => {
  $('.overlay-guides-container').show();
  var elementsWithGuides = getElementsByPathName();
  for (const [elementId, position] of Object.entries(elementsWithGuides)) {
    showTextGuides(position, elementId);
  }
  showMerlinPrompterTextGuides();
}

const handleShowOverlayGuidesWhenUserFirstAccess = () => {
  if ('localStorage' in window && window['localStorage'] !== null) {
    const pathName = window.location.pathname.replaceAll('/', '');

    // if the counter has been defined, increment its value, // otherwise, set it to 0
    (`${pathName}` in localStorage && localStorage[`${pathName}`] !== null) ? localStorage[`${pathName}`]++ : localStorage[`${pathName}`] = 0;
    if (localStorage[`${pathName}`] == 0) {
      showOverlayGuidesText();
    } else  {
      $('.overlay-guides-container').hide();
    }
  }
}

const getElementsByPathName = () => {
  const pathName = window.location.pathname;

  if (pathName.includes('planners')) {
    return plannersOverlayGuidesElements;
  }
  else if (pathName.includes('opportunities')) {
    return casesOverlayGuidesElements;
  }
  else if (pathName.includes('referees')) {
    return refereeOverlayGuidesElements;
  } else {
    return dashboardOverlayGuidesElements;
  }
}

export const handleOverlayGuides = () => {
  closeOverlayGuides();
  handleShowOverlayGuidesWhenUserFirstAccess();
  handleClickViewOverlay();
}

const showTextGuides = (position, elementId) => {
  const guideTextWrapperPosition = positioningGuidesTextWrapper(position);
  const guideTextPosition = positioningGuidesText(position);
  const guideText = GUIDESTEXT[`${elementId}`];
  var minWidth = 350;

  if (elementId.includes('table')) {
    minWidth = 125;
  }

  $(`#${elementId}`).append(`
    <div class='guides-text-wrapper position-absolute' style='${guideTextWrapperPosition}'>
      <div class='${position}-line-arrow overlay-guides-text'>-></div>
      <div class='overlay-guides-text' style='max-width: 400px; min-width: ${minWidth}px; position: absolute; ${guideTextPosition}'>
        ${guideText}
      </div>
    </div>
  `)
}

const showMerlinPrompterTextGuides = () => {
  $(`#merlin-prompter`).append(`
    <div class='guides-text-wrapper position-fixed' style='right: 0; bottom: 162px; z-index: 99999'>
      <div class='merlin-prompter-line-arrow overlay-guides-text'>-></div>
      <div class='overlay-guides-text' style='max-width: 400px; min-width: 350px; position: absolute; bottom: -150px; right: 50px'>
        ${GUIDESTEXT['merlin-prompter']}
      </div>
    </div>
  `)
}

const handleClickViewOverlay = () => {
  $('#show-overlay-btn').on('click', () => {
    $('.question-section').hide();
    showOverlayGuidesText();
  })
}

const positioningGuidesTextWrapper = (position) => {
  switch(position) {
    case 'top-left':
      return 'left: 100%; margin-top: 20px';
    case 'bottom-left':
      return 'left: 100%; margin-top: 20px';
    case 'left':
      return 'left: 110%; margin-top: 20px';
    case 'top-right':
      return 'margin-top: 35px; left: 0';
    case 'bottom-right':
      return 'top: 0; right: 0';
    case 'right':
      return 'left: 0; top: 20px';
    case 'top':
      return 'left: 0; top: 90px;'
    case 'bottom':
      return 'left: 60%; top: 0';
    // special for those element in table
    case 'table-right':
      return 'margin-top: 35px; left: 0';
    case 'table-top':
      return 'left: 0; top: 90px;'
    case 'table-bottom':
      return 'left: 60%; top: 0';
  }
}

const positioningGuidesText = (position) => {
  switch(position) {
    case 'top-left':
      return 'margin-left: 50px;';
    case 'bottom-left':
      return 'margin-left: 60px; bottom: 60px';
    case 'left':
      return 'margin-left: 60px; bottom: 40px';
    case 'top-right':
      return 'right: 20px';
    case 'bottom-right':
      return 'top: -80px; right: 20px';
    case 'right':
      return 'right: 30px; top: -30px';
    case 'top':
      return 'margin-top: 20px; left: 0';
    case 'bottom':
      return 'bottom: 40px; right: 0';
    // special for those element in table
    case 'table-right':
      return 'right: 0; top: -10px';
    case 'table-top':
      return 'top: -10px; left: -40px';
    case 'table-bottom':
      return 'bottom: 0; right: 20px';
  }
}
