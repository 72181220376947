export function waitForElement(selector) {
  return new Promise(function (resolve, reject) {
    var element = document.querySelector(selector);

    if (element) {
      resolve(element);
      return;
    }

    new MutationObserver((mutationRecords, observer) => {
      Array.from(document.querySelectorAll(selector)).forEach((elm) => {
        resolve(elm);
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

export function getUrlParamValueByName(paramName) {
  const url = new URL(window.location.href);
  const paramValue = url.searchParams.get(paramName);
  return paramValue;
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function camelToSnake(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => camelToSnake(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    acc[snakeKey] = camelToSnake(obj[key]);
    return acc;
  }, {});
}

export function snakeToCamel(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const camelObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelKey = key.replace(/_([a-z])/g, function (match, letter) {
          return letter.toUpperCase();
        });
        camelObj[camelKey] = snakeToCamel(obj[key]);
      }
    }
    return camelObj;
  } else {
    return obj;
  }
}

export function convertToEmbedLink(link) {
  const regex = /(?:\?v=|\/embed\/|\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;
  const match = link.match(regex);

  if (match && match[1]) {
    const videoID = match[1];
    const embeddedLink = `https://www.youtube.com/embed/${videoID}`;

    return embeddedLink;
  }
  return link;
}
