const calculateTartgetYearBaseOnStartDate = (startDateSelector, durationSelector, tartgetYearSelector, targetMonthSelector = "") => {
  calculateTargetYear(startDateSelector, durationSelector, tartgetYearSelector, targetMonthSelector)

  $(`${startDateSelector}, ${durationSelector}`).on('input', () => {
    calculateTargetYear(startDateSelector, durationSelector, tartgetYearSelector, targetMonthSelector)
  })

  if (targetMonthSelector) {
    $(`${targetMonthSelector}`).on('input', () => {
      calculateTargetYear(startDateSelector, durationSelector, tartgetYearSelector, targetMonthSelector)
    })
  }
}

const calculateTargetYear = (startDateSelector, durationSelector, tartgetYearSelector, targetMonthSelector) => {
  const currentDateYear = parseInt(new Date().getFullYear() || 0);
  let startDateYear = parseInt(new Date($(startDateSelector).val()).getFullYear() || 0);
  const startMonthYear = parseInt((new Date($(startDateSelector).val()).getMonth() + 1) || 0);
  const extraMonth = parseInt($(targetMonthSelector).val() || 0);
  const duration = parseInt($(durationSelector).val() || 0);

  if(startDateYear <= currentDateYear){
    startDateYear = currentDateYear
  }

  if (Number.isInteger(startDateYear) && Number.isInteger(duration) && Number.isInteger(extraMonth) && Number.isInteger(startMonthYear)) {
    let tartgetYear = startDateYear + duration;
    if(startMonthYear + extraMonth >= 12){
      tartgetYear += (((startMonthYear + extraMonth) - (startMonthYear + extraMonth)%12) / 12);
    }
    
    $(tartgetYearSelector).html(tartgetYear);
  }
}

export default calculateTartgetYearBaseOnStartDate;
