import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { CkeditorUploadAdapterPlugin } from '@/layouts/application/utils/ckeditorUploadAdapter';
import { debounce, camelToSnake, snakeToCamel } from '@/helpers/index';
import { convertToEmbedLink } from '../../../helpers';
const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);
const fileExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'csv', 'pptx', 'docx', 'xlsx'];
let step = 0;
let existedName;
let replaceName;
let imageLinks = [];

const setupEditor = () => {
  return ClassicEditor.create($('.editor')[0], {
    extraPlugins: [CkeditorUploadAdapterPlugin],
  }).then((editor) => {
    imageLinks = [];
    editor.model.document.on(
      'change:data',
      debounce(() => {
        const fcName = $('#preview-email-template .text-container').data('fc-name');
        $('#preview-email-template .text-container').html(editor.getData().replace('{{fc name}}', fcName));
        step = 0;

        if (editor.getData().includes('oembed')) {
          handlePreviewMedia();
        }
      }, 400),
    );
    editor.editing.view.document.on('change:isFocused', (evt, data, isFocused) => {
      if (isFocused) {
        $('.editor-footer').addClass('border-blue');
      } else {
        $('.editor-footer').removeClass('border-blue');
      }
      if (editor.getData().includes('img')) {
        handleAddAttachToImage();
      }
    });
    editor.ui.view.toolbar.element.addEventListener('click', () => {
      $('.editor-footer').addClass('border-blue');
    });

    if (editor.getData().includes('oembed')) {
      handlePreviewMedia();
    }

    return editor;
  });
};

const handleOpenPreviewModal = (previewPath, sendTestEmailPath) => {
  const openPreviewModals = '.open-preview-modal';
  const modalSelector = '#preview-template-modal';
  const templateLink = '#template-link';
  const previewTemplateBtn = '#preview-template-btn';

  Array.from($(openPreviewModals)).forEach((preview) => {
    $(preview).on('click', () => {
      const id = $(preview).data('id');
      const broadcastType = $(preview).data('broadcast-type');
      const url = window.location.origin + Routes[previewPath](id);
      $(templateLink).val(url);
      $(previewTemplateBtn).prop('href', url);
      $('#copy-template-link').off();
      copyExecuted('#copy-template-link', '#template-link');
      if (broadcastType === 'broadcast') {
        $('.unsubscribe-btn-description').removeClass('d-none');
      } else {
        $('.unsubscribe-btn-description').addClass('d-none');
      }
      $(modalSelector).modal('show');
      let path = Routes[sendTestEmailPath](id);
      handleSendTestEmail(path);
    });
  });
};

const handleSendTestEmail = (path) => {
  const sendTestEmailBtn = '#send-test-email';
  $(sendTestEmailBtn).on('click', () => {
    const emails = $('#campaign-link').val();
    $.post(path, { emails });
  });
};

const copyDynamicTagName = () => {
  $('#copy-dynamic-name').on('click', () => {
    navigator.clipboard.writeText('{{client}}').then(() => {
      alert('Copied to clipboard!');
    });
  });
  $('#copy-dynamic-fc-name').on('click', () => {
    navigator.clipboard.writeText('{{fc name}}').then(() => {
      alert('Copied to clipboard!');
    });
  });
};

const handleAttachFileChange = () => {
  $('#attach-file').on('change', (e) => {
    let files = Array.from(e.target.files);
    if (!validateFiles(files)) {
      return;
    }
    uploadAttachFiles(files).then((result) => {
      const blobIds = result.blob_ids;
      let attachedFileIds = getAtttachedFiles();
      setAttachedFiles(attachedFileIds.concat(blobIds));
      files = files.map((file, index) => ({
        type: file.type,
        name: file.name,
        blob: blobIds[index],
        status: 'new',
      }));
      syncAttachFiles(files);
    });
  });
};

const syncAttachFiles = (files, type = 'append') => {
  if (files.length == 0) {
    $('.file-container').html('');
  }

  const attachments = files.map((file) => {
    let fileType = file.type.split('/')[1];
    let fileExtension = file.name.split('.').at(-1);
    let iconUrl;
    if (fileExtensions.includes(fileExtension)) {
      iconUrl = imagePath(`./icons/${fileType}-document.svg`);
    } else {
      iconUrl = imagePath(`./icons/file-common-icon.svg`);
    }
    return `
      <div class="file-wrapper">
        <div class="file-icon">
          <img src="${iconUrl}" />
        </div>
        <span class="file-name">
          ${file.name}
        </span>
        <button class="mb-0 btn delete-attach-file" data-blob-id="${file.blob}" data-status="${file?.status}">
        x
        </button>
      </div>
    `;
  });
  if (type == 'replace') {
    $('.file-container').html(attachments.join(''));
  } else $('.file-container').append(attachments.join(''));
  handleDeleteAttachFile();
};

const handleDeleteAttachFile = () => {
  $('.delete-attach-file').each((_index, btnDeleteFile) => {
    $(btnDeleteFile).on('click', (e) => {
      e.preventDefault();
      const blobId = $(btnDeleteFile).data('blob-id');
      const status = $(btnDeleteFile).data('status');
      let attachedFileIds = getAtttachedFiles();
      attachedFileIds = attachedFileIds.filter((file) => file != blobId);
      setAttachedFiles(attachedFileIds);
      if (status == 'new') {
        $.ajax({
          method: 'DELETE',
          url: Routes.upload_file_path({ id: blobId }),
        });
      }
      $(`[data-blob-id="${blobId}"]`).parent().remove();
    });
  });
};

const handleShowRealClientName = () => {
  $('#icon-right').on('click', () => {
    replacePreviewName('{{client}}', 'Aaron');
  });
  $('#icon-left').on('click', () => {
    replacePreviewName('Aaron', '{{client}}');
  });
};

const uploadAttachFiles = async (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files[]', file);
  });
  const response = await fetch(Routes.upload_multiple_files_upload_files_path(), {
    method: 'POST',
    body: formData,
  });
  const result = response.json();
  return result;
};

const validateFiles = (files) => {
  let valid = true;
  for (let i = 0; i < files.length; i++) {
    const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
    if (files[i].size > maxSizeInBytes) {
      alert('File exceeds maximum size limit, must be smaller than 20 MB');
      valid = false;
      break;
    }
  }
  return valid;
};

const getAtttachedFiles = () => {
  if ($('#attach-file-id').val() == '') {
    return [];
  }
  return JSON.parse($('#attach-file-id').val());
};

const setAttachedFiles = (files) => {
  $('#attach-file-id').val(JSON.stringify(files));
};

const replacePreviewName = (existedName, replaceName) => {
  const previewTemplate = $('#preview-email-template .text-container');
  const htmlContent = previewTemplate.html();
  previewTemplate.html(htmlContent.replace(existedName, replaceName));
};

const handleShowClientNameToPreviewCampaign = (role) => {
  handleShowClientNameFromTabAll(role);
  handleShowClientNameFromCustomTab(role);
};

const handleShowClientNameFromTabAll = (role) => {
  const whiteLists = ['all_people', 'all_contacts', 'all_leads'];
  Array.from($('#email-audience-tab .nav-link')).forEach((tab) => {
    $(tab).on('click', () => {
      const audienceType = $(tab).data('value');
      replacePreviewName(replaceName, '{{client}}');
      if (!whiteLists.includes(audienceType)) {
        $('#icon-right').off();
        $('#icon-left').off();
        replaceName = undefined;
        return;
      }
      $.get(
        Routes[`get_preview_recipients_${role}_enhance_emails_path`]({
          audience_type: audienceType,
          type: 'all',
        }),
      ).done((data) => {
        handleChangeClientNameOnPreviewContent(data);
      });
    });

    if ($(tab).hasClass('active')) {
      $(tab).click();
    }
  });
};

const handleShowClientNameFromCustomTab = (role) => {
  let clientIds = [];
  let leadIds = [];
  $('.email-custom-input').on('change', (e) => {
    const customLists = $('.email-custom-input').val();
    if (role == 'consultant') {
      clientIds = customLists;
    } else {
      const payload = customLists.map((item) => JSON.parse(item));
      clientIds = payload.filter((item) => item.type == 'User').map((item) => item.id);
      leadIds = payload.filter((item) => item.type == 'Referee').map((item) => item.id);
    }

    if (clientIds.length == 0 && leadIds.length == 0) {
      replacePreviewName(replaceName, '{{client}}');
      return;
    }

    $.get(
      Routes[`get_preview_recipients_${role}_enhance_emails_path`]({
        client_ids: clientIds,
        lead_ids: leadIds,
        type: 'custom',
      }),
    ).done((data) => {
      handleChangeClientNameOnPreviewContent(data);
      replacePreviewName(replaceName, '{{client}}');
      $('#icon-right').click();
    });
  });

  if ($('.email-custom-input').val() != []) {
    setTimeout(() => {
      $('.email-custom-input').trigger('change');
    }, 1000);
  }
};

const handleChangeClientNameOnPreviewContent = (recipients) => {
  step = 0;
  $('#icon-right').off();
  $('#icon-left').off();
  $('#icon-right').on('click', () => {
    if (step == 0) {
      existedName = '{{client}}';
      replaceName = recipients[step++].name;
    } else if (step == recipients.length) {
      existedName = recipients[step - 1].name;
      replaceName = '{{client}}';
      step = 0;
    } else {
      existedName = recipients[step - 1].name;
      replaceName = recipients[step++].name;
    }
    replacePreviewName(existedName, replaceName);
  });
  $('#icon-left').on('click', () => {
    replacePreviewName(replaceName, '{{client}}');
    step = 0;
  });
};

const convertStringToHTML = (htmlString) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, 'text/html');
  return html.body;
};

const resetCssBeforeSave = () => {
  $('form').on('submit', () => {
    const templateString = $('.editor').val();
    const templateHtml = convertStringToHTML(templateString);
    const pElements = $(templateHtml).find('p');
    $(templateHtml).find('h1, h2, h3, h4').css({ margin: 0, lineHeight: 1.5 });
    $(templateHtml).find('img').css({ margin: '5px 0' });
    pElements.css({ margin: 0 });
    $.each(pElements, (_i, element) => {
      if (element.innerHTML == '&nbsp;') {
        $(element).css({ lineHeight: '18px' });
      } else {
        $(element).css({ lineHeight: 1.5 });
      }
    });

    if (imageLinks.length > 0) {
      const filteredImageLinks = imageLinks.filter((imageLink) => imageLink.link);
      const imageLinksInput = $('<input type="text" name="image_links" class="d-none"/>');
      imageLinksInput.val(JSON.stringify(camelToSnake(filteredImageLinks)) || []);
      $('form').append(imageLinksInput);
    }

    $('.editor').val(templateHtml.innerHTML);
  });
};

const handleAddAttachToImage = () => {
  $('.ck-widget').on('click', ({ target }) => {
    $('#image-link-form').removeClass('d-none');
    if (imageLinks.length == 0) {
      imageLinks = Array.from(snakeToCamel($('#image-links').data('value'))) || [];
    }

    if (target.tagName != 'IMG') return;

    const imageLink = imageLinks.find((item) => item.imgSrc == target.src);
    if (imageLink) {
      $('#link-url-input').val(imageLink.link);
      $('#link-url-input').attr('data-img-src', imageLink.imgSrc);
    } else {
      const linkObject = {
        imgSrc: target.src,
        link: null,
      };
      imageLinks = [...imageLinks, linkObject];
      $('#link-url-input').val(linkObject.link);
      $('#link-url-input').attr('data-img-src', linkObject.imgSrc);
    }
  });

  $('#attach-link-to-image').on('click', () => {
    $('#image-link-form').addClass('d-none');
  });

  $('#btn-save-link-url').on('click', (e) => {
    e.preventDefault();
    const linkUrlValue = $('#link-url-input').val();
    const imgSrc = $('#link-url-input').attr('data-img-src');
    const selectedItem = imageLinks.find((item) => item.imgSrc == imgSrc);
    selectedItem.link = linkUrlValue;
    $('#image-link-form').addClass('d-none');
  });

  $('#btn-cancel-link-url').on('click', (e) => {
    e.preventDefault();
    $('#image-link-form').addClass('d-none');
  });
};

const handlePreviewMedia = () => {
  const medias = $('oembed[url]');
  if (medias.length == 0) return;

  Array.from(medias).forEach((element) => {
    const iframe = $('<iframe>');
    const link = convertToEmbedLink($(element).attr('url'));

    iframe.attr('src', link);
    iframe.attr('width', '100%');
    iframe.attr('height', '400');
    iframe.attr('frameborder', '0');
    $(element).append(iframe);
  });
};

export {
  handleOpenPreviewModal,
  copyDynamicTagName,
  handleAttachFileChange,
  setupEditor,
  syncAttachFiles,
  handleShowRealClientName,
  handleDeleteAttachFile,
  setAttachedFiles,
  handleShowClientNameToPreviewCampaign,
  resetCssBeforeSave,
  handlePreviewMedia,
};
