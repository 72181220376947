import Raicon from 'raicon';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';

import {
  presentValidator,
  consultantAndAdminEmailValidator,
  dobInThePastValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';

class QuizController {
  constructor() {
    this.formSelector = '#quiz-information-form';
    this.currentStep = 0;
    this.getStarted = '#get-started-button';
    this.stepQueues = [];
    this.stepQuiz = [
      '#welcome-step',
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
      '#information-step-8',
      '#information-step-9',
      '#information-step-10',
      '#information-step-11',
      '#information-step-12',
      '#information-step-13',
      '#information-step-14',
      '#information-step-15',
      '#information-step-16',
      '#information-step-17',
      '#information-step-18',
    ];
    this.stepQuizCareer = [
      '#welcome-step',
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
      '#information-step-8',
      '#information-step-9',
      '#information-step-10',
      '#information-step-11',
      '#information-step-12',
      '#information-step-13',
      '#information-step-14',
      '#information-step-15',
      '#information-step-16',
      '#information-step-17',
      '#information-step-18',
      '#information-step-19',
      '#information-step-20',
    ];
    this.stepResultPageSeedly = ['#first-step', '#second-step', '#third-step', '#fourth-step'];

    this.navigationSection = '#navigation-section';
    this.nextButton = '#next-button';
    this.backButton = '#back-button';
    this.submitButton = '#submit-step';
    this.submitFormButton = '#submit-button';
    this.formInformationStepsSelector = '#form-information-steps';
    this.requiredFields = ['#quiz_name', '#quiz_email', '#quiz_phone_number'];
  }

  lastPageRequiredFields = [
    {
      selector: '#quiz_name',
      validators: [presentValidator],
    },
    {
      selector: '#quiz_email',
      validators: [consultantAndAdminEmailValidator],
    },
  ];

  lastPageRequiredFieldsQuizSeedly = [
    {
      selector: '#quiz_name',
      validators: [presentValidator],
    },
    {
      selector: '#quiz_email',
      validators: [consultantAndAdminEmailValidator],
    },
    {
      selector: '#quiz_phone_number',
      validators: [presentValidator],
    },
    {
      selector: '#quiz_age_range',
      validators: [presentValidator],
    },
    {
      selector: '#quiz_nation',
      validators: [presentValidator],
    },
  ];

  resultSeedlyThirdPageRequiredFields = [
    {
      selector: '#first-name-input',
      validators: [presentValidator],
    },
    {
      selector: '#email-input',
      validators: [consultantAndAdminEmailValidator],
    },
    {
      selector: '#pre_fact_find_dob',
      validators: [presentValidator, dobInThePastValidator],
    },
    {
      selector: '#mobile-number-input',
      validators: [presentValidator],
    },
  ];

  resultSeedlyFourthPageRequiredFields = [
    {
      selector: '#employment-status-input',
      validators: [presentValidator],
    },
    {
      selector: '#monthly-income-range-input',
      validators: [presentValidator],
    },
    {
      selector: '#occupation-input',
      validators: [presentValidator],
    },
  ];

  new() {
    this.stepQueues = this.stepQuiz;
    this.handleNextButton('create');
    this.setup();
    $('.check-privacy-box').on('click', () => {
      if ($('.check-privacy-box').is(':checked')) {
        $('.submit-quiz-button').prop('disabled', false);
      } else {
        $('.submit-quiz-button').prop('disabled', true);
      }
    });
  }

  newQuizCareer() {
    this.stepQueues = this.stepQuizCareer;
    this.handleNextButton('create');
    this.setup();
    this.handlePrivacy();
  }

  newQuizSeedly() {
    this.stepQueues = this.stepQuiz;
    this.handleNextButton('create');
    this.setup('seedly');
    this.handlePrivacy();
    this.handleSingaporePrSection();
  }

  handlePrivacy() {
    $('.check-privacy-box').on('click', () => {
      if ($('.check-privacy-box').is(':checked')) {
        $('.submit-quiz-button').prop('disabled', false);
      } else {
        $('.submit-quiz-button').prop('disabled', true);
      }
    });
  }

  handleSingaporePrSection() {
    $('#quiz_nation').on('change', () => {
      if ($('#quiz_nation').val() != 'Singapore Citizen') {
        $('.singapore-pr-selector').removeClass('d-none');
      } else {
        $('.singapore-pr-selector').addClass('d-none');
      }

      $(':radio[name="quiz[singapore_pr]"]').on('change', function () {
        if ($(this).filter(':checked').val() == 'true' && $('#quiz_nation').val() != 'Singapore Citizen') {
          $('.quiz_singapore_pr_since').removeClass('d-none');
        } else {
          $('.quiz_singapore_pr_since').addClass('d-none');
        }
      });
    });
  }

  create() {
    this.handleNextButton('create');
    this.setup();
  }

  show() {
    this.handleNextButton('show');
    this.setup();
  }

  setup(page = '') {
    this.displayLastPageError();
    this.handleGetStarted();
    this.handleChoseAnswerToNext();
    this.hanldeBackButton();
    calculateAgeByInput('#dob', '.input-age');
    this.handleSubmited(page);
  }

  displayLastPageError() {
    const $formInformationSteps = $(this.formInformationStepsSelector);

    if ($formInformationSteps.length > 0) {
      const lastPageError = parseInt($formInformationSteps.data('first-page-error'));
      this.setStep(lastPageError || 0);
    }
  }

  resultPage() {
    $('#quiz_refree_fc_phone_number, #quiz_refree_fc_name').on('keyup', function (e) {
      let fc_name = $('#quiz_refree_fc_name').val();
      let fc_phone_number = $('#quiz_refree_fc_phone_number').val();
      if (fc_name != '' && fc_phone_number != '') {
        $('#send_mail_result_page').removeClass('d-none');
      } else {
        $('#send_mail_result_page').addClass('d-none');
      }
    });
    this.handlePrivacy();
    this.submitResultForm();
  }

  resultPageSeedly() {
    this.handleNavigationForResultQuizSeedly();
    this.validateForResultQuizSeedly();
  }

  handleNavigationForResultQuizSeedly() {
    $('.next-button').on('click', (e) => {
      let step = $(e.target).attr('data-step');
      if (step == 'first') {
        $('#first-step').addClass('d-none');
        $('#second-step').removeClass('d-none');
      } else if (step == 'second') {
        $('#second-step').addClass('d-none');
        $('#third-step').removeClass('d-none');
      } else if (step == 'third') {
        $('#third-step').addClass('d-none');
        $('#fourth-step').removeClass('d-none');
      }
    });

    $('.back-button-seedly').on('click', (e) => {
      let step = $(e.target).attr('data-step');
      if (step == 'second') {
        $('#first-step').removeClass('d-none');
        $('#second-step').addClass('d-none');
      } else if (step == 'third') {
        $('#second-step').removeClass('d-none');
        $('#third-step').addClass('d-none');
      } else if (step == 'fourth') {
        $('#third-step').removeClass('d-none');
        $('#fourth-step').addClass('d-none');
      }
    });
  }

  validateForResultQuizSeedly() {
    $('.next-button').on('click', (e) => {
      let step = $(e.target).attr('data-step');

      if (step == 'second') {
        $('#first-name-input, #pre_fact_find_dob, #email-input, #mobile-number-input').on('input', () => {
          let currentStepValidationPassed = true;
          let phoneNumberValidate = this.resultSeedlyThirdPageRequiredFields.find(
            (requiredField) => requiredField.selector === '#mobile-number-input',
          );
          setupValidatorBaseOnCountryCode(phoneNumberValidate, '#pre_fact_find_country_code');

          this.resultSeedlyThirdPageRequiredFields.forEach((fieldInfor) => {
            const fieldInput = $(fieldInfor.selector);
            let isValidField = true;

            fieldInfor.validators.forEach((validatorMethod) => {
              isValidField = validatorMethod.bind(this)(fieldInput.val());

              if (!isValidField) {
                currentStepValidationPassed = false;
                fieldInput.addClass('is-invalid');
                fieldInput.removeClass('is-valid');
                fieldInput.parent().parent().find('.invalid-feedback').show();
              } else {
                fieldInput.addClass('is-valid');
                fieldInput.removeClass('is-invalid');
                fieldInput.parent().parent().find('.invalid-feedback').hide();
              }
            });
          });
          if (currentStepValidationPassed) {
            $('#third-step .next-button button').removeClass('disabled');
            $('#third-step .next-button button').removeAttr('disabled');
          } else {
            $('#third-step .next-button button').addClass('disabled');
            $('#third-step .next-button button').attr('disabled', 'disabled');
          }
        });
      }

      if (step == 'third') {
        $('#employment-status-input, #monthly-income-range-input, #occupation-input, .check-privacy-box').on(
          'change',
          () => {
            let currentStepValidationPassed = true;
            this.resultSeedlyFourthPageRequiredFields.forEach((fieldInfor) => {
              const fieldInput = $(fieldInfor.selector);
              let isValidField = true;

              fieldInfor.validators.forEach((validatorMethod) => {
                isValidField = validatorMethod.bind(this)(fieldInput.val());

                if (!isValidField) {
                  currentStepValidationPassed = false;
                  fieldInput.addClass('is-invalid');
                  fieldInput.removeClass('is-valid');
                  fieldInput.parent().parent().find('.invalid-feedback').show();
                } else {
                  fieldInput.addClass('is-valid');
                  fieldInput.removeClass('is-invalid');
                  fieldInput.parent().parent().find('.invalid-feedback').hide();
                }
              });
            });
            if (currentStepValidationPassed && $('.check-privacy-box').is(':checked')) {
              $('#fourth-step .next-button button').removeClass('disabled');
              $('#fourth-step .next-button button').removeAttr('disabled');
            } else {
              $('#fourth-step .next-button button').addClass('disabled');
              $('#fourth-step .next-button button').attr('disabled', 'disabled');
            }
          },
        );
      }
    });
  }

  submitResultForm() {
    $('#send_mail_result_page').on('click', function (e) {
      let fc_name = $('#quiz_refree_fc_name').val();
      let fc_phone_number = $('#quiz_refree_fc_phone_number').val();
      $('#send_mail_result_page :button').attr('disabled', true);
      let referee_fc = {
        quiz: {
          referee_fc_attributes: {
            name: fc_name,
            phone_number: fc_phone_number,
          },
        },
      };
      let url = window.location.href;
      const quizID = url.substring(url.lastIndexOf('/') - 2).split('/')[0];
      const fcId = url.substring(url.lastIndexOf('/') - 2).split('fc=')[1];
      $.ajax({
        type: 'PATCH',
        url: url.replace('/result_page', ''),
        data: referee_fc,
        success: (data) => {},
      });
    });
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep == 0 || this.currentStep > this.stepQueues.length - 1) {
      $(this.navigationSection).addClass('d-none');
    } else {
      $(this.navigationSection).removeClass('d-none');
      if (this.currentStep == this.stepQueues.length) {
        $(this.submitButton).removeClass('d-none');
      } else {
        $(this.submitButton).addClass('d-none');
      }
    }
  }

  navigateToNextStep() {
    this.stepQueues.forEach((step, index) => {
      const $indexStepElement = $(step);
      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    });
  }

  handleChoseAnswerToNext() {
    this.stepQueues.forEach((step, index) => {
      $(step)
        .find('.collection_radio_buttons')
        .on('click', () => {
          setTimeout(() => {
            $(this.nextButton).trigger('click');
          }, 50);
        });
    });
  }

  handleGetStarted() {
    $(this.getStarted).on('click', () => {
      $('.disclaimer').addClass('d-none');
      this.nextStep();
    });
  }

  handleSubmited(page) {
    $(this.submitFormButton).on('click', (e) => {
      const currentStepValidationPassed = this.validateCurrentStep(page);

      if (!currentStepValidationPassed) {
        e.preventDefault();
      }
    });
  }

  handleNextButton(type) {
    $(this.nextButton).on('click', () => {
      if (type == 'create') {
        this.nextStep();
      } else {
        this.nextStepForShow();
      }
    });
  }

  hanldeBackButton() {
    $(this.backButton).on('click', () => {
      if (this.currentStep === 1) {
        $('.disclaimer').removeClass('d-none');
      } else {
        $('.disclaimer').addClass('d-none');
      }
      this.backStep();
    });
  }

  nextStepForShow() {
    const MAX_STEP = this.stepQueues.length;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === MAX_STEP) {
      $(this.submitButton).removeClass('d-none');
    }
  }

  nextStep() {
    const MAX_STEP = this.stepQueues.length;
    let validCheckedInput = $(this.stepQueues[this.currentStep]).find('input:radio, input:checkbox').is(':checked');
    if (this.currentStep == 0) {
      validCheckedInput = true;
    }

    if (validCheckedInput) {
      $(this.stepQueues[this.currentStep]).addClass('d-none');
      this.setStep(this.currentStep + 1);
      $(this.navigationSection).removeClass('d-none');
      $(this.stepQueues[this.currentStep]).removeClass('d-none');
      if (this.currentStep === MAX_STEP) {
        $(this.submitButton).removeClass('d-none');
      }
    } else {
      alert('Please choose an answer!');
    }
  }

  validateCurrentStep(page) {
    let currentStepValidationPassed = true;
    // let phoneNumberValidate = this.lastPageRequiredFields.find(
    //   (requiredField) => requiredField.selector === '#quiz_phone_number',
    // );
    // setupValidatorBaseOnCountryCode(phoneNumberValidate, '#quiz_country_code');

    let requiredFields = [];
    if (page == 'seedly') {
      requiredFields = this.lastPageRequiredFieldsQuizSeedly;
    } else {
      requiredFields = this.lastPageRequiredFields;
    }

    requiredFields.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());

        if (!isValidField) {
          currentStepValidationPassed = false;
          fieldInput.addClass('is-invalid');
          fieldInput.removeClass('is-valid');
          fieldInput.parent().parent().find('.invalid-feedback').show();
        } else {
          fieldInput.addClass('is-valid');
          fieldInput.removeClass('is-invalid');
          fieldInput.parent().parent().find('.invalid-feedback').hide();
        }
      });
    });

    return currentStepValidationPassed;
  }

  backStep() {
    const MIN_STEP = 0;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep -= 1;
    if (this.currentStep === MIN_STEP) {
      $(this.navigationSection).addClass('d-none');
    }
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    $(this.submitButton).addClass('d-none');
  }
}

Raicon.register('quizzes', QuizController);
