import { formatCurrency, formatCurrencyToNumber, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import {
  calculateCarDreamLoanOutstandingAmount,
  calculateCarDreamLoanTotalMonthlyRepayment,
} from './dreamCalculator';

import ToggleSharedDreamEvent from './shared/toggleSharedDreamEvent'
import calculateTartgetYearBaseOnStartDate from '../shared/calculateTargetYearBaseOnStartDate';
import updateAgeByInput from './shared/updateAgeByInput';
import checkValidDreamStartDate from './shared/checkInvalidStartDate';

export default class DreamCarForm {
  constructor() {
    this.containerSelector = '.dream-car-form';
    this.cashPaymentAmountSelector = '.cash-payment-amount';
    this.loanPaysmentAmountSelector = '.loan-payment-amount';
    this.loanOutstandingAmountSelector = '.loan-outstanding-amount';
    this.loanTotalMonthlyRepaymentSelector = '.loan-total-monthly-repayment-amount';
    this.baseValueSelector = 'input[name="dream[property_value]"]';
    this.downPaymentSelector = 'input[name="dream[property_down_payment]"]';
    this.loanRateSelector = 'input[name="dream[property_loan_rate]"]';
    this.loanDurationSelector = 'input[name="dream[property_loan_duration]"]';
    this.loanMonthlyDurationSelector = 'input[name="dream[property_loan_monthly_duration]"]';
    this.paymentMethodInputSelector = 'input[name="dream[property_payment_method]"]';
    this.sharedCashRepaymentByClientSelector = '#shared-cash-repayment-by-client-input';
    this.loanRepaymentByMemberSelector = '#loan-repayment-by-member';
    this.loanDurationTimeSelector = '#loan-duration-time';
    this.purchaseStartDateSelector = '#date-expected-input';
    this.ageExpectedShowSelector = '#age-happen-value';
  }

  setup() {
    new ToggleSharedDreamEvent().setup();
    this.setUpExistingData();
    this.addOnChangeForCashRepaymentByClient();

    calculateTartgetYearBaseOnStartDate(this.purchaseStartDateSelector, this.loanDurationSelector, this.loanDurationTimeSelector, this.loanMonthlyDurationSelector);
    updateAgeByInput(this.purchaseStartDateSelector, this.ageExpectedShowSelector);
    checkValidDreamStartDate(this.purchaseStartDateSelector);

    $(this.containerSelector).on('change', this.paymentMethodInputSelector, () => {
      this.syncPaymentMethod();
    });

    $(this.containerSelector).on('input', `${this.baseValueSelector}`, () => {
      if (this.getPaymentMethod() === 'cash') {
        this.syncCashPaymentAmounts();
      }
    });

    $(this.containerSelector).on(
      'input',
      `${this.baseValueSelector}, ${this.downPaymentSelector}, ${this.loanRateSelector}, ${this.loanDurationSelector}, ${this.loanMonthlyDurationSelector}`,
      () => {
        if (this.getPaymentMethod() === 'loan') {
          this.syncLoanAmounts();
          this.syncLoanRepaymentAmounts();
        }
      },
    );

    if (!this.getPaymentMethod()) {
      $(`${this.paymentMethodInputSelector}[value="cash"]`).attr('checked', 'checked');
    }

    $(this.purchaseStartDateSelector).on('input', () => {
      checkValidDreamStartDate(this.purchaseStartDateSelector);
    });

    this.syncPaymentMethod();
  }

  setUpExistingData() {
    this.syncPaymentMethod();
    this.syncCashPaymentAmounts();
    this.syncLoanAmounts();
    this.syncLoanRepaymentAmounts();
  }

  addOnChangeForCashRepaymentByClient() {
    $(this.sharedCashRepaymentByClientSelector).on('input', () => {
      this.calculateMemberRepaymentAmount();
    })
  }

  syncPaymentMethod() {
    const paymentMethod = this.getPaymentMethod();
    const $withCashElements = $(`${this.containerSelector} .with-cash-payment`);
    const $withLoanElements = $(`${this.containerSelector} .with-loan-payment`);

    if (paymentMethod === 'cash') {
      $withLoanElements.addClass('d-none');
      $withCashElements.removeClass('d-none');
    }

    if (paymentMethod === 'loan') {
      $withLoanElements.removeClass('d-none');
      $withCashElements.addClass('d-none');
    }
  }

  syncCashPaymentAmounts() {
    const paymentAmount = this.getBaseValueAmount();
    $(this.cashPaymentAmountSelector).text(formatCurrency(paymentAmount));
  }

  syncLoanAmounts() {
    const paymentAmount = this.getDownPaymentAmount();
    $(this.loanPaysmentAmountSelector).text(formatCurrency(paymentAmount));
  }

  syncLoanRepaymentAmounts() {
    const baseValueAmout = this.getBaseValueAmount();
    const downPaymentAmount = this.getDownPaymentAmount();
    const loanOutstandingAmount = calculateCarDreamLoanOutstandingAmount(baseValueAmout, downPaymentAmount);

    const loanRate = this.getLoanRate();
    const loanDuration = this.getLoanDuration();
    const loanMonthlyDuration = this.getLoanMonthlyDuration();
    const loanTotalMonthlyRepaymentAmount =
      loanDuration !== 0 || loanMonthlyDuration !== 0
        ? calculateCarDreamLoanTotalMonthlyRepayment(loanOutstandingAmount, loanRate, loanDuration, loanMonthlyDuration)
        : 0.00;
    $(this.loanOutstandingAmountSelector).text(formatCurrency(loanOutstandingAmount));
    $(this.loanTotalMonthlyRepaymentSelector).text(formatCurrency(loanTotalMonthlyRepaymentAmount));
    this.calculateMemberRepaymentAmount();
  }

  calculateMemberRepaymentAmount() {
    const monthlyRepaymentAmount = formatCurrencyToNumber($(this.loanTotalMonthlyRepaymentSelector).html())
    const clientRepaymentAmount = formatCurrencyToNumber($(this.sharedCashRepaymentByClientSelector).val())

    if (monthlyRepaymentAmount > 0 && clientRepaymentAmount) {
      const memberRepaymentAmount = monthlyRepaymentAmount - clientRepaymentAmount;

      if (memberRepaymentAmount >= 0) {
        $(this.loanRepaymentByMemberSelector).html(formatCurrency(memberRepaymentAmount));
      } else {
        $(this.loanRepaymentByMemberSelector).html(formatCurrency(0));
      }
    }
  }

  getPaymentMethod() {
    return $(`${this.paymentMethodInputSelector}:checked`).val();
  }

  getBaseValueAmount() {
    return parseFloat(formatMoneyToNumber($(this.baseValueSelector).val())) || 0;
  }

  getDownPaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.downPaymentSelector).val())) || 0;
  }

  getLoanRate() {
    return (parseFloat($(this.loanRateSelector).val()) || 0) / 100;
  }

  getLoanDuration() {
    return parseInt($(this.loanDurationSelector).val()) || 0;
  }

  getLoanMonthlyDuration() {
    return parseInt($(this.loanMonthlyDurationSelector).val()) || 0;
  }
}
