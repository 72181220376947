import { getCurrentUrlQueryParams } from '@/layouts/application/utils/getCurrentUrlQueryParams';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatCurrency } from '@/layouts/application/utils/formats';

export default function setupCpfLifeChart(forClientPortal = false) {
  const params = getQueryParams();
  if (!params) return;

  const { income_id, respective_client_id: client_id } = params;
  let route = forClientPortal
    ? Routes.estimated_cpf_life_chart_clients_incomes_path(income_id, { client_id })
    : Routes.estimated_cpf_life_chart_consultant_client_incomes_path(income_id, { client_id });

  $.get(route, (response) => {
    const labels = Object.keys(response.data);
    const data = Object.values(response.data).map((item) => item.amount);

    ['basic', 'standard', 'escalating'].forEach((plan, index) => {
      let amount = response.table[plan];
      if (index === 0) {
        $('#starting-age-plan').text(Object.keys(amount)[0]);
      }
      const amountValues = Object.values(amount);
      amountValues.forEach((v, index) => {
        amount = v['amount'];
        if (v === true) {
          $(`#${plan}-header`).css('background-color', 'rgba(232, 176, 19, 1)');
        }
        $(`#table-row-${index}`).append(`<th class="font-weight-300-text text-center">${formatCurrency(amount)}</th>`);
      });
    });
    setupChartInModal(labels, data);
  });
}

const getQueryParams = () => {
  const params = getCurrentUrlQueryParams();
  if (params.js_action === undefined || params.income_id === undefined) return false;
  if (params.js_action !== 'show_cpf_life_chart') return false;
  return params;
};

const setupChartInModal = (labels, data) => {
  new Chart($('#estimated_cpf_life_payout_modal_chart'), {
    type: 'bar',
    plugins: [ChartDataLabels],
    data: {
      labels: labels,
      datasets: [
        {
          label: 'Amount',
          data,
          backgroundColor: ['rgba(232, 176, 19, 1)', 'rgba(232, 176, 19, 1)', 'rgba(232, 176, 19, 1)'],
        },
      ],
    },
    options: {
      layout: {
        padding: 20,
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function (value, context) {
            return formatCurrency(value);
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Amount',
          },
          ticks: {
            stepSize: 1000,
            callback: function (label, index, labels) {
              return formatCurrency(label);
            },
          },
        },
      },
    },
    showTooltips: false,
  });
  hideChartLoading();
};

const hideChartLoading = () => {
  $('#estimated_cpf_life_payout_modal_chart').closest('.chart-container').removeClass('chart-loading');
};
