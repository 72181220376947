import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import handleShare from '../shared/handleShare';
class PolicyNetworksController {
  constructor() {
    this.checkboxTitle = '.permission-checkbox-title input[type="checkbox"]';
    this.checkboxItem = '.permission-checkbox-item input[type="checkbox"]';
    this.btnRemoveShare = '.btn-remove-share-policy';
    this.btnRemoveConnection = '.btn-remove-connection';
    this.emailYourLove = '.email-your-love';
    this.confirmModal = '#confirm-modal';
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {
    new handleShare().setup();
  }

  new() {
    new handleShare().setup();
    this.setup();
    $('#policy_network_email_false').on('click', () => {
      $('#custom-email-shared-policy').prop('disabled', false)
      $('#family-member').prop('disabled', true );
      $('#family-member').addClass('d-none');
      $('.email-other').removeClass('d-none')
    })

    if($('.btn-share-now')){
      $('.btn-share-now').trigger('click');
    }
  }

  create() {
    this.setup();
  }

  shareWithOthers() {
    this.removeSharedPolicy();
  }

  shareWithMe() {
    this.removeConnectionWithOther();
  }

  edit() {
    this.setup();
    this.removeSharedPolicy();
  }

  setup() {
    this.toggleCheckAllWhenPolicyTitleChecked();
    this.checkPolicyTitleWhenAtLeastOneItemChecked();
    this.checkUserExistWithEmail();
  }

  removeConnectionWithOther() {
    Array.from($(this.btnRemoveConnection)).forEach((item) => {
      $(item).on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const clientId = $(item).data('client-id');
        $.ajax({
          url: Routes.destroy_connection_with_other_clients_policy_network_path({ id: clientId }),
          type: 'DELETE',
        });
      });
    });
  }

  removeSharedPolicy() {
    Array.from($(this.btnRemoveShare)).forEach((item) => {
      $(item).on('click', (e) => {
        const clientShareId = $(item).data('client-id');
        const clientShareName = $(item).data('client-name');
        const btnConfirm = this.showModal(
          this.confirmModal,
          `Are you sure you want to stop sharing your policies with ${clientShareName}?`,
        );
        btnConfirm.on('click', () => {
          $.ajax({
            url: Routes.clients_policy_network_path({ id: clientShareId }),
            type: 'DELETE',
          });
        });
      });
    });
  }

  toggleCheckAllWhenPolicyTitleChecked() {
    Array.from($(this.checkboxTitle)).forEach((item) => {
      $(item).on('change', () => {
        const checkboxWrapper = $(item).parent().parent().parent();
        const checkboxes = checkboxWrapper.find('input[type="checkbox"]');
        if ($(item).is(':checked')) {
          Array.from(checkboxes).forEach((checkbox) => {
            $(checkbox).prop('checked', true);
          });
        } else {
          Array.from(checkboxes).forEach((checkbox) => {
            $(checkbox).prop('checked', false);
          });
        }
      });
    });
  }

  checkPolicyTitleWhenAtLeastOneItemChecked() {
    Array.from($(this.checkboxItem)).forEach((item) => {
      $(item).on('change', () => {
        const checkboxWrapper = $(item).parent().parent().parent();
        const checkboxTitle = checkboxWrapper.find('.permission-checkbox-title input[type="checkbox"]');
        if (!checkboxTitle.is(':checked')) {
          $(checkboxTitle).prop('checked', true);
        }
      });
    });
  }

  showModal(modalSelector, message) {
    const confirmTitle = $(modalSelector).find('.confirm-title');
    const btnConfirm = $(modalSelector).find('.btn-confirm');
    confirmTitle.text(message);
    $(modalSelector).modal('show');
    $(modalSelector).removeClass('d-none');
    return btnConfirm;
  }

  checkUserExistWithEmail() {
    const parentElement = $(this.emailYourLove).parent().parent();
    const invalidFeedback = parentElement.find('.invalid-feedback');
    $(this.emailYourLove).on('blur', () => {
      const email = $(this.emailYourLove).val();
      $.post(Routes.verify_email_shared_clients_policy_networks_path({ email })).done((data) => {
        if (!data.is_existed) {
          invalidFeedback.show();
        }
      });
    });
    $(this.emailYourLove).on('focus', () => {
      invalidFeedback.hide();
    });
  }
}

Raicon.register('clients/policyNetworks', PolicyNetworksController);
