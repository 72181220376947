import Raicon from 'raicon';
import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import {
  handleOpenPreviewModal,
  copyDynamicTagName,
  handleAttachFileChange,
  setupEditor,
  handleShowRealClientName,
  handleDeleteAttachFile,
  resetCssBeforeSave,
  handlePreviewMedia,
} from '../shared/campaignTemplateUtil';
class EmailTemplatesController {
  constructor() {
    this.imageSelector = '#editor-image';
    this.attachFileSelector = '#attach-file';
    this.fileContainer = '#file-container';
    this.editorSelector = '.editor';
    this.destroyMultipleSelector = '#btn-destroy-email-templates';
    this.filterTemplateName = '.filter-template-name';
    this.previewEmailTemplate = '#preview-email-template .text-container';
    this.emailTemplateForm = '#email-template-form';
    this.signatureSelection = '#signature-selection';
    this.templateType = '.template-type';
    this.editor = null;
  }

  index() {
    toggleTdTable();
    checkboxSelected(this.destroyMultipleSelector, 'template');
    this.setupPreviewModal();
  }

  new() {
    this.setup();
  }

  create() {
    this.setup();
  }

  edit() {
    this.setup();
    this.setupPreviewModal();
  }

  update() {
    this.setup();
    this.setupPreviewModal();
  }

  show() {
    handlePreviewMedia();
  }

  setup() {
    setupEditor().then((editor) => (this.editor = editor));
    handleAttachFileChange();
    copyDynamicTagName();
    handleShowRealClientName();
    handleDeleteAttachFile();
    resetCssBeforeSave();
    this.handleChangeSignature();
  }

  setupPreviewModal() {
    let showPath = 'consultant_email_template_path';
    let sendTestEmailPath = 'send_test_email_consultant_email_template_path';
    handleOpenPreviewModal(showPath, sendTestEmailPath);
  }

  handleChangeSignature() {
    $(this.signatureSelection).on('change', () => {
      const id = $(this.signatureSelection).val();
      if (id) {
        this.getSignatureHtmlFromServer(id);
      }
    });
  }

  getSignatureHtmlFromServer(id) {
    $.get(Routes.show_signature_html_consultant_signature_path(id)).done((data) => {
      const htmlContent = this.editor.getData();
      this.editor.setData(`${htmlContent}${data.signature_html}`);
    });
  }
}

Raicon.register('consultant/emailTemplates', EmailTemplatesController);
