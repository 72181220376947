import Chart from 'chart.js/auto';

const initMultipleLinesChart = (element, datasets = [], labels, options = {}, plugins = []) => {
  const data = {
    labels,
    datasets,
  };

  return new Chart($(element), {
    type: 'line',
    data,
    options,
    plugins
  });
};

export default initMultipleLinesChart;
