export default function validatePasswordCharacter() {
  const atLeastUpperCaseRegex = /.*?[A-Z]/;
  const atLeastNumRegex = /.*?[0-9]/;
  const atLeastSpecialRegex = /.*?[#?!@$%^&*-._\(\)]/;

  $('#user_password').on('keyup', function () {
    const $this = $(this);
    $("#leastCharacter").toggleClass('checked', $this.val().length >= 8);
    $("#leastCapital").toggleClass('checked', $this.val().match(atLeastUpperCaseRegex) !== null);
    $("#leastNumber").toggleClass('checked', $this.val().match(atLeastNumRegex) !== null);
    $("#leastSymbol").toggleClass('checked', $this.val().match(atLeastSpecialRegex) !== null);
  });
}
