import calculationForOutstandingDurationYearTarget from './handleOutstandingDurationYearTarget';
import { calculateCarAssetLoanTotalMonthlyRepayment, calculateCarAssetLoanTotalAmount } from './assetCalculator';
import { formatCurrency, formatMoneyToNumber, formatCurrencyWithoutSign } from '@/layouts/application/utils/formats';
import eventHandlerForIsInputMonthlyRepayment from './shared/eventHandlerForIsInputMonthlyRepayment';
import { DEFAULT_CURRENCY } from '@/constants';

export default class AssetCarForm {
  constructor() {
    this.outstandingDurationInputSelector = '#outstanding-duration';
    this.monthlyOutstandingDurationInputSelector = '#monthly-outstanding-duration';
    this.outstandingAmountInputSelector = '#outstanding-amount';
    this.outstandingRateInputSelector = '#outstanding-rate';
    this.outstandingMonthlyRepaymentInputSelector = '#monthly-repayment-input';
    this.monthlyRepaymentAmountSelector = '#monthly-repayment-amount';
    this.outstandingAmountSelector = '#out-standing-amount-with-input-monthly-repayment';
    this.targetYearvalueSelector = '#target-year-value';
    this.formSelector = '#asset-car-form';
    this.inputMonthlyRepaymentSelector = '#is-input-monthly-repayment';
  }

  setup() {
    calculationForOutstandingDurationYearTarget(
      this.formSelector,
      this.outstandingDurationInputSelector,
      this.monthlyOutstandingDurationInputSelector,
      this.targetYearvalueSelector,
    );
    this.syncDataForLoanMonthlyRepayment();
    this.syncDataForOutstadingAmount();
    this.calculateForMonthlyRepayment();
    eventHandlerForIsInputMonthlyRepayment();
  }

  syncDataForLoanMonthlyRepayment() {
    $(this.formSelector).on(
      'input',
      `${this.baseValueSelector}, ${this.outstandingDurationInputSelector}, ${this.outstandingAmountInputSelector}, ${this.outstandingRateInputSelector}, ${this.monthlyOutstandingDurationInputSelector}`,
      () => {
        this.calculateForMonthlyRepayment();
      },
    );
  }

  calculateForMonthlyRepayment() {
    const loanOutstandingAmount = this.getLoanAmount();
    const loanRate = this.getLoanRate();
    const loanDuration = this.getLoanDuration();
    const monthlyLoanDuration = this.getMonthlyLoanDuration();

    const monthlyRepaymentAmount =
      loanDuration !== 0 || monthlyLoanDuration !== 0
        ? calculateCarAssetLoanTotalMonthlyRepayment(loanOutstandingAmount, loanRate, loanDuration, monthlyLoanDuration)
        : 0.0;
    $(this.monthlyRepaymentAmountSelector).html(formatCurrency(monthlyRepaymentAmount));
    $(this.outstandingMonthlyRepaymentInputSelector).val(formatCurrencyWithoutSign(monthlyRepaymentAmount));
  }

  syncDataForOutstadingAmount() {
    $(this.formSelector).on(
      'input',
      `${this.outstandingDurationInputSelector}, ${this.outstandingRateInputSelector}, ${this.outstandingMonthlyRepaymentInputSelector}, ${this.monthlyOutstandingDurationInputSelector}`,
      () => {
        const loanMonthlyRepaymentAmount = this.getLoanMonthlyRepaymentAmount();
        const loanRate = this.getLoanRate();
        const loanDuration = this.getLoanDuration();
        const monthlyLoanDuration = this.getMonthlyLoanDuration();

        const outstandingAmount =
          loanDuration !== 0 || monthlyLoanDuration !== 0
            ? calculateCarAssetLoanTotalAmount(loanMonthlyRepaymentAmount, loanRate, loanDuration, monthlyLoanDuration)
            : 0.0;
        let currency = $('#currency-select').val() || DEFAULT_CURRENCY;
        $(this.outstandingAmountSelector).html(formatCurrency(outstandingAmount, currency));
        if ($(this.inputMonthlyRepaymentSelector).is(':checked')) {
          $(this.outstandingAmountInputSelector).val(formatCurrencyWithoutSign(outstandingAmount));
        }
      },
    );
  }

  getLoanRate() {
    return (parseFloat($(this.outstandingRateInputSelector).val()) || 0) / 100;
  }

  getLoanDuration() {
    return parseInt($(this.outstandingDurationInputSelector).val()) || 0;
  }

  getMonthlyLoanDuration() {
    return parseInt($(this.monthlyOutstandingDurationInputSelector).val()) || 0;
  }

  getLoanAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingAmountInputSelector).val())) || 0;
  }

  getLoanMonthlyRepaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingMonthlyRepaymentInputSelector).val())) || 0;
  }
}
