import { formatCurrencyToNumber, formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import {
  calculateHouseAssetMortgageTotalMonthlyRepayment,
  calculateHouseAssetMortgageTotalAmount,
} from './assetCalculator';
import dynamicIncludedMonth from '../dynamicIncludedMonth';

import eventHandlerForIsInputMonthlyRepayment from './shared/eventHandlerForIsInputMonthlyRepayment';

import calculateTartgetYearBaseOnStartDate from '../shared/calculateTargetYearBaseOnStartDate';
export default class AssetPropertyForm {
  constructor() {
    this.formSelector = '#asset-property-form';
    this.outstandingDurationInputSelector = '#outstanding-duration';
    this.outstandingMorgateAmountSelector = '#outstanding-amount';
    this.outstandingRateSelector = '#outstanding-rate';
    this.outstandingMonthlyRepaymentAmountInputSelector = '#monthly-repayment-input';
    this.isInputMonthlyRepaymentCheckboxSelector = '#is-input-monthly-repayment';
    this.outstandingAmountTextSelector = '#outstanding-mortgage-amount-text';
    this.monthlyRepaymentInputSelector = '#monthly-repayment-amount';
    this.cpfRepaymentInputSelector = '#cpf-repayment-input';
    this.cashRepaymentInputSelector = '#cash-repayment-input';
    this.purchaseStartDate = '#purchase-date-input';
    this.outstandingDurationSelector = '#outstanding-duration';
    this.targetYearValueSelector = '#target-year-value';
    this.outstandingDurationMonthlySelector = '#outstanding-duration-monthly';
    this.submitButton = '.btn-submit-asset';
    this.errorsCashRepayment = '#errors-cashrepayment';
    this.propertyType = '#property-type';
    this.currencyOption = '#property-currency-option';
    this.currencySelector = '#currency-select';
    this.ordinary_account = '#oa-account-value';
    this.ordinary_account_block = '#oa-account-block';
    this.ordinary_contribution = '#oa-contribution';
    this.ordinary_contribution_text = '#oa-contribution-text';
    this.ordinary_contribution_text_extra = '#oa-contribution-text-extra';
    this.cpfDownpaymentInput = '#cpf-downpayment-input';
    this.cpfDownpaymentAsPercentInput = '#downpayment-as-percent';
    this.propertyValueInput = '#property-value-input';
    this.downpaymentAmount = '#downpayment-amount';
    this.includedThisMonthBlock = '#included-this-month';
  }

  setup(forClientPortal = false) {
    this.setOnchangeForMonthlyAndCpfRepayment();
    this.syncMortgageRepaymentAmounts();
    eventHandlerForIsInputMonthlyRepayment();
    this.setExistingData();
    // this.handleCheckIsInputMonthlyRepayment();
    this.toggleCurrencyOption();
    this.getDataFromCpfDetails(forClientPortal);
    this.setDownpayment();
    new dynamicIncludedMonth().setup('');

    $(this.formSelector).on(
      'input',
      `${this.outstandingRateSelector}, ${this.outstandingMorgateAmountSelector}, ${this.outstandingDurationInputSelector}, ${this.outstandingDurationMonthlySelector}`,
      () => {
        this.syncMortgageRepaymentAmounts();
        this.calculateForCashRepayment();
      },
    );

    $(this.formSelector).on(
      'input',
      `${this.outstandingRateSelector}, ${this.outstandingMonthlyRepaymentAmountInputSelector}, ${this.outstandingDurationInputSelector}, ${this.outstandingDurationMonthlySelector}`,
      () => {
        this.syncMortgageTotalAmount();
        this.calculateForCashRepayment();
      },
    );

    $(this.formSelector).on('change', `${this.purchaseStartDate}`, () => {
      this.getDataFromCpfDetails(forClientPortal);
    });

    $(this.formSelector).on('change', `${this.cpfDownpaymentAsPercentInput}, ${this.propertyValueInput}`, () => {
      this.setDownpayment();
    });

    calculateTartgetYearBaseOnStartDate(
      this.purchaseStartDate,
      this.outstandingDurationSelector,
      this.targetYearValueSelector,
      this.outstandingDurationMonthlySelector,
    );
  }

  setDownpayment() {
    let downpaymentAmount =
      (parseFloat(formatMoneyToNumber($(this.propertyValueInput).val())) *
        parseFloat($(this.cpfDownpaymentAsPercentInput).val())) /
      100;
    $(this.downpaymentAmount).text(formatCurrency(downpaymentAmount, $(this.currencySelector).val()));
  }

  setExistingData() {
    this.calculateForCashRepayment();
  }

  setOnchangeForMonthlyAndCpfRepayment() {
    $(this.formSelector).on('input', `${this.cpfRepaymentInputSelector}`, () => {
      this.calculateForCashRepayment();
    });
  }

  getDataFromCpfDetails(forClientPortal) {
    if ($(this.purchaseStartDate).val() === '') return;
    const date = new Date($(this.purchaseStartDate).val());
    const currentDate = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    const clientId = $(this.purchaseStartDate).data('client-id');
    let cpfDetailRoute;

    if (forClientPortal) {
      cpfDetailRoute = Routes.cpf_details_clients_assets_path({
        cpf_account: 'ordinary_account',
        month: month,
        year: year,
      });
    } else {
      cpfDetailRoute = Routes.cpf_details_consultant_cpf_accounts_path({
        cpf_account: 'ordinary_account',
        month: month,
        year: year,
        client_id: clientId,
      });
    }

    $.get(cpfDetailRoute, (data) => {
      if (currentDate.getDate() == day && currentDate.getMonth() + 1 == month && currentDate.getFullYear() == year) {
        $(this.ordinary_contribution_text).text('Your current monthly OA Contribution is ');
      } else {
        $(this.ordinary_contribution_text).text(
          'Based on your income as of purchase date, you will have an estimated of ',
        );
      }
      if (
        (currentDate.getMonth() + 1 > month && currentDate.getFullYear() == year) ||
        currentDate.getFullYear() > year
      ) {
        $(this.ordinary_account_block).addClass('d-none');
        $(this.ordinary_contribution_text).text('Your current monthly OA Contribution is ');
        $(this.ordinary_contribution).text(
          formatCurrency($(this.ordinary_contribution).data('oa-contribution'), $(this.currencySelector).val()),
        );
        $(this.ordinary_contribution_text_extra).addClass('d-none');
        $(this.cpfDownpaymentInput).addClass('d-none');
        $(this.includedThisMonthBlock).removeClass('d-none');
      } else if (
        currentDate.getDate() > day &&
        currentDate.getMonth() + 1 == month &&
        currentDate.getFullYear() == year
      ) {
        $(this.ordinary_account_block).addClass('d-none');
        $(this.ordinary_contribution_text).text('Your current monthly OA Contribution is ');
        $(this.ordinary_contribution).text(formatCurrency(data['contribution'], $(this.currencySelector).val()));
        $(this.ordinary_contribution_text_extra).addClass('d-none');
        $(this.cpfDownpaymentInput).addClass('d-none');
        $(this.includedThisMonthBlock).removeClass('d-none');
      } else {
        $(this.includedThisMonthBlock).addClass('d-none');
        $(this.cpfDownpaymentInput).removeClass('d-none');
        $(this.ordinary_account_block).removeClass('d-none');
        $(this.ordinary_contribution_text_extra).removeClass('d-none');
        $(this.ordinary_account).text(formatCurrency(data['cpf_balance'], $(this.currencySelector).val()));
        $(this.ordinary_contribution).text(formatCurrency(data['contribution'], $(this.currencySelector).val()));
      }
    });
  }

  calculateForCashRepayment() {
    let monthlyRepaymentvalue = 0;
    if (this.isInputMonthlyRepayment()) {
      monthlyRepaymentvalue = this.getOutstandingMonthlyRepaymentAmount();
    } else {
      monthlyRepaymentvalue = formatCurrencyToNumber($(this.monthlyRepaymentInputSelector).html());
    }

    const cpfRepaymentvalue = formatCurrencyToNumber($(this.cpfRepaymentInputSelector).val());
    let cashRepaymentvalue = monthlyRepaymentvalue - cpfRepaymentvalue;

    cashRepaymentvalue = cashRepaymentvalue ? cashRepaymentvalue : 0;

    $(this.cashRepaymentInputSelector).html(formatCurrency(cashRepaymentvalue, $(this.currencySelector).val()));

    if (cashRepaymentvalue < 0) {
      $(this.submitButton).prop('disabled', true);
      $(this.errorsCashRepayment).removeClass('d-none');
    } else {
      $(this.submitButton).prop('disabled', false);
      $(this.errorsCashRepayment).addClass('d-none');
    }
  }

  syncMortgageRepaymentAmounts() {
    const outstandingMorgateAmount = this.getOutstandingMorgateAmount();

    const mortgageRate = this.getMortgageRate();
    const mortgageDuration = this.getMortgageDuration();
    const extraMonth = this.getMonthlyOutStandingDuration();
    const mortgageTotalMonthlyRepaymentAmount =
      mortgageDuration !== 0 || extraMonth !== 0
        ? calculateHouseAssetMortgageTotalMonthlyRepayment(
            outstandingMorgateAmount,
            mortgageRate,
            mortgageDuration,
            extraMonth,
          )
        : 0.0;

    $(this.monthlyRepaymentInputSelector).text(
      formatCurrency(mortgageTotalMonthlyRepaymentAmount, $(this.currencySelector).val()),
    );
  }

  syncMortgageTotalAmount() {
    const outstandingMonthlyRepaymentAmount = this.getOutstandingMonthlyRepaymentAmount();

    const mortgageRate = this.getMortgageRate();
    const mortgageDuration = this.getMortgageDuration();
    const extraMonth = this.getMonthlyOutStandingDuration();
    const mortgageTotalAmount =
      mortgageDuration !== 0 || extraMonth !== 0
        ? calculateHouseAssetMortgageTotalAmount(
            outstandingMonthlyRepaymentAmount,
            mortgageRate,
            mortgageDuration,
            extraMonth,
          )
        : 0.0;

    $(this.outstandingAmountTextSelector).text(formatCurrency(mortgageTotalAmount, $(this.currencySelector).val()));
  }

  // handleCheckIsInputMonthlyRepayment() {
  //   $(this.isInputMonthlyRepaymentCheckboxSelector).click(() => {
  //     this.calculateForCashRepayment();
  //   });
  // }

  isInputMonthlyRepayment() {
    return $(this.isInputMonthlyRepaymentCheckboxSelector).is(':checked');
  }

  getOutstandingMorgateAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingMorgateAmountSelector).val())) || 0;
  }

  getOutstandingMonthlyRepaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingMonthlyRepaymentAmountInputSelector).val())) || 0;
  }

  getMortgageRate() {
    return (parseFloat($(this.outstandingRateSelector).val()) || 0) / 100;
  }

  getMortgageDuration() {
    return parseInt($(this.outstandingDurationInputSelector).val()) || 0;
  }

  getMonthlyOutStandingDuration() {
    return parseInt($(this.outstandingDurationMonthlySelector).val()) || 0;
  }

  getLoanDuration() {
    return parseInt($(this.outstandingDurationSelector).val()) || 0;
  }

  toggleCurrencyOption() {
    const currentType = $('#property-type input:checked').val();
    if (currentType == 'other') {
      $(this.currencyOption).show();
    } else {
      $(this.currencyOption).hide();
    }

    $(this.propertyType).on('change', () => {
      const selectedType = $('#property-type input:checked').val();

      if (selectedType == 'other') {
        $(this.currencyOption).show();
      } else {
        $(this.currencySelector).val('SGD');
        $(this.currencySelector).trigger('change');
        $(this.currencyOption).hide();
      }
    });
  }
}
