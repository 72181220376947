const monthsOfYear = 12

export function calculateCarAssetLoanTotalMonthlyRepayment(loanOutstandingAmount, yearlyRate, yearlyDuration, monthlyDuration) {
  const totalMonthlyDuration = yearlyDuration * monthsOfYear + monthlyDuration;

  if (yearlyRate == 0) {
    return (loanOutstandingAmount / totalMonthlyDuration)
  }

  return ((loanOutstandingAmount * yearlyRate * totalMonthlyDuration / monthsOfYear) + loanOutstandingAmount) / totalMonthlyDuration;
}

export function calculateCarAssetLoanTotalAmount(loanMonthlyRepaymentAmount, yearlyRate, yearlyDuration, monthlyDuration) {
  const totalMonthlyDuration = yearlyDuration * monthsOfYear + monthlyDuration;

  if (yearlyRate == 0) {
    return (loanMonthlyRepaymentAmount * totalMonthlyDuration)
  }

  return (loanMonthlyRepaymentAmount * totalMonthlyDuration) / ((yearlyRate * totalMonthlyDuration / monthsOfYear) + 1);
}

export function calculateHouseAssetMortgageTotalMonthlyRepayment(principalAmount, yearlyRate, yearlyDuration, extraMonth = 0) {
  const monthlyDuration = (yearlyDuration * monthsOfYear) + extraMonth;

  if (yearlyRate == 0) {
    return (principalAmount / monthlyDuration)
  }

  const monthlyRate = yearlyRate / monthsOfYear;

  return (
    (principalAmount * (monthlyRate * Math.pow(1 + monthlyRate, monthlyDuration))) /
    (Math.pow(1 + monthlyRate, monthlyDuration) - 1)
  );
}

export function calculateHouseAssetMortgageTotalAmount(mortgageMonthlyRepayment, yearlyRate, yearlyDuration, extraMonth = 0) {
  const monthlyDuration = (yearlyDuration * monthsOfYear) + extraMonth;

  if (yearlyRate == 0) {
    return (mortgageMonthlyRepayment * monthlyDuration)
  }

  const monthlyRate = yearlyRate / monthsOfYear;

  return (
    (mortgageMonthlyRepayment * (Math.pow(1 + monthlyRate, monthlyDuration) - 1)) /
    (monthlyRate * Math.pow(1 + monthlyRate, monthlyDuration))
  )
}
