import { Tooltip } from 'chart.js';

export default Tooltip.positioners.tooltipPositionCustom = (elements, eventPosition) => {
  if((elements.length <=  0) || (eventPosition == undefined)) {
    return false;
  }

  const windowWidth = window.innerWidth;
  const positionX = eventPosition.x;
  const positionY = eventPosition.y;
  const oneThirdOfWidth = windowWidth / 3;

  let offsetX = 0;
  let offsetY = 10;
  if(positionX < oneThirdOfWidth) {
    offsetX = 80;
  }
  else if (positionX >  2*oneThirdOfWidth) {
    offsetX = -80;
  }

  return {
    x: positionX + offsetX,
    y: positionY + offsetY
  };
}
