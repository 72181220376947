import Raicon from 'raicon';

class NonDisclosureAgreements {
  show(){
    this.handleEventPrintOut();
  }

  handleEventPrintOut(){
    $('#btn-print-out').on('click', () => {
      window.print()
    })
  }
}

Raicon.register('admin/nonDisclosureAgreements', NonDisclosureAgreements);
