import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class ClientsEmergencyNetworksController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {
  }

  new() {
    this.setupForm();
  }

  create() {
    this.setupForm();
  }

  edit() {
    this.setupForm();
  }

  newMember() {
    this.setupForm();
  }

  createNewMember() {
    this.setupForm();
  }

  setupForm() {
    this.handleSelectAllBtn()
    this.handleDeselectAllBtn()
    this.handleSelectMemberBtn()
  }

  handleSelectMemberBtn() {
    $('.member-radio .form-check-label').on('click', (e) => {
      var memberId = $(e.target).attr('for').split('_').pop();

      if (memberId !== 'false') {
        window.location.href = Routes.edit_clients_emergency_network_path({ id: memberId });
        return;
      }

      window.location.href = Routes.new_member_clients_emergency_networks_path();
    });
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', false);
    });
  }
}

Raicon.register('clients/emergencyNetworks', ClientsEmergencyNetworksController);
