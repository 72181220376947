import Raicon from 'raicon';

$(document).on('click', '.toggle-password', ({ target }) => {
  const $passwordInput = $(target).parent().find(':input');
  if ($passwordInput.attr('type') === 'password') {
    $passwordInput.attr('type', 'text');
    $(target).addClass('show-pass');
    $(target).removeClass('hidden-pass');
  } else {
    $passwordInput.attr('type', 'password');
    $(target).addClass('hidden-pass');
    $(target).removeClass('show-pass');
  }
});

class AdminSettingsController {
  edit() {
    this.checkCorrectPasswordLength();
  }

  updatePassword() {
    this.checkCorrectPasswordLength();
  }

  checkCorrectPasswordLength() {
    const atLeastUpperCaseRegex = /.*?[A-Z]/;
    const atLeastNumRegex = /.*?[0-9]/;
    const atLeastSpecialRegex = /.*?[#?!@$%^&*-._\(\)]/;

    $('#user_password').on('keyup', function () {
      const $this = $(this);
      $("#leastCharacter").toggleClass('checked', $this.val().length >= 8);
      $("#leastCapital").toggleClass('checked', $this.val().match(atLeastUpperCaseRegex) !== null);
      $("#leastNumber").toggleClass('checked', $this.val().match(atLeastNumRegex) !== null);
      $("#leastSymbol").toggleClass('checked', $this.val().match(atLeastSpecialRegex) !== null);
    });
  }
}

Raicon.register('admin/settings', AdminSettingsController);
