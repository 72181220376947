import { formatCurrency, formatCurrencyToNumber } from '@/layouts/application/utils/formats';

const STATUS_MESSAGES = {
  correctAmount: 'Great! You have allocated all your surplus to your dream(s)!',
  underAmount: 'You still have some surplus to allocate to your dream(s)',
  overAmount: 'You allocated more than your available monthly surplus'
}

const STATUS_COLORS = {
  correctAmount: '#E8B012',
  underAmount: '#E8B012',
  overAmount: '#CF0000'
}
export default class DreamAllocation {
  constructor() {
    this.allocationInputSelector = '.allocation-input';
    this.submitButtonSelector = '.save-allocations-btn';
    this.currentSavingValueSelector = '.current-saving-value';
    this.totalAllocationValueSelector = '.total-allocation-value';
    this.closeModalButtonSelector = '.modal-close-btn';
    this.statusTextSelector = '.status-text';
    this.statusBarSelector = '.status-bar';
    this.currentProgressBarSelector = '.status-bar .target'
  }

  currentSavingValue = 0;
  totalAllocationValue = 0;

  setup() {
    this.setCurrentSavingValue();
    this.addOnchangeEventToAllocationInput();
    this.calculateTotalAllocationValue();
    this.setOnSubmitEvent();
  }

  setCurrentSavingValue() {
    this.currentSavingValue = formatCurrencyToNumber($(this.currentSavingValueSelector).html());
    $(this.currentSavingValueSelector).html(` ${formatCurrency(this.currentSavingValue)}`)
  }

  addOnchangeEventToAllocationInput() {
    $(this.allocationInputSelector).on('input', () => {
      this.calculateTotalAllocationValue();
    })
  }

  calculateTotalAllocationValue() {
    this.totalAllocationValue = 0;

    $(this.allocationInputSelector).each((index, element) => {
      const value = parseFloat(element.value);
      if (!isNaN(value)) {
        this.totalAllocationValue += value;
      }
    })

    $(this.totalAllocationValueSelector).html(formatCurrency(this.totalAllocationValue));

    this.adjustStatusBar();
  }

  adjustStatusBar() {
    if (this.totalAllocationValue > this.currentSavingValue) {
      $(this.statusTextSelector).html(STATUS_MESSAGES.overAmount)
      $(this.currentProgressBarSelector).css('background-color', STATUS_COLORS.overAmount)
      $(this.submitButtonSelector).attr('disabled', true)
    } else if (this.totalAllocationValue < this.currentSavingValue) {
      $(this.statusTextSelector).html(STATUS_MESSAGES.underAmount)
      $(this.currentProgressBarSelector).css('background-color', STATUS_COLORS.underAmount)
      $(this.submitButtonSelector).attr('disabled', false)
    } else {
      $(this.statusTextSelector).html(STATUS_MESSAGES.correctAmount)
      $(this.currentProgressBarSelector).css('background-color', STATUS_COLORS.correctAmount)
      $(this.submitButtonSelector).attr('disabled', false)
    }

    this.changeStatusBarProgress();
  }

  changeStatusBarProgress() {
    let progressInPercent = (this.totalAllocationValue / this.currentSavingValue) * 100

    if (progressInPercent > 100) {
      progressInPercent = 100
    }

    $(this.currentProgressBarSelector).css('width', `${progressInPercent}%`)
  }

  setOnSubmitEvent() {
    $(this.submitButtonSelector).on('click', () => {
      const data = this.buildDataForChangeAllocationRequest();
      const clientId = $('body').data('client-id')

      $.post(Routes.bulk_update_dream_allocation_consultant_client_dreams_path({ client_id: clientId }), { data: data })
        .done(() => {
          $(this.closeModalButtonSelector)[0].click();
        })
    })
  }

  buildDataForChangeAllocationRequest() {
    let data = [];

    $(this.allocationInputSelector).each((index, element) => {
      data.push({
        dream_id: $(element).data('dream-id'),
        allocation_value: $(element).val()
      })
    })

    return data;
  }
}
