export default class OverviewRefereeList {
  constructor() {
    this.viewAllRefereeSelector = '.btn-view-all-referee';
    this.refereeItemSelector = '.referral';
  }

  setup() {
    $(this.viewAllRefereeSelector).on('click', (e) => {
      e.preventDefault();

      $(this.refereeItemSelector).removeClass('d-none');
      $(this.viewAllRefereeSelector).hide();
    });
  }
}
