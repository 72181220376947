import taskFormEvents  from '../tasks/events';
export default class TaskForm {
  constructor() {
    this.taskType = $(`input[name="task[task_type]"]`);
    this.checkedDefault = $(`input[name="task[task_type]"]:checked`).val();
    this.recurringForm = $('.recurring-task-form');
    this.checkbox = $('#recurring-task-checkbox');
    this.statusCheckbox = $('input[name="task[status]"]');
    this.duedate = $('#duedate');
    this.repeatInterval = $('#repeat-interval');
    this.repeatOn = $('#repeat-on');
    this.taskName = $('#task_name');
    this.relatedContactSelector = $('#related-contact-select');
  }

  setup() {
    this.toggleRecurringTask();
    this.toggleFormCall();
    this.disableRecurringWhenDuedateEmpty();
    this.disableRecurringWhenStatusIsCompleted();
    this.disableRepeatOnWithDayOrWeek();
    this.changeRepeatOnWithYearAndMonth();
    this.triggerDeleteTask();
    this.setAddTaskFormEvent();

    $(this.relatedContactSelector).select2({
      allowClear: true,
      placeholder: "Choose a related contact"
    })
  }

  setAddTaskFormEvent() {
    (new taskFormEvents()).setup();
    $('body').on('cocoon:after-insert', function (event, insertedItem) {
      (new taskFormEvents()).setup();
    });
  }

  toggleElementByCheckboxEvent($checkbox, $form){
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function() {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  triggerDeleteTask(){
    const btnDelete = $('#btn-delete');
    btnDelete.click(function (e) {
      e.preventDefault();
      const $this = $(this);
      const taskId = $this.data('task');
      const isRecurring = $this.data('recurring');
      let confirmDelete = confirm("Are you sure?");
      let url = Routes.consultant_task_path(taskId);
      if(confirmDelete){
        //if(isRecurring) {
          //let deleted = confirm("Delete all past completed tasks too?");
          //if(deleted) {
            //url = Routes.consultant_task_path(taskId, {is_delete: true});
          //}
        //}

        $.ajax({
          url: url,
          type: 'DELETE',
        });
      }
    })
  }

  toggleFormCall() {
    const taskType = this.taskType;
    const checkedDefault = this.checkedDefault;
    let oldValue = taskType.val();
    let taskName = this.taskName;
    let taskNameVal = '';

    if(checkedDefault === 'todo'){
      taskNameVal = taskName.val();
      $('.form-todo').show();
      $('.form-call').hide();
    } else if (checkedDefault === 'to_call') {
      $('.form-todo').hide();
      $('.form-call').show();
    }

    taskType.change(function() {
      const value = $(this).val();
      if(value === oldValue || oldValue === "" && value === 'todo' && checkedDefault === undefined){
        return;
      }

      if(value === 'todo'){
        taskName.val(taskNameVal);
        $('.form-todo').show();
        $('.form-call').hide();
      } else {
        taskName.val('');
        $('.form-todo').hide();
        $('.form-call').show();
      }

      //$("input[type=text], textarea").val("");
      //$(".modal-body").find('select').val([]).change();
      oldValue = value;
    })
  }


  toggleRecurringTask(){
    const $checkbox = this.checkbox;
    const $recurringForm = this.recurringForm;

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  disableRecurringWhenStatusIsCompleted(){
    const isRecurring = this.checkbox;
    const statusCheckbox = this.statusCheckbox;
    const recurringForm = this.recurringForm;
    const dueDate = this.duedate;

    if($('input[name="task[status]"]:checked').val() === 'completed' && (dueDate.val() === '' || dueDate.val() === undefined)){
      isRecurring.prop('disabled', true);
    }

    statusCheckbox.change((e) => {
      let val = $(e.target).val();
      if(val === 'completed'){
        isRecurring.prop('disabled', true);
        isRecurring.prop('checked', false);
        recurringForm.find('input').val('');
        recurringForm.find('select').val([]).change();
        recurringForm.hide();
      } else if (val === 'open' && dueDate.val() !== '' && dueDate.val() !== undefined) {
        isRecurring.prop('disabled', false);
      }
    })
  }

  disableRecurringWhenDuedateEmpty() {
    const isRecurring = this.checkbox;
    const dueDate = this.duedate;
    const recurringForm = this.recurringForm;
    const statusCheckbox = this.statusCheckbox;

    if(dueDate.val() === '' || dueDate.val() === undefined){
      isRecurring.prop('disabled', true);
    }

    dueDate.change(function() {
      if($(this).val() === ''){
        isRecurring.prop('disabled', true);
        isRecurring.prop('checked', false);
        recurringForm.find('input').val('');
        recurringForm.find('select').val([]).change();
        recurringForm.hide();
      } else {
        if($('input[name="task[status]"]:checked').val() === 'open'){
          isRecurring.prop('disabled', false);
        }
      }
    })
  }
  disableRepeatOnWithDayOrWeek = () => {
    const repeatInterval = this.repeatInterval;
    const repeatOn = this.repeatOn;
    const repeatIntervalValue = repeatInterval.children("option:selected").val();

    if(repeatIntervalValue === 'day' || repeatIntervalValue === 'week'){
      repeatOn.prop('disabled', true);
      repeatOn.trigger('change');
    }

    repeatInterval.change(function(){
      const value = this.value;
      if(value === 'day' || value === 'week')
      {
        repeatOn.prop('disabled', true);
        repeatOn.val('').trigger('change');
      } else {
        repeatOn.prop('disabled', false);
      }
    })
  }

  toggleRepeatOn(value, repeatOn){
    if(value === 'month'){
      repeatOn.find('option[value="day_of_the_month"]').prop('disabled', false);
      repeatOn.find('option[value="day_of_week_of_the_month"]').prop('disabled', false);
      repeatOn.find('option[value="day_of_month_of_year"]').prop('disabled', 'disabled');
      repeatOn.find('option[value="day_of_week_of_the_month_of_year"]').prop('disabled', 'disabled');
    } else if (value === 'year'){
      repeatOn.find('option[value="day_of_month_of_year"]').prop('disabled', false);
      repeatOn.find('option[value="day_of_week_of_the_month_of_year"]').prop('disabled', false);
      repeatOn.find('option[value="day_of_the_month"]').prop('disabled', 'disabled');
      repeatOn.find('option[value="day_of_week_of_the_month"]').prop('disabled', 'disabled');
    }
  }

  changeRepeatOnWithYearAndMonth(){
    const repeatInterval = this.repeatInterval;
    const repeatOn = this.repeatOn;
    const repeatIntervalValue = repeatInterval.children("option:selected").val();
    const _this = this;

    this.toggleRepeatOn(repeatIntervalValue, repeatOn);
    repeatOn.trigger('change');

    repeatInterval.change(function(){
      let value = this.value;
      _this.toggleRepeatOn(value, repeatOn);
      repeatOn.val('').trigger('change');
    })
  }
}
