import Chart from 'chart.js/auto';
import { BarController } from 'chart.js'
import { draw } from 'patternomaly';
import { DashedBorderBar } from '@/layouts/application/utils/charts/dashedBorderBar'
import { formatMoneyToNumber, formatCurrencyWholeNumber } from '@/layouts/application/utils/formats';
import { autoFormatMoneyFields } from '@/layouts/application/utils/handleMoneyField';
import { formatCurrency } from '@/layouts/application/utils/formats';

export default class OttermiseChart {
  constructor($chartElementContainer) {
    this.$chartElementContainer = $($chartElementContainer)
    this.ottermiseChart = null;
    this.growthOttermise = [true, true, true]; // true is positive, false is negative;
    this.datasetDepositIndex = 0;
    this.datasetValueIndex = 1;
    this.maxLeft = 0;
    this.maxWidth = 0;
    this.dataSets = {
      labels: ['Scenario 1', 'Scenario 2', 'Scenario 3'],
      datasets: [
        {
          label: 'Total Deposit',
          data: [0, 0, 0],
          backgroundColor: 'transparent',
          borderDash: [5, 5],
          dashedBorderColor: 'rgb(1,51,160)',
          dashedBorderWidth: 2,
          borderWidth: 2
        },
        {
          label: 'Total Value',
          data: [0, 0, 0],
          backgroundColor: [
            'rgb(232, 176, 18)',
            'rgb(232, 176, 18)',
            'rgb(232, 176, 18)'
          ]
        },
      ]
    }
  }

  setup(){
    this.initDashboardBorderBar();
    this.triggerUpdateData();
    this.drawInvestmentIlustratorChart();
    this.triggerInitData();
  }

  initDashboardBorderBar(){
    DashedBorderBar.id = 'dashedBorderBar';
    DashedBorderBar.defaults = DashedBorderBar.defaults;
    Chart.register(DashedBorderBar);
  }

  triggerInitData(){
    $('form').each((_, f) => {
      $(f).find('input').trigger('change')
    })
  }

  drawInvestmentIlustratorChart(){
    this.initOttomiseChart();
  }

  initOttomiseChart(){
    if(this.ottermiseChart){
      this.ottermiseChart.data = this.dataSets;
      this.ottermiseChart.update();
      this.hideLoading();
    } else {
      this.ottermiseChart = new Chart(this.$chartElementContainer, {
        type: 'dashedBorderBar',
        data: this.dataSets,
        options: {
          scales: {
            x: {
              stacked: true
            },
            y: {
              ticks: {
                callback: function (value, index, ticks) {
                  return formatCurrencyWholeNumber(value);
                },
              },
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const growthIndex = context.dataset.data.indexOf(context.raw)
                  let label = context.dataset.label || '';

                  if(context.dataset.label === 'Growth') {
                    let growth = context.raw - this.dataSets.datasets[this.datasetDepositIndex].data[growthIndex]
                    if(!this.growthOttermise[growthIndex]){
                      growth = -growth
                    }

                    label += `: ${formatCurrency(growth)}`
                  } else {
                    let totalDeposit = context.raw
                    let growth = this.dataSets.datasets[this.datasetValueIndex].data[growthIndex] - this.dataSets.datasets[this.datasetDepositIndex].data[growthIndex]
                    if(!this.growthOttermise[growthIndex]){
                      totalDeposit += Math.abs(growth)
                    }

                    label += `: ${formatCurrency(totalDeposit)}`
                  }

                  return label;
                }
              }
            }
          }
        },
      })
    }
  }

  triggerUpdateData(){
    $('form').on('keyup', '.initial-deposit, .monthly-deposit', (e) => {
      autoFormatMoneyFields(e.target);
    })

    $('form').on('change', '.compound-interest-calculators-input', (e) => {
      const $form = $(e.target).closest('form')
      const formTable = $form.attr('id').replace('-form', '');
      const scenarioNumber = formTable[formTable.length - 1];
      const startAge = $form.find('.start-age').val();
      const endAge = $form.find('.end-age').val();
      const initialDeposit = formatMoneyToNumber($form.find('.initial-deposit').val());
      const monthlyDeposit = formatMoneyToNumber($form.find('.monthly-deposit').val());
      const annualRate = $form.find('.annual-rate').val();
      this.showLoading();

      const formData = {}
      formData[formTable] = {
        start_age: startAge,
        end_age: endAge,
        initial_deposit: initialDeposit,
        monthly_deposit: monthlyDeposit,
        annual_rate: annualRate
      }

      $.ajax({
        type: 'POST',
        url: Routes.calculate_consultant_ottermise_calculators_path(),
        data: formData,
        success: (data) => {
          let totalDeposit = data.total_deposit;
          let totalGrowth = data.total_growth;
          const totalValue = data.total_value;

          // ottermise table
          $(`#${formTable}`).find('.total-deposit').text(formatCurrency(totalDeposit))
          $(`#${formTable}`).find('.total-growth').text(formatCurrency(totalGrowth))
          $(`#${formTable}`).find('.total-value').text(formatCurrency(totalValue))

          // ottermise chart
          const datasets = this.dataSets.datasets

          // Deposit
          datasets[this.datasetDepositIndex].data[scenarioNumber - 1] = totalDeposit;

          // Value
          datasets[this.datasetValueIndex].backgroundColor[scenarioNumber - 1] = totalValue >= totalDeposit ? 'rgb(232, 176, 18)' : 'rgb(220, 53, 69)';
          datasets[this.datasetValueIndex].data[scenarioNumber - 1] = totalValue;

          this.dataSets.datasets = datasets;
          this.initOttomiseChart();
        }
      })
    })
  }

  showLoading() {
    $(this.chartElementContainer).closest('.chart-container').addClass('chart-loading');
  }

  hideLoading() {
    $(this.chartElementContainer).closest('.chart-loading').removeClass('chart-loading');
  }
}
