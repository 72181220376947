import Raicon from 'raicon';
import Chart from 'chart.js/auto';

class ConsultantSharedDreamsController {
  index() {
    const element = $('#dreams-share-chart');
    const ctx = document.getElementById('dreams-share-chart').getContext("2d");
    const [lines, line2] = this.getSampleData();
    const gradientFill = this.prepareGradientColor(ctx, 'rgb(0, 51, 160, .5)', 'rgb(0, 51, 160, 0)');
    const gradientFill2 = this.prepareGradientColor(ctx, 'rgba(99, 28, 208, .5)', 'rgba(99, 28, 208, 0)');
    const data = {labels: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      datasets: [
        this.prepareDataSetOption({
          data: lines,
          fill: {
            target: 'stack',
            above: gradientFill,
          },
          borderColor: '#0033A0',
        }),
        this.prepareDataSetOption({
          data: line2,
          fill: {
            target: 'stack',
            above: gradientFill2,
          },
          borderColor: '#631CD0',
        })]
    };
    this.initLineChart({element, data});
  }

  getSampleData() {
    return [
      [30, 38, 39, 41, 57, 58, 64, 70, 78, 85],
      [40, 48, 49, 51, 67, 68, 74, 80, 88, 95]
    ];
  }

  prepareDataSetOption(opts) {
    const defaultLineOpts = this.getDefaultLineOptions();
    return {
      ...defaultLineOpts,
      ...opts,
    }
  }

  getDefaultLineOptions() {
    return {
      label: false,
      tension: 0.1,
      pointBackgroundColor: '#E8B012',
      pointBorderColor: '#E8B012',
      pointBorderWidth: 4
    }
  }

  prepareGradientColor(ctx, firstColor, secondColor) {
    const gradientFill = ctx.createLinearGradient(0, 0, 0, 450);
    gradientFill.addColorStop(0, firstColor);
    gradientFill.addColorStop(1, secondColor);
    return gradientFill;
  }

  initLineChart({element, data = null}) {
    const defaultData = {
      labels: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      datasets: [{
        label: false,
        data: [0, 120],
        fill: false,
        borderColor: 'transparent',
        tension: 0.1
      }]
    };

    const options = this.getDefaultChartOption();

    return new Chart(element, {
      type: 'line',
      data: data ? data : defaultData,
      options,
    });
  }

  getDefaultChartOption() {
    return {
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }
  }
}

Raicon.register('consultant/sharedDreams', ConsultantSharedDreamsController);
