export default class dynamicIncludedMonth {
  constructor() {
    this.label = $('.included-month-label')
  }

  setup(intervalBtn) {
    const defaultValue = $(intervalBtn).val();
    if (defaultValue) {
      this.changeLabelByValue(defaultValue)
    }

    $(intervalBtn).on('change', ({ target }) => {
      this.changeLabelByValue(target.value)
    })
  }

  changeLabelByValue(value) {
    switch (value) {
      case 'monthly':
        this.label.text('Include payment for this month')
        break;
      case 'yearly':
        this.label.text('Include payment for this year');
        break;
      case 'quarterly':
        this.label.text('Include payment for this quarter')
        break;
      case 'half_yearly':
        this.label.text('Include payment for this half year')
        break;
      case 'single':
        this.label.text('Include payment for this single premium')
        break;
      default:
        this.label.text('Include payment for this month')
    }
  }
}
