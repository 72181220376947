import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import {
  emailFormatValidator,
  presentValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';

class PostFnaController {
  constructor() {
    this.calendlyContainerSelector = '#calendly-container';
    this.currentStep = 0;
    this.getStarted = '#get-started-button';
    // this.stepQueues = ['#welcome-step', '#information-step', '#financial-goal-step', '#benefits-form', '#favreco-form', '#personal-preferences'];
    this.stepQueues = [
      '#welcome-step',
      '#information-step',
      '#financial-goal-step',
      '#favreco-form',
      '#personal-preferences',
    ];
    this.navigationSection = '#navigation-section';
    this.nextButton = '#next-button';
    this.backButton = '#back-button';
    this.submitButton = '#submit-button';
    this.formInformationStepsSelector = '#form-information-steps';
    this.requiredFields = ['#post_fna_name', '#post_fna_email', '#mobile-number-input'];
    this.addRefereeButton = '#add-referee-fields';
    this.addCareerButton = '#add-career-fields';
    this.nextButtonBtnMain = `${this.nextButton} .btn-main`;
    this.termAndConditionCheckbox = '#checkbox-term';
  }

  firstPageRequiredFields = [
    {
      selector: '#post_fna_name',
      validators: [presentValidator],
    },
    {
      selector: '#post_fna_email',
      validators: [presentValidator, emailFormatValidator],
    },
    {
      selector: '#mobile-number-input',
      validators: [presentValidator],
    },
  ];

  new() {
    this.handleNextButton('create');
    this.setupCocoon();
    this.setup();
  }

  create() {
    this.handleNextButton('create');
    this.setupCocoon();
    this.setup();
  }

  show() {
    this.handleNextButton('show');
    this.setup();
  }

  setup() {
    this.displayFirstPageError();
    this.setupCalendly();
    this.handleGetStarted();
    this.hanldeBackButton();
    this.initDropzone();
    this.changeIputChangeValue();
    this.handleTermAndConditionsCheckbox();
    this.checkValidLeadWithPhoneNumber();
  }

  handleTermAndConditionsCheckbox() {
    this.toggleNextButton();

    $('body').on('change', `${this.termAndConditionCheckbox}`, (e) => {
      this.toggleNextButton();
    });
  }

  toggleNextButton() {
    if ($(this.termAndConditionCheckbox).is(':checked')) {
      $(this.nextButtonBtnMain).removeClass('disabled');
      $(this.nextButton).attr('disabled', false);
    } else {
      $(this.nextButtonBtnMain).addClass('disabled');
      $(this.nextButton).attr('disabled', true);
    }
  }

  setupCocoon() {
    for (let i = 0; i < 3; i++) {
      $(this.addRefereeButton).click();
      $(this.addCareerButton).click();
    }
  }

  changeIputChangeValue() {
    this.changeInputAndSelectValue();

    $(document).on("cocoon:after-insert", () => {
      this.changeInputAndSelectValue();
    });
  }

  changeInputAndSelectValue() {
    $('input, select').on('change', (e) => {
      const $target = $(e.target);
      $target.attr('value', $target.val());
      var validIdElement = ($target[0].id !== 'post_fna_name' &&
        $target[0].id !== 'post_fna_email' &&
        $target[0].id !== 'mobile-number-input' &&
        $target[0].id !== 'country-code-input' &&
        $target[0].id !== 'checkbox-term' &&
        $target[0].id !== 'post_fna_allow_ipreciate_subscribe' &&
        $target[0].id !== 'post_fna_other_financial_goals' &&
        $target[0].id !== 'submit-post-fna')

      if (validIdElement) {
        const outerDiv = $target.closest('.row');
        const mobileNumber = $target.is('input[type="tel"]') ? $target.val() : outerDiv.find('.post-fna-phone-number input[type="tel"]').val();
        const countryCode = $target.is('select') ? $target.val() : outerDiv.find('select').val();
        var errorElement = outerDiv.find('.error-post-fna-phone-number');

        if (mobileNumber !== '') {
          const regexPattern = /^\d{8}$/;
          const regexPatternOther = /^\d{4,16}$/;
          const isValid = (countryCode === '+65' && regexPattern.test(mobileNumber)) || (countryCode !== '+65' && regexPatternOther.test(mobileNumber));

          if (isValid) {
            $(errorElement).removeClass('is-invalid');
            $(errorElement).css('display', 'none');
          } else {
            $(errorElement).addClass('is-invalid');
            $(errorElement).css('display', 'block');
          }
        } else {
          $(errorElement).removeClass('is-invalid');
          $(errorElement).css('display', 'none');
        }

        let presentValidator = $('#favreco-form').find('.is-invalid');
        if (presentValidator.length > 0) {
          $('input[type="submit"]').attr('disabled', true);
        } else {
          $('input[type="submit"]').removeAttr('disabled');
        }
      }
    });
  }


  checkValidLeadWithPhoneNumber() {
    $('.new_post_fna').on('submit', (e) => {
      e.preventDefault();
      const fcId = $('.new_post_fna .main-content').attr('fc-id');
      const mobileNumberList = [];

      $('.post_fna_referee_fav_phone_number input, .post_fna_referee_career_phone_number input').each(function() {
        const value = $(this).val().trim();
        if (value !== '') {
          mobileNumberList.push(value);
        }
      });

      if (mobileNumberList.length == 0) {
        $('.new_post_fna').off('submit').submit();
        return
      }

      $.get(
        Routes.check_valid_lead_with_phone_number_path(fcId, { mobile_number_list: mobileNumberList.join(',') })
      ).done((data) => {
        if (data.message !== '') {
          alert(data.message);
          $('input[type="submit"]').removeAttr('disabled');
        } else {
          $('.new_post_fna').off('submit').submit();
        }
      });
    });
  }

  displayFirstPageError() {
    const $formInformationSteps = $(this.formInformationStepsSelector);

    if ($formInformationSteps.length > 0) {
      const firstPageError = parseInt($formInformationSteps.data('first-page-error'));
      this.setStep(firstPageError || 0);
    }
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep == 0 || this.currentStep > 3) {
      $(this.navigationSection).addClass('d-none');
    } else {
      $(this.navigationSection).removeClass('d-none');
      if (this.currentStep == 3) {
        $(this.nextButton).addClass('d-none');
        $(this.submitButton).removeClass('d-none');
      } else {
        $(this.nextButton).removeClass('d-none');
        $(this.submitButton).addClass('d-none');
      }
    }
  }

  navigateToNextStep() {
    this.stepQueues.forEach((step, index) => {
      const $indexStepElement = $(step);

      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    });
  }

  setupCalendly() {
    const $calendlyContianer = $(this.calendlyContainerSelector);

    if ($calendlyContianer.length > 0) {
      const calendlyUserName = $calendlyContianer.data('calendly-username');

      $(this.calendlyContainerSelector).html(`
        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/${calendlyUserName}?hide_landing_page_details=1&hide_gdpr_banner=1" style="min-width:320px;height:630px;"></div>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
        <!-- Calendly inline widget end -->
      `);
    }
  }

  handleGetStarted() {
    $(this.getStarted).on('click', () => {
      this.nextStep();
    });
  }

  handleNextButton(type) {
    $(this.nextButton).on('click', () => {
      if ($(this.nextButton).attr('disabled') === 'disabled') {
        return;
      } else if (type == 'create') {
        this.nextStep();
      } else {
        this.nextStepForShow();
      }
    });
  }

  hanldeBackButton() {
    $(this.backButton).on('click', () => {
      this.backStep();
    });
  }

  nextStepForShow() {
    const MAX_STEP = 4;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === MAX_STEP) {
      $(this.nextButton).addClass('d-none');
      $(this.submitButton).removeClass('d-none');
    }
  }

  nextStep() {
    const MAX_STEP = 3;
    let currentStepValidationPassed = true;

    if (this.currentStep == 1) {
      const currentStepValidationPassed = this.validateCurrentStep();

      if (!currentStepValidationPassed) return;
    }

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === MAX_STEP) {
      $(this.nextButton).addClass('d-none');
      $(this.submitButton).removeClass('d-none');
    }
  }

  validateCurrentStep() {
    let currentStepValidationPassed = true;
    let phoneNumberValidate = this.firstPageRequiredFields.find(
      (requiredField) => requiredField.selector === '#mobile-number-input',
    );
    setupValidatorBaseOnCountryCode(phoneNumberValidate, '#country-code-input');

    this.firstPageRequiredFields.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());

        if (!isValidField) {
          currentStepValidationPassed = false;
          fieldInput.addClass('is-invalid');
          fieldInput.removeClass('is-valid');
          fieldInput.parent().parent().find('.invalid-feedback').show();
        } else {
          fieldInput.addClass('is-valid');
          fieldInput.removeClass('is-invalid');
          fieldInput.parent().parent().find('.invalid-feedback').hide();
        }
      });
    });

    return currentStepValidationPassed;
  }

  backStep() {
    const MIN_STEP = 0;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep -= 1;
    if (this.currentStep === MIN_STEP) {
      $(this.navigationSection).addClass('d-none');
    }
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    $(this.nextButton).removeClass('d-none');
    $(this.submitButton).addClass('d-none');
  }

  initDropzone() {
    const dropZone = new DropZoneForm({
      uploadMultiple: true,
      isValidateUpload: false,
      formSelector: '#post-fna-information-form',
      submitBtnSelector: this.submitButton,
      fileParamName: 'post_fna[policy_files]',
      acceptedFiles: ['image/png', '.jpg', 'application/pdf', '.doc', '.docx'],
    });

    dropZone.setupDropZone();
    dropZone.setupEventDropZone();
  }
}

Raicon.register('postFnas', PostFnaController);
