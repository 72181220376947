export default function handleMoneyField(formSelector = 'form.simple_form') {
  let event = triggerEventBaseOnDevice();
  $(formSelector).on(event, 'input.money', (e) => {
    autoFormatMoneyFields(e.target);
  });

  $(formSelector).on('submit', (e) => {
    removeCommasBeforeSubmitForm(e.target);
  });
}

export const autoFormatMoneyFields = (targetField) => {
  const isAllowNegative = $(targetField).hasClass('allow-negative');
  const formatedValue = formatCurrencyInput($(targetField).val(), isAllowNegative);

  $(targetField).val(formatedValue);
};

export const removeCommasBeforeSubmitForm = (form) => {
  const moneyFields = $(form).find('input.money');
  moneyFields.each((_, field) => {
    const currentValue = $(field).val();

    $(field).val(currentValue.replaceAll(',', ''));
  });
};

const triggerEventBaseOnDevice = () => {
  return 'input';
};

const formatCurrencyInput = (str, isAllowNegative) => {
  const arr = str.split('');
  let hasValidHyphen = isAllowNegative && arr[0] == '-';
  let formatedArr = new Array();

  for (let i = 0; i < arr.length; i++) {
    if (isNaN(arr[i]) == false || isValidDot(i, str)) {
      formatedArr.push(arr[i]);
    }
  }

  const len = formatedArr.length;
  const hasLastDot = len > 1 && formatedArr[len - 1] == '.';
  const numStr = formatedArr.join('');
  const dotPosition = numStr.indexOf('.');
  let afterDotPart = '';
  if (dotPosition > 0) {
    afterDotPart = getAfterDotPart(numStr, dotPosition);
  }

  if (numStr == '' && hasValidHyphen) {
    return '-';
  }

  const num = parseFloat(numStr);
  let result = '';

  if (isNaN(num)) {
    return result;
  }

  result = num.toLocaleString('en-US');

  if (hasLastDot) {
    result += '.';
  } else {
    result += afterDotPart;
  }

  if (hasValidHyphen) {
    result = '-' + result;
  }

  return result;
};

const isValidDot = (dotPos, str) => {
  if (str[dotPos] != '.') {
    return false;
  }

  const firstDotPosition = str.indexOf('.');
  if (firstDotPosition < 0) {
    return true;
  }

  return firstDotPosition == dotPos;
};

const getAfterDotPart = (numStr, dotPos) => {
  const afterDotPart = numStr.slice(dotPos, numStr.length);
  const validStr = ['.0', '.00'];

  if (validStr.includes(afterDotPart)) {
    return afterDotPart;
  }

  return '';
};
