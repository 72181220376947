import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import { copyExecutedButton } from '@/layouts/application/utils/copyExecuted';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class DashBoardController {
  constructor() {
    this.clientPopup = '#client-popup';
    this.checkboxPopup = '#checkbox-popup';
  }

  index() {
    this.showPopup();
    this.togglePopupEvent();
    handleSideMenu();
    handleUploadAvatar();
    copyExecutedButton('#copy-link-btn');
  }

  showPopup() {
    const visible = $(this.clientPopup).data('visible');
    if (visible) {
      $(this.clientPopup).modal('show');
      $(this.clientPopup).removeClass('d-none');
    }
  }

  togglePopupEvent() {
    const id = $(this.clientPopup).data('id');
    let visible = $(this.clientPopup).data('visible');
    $(this.checkboxPopup).on('change', () => {
      if ($(this.checkboxPopup).is(':checked')) {
        visible = false;
      } else {
        visible = true;
      }
      $.ajax({
        url: Routes.update_popup_visible_clients_dashboard_index_path({ popup_id: id, visible: visible }),
        type: 'PUT',
      });
    });
  }
}

Raicon.register('clients/dashboard', DashBoardController);
