export function handleHideSignSgd(
  currencySelector,
  propertyCurrencyWrapperSelector,
  propertyCurrencyTextSelector = '.property-currency-text',
) {
  $('body').on('change', `${currencySelector}`, () => {
    const selectedCurrency = $(currencySelector).val();

    if (selectedCurrency === 'SGD') {
      $(propertyCurrencyWrapperSelector).removeClass('currency-sign-no-content').addClass('currency-sign');
    } else {
      $(propertyCurrencyWrapperSelector).removeClass('currency-sign').addClass('currency-sign-no-content');
    }

    $(propertyCurrencyTextSelector).each(function () {
      let propertyValue = $(this).text();

      // Remove $ sign from digit part
      propertyValue = propertyValue.replace(/\$(\d+(,\d{3})*(\.\d+)?)/g, '$1');

      if (selectedCurrency !== 'SGD') {
        // If the currency is not SGD, just display the number
        $(this).text(propertyValue);
      } else {
        // If the currency is SGD, add the $ sign back
        $(this).text(propertyValue.replace(/(\d+(,\d{3})*(\.\d+)?)/g, '$$$1'));
      }
    });
  });
}
