import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';

class ClientsDocumentsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
  }
}

Raicon.register('clients/documents', ClientsDocumentsController);
