import Raicon from 'raicon';
import validatePasswordCharacter from '../../consultant/shared/validatePasswordCharacter';
import { togglePassword } from '../shared/togglePassword';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import setupLoginWithSocialProviders from '@/layouts/application/utils/setupLoginWithSocialProviders';

class SettingsController {
  constructor() {
    this.toggleBiometricLoginBtn = '#toggle-biometric-login';
    this.enterPasswordModal = '#enter-password-modal';
    this.enterPasswordForm = '#enter-password-form';
    this.alertContainer = '#alert-container';
    this.closeAlertBtn = '#close-alert-btn';
    this.username = '#user-username';
    this.password = '#user-password';
    this.submitPasswordBtn = '#submit-password-btn';
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
    setupLoginWithSocialProviders();
  }

  edit() {
    this.setup();
  }

  updatePassword() {
    this.setup();
  }

  setup() {
    togglePassword();
    validatePasswordCharacter();

    if (navigator.userAgent.indexOf('median') > -1) {
      this.setupBiometricLoginBtn();
      this.setUpEnterPasswordForm();
      this.setUpCloseAlertBtn();
    }
  }

  setupBiometricLoginBtn() {
    this.setupBiometricLoginBtnText();
    this.setupBiometricLoginBtnFunc();
  }

  setUpEnterPasswordForm() {
    $(this.enterPasswordForm).on('submit', (e) => {
      e.preventDefault();
      this.enableBiometricLogin();
    });
  }

  showEnterPasswordModal() {
    $(this.password).val('');
    $(this.alertContainer).hide();
    $(this.enterPasswordModal).modal('show');
  }

  showAlert(msg) {
    $(`${this.alertContainer} .alert-msg`).html(msg);
    $(this.alertContainer).show();
  }

  setUpCloseAlertBtn() {
    $(this.closeAlertBtn).on('click', () => {
      $(this.alertContainer).hide();
    })
  }

  setupBiometricLoginBtnText() {
    median.auth.status({'callbackFunction': (result) => {
      if (result && result.hasTouchId && result.hasSecret) {
        $(this.toggleBiometricLoginBtn).text('Disable biometric login');
      } else {
        $(this.toggleBiometricLoginBtn).text('Enable biometric login');
      }
    }});
  }

  setupBiometricLoginBtnFunc() {
    $(this.toggleBiometricLoginBtn).on('click', () => {
      median.auth.status({'callbackFunction': (result) => {
        if (result && result.hasTouchId && result.hasSecret) {
          this.disableBiometricLogin();
        } else {
          this.showEnterPasswordModal();
        }
      }});
    });
  }

  disableBiometricLogin() {
    median.auth.delete({'callbackFunction': (result) => {}});
    $(this.toggleBiometricLoginBtn).text('Enable biometric login');
    alert('Biometric login disabled');
  }

  enableBiometricLogin() {
    const username = $(this.username).val();
    const password = $(this.password).val();

    const secret = JSON.stringify({
      username: username,
      password: password,
    });

    $.ajax({
      type: 'POST',
      url: Routes.login_api_auth_path(),
      data: secret,
      processData: false,
      contentType: 'application/json'
    }).done((data) => {
      median.auth.status({'callbackFunction': (result) => {
        if (result && result.hasTouchId) {
          median.auth.save({
            'secret': secret,
            'minimumAndroidBiometric': 'strong'
          });

          // store secret username to check if login with different user
          localStorage.setItem('secretUsername', username);

          $(this.toggleBiometricLoginBtn).text('Disable biometric login');
          $(this.enterPasswordModal).modal('hide');
          alert('Biometric login enabled');
        } else {
          this.showAlert('You will need to set up your biometric from your device setting first before enabling biometric login for finConnect');
        }
      }});
    }).fail((data) => {
      this.showAlert('Please enter the valid password');
    }).always(() => {
      $(this.submitPasswordBtn).prop('disabled', false);
    });
  }
}

Raicon.register('clients/settings', SettingsController);
