import Raicon from 'raicon';

class OtpAuthenticationsController {
  new(){
    this.initPage();
    this.otpCode();
    this.setTimeoutResendOTP();
  }

  create(){
    this.initPage();
    this.otpCode();
    this.setTimeoutResendOTP();
  }

  initPage(){
    $(`.input-otp[data-index="1"]`).focus();
  }

  setTimeoutResendOTP(){
    const $resendBtn = $('#resend-btn');
    if(!$resendBtn.data('session')) return;

    const timeSession = new Date($resendBtn.data('session'));
    const timeCurrent = new Date();
    const difference = timeCurrent - timeSession;
    const minutesDifference = Math.floor(difference/1000/60);

    if(minutesDifference >= 0) {
      $resendBtn.attr('disabled', false);
      $resendBtn.addClass('color-main');
      $resendBtn.removeClass('disabled')
    } else {
      const $timeoutCountDown = $('#timeout-countdown');
      $resendBtn.attr('disabled', true);
      $resendBtn.removeClass('color-main');
      $resendBtn.addClass('disabled')

      setInterval(() => {
        const secondsDifference = Math.floor((timeSession - new Date())/1000);
        $timeoutCountDown.html(`(${secondsDifference})`)

        if(secondsDifference < 0){
          clearInterval();
          $timeoutCountDown.remove();
          $resendBtn.attr('disabled', false);
          $resendBtn.addClass('color-main');
          $resendBtn.removeClass('disabled')
        }
      }, 1000);
    }
  }

  otpCode(){
    const inputOTP = $('.input-otp');
    const maxCount = 6;
    const otpInput = $('#otp-value');
    let otpValue = [];
    let count = 1;
    let tmpValue = "";

    inputOTP.keydown(function(){
      tmpValue = $(this).val();
    })

    $(`.input-otp[data-index="1"]`).bind("paste", function(e){
      const pastedData = e.originalEvent.clipboardData.getData('text').toString();
      for(let i = 0; i <= pastedData.length ; i++){
	$(`.input-otp[data-index="${i + 1}"]`).val(pastedData[i]);
      }
    } );

    inputOTP.keyup(function(){
      const $this = $(this);

      if($.isNumeric($this.val()) && count <= maxCount){
	if($this.data('index') < count)
	{
	  $(`.input-otp[data-index="${count}"]`).focus();
	} else {
	  count <= 5 ? count++ : count;
	  $(`.input-otp[data-index="${count}"]`).focus();
	}
      }

      if (!$.isNumeric($this.val())) {
	$this.val("");
      }

      if ($this.val() === '' && count > 1 && tmpValue === '') {
	if($this.data('index') >= count){
	  count--;
	  $(`.input-otp[data-index="${count}"]`).focus();
	}
      }

      inputOTP.each(function(idx, e) {
	otpValue[idx] = $(e).val();
      })

      otpInput.val(otpValue.join(''));
      if(otpInput.val().length === maxCount){
	count=0; // Reset count for wrong code
	otpInput.parents('form').submit();
      }
    })
  }
}

Raicon.register('users/sessions/otpAuthentications', OtpAuthenticationsController);
