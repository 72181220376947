$(document).on('turbolinks:load', function () {
  addEventForRatingStar();
});

const addEventForRatingStar = () => {
  const clientRatingStar = '#client-rating-star';
  const ratingStarContent = '#rating-star-content';
  const ratingStarSelector = '.rating-star-item';
  const starNotSelect = '.star-not-select';
  const starSelected = '.star-selected';

  initPopover(clientRatingStar, ratingStarContent);

  updateRating(ratingStarSelector, starSelected, starNotSelect);
};

const initPopover = (clientRatingStar, ratingStarContent) => {
  $(ratingStarContent).hide();

  $(clientRatingStar).popover({
    content: $(ratingStarContent),
    placement: 'bottom',
    html: true,
  });

  $(clientRatingStar).popover('show');
  $(clientRatingStar).popover('hide');
  $(ratingStarContent).show();

  $('body').on('click', function (e) {
    $(clientRatingStar).each(function () {
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        $(this).popover('hide');
      }
    });
  });
};

const updateRating = (ratingStarSelector, starSelected, starNotSelect) => {
  $(ratingStarSelector).on('click', function () {
    let parentSelector = $(this).parent();
    let clientId = parentSelector.data('client-id');
    let rating = $(this).data('rating-value');
    let currentRating = parentSelector.find('.star-selected').not('.d-none').length;
    if (currentRating == 1) rating = 0;
    for (let i = 1; i <= 4; i++) {
      let item = parentSelector.find(ratingStarSelector)[i - 1];
      if (i <= rating) {
        $(item).find(starSelected).removeClass('d-none');
        $(item).find(starNotSelect).addClass('d-none');
      } else {
        $(item).find(starSelected).addClass('d-none');
        $(item).find(starNotSelect).removeClass('d-none');
      }
    }

    $.post(Routes.update_rating_star_consultant_client_rating_star_path({ client_id: clientId }), {
      user: { rating: rating },
    });
  });
};
