import Raicon from 'raicon';

class AdminCompanyEventsController {
  constructor() {
    this.bannerImageSelector = '#banner-image';
    this.imagePreviewSelector = '.image-preview';
  }

  index() {
    this.triggerModalOpen();
  }

  triggerModalOpen(){
    $('body').on('modal:afterSetContent', '#open-new-company-events, #edit-company-event-link', () => {
      this.handleImageChange();
    })
  }

  handleImageChange() {
    $(this.bannerImageSelector).on('change', (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const white_list = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!white_list.includes(file.type)) {
        alert("You can't upload files of this type.");
        $(e.target).val('');
        $(this.imagePreviewSelector).removeAttr('src');
        return;
      }
      $(this.imagePreviewSelector).attr('src', URL.createObjectURL(file));
    });
  }
}

Raicon.register('admin/companyEvents', AdminCompanyEventsController);
