class NoteForm {
  constructor() {
    this.noteCreateFormSelector = '#note-form';
    this.noteEditFormSelector = '.edit-note-form';
    this.noteCardSelector = '.note-card';
    this.formTextAreaSelector = '#note-form textarea';
    this.buttonAddNoteSelector = '.btn-add-note';
    this.buttonRemoveCreatingFormSelector = '#note-form .remove-create-form';
    this.buttonRemoveEditingFormSelector = '.remove-edit-form';
    this.noteListSelector = '#note-list';
    this.noteRemoveSelector = '.note-remove';
    this.buttonStickNoteSelector = '.btn-note-stick';
    this.buttonUnStickNoteSelector = '.btn-note-unstick';
    this.noteTextSelector = '.note-text';
    this.checkDropdownToggleSelector = '#note-list .show';
    this.closeReferralQuestion = '.question-section .cancle-icon';
    this.toggleNoteBoxSelector = '#toggle-note-box';
    this.noteCollapseSelector = '#notes';
    this.quickToolNoteSelector = '#quicktool-note';
    this.isMobileview = '#quicktool-note .desktop-d-none';

    this.setup();
  }

  setup() {
    document.addEventListener("turbolinks:load", () => {
      this.addDefaultCollapse();
      this.addPinAndUnpinEvent();
      this.handleNewNoteEvent();
      this.handleCreateNoteEvent();
      this.addEditNoteEvent();
      this.addUpdateNoteEvent();
      this.handleOpenNoteBox();
    });
  }

  addPinAndUnpinEvent() {
    $('body').on('ajax:success', `${this.noteRemoveSelector}, ${this.buttonStickNoteSelector}, ${this.buttonUnStickNoteSelector}`, (event) => {
      $(this.noteListSelector).replaceWith(event.detail[2].responseText);

      this.handleOpenAndShowNoteBox()
    });
  }

  addRemoveEditFormEvent() {
    $(this.buttonRemoveEditFormSelector).on('click', ({ target }) => {
      const $editForm = $(target).closest(this.noteEditFormSelector);
      $editForm.hide();
      const $noteCard = $editForm.parent().find(this.noteCardSelector)
      $noteCard.show();
    });
  }

  hideAllEditForm() {
    $(this.noteEditFormSelector).hide();
    $(this.noteCardSelector).show();
  }

  handleNewNoteEvent() {
    $('body').on('ajax:success', this.buttonAddNoteSelector, (event) => {
      if(this.noteBoxIsClosed()) {
        this.openNoteBox();
      }
      $(this.buttonAddNoteSelector).hide();
      this.hideAllEditForm();
      $(this.noteCreateFormSelector).html(event.detail[2].responseText);
      this.addRemoveCreatingFormEvent();
    });
  }

  handleCreateNoteEvent() {
    $('body').on('ajax:complete', this.noteCreateFormSelector, (event) => {
      const [xhr] = event.detail
      if (xhr.status == 200) {
        $(this.noteListSelector).replaceWith(xhr.responseText);
      } else {
        $(this.noteCreateFormSelector).html(xhr.responseText);
        this.addRemoveCreatingFormEvent();
      }

      this.handleOpenAndShowNoteBox()
    });
  }

  addRemoveCreatingFormEvent() {
    $(this.buttonRemoveCreatingFormSelector).on('click', () => {
      $(this.noteCreateFormSelector).html('');
      $(this.buttonAddNoteSelector).show();
    });
  }

  addRemoveEditingFormEvent($noteCard) {
    $(this.buttonRemoveEditingFormSelector).on('click', () => {
      $(this.noteEditFormSelector).html('');
      $noteCard.show();
    });
  }

  addEditNoteEvent() {
    $('body').on('ajax:success', this.noteTextSelector, (event) => {
      const $editForm = $($(event.target).parent().siblings()[0]);
      const $textNote = event.target;
      this.hideAllEditForm();
      const $noteCard = $($textNote).closest(this.noteCardSelector)
      $noteCard.hide();
      $editForm.html(event.detail[2].responseText)
      $editForm.show();
      this.addRemoveEditFormEvent();
      this.addRemoveEditingFormEvent($noteCard);
    });
  }

  addUpdateNoteEvent() {
    $('body').on('ajax:complete', this.noteEditFormSelector, (event) => {
      const [xhr] = event.detail

      if (xhr.status == 200) {
        $(this.noteListSelector).replaceWith(xhr.responseText);
      } else {
        const $noteCard = $(event.target).parent().siblings();
        const $editForm = $(event.target);
        $editForm.replaceWith(xhr.responseText);
        this.addRemoveEditingFormEvent($noteCard);
      }

      this.handleOpenAndShowNoteBox()
    });
  }

  handleOpenNoteBox() {
    $(this.toggleNoteBoxSelector).on('click', () => {
      if(this.noteBoxIsClosed()) {
        $(this.closeReferralQuestion).click();
      }
    });
  }

  noteBoxIsClosed() {
    return $(this.checkDropdownToggleSelector).length < 1;
  }

  openNoteBox() {
    $(this.closeReferralQuestion).click();
    $(this.toggleNoteBoxSelector).click();
  }

  addDefaultCollapse() {
    if($(this.isMobileview).is(":visible")) {
      $(this.quickToolNoteSelector).addClass('collapse');
    }
    else {
      $(this.noteCollapseSelector).addClass('collapse');
    }
  }

  addShowCollapse() {
    if($(this.isMobileview).is(":visible")) {
      $(this.quickToolNoteSelector).addClass('show');
    }
    else {
      $(this.noteCollapseSelector).addClass('show');
    }
  }

  handleOpenAndShowNoteBox() {
    this.handleOpenNoteBox();
    this.addShowCollapse();
  }
}

export default new NoteForm();