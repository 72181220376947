export const add10Referees = (openRefereeBtn, refereeForm) => {
  $('body').on('modal:afterSetContent', `${openRefereeBtn}, ${refereeForm}`, () => {
    const maxReferrals = 10;

    const addButton = $('#add-new-referral');
    const number = maxReferrals - $('.referee-inline').length;
    for (let i = 0; i < number; i++) {
      addButton.trigger('click');
    }
  });
};
