import Raicon from 'raicon';
import formAutomationContent from './formAutomationContent';

class AdminAutomationsController {
  edit() {
    new formAutomationContent().setup();
  }

  update() {
    new formAutomationContent().setup();
  }
}

Raicon.register('admin/automations', AdminAutomationsController);
