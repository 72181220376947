export function handleTrackClickBanner() {
  $('.tracking-banner-click').on('click', (e) => {
    const banner_id = e.target.dataset['id']
    if (!banner_id) return ;

    $.ajax({
      url: Routes.update_banner_click_consultant_banner_managers_path(),
      type: 'put',
      data: { banner_id: banner_id }
    });
  });
}
