import Raicon from 'raicon';
import { toggleTdTable } from '@/layouts/application/utils/multipleSelectTable';

class SubscribersController {
  constructor() {
    this.bulkUpdateSubcription = '#bulk-update-subscription';
    this.addTag = '#add-tag';
  }

  index() {
    toggleTdTable();
    this.handleOnSubscribeStatusChange();
    this.checkboxSelected();
    this.getDataForSelectFc();
    this.handleReasonChange();
  }

  handleOnSubscribeStatusChange() {
    $('.subscribe-status-selection').on('change', (e) => {
      const currentUrl = $(this.bulkUpdateSubcription).prop('href');
      const url = new URL(currentUrl);
      url.searchParams.set('subscriber[unsubscribe_email]', e.target.value);
      $(this.bulkUpdateSubcription).prop('href', url.href);
    });
  }

  checkboxSelected() {
    const selectBox = $('.select-box');
    const maxSelectBox = selectBox.length;
    const btnBulkUpdateSubscription = $(this.bulkUpdateSubcription);
    const baseUrl = btnBulkUpdateSubscription.attr('href');

    let client_ids = [];
    let referee_ids = [];
    let countChecked = 0;

    $('#main-select-box').change(function () {
      countChecked = 0;
      const $this = $(this);
      selectBox.prop('checked', $this.is(':checked')).change();
    });

    $('body').on('change', '.select-box', function () {
      const $this = $(this);
      const value = $this.data('value');

      if ($this.is(':checked')) {
        $this.parents('tr').addClass('bg-light-blue');
        const type = $this.data('type');
        if ('client' === type) {
          client_ids.push(value);
          countChecked++;
        }
        if ('referee' === type) {
          referee_ids.push(value);
          countChecked++;
        }

        if (countChecked == maxSelectBox) {
          $('#main-select-box').prop('checked', true);
        }
      } else {
        $this.parents('tr').removeClass('bg-light-blue');
        client_ids = client_ids.filter((i) => i !== value);
        referee_ids = referee_ids.filter((i) => i !== value);

        if (countChecked > 0) {
          countChecked--;
        }

        $('#main-select-box').prop('checked', false);
      }

      const batchDeleteUrl = `${baseUrl.replace(/\/$/, '')}${client_ids.length ? client_ids : -1}/${
        referee_ids.length ? referee_ids : -1
      }`;
      btnBulkUpdateSubscription.attr('href', batchDeleteUrl);
      $('#nums-checked').text(`${countChecked} Subscriber${countChecked > 1 ? 's' : ''} selected`);
    });
  }

  getDataForSelectFc() {
    $('.fc-custom-input').select2({
      ajax: {
        url: Routes.search_fcs_admin_subscribers_path,
        dataType: 'json',
        delay: 500,
        data: (params) => {
          return { search: { q: params.term } };
        },
        processResults: (data) => {
          return {
            results: $.map(data, (fc) => ({ id: fc.id, text: fc.full_name })),
          };
        },
        cache: true,
      },
      minimumInputLength: 1,
    });
  }

  handleReasonChange() {
    $('body').on('modal:afterSetContent', '#edit-subscribe-status', () => {
      $('.reason-selection').on('change', (e) => {
        if (e.target.value == 'Other (fill in reason below)') {
          $('#reason-input').parent().removeClass('d-none');
          $('#reason-input').val('');
        } else {
          $('#reason-input').parent().addClass('d-none');
          $('#reason-input').val(e.target.value);
        }
      });

      $('.subscribe-status').on('change', (e) => {
        if (e.target.value == 'true') {
          $('.unsubscribe-description').removeClass('d-none');
        } else {
          $('.unsubscribe-description').addClass('d-none');
        }
      });

      if ($('.unsubscribe-description').data('is-show')) {
        $('.unsubscribe-description').removeClass('d-none');
      }
    });
  }
}

Raicon.register('admin/subscribers', SubscribersController);
