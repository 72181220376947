export default class AppointmentForm {
  constructor() {
    this.sendReminderCheckboxSelector = '#send-appointment-reminder-checkbox';
    this.virtualAppointmentCheckboxSelector = '#virtual-appointment-checkbox';
    this.appointmentLocationInputSelector = '#physical-appointment-location';
    this.appointmentConferenceLinkSelector = '#virtual-appointment-conference-link';
    this.scheduleSettingsSectionSelector = '#schedule-settings-section';
    this.copyConferenceLinkButtonSelector = '#copy-conference-link-button';
    this.formSelector = '#new-appointment-form';
    this.appointmentConferenceLinkParagaphSelector = 'conference-link-input';
    this.appointmentEmailContentSelector = 'appointment-email-content';
    this.sendConfirmationButtonSelector = '#send-confirmation-submit-btn';
    this.sendCancellationButtonSelector = '#send-cancellation-submit-btn';
    this.sendWhatsAppCopyCheckboxSelector = '#send-whatsapp-copy-checkbox';
    this.SubmitCreateAppointmentSelector = '#submit-create-appointment';
    this.sendWhatsAppCopyButtonSelector = '#send-whatsapp-btn';
  }

  setup() {
    this.toggelEventForSendReminder();
    this.toggelEventForVirtualAppointment();
    this.eventForCopyConferenceLinkButton();
    this.handleSubmitSendConfirmationEmail();
    this.autoSetEndTimeAfterInputStartTime();
    this.handleButtonSendWhatsApp();
  }

  autoSetEndTimeAfterInputStartTime() {
    $('#appointment-start-time').on('change', ({ target }) => {
      const endTimeInput = flatpickr(document.getElementById('appointment-end-time'), {
        enableTime: true
      });

      const currentStartTime = endTimeInput.parseDate(target.value);
      const targetEndTime = endTimeInput.parseDate(currentStartTime.setHours(currentStartTime.getHours() + 1));
      endTimeInput.setDate(targetEndTime);
    })
  }

  eventForCopyConferenceLinkButton() {
    $(this.copyConferenceLinkButtonSelector).on('click', () => {
      this.selectContent(this.appointmentConferenceLinkParagaphSelector);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      alert('Copied to clipboard!');
    })
  }

  toggelEventForVirtualAppointment() {
    if ($(this.virtualAppointmentCheckboxSelector).is(':checked')) {
      this.showConferenceLinkSection();
    } else {
      this.hideConferenceLinkSection();
    }

    $(this.virtualAppointmentCheckboxSelector).on('change', ({ target }) => {
      if (target.checked) {
        this.showConferenceLinkSection();
      } else {
        this.hideConferenceLinkSection();
      }
    })
  }

  toggelEventForSendReminder() {
    if ($(this.sendReminderCheckboxSelector).is(':checked')) {
      this.showScheduleSection();
    } else {
      this.hideScheduleSection();
    }
    $(this.sendReminderCheckboxSelector).on('change', ({ target }) => {
      if (target.checked) {
        this.showScheduleSection();
      } else {
        this.hideScheduleSection();
      }
    })
  }

  showConferenceLinkSection() {
    $(this.appointmentConferenceLinkSelector).show();
    $(this.appointmentLocationInputSelector).hide();
  }

  hideConferenceLinkSection() {
    $(this.appointmentConferenceLinkSelector).hide();
    $(this.appointmentLocationInputSelector).show();
  }

  showScheduleSection() {
    $(this.scheduleSettingsSectionSelector).show();
  }

  hideScheduleSection() {
    $(this.scheduleSettingsSectionSelector).hide();
  }

  selectContent(elementId) {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  handleSubmitSendConfirmationEmail() {
    $('body').on('click', `${this.sendConfirmationButtonSelector}, ${this.sendCancellationButtonSelector}`, () => {
      if ($(this.sendWhatsAppCopyCheckboxSelector).is(':checked')) {
        const whatsAppLink = 'https://api.whatsapp.com/send/';
        const firstClientPhoneNumber = $(`#${this.appointmentEmailContentSelector}`).data('first-client-phone').slice(1);

        this.selectContent(this.appointmentEmailContentSelector);
        const mailContentText = encodeURIComponent(window.getSelection().toString());

        const fullWhatsAppLink = `${whatsAppLink}?phone=${firstClientPhoneNumber}&text=${mailContentText}&app_absent=0`;
        window.open(fullWhatsAppLink);
      }
    });
  }

  handleButtonSendWhatsApp() {
    $('body').on('click', `${this.sendWhatsAppCopyButtonSelector}`, () => {
      const whatsAppLink = 'https://api.whatsapp.com/send/';
      const firstClientPhoneNumber = $(`#${this.appointmentEmailContentSelector}`).data('first-client-phone').slice(1);

      this.selectContent(this.appointmentEmailContentSelector);
      const mailContentText = encodeURIComponent(window.getSelection().toString());

      const fullWhatsAppLink = `${whatsAppLink}?phone=${firstClientPhoneNumber}&text=${mailContentText}&app_absent=0`;
      window.open(fullWhatsAppLink);
    });
  }
}
