import Raicon from 'raicon';
import ProductForm from './productForm';
import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import TaskForm from '@/controllers/consultant/tasks/formTask';
import AppointmentForm from '../planners/appointmentForm';
import { handleOverlayGuides } from '../overlayGuides';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';

class ConsultantCasesController {
  index() {
    toggleTdTable();
    checkboxSelected();

    $('body').on('modal:afterSetContent', '#add-new-case, #new-case-form, #edit-case-link, #edit-case-form', () => {
      this.setupProductForm();
    });
    this.setupTaskForm();
    this.setupAppointmentForm();
    handleOverlayGuides();
    changeParamenterByTab('cases', 'active_tab');
    handleTrackClickBanner();
  }

  setupProductForm() {
    new ProductForm();
  }

  setupTaskForm() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task', () => {
      new TaskForm().setup();
    });
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }
}

Raicon.register('consultant/cases', ConsultantCasesController);
