export const handleChangeContactType = () => {
  const contactTypeColor = {
    approached: { color: '#C05780', name: 'A' },
    met: { color: '#FFA23A', name: 'M' },
    closed: { color: '#00B0BA', name: 'C' },
    leads: { color: '#1379AB', name: 'L' },
  };

  const contactTypeMapping = {
    leads: 'approached',
    approached: 'met',
    met: 'closed',
    closed: 'leads',
  };
  Array.from($('.contact-type-wrapper')).forEach((item) => {
    $(item).on('click', function () {
      const clientId = $(this).data('client-id');
      const contactType = $(this).data('contact-type');
      const nextContactType = contactTypeMapping[contactType];
      const type = contactTypeColor[nextContactType]?.name;
      const color = contactTypeColor[nextContactType]?.color;
      const element = `
        <div class='contact-type-circle' style='background: ${color}'>
          ${type}
        </div>
      `;
      $.ajax({
        url: Routes.update_contact_type_consultant_client_path(clientId, {
          contact_type: nextContactType,
        }),
        type: 'PUT',
      }).done(() => {
        $(this).data('contact-type', nextContactType);
        $(this).html(element);
      });
    });
  });
};
