const handleInsurerOther = () => {
  $('.insurer-list').on('change', () => {
    if ($('.insurer-list').val() == 'Other') {
      $('.insurer-other-input').parent().removeClass('d-none');
    } else {
      $('.insurer-other-input').parent().addClass('d-none');
      $('.insurer-other-input').val('');
    }
  });
  if ($('.insurer-list').val() == 'Other') {
    $('.insurer-other-input').parent().removeClass('d-none');
  }
};

export { handleInsurerOther };
