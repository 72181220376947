export function triggerCompletedTask() {
  $('.check-box-task').on('click', function(e) {
    const $this = e.target;
    const taskId = $($this).data('id');
    const duedate = $($this).data('duedate');
    const isCall = $($this).data('call');
    const btnLog = $('#log-call');
    const card = $(`#task-${taskId}`);

    const data = {
      task: { status: 'completed' },
      recurring_status: { recurring_status: 'completed', recurring_duedate: duedate }
    };

    $.ajax({
      url: Routes.mark_completed_consultant_task_path(taskId),
      type: 'PUT',
      dataType: 'json',
      data: data,
      success: function (res) {
        if (isCall) {
          btnLog.attr("href", Routes.new_log_call_consultant_task_path(taskId, { recurring_duedate: duedate }));
          btnLog[0].click();
          btnLog.attr("href", '#');
        }

        card.remove();

        if ($('.today-task-card').length < 1) {
          $('#view-all-tasks').remove();
        }
      }
    })
  })
}
