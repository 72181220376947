import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';

class NetwealthsPasswordsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
    this.drawNetworthCharts();
  }

  drawNetworthCharts() {
    setTimeout(() => {
      const $networthAssetChart = $('#networth-asset-chart');

      if ($networthAssetChart.length > 0) {
        const assetData = $networthAssetChart.data('chart-data');
        const assetColor = $networthAssetChart.data('chart-color');
        initDoughnutChart($networthAssetChart, assetData, assetColor.slice(0, assetData.length), 170);
      }
    }, 100);
  }
}

Raicon.register('clients/netwealths', NetwealthsPasswordsController);
