import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/dark.css';

export const initFlatPickr = (
  dateSelector = '.datepicker',
  dateTimeSelector = '.datetimepicker',
  withoutYearOption = false
) => {
  flatpickr(dateSelector, {
    altInput: true,
    altFormat: withoutYearOption ? "d/m" : "d/m/Y",
    monthSelectorType: "static",
    allowInput: true,
    disableMobile: true
  });

  flatpickr(dateTimeSelector, { enableTime: true });

  if (withoutYearOption) {
    $('.flatpickr-calendar').addClass('without-year')
  }
};

document.addEventListener('turbolinks:load', () => {
  initFlatPickr();
});
