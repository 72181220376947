export const NET_WEALTH = '#0033A0';
export const LIABILITY = '#E8B012';
export const PERSONAL_DREAMS = '#E8B012';
export const EMPTY = '#EEEEEE';
export const GREEN = 'rgb(0,128,0)';

// shared dreams
export const SHARED_DREAM_AMOUNT_SAVED_LINE = '#E8B012';
export const SHARED_DREAM_MEMBER_CONTRIBUTION_LINE = '#FF6D7A';
export const SHARED_DREAM_CLIENT_CONTRIBUTION_LINE = '#631CD0';

// solo dream
export const SOLO_DREAM_LINE = '#0033A0';

// dream cashflow netwealth
export const INCOME = '#0033A0';
export const EXPENSE = '#E8B012';
export const ASSET = '#0033A0';

// retirement
export const RETIREMENT_CURRENT_SAVING_BODER_COLOR = '#E8B012';
export const RETIREMENT_FUNTS_BODER_COLOR = '#CF0000';
export const RETIREMENT_DESIRED_BODER_COLOR = '#631CD0';
export const RETIREMENT_SIMULATED_BODER_COLOR = ['#0033A0', '#3399FF', '#00BBD1'];
export const RETIREMENT_DREAM_COLOR = '#28B446';

// Porfolio
export const PORTFOLIO_INVESTMENT_COLOR = '#0033A0';
export const PORTFOLIO_ENDOWMENT_COLOR = '#E8B012';
export const PORTFOLIO_WHOLE_LIFE_COLOR = '#FE4F60';
export const PORTFOLIO_PERSONAL_ACCIDENTS_COLOR = '#2CD9C5';
export const PORTFOLIO_TERM_LIFE_COLOR = '#BDEFFC';
export const PORTFOLIO_OTHERS_COLOR = '#3399FF';
export const PORTFOLIO_HEALTH_COLOR = '#631CD0';
export const PORTFOLIO_DISABILITY_COLOR = '#A54FFE';
export const PORTFOLIO_ASSET_INVESTMENT_COLOR = '#5D709E';
