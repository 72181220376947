import { BarController } from 'chart.js'

export class DashedBorderBar extends BarController {
  draw() {
    super.draw(arguments);
    const ctx = this.chart.ctx;
    ctx.save();
    const dataset = this.chart.data.datasets[0];
    ctx.strokeStyle = dataset.dashedBorderColor;
    ctx.lineWidth = dataset.dashedBorderWidth;
    ctx.setLineDash(dataset.borderDash);

    this.getMeta().data.forEach((d, index) => {
      let prototype = Object.getPrototypeOf(d.$context)

      if(prototype.dataset.label === 'Total Deposit'){
        ctx.strokeRect(d.x - d.width / 2, d.y, d.width, (d.height + ctx.lineWidth));
      }
    });
    ctx.restore();
  }
}
