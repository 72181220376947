const bulkDeleteItem = (btnBulkDeleteSelector, checkboxSelector) => {
  Array.from($(checkboxSelector)).forEach((item) => {
    $(item).on('change', () => {
      const itemIds = [];
      Array.from($(checkboxSelector)).forEach((checkbox) => {
        if ($(checkbox).is(':checked')) {
          itemIds.push(checkbox.value);
        }
      });

      const btnBulkDelete = $(btnBulkDeleteSelector);
      if (itemIds.length > 0) {
        btnBulkDelete.removeClass('d-none');
      } else {
        btnBulkDelete.addClass('d-none');
        return;
      }
      let url = btnBulkDelete.attr('href').split('/');
      url[url.length - 1] = itemIds.join(',');
      btnBulkDelete.attr('href', url.join('/'));
    });
  });
};

export { bulkDeleteItem };
