import Raicon from 'raicon';
import validatePasswordCharacter from '../../consultant/shared/validatePasswordCharacter';
import { togglePassword } from '../shared/togglePassword';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
class ClientsPasswordsController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  editPassword() {
    this.setup();
  }

  updatePassword() {
    this.setup();
  }

  confirmationPage() {}

  setup() {
    togglePassword();
    validatePasswordCharacter();
  }
}

Raicon.register('clients/passwords', ClientsPasswordsController);
