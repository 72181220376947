import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';

class RetirementSummariesController {
  editRetirementTarget() {
    handleSideMenu();
    handleUploadAvatar();
    handleLockForm(['#client-retirement-target-form']);
    handleMoneyField();
  }
}

Raicon.register('clients/retirementSummaries', RetirementSummariesController);
