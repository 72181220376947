import { DEFAULT_CURRENCY } from '@/constants';

export function formatCurrency(value, currency = DEFAULT_CURRENCY) {
  if (currency !== DEFAULT_CURRENCY) {
    return new Intl.NumberFormat('en-SG', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
  return new Intl.NumberFormat('en-SG', { style: 'currency', currency: currency }).format(value);
}

export function formatCurrencyWithoutSign(value) {
  return new Intl.NumberFormat('en-SG', {
    currency: 'SGD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

export function formatCurrencyWholeNumber(value) {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export const formatCurrencyToNumber = (value) => {
  if (value) {
    return parseFloat(value.replace('$', '').replaceAll(',', ''));
  } else {
    return 0;
  }
};

export const formatMoneyToNumber = (str) => {
  if (str) {
    return str.replaceAll(',', '');
  } else {
    return '0';
  }
};

export const formatDate = (date) => {
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
