export class CurrencyHandler {
  constructor(currencySelector, currencySelectorBusinessSalary, propertyCurrency, propertyCurrencyBusinessSalary) {
    this.currencySelector = currencySelector;
    this.currencySelectorBusinessSalary = currencySelectorBusinessSalary;
    this.propertyCurrency = propertyCurrency;
    this.propertyCurrencyBusinessSalary = propertyCurrencyBusinessSalary;
  }

  currencyChangeHandler() {
    $('body').on('change', `${this.currencySelector}, ${this.currencySelectorBusinessSalary}`, (event) => {
      const selectedCurrency = $(event.target).val();
      const isBusinessSalary = $(event.target).is(this.currencySelectorBusinessSalary);
      const propertyCurrencySelector = isBusinessSalary ? this.propertyCurrencyBusinessSalary : this.propertyCurrency;
      if (selectedCurrency === 'SGD') {
        $(propertyCurrencySelector).removeClass('currency-sign-no-content').addClass('currency-sign');
      } else {
        $(propertyCurrencySelector).removeClass('currency-sign').addClass('currency-sign-no-content');
      }
    });
  }
}
