import { formatCurrency, formatCurrencyToNumber, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import {
  calculateHouseDreamPaymentAmountWithCashPayment,
  calculateHouseDreamPaymentAmountWithLoanPayment,
  calculateHouseDreamMortgageOutstandingAmount,
  calculateHouseDreamMortgageTotalMonthlyRepayment,
  calculateHouseDreamMortgageCashMonthlyRepaymentAmount,
} from './dreamCalculator';

import ToggleSharedDreamEvent from './shared/toggleSharedDreamEvent'
import calculateTartgetYearBaseOnStartDate from '../shared/calculateTargetYearBaseOnStartDate';
import updateAgeByInput from './shared/updateAgeByInput';
import checkValidDreamStartDate from './shared/checkInvalidStartDate';

export default class DreamHouseForm {
  constructor() {
    this.containerSelector = '.dream-house-form';
    this.cashPaymentAmountSelector = '.cash-payment-amount';
    this.loanPaysmentAmountSelector = '.loan-payment-amount';
    this.mortgageOutstandingAmountSelector = '.outstanding-mortgage-amount';
    this.mortgageTotalMonthlyRepaymentSelector = '.total-monthly-repayment-amount';
    this.mortgageCashMonthlyRepaymentAmountSelector = '.cash-monthly-repayment-amount';
    this.baseValueSelector = 'input[name="dream[property_value]"]';
    this.otherFeeSelector = 'input[name="dream[property_other_fee]"]';
    this.downPaymentSelector = 'input[name="dream[property_down_payment]"]';
    this.cpfDownPaymentSelector = 'input[name="dream[property_cpf_down_payment]"]';
    this.mortgageRateSelector = 'input[name="dream[property_mortgage_rate]"]';
    this.mortgageDurationSelector = 'input[name="dream[property_mortgage_duration]"]';
    this.mortgageMonthlyDurationSelector = 'input[name="dream[property_mortgage_monthly_duration]"]';
    this.mortgageCpfMonthlyRepaymentSelector = 'input[name="dream[property_mortgage_cpf_monthly_repayment]"]';
    this.paymentMethodInputSelector = 'input[name="dream[property_payment_method]"]';
    this.sharedCashRepaymentByClientSelector = '#shared-cash-repayment-by-client-input';
    this.loanRepaymentByMemberSelector = '#loan-repayment-by-member';
    this.mortgageDurationTimeSelector = '#property-mortgage-duration-time';
    this.purchaseStartDateSelector = '#date-expected-input';
    this.ageExpectedShowSelector = '#age-happen-value';
    this.errorsCashRepayment = '#errors-cashrepayment';
    this.submitButton = '#btn-submit-dream';
    this.ordinary_account = '#oa-account-value';
    this.ordinary_account_block = '#oa-account-block';
    this.ordinary_contribution = '#oa-contribution';
    this.ordinary_contribution_text = '#oa-contribution-text';
    this.ordinary_contribution_text_extra = '#oa-contribution-text-extra'
  }

  setup() {
    new ToggleSharedDreamEvent().setup();
    this.addOnChangeForCashRepaymentByClient();
    this.getDataFromCpfDetails();

    calculateTartgetYearBaseOnStartDate(this.purchaseStartDateSelector, this.mortgageDurationSelector, this.mortgageDurationTimeSelector, this.mortgageMonthlyDurationSelector);
    updateAgeByInput(this.purchaseStartDateSelector, this.ageExpectedShowSelector);
    checkValidDreamStartDate(this.purchaseStartDateSelector);

    $(this.containerSelector).on('change', this.paymentMethodInputSelector, () => {
      this.syncPaymentMethod();
    });

    $(this.containerSelector).on('input', `${this.baseValueSelector}, ${this.otherFeeSelector}`, () => {
      if (this.getPaymentMethod() === 'cash') {
        this.syncCashPaymentAmounts();
      }
    });

    $(this.containerSelector).on(
      'input',
      `${this.baseValueSelector}, ${this.otherFeeSelector}, ${this.downPaymentSelector}, ${this.cpfDownPaymentSelector}`,
      () => {
        if (this.getPaymentMethod() === 'loan') {
          this.syncLoanPaymentAmounts();
          this.syncMortgageRepaymentAmounts();
        }
      },
    );

    $(this.containerSelector).on(
      'input',
      `${this.mortgageRateSelector}, ${this.mortgageDurationSelector}, ${this.mortgageCpfMonthlyRepaymentSelector}, ${this.mortgageMonthlyDurationSelector}`,
      () => {
        this.syncMortgageRepaymentAmounts();
      },
    );

    if (!this.getPaymentMethod()) {
      $(`${this.paymentMethodInputSelector}[value="cash"]`).attr('checked', 'checked');
    }

    $(this.purchaseStartDateSelector).on('input', () => {
      checkValidDreamStartDate(this.purchaseStartDateSelector);
    });

    this.syncPaymentMethod();

    $(this.containerSelector).on('change', `${this.purchaseStartDateSelector}, ${this.purchaseStartDate}, ${this.cpfDownPaymentSelector}`, () => {
        this.getDataFromCpfDetails();
      },
    );
  }

  addOnChangeForCashRepaymentByClient() {
    $(this.sharedCashRepaymentByClientSelector).on('input', ({ target }) => {
      this.calculateMemberRepaymentAmount();
    })
  }

  calculateMemberRepaymentAmount() {
    const monthlyRepaymentAmount = formatCurrencyToNumber($(this.mortgageCashMonthlyRepaymentAmountSelector).html())
    const clientRepaymentAmount = formatCurrencyToNumber($(this.sharedCashRepaymentByClientSelector).val())

    if (monthlyRepaymentAmount > 0 && clientRepaymentAmount) {
      const memberRepaymentAmount = monthlyRepaymentAmount - clientRepaymentAmount;

      if (memberRepaymentAmount >= 0) {
        $(this.loanRepaymentByMemberSelector).html(formatCurrency(memberRepaymentAmount));
      } else {
        $(this.loanRepaymentByMemberSelector).html(formatCurrency(0));
      }
    }
  }

  syncPaymentMethod() {
    const paymentMethod = this.getPaymentMethod();
    const $withCashElements = $(`${this.containerSelector} .with-cash-payment`);
    const $withLoanElements = $(`${this.containerSelector} .with-loan-payment`);

    if (paymentMethod === 'cash') {
      this.syncCashPaymentAmounts();
      $withLoanElements.addClass('d-none');
      $withCashElements.removeClass('d-none');
    }

    if (paymentMethod === 'loan') {
      this.syncLoanPaymentAmounts();
      this.syncMortgageRepaymentAmounts();
      $withLoanElements.removeClass('d-none');
      $withCashElements.addClass('d-none');
    }
  }

  syncCashPaymentAmounts() {
    const paymentAmount = calculateHouseDreamPaymentAmountWithCashPayment(
      this.getBaseValueAmount(),
      this.getOtherFeeAmount(),
    );
    $(this.cashPaymentAmountSelector).text(formatCurrency(paymentAmount));
  }

  syncLoanPaymentAmounts() {
    const paymentAmount = calculateHouseDreamPaymentAmountWithLoanPayment(
      this.getDownPaymentAmount(),
      this.getCpfDownPaymentAmount(),
      this.getOtherFeeAmount(),
    );

    $(this.loanPaysmentAmountSelector).text(formatCurrency(paymentAmount));
  }

  syncMortgageRepaymentAmounts() {
    const baseValueAmout = this.getBaseValueAmount();
    const downPaymentAmount = this.getDownPaymentAmount();
    const outstandingMorgateAmount = calculateHouseDreamMortgageOutstandingAmount(baseValueAmout, downPaymentAmount);

    const mortgageRate = this.getMortgageRate();
    const mortgageDuration = this.getMortgageDuration();
    const mortgageMonthlyDuration = this.getMortgageMonthlyDuration();
    const mortgageTotalMonthlyRepaymentAmount =
      mortgageDuration !== 0 || mortgageMonthlyDuration !== 0
        ? calculateHouseDreamMortgageTotalMonthlyRepayment(outstandingMorgateAmount, mortgageRate, mortgageDuration, mortgageMonthlyDuration)
        : 0.00;
    const mortgageCpfMonthlyRepaymentAmount = this.getMortgageCpfMonthlyRepaymentAmount();
    const mortgageCashMonthlyRepaymentAmount = calculateHouseDreamMortgageCashMonthlyRepaymentAmount(
      mortgageTotalMonthlyRepaymentAmount,
      mortgageCpfMonthlyRepaymentAmount,
    );

    $(this.mortgageOutstandingAmountSelector).text(formatCurrency(outstandingMorgateAmount));
    $(this.mortgageTotalMonthlyRepaymentSelector).text(formatCurrency(mortgageTotalMonthlyRepaymentAmount));
    $(this.mortgageCashMonthlyRepaymentAmountSelector).text(formatCurrency(mortgageCashMonthlyRepaymentAmount));
    this.calculateMemberRepaymentAmount();

    if(mortgageCashMonthlyRepaymentAmount < 0){
      $(this.submitButton).prop('disabled', true);
      $(this.errorsCashRepayment).removeClass('d-none');
    } else {
      $(this.submitButton).prop('disabled', false);
      $(this.errorsCashRepayment).addClass('d-none');
    }
  }

  getPaymentMethod() {
    return $(`${this.paymentMethodInputSelector}:checked`).val();
  }

  getBaseValueAmount() {
    return parseFloat(formatMoneyToNumber($(this.baseValueSelector).val())) || 0;
  }

  getOtherFeeAmount() {
    return parseFloat(formatMoneyToNumber($(this.otherFeeSelector).val())) || 0;
  }

  getDownPaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.downPaymentSelector).val())) || 0;
  }

  getCpfDownPaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.cpfDownPaymentSelector).val())) || 0;
  }

  getMortgageRate() {
    return (parseFloat($(this.mortgageRateSelector).val()) || 0) / 100;
  }

  getMortgageDuration() {
    return parseInt($(this.mortgageDurationSelector).val()) || 0;
  }

  getMortgageMonthlyDuration() {
    return parseInt($(this.mortgageMonthlyDurationSelector).val()) || 0;
  }

  getMortgageCpfMonthlyRepaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.mortgageCpfMonthlyRepaymentSelector).val())) || 0;
  }

  getDataFromCpfDetails(){
    if($(this.purchaseStartDateSelector).val() === '') return;

    const date = new Date($(this.purchaseStartDateSelector).val())
    const currentDate = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    const clientId = $(this.purchaseStartDateSelector).data('client-id');

    let cpf_details_user_cpf_accounts_path = Routes.cpf_details_consultant_cpf_accounts_path({
      cpf_account: 'ordinary_account', 
      month: month, year: year,
      client_id: clientId,
      housing_dream: this.editing_house_dream_params()
    })

    if (window.location.href.includes('clients/dream')) {
      cpf_details_user_cpf_accounts_path = Routes.cpf_details_clients_cpf_accounts_path({
        cpf_account: 'ordinary_account',
        month: month,
        year: year,
        client_id: clientId,
        housing_dream: this.editing_house_dream_params()
      })
    }

    $.get(cpf_details_user_cpf_accounts_path, (data) => {
      if(currentDate.getDate() == day && (currentDate.getMonth() + 1) == month && currentDate.getFullYear() == year){
        $(this.ordinary_contribution_text).text('Your current monthly OA Contribution is ');
      } else {
        $(this.ordinary_contribution_text).text('You will have an estimated ');
      }

      if ( currentDate.getDate() > day && (currentDate.getMonth() + 1) == month && currentDate.getFullYear() == year || (currentDate.getMonth() + 1) > month && currentDate.getFullYear() == year || currentDate.getFullYear() > year ){
        $(this.ordinary_account_block).addClass('d-none');
        $(this.ordinary_contribution).text(formatCurrency($(this.ordinary_contribution).data('oa-contribution')));
        $(this.ordinary_contribution_text).text('Your current monthly OA Contribution is ');
        $(this.ordinary_contribution_text_extra).addClass('d-none');
      } else {
        $(this.ordinary_account_block).removeClass('d-none');
        $(this.ordinary_contribution_text_extra).removeClass('d-none');
      }

      $(this.ordinary_account).text(formatCurrency(data['cpf_balance']));
      $(this.ordinary_contribution).text(formatCurrency(data['contribution']));
    })
  }

  editing_house_dream_params = () => {
    return {
      id: $(this.containerSelector).data('dream-id'),
      dream_start_date: $(this.purchaseStartDateSelector).val(),
      property_cpf_down_payment: $(this.cpfDownPaymentSelector).val().replaceAll(',', ''),
      property_mortgage_monthly_duration: $(this.mortgageMonthlyDurationSelector).val(),
      property_mortgage_duration: $(this.mortgageDurationSelector).val(),
      property_mortgage_cpf_monthly_repayment: $(this.mortgageCpfMonthlyRepaymentSelector).val().replaceAll(',', '')
    }
  }
}
