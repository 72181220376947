import Raicon from 'raicon';
import EmailForm from '../../consultant/enhance_emails/emailForm';

class EnhanceEmailsController extends EmailForm {
  constructor() {
    super();
    this.role = 'admin';
    this.showPath = 'admin_enhance_email_path';
    this.sendTestEmailPath = 'send_test_email_admin_enhance_email_path';
  }

  index() {
    super.index();
    this.getDataForSelectCustomRecipient('index');
  }

  setup() {
    super.setup();
    this.setDefaultValueForInputs();
    this.handleAudienceTabChange();
    this.getDataForSelectCustomRecipient('new');
    this.getDataForSelectCustomFc();
    this.toggleAllSubscribersAudience();
  }

  setDefaultValueForInputs() {
    $('#email-submit-btn').on('click', () => {
      // set default value for audience field
      const inactiveAudienceTab = $('#email-audience-tab-content').children('li').not('.active');
      const activeAudienceTab = $('#email-audience-tab-content li.active')[0];
      const allPeopleFromTab = $('#all-people-from-tab-content li');
      Array.from(inactiveAudienceTab).forEach((tab) => {
        tab.id.includes('all') ? $(`.${tab.id}-input`).val('0') : $(`.${tab.id}-input`).val([]);
      });
      if (activeAudienceTab.id.includes('all')) {
        Array.from(allPeopleFromTab).forEach((tab) => {
          if (!$(tab).hasClass('active')) {
            tab.id.includes('all') ? $(`.${tab.id}-input`).val('0') : $(`.${tab.id}-input`).val([]);
          }
        });
      } else {
        Array.from(allPeopleFromTab).forEach((tab) => {
          tab.id.includes('all') ? $(`.${tab.id}-input`).val('0') : $(`.${tab.id}-input`).val([]);
        });
      }

      // set default value for save content as template field
      if ($(this.saveAsTemplate).val() == '1' && $('a[href="#new-template"').hasClass('active')) {
        $('#override-template-input').val('');
      }
    });

    $('#override-template-input').val('');
  }

  handleAudienceTabChange() {
    Array.from($(this.emailAudienceTab)).forEach((audience) => {
      $(audience).on('click', (e) => {
        if (e.target.href.includes('all')) {
          $(this.allPeopleFromWrapper).removeClass('d-none');
        } else {
          $(this.allPeopleFromWrapper).addClass('d-none');
        }
      });
    });
  }

  getDataForSelectCustomRecipient(page) {
    const selector = '.email-custom-input';
    const url = Routes.search_custom_recipients_admin_enhance_emails_path;
    this.common_select2_search(selector, url, page);
  }

  getDataForSelectCustomFc() {
    const selector = '.specific-fc-input';
    const url = Routes.search_custom_fcs_admin_enhance_emails_path;
    this.common_select2_search(selector, url);
  }

  common_select2_search(selector, url, page) {
    $(selector).select2({
      ajax: {
        url,
        dataType: 'json',
        delay: 500,
        data: (params) => {
          const activeAudienceTab = $('#email-audience-tab-content li.active')[0];
          const fcOptedIn = activeAudienceTab?.id == 'all-subscriber' || activeAudienceTab?.id == 'all-email-client-type';
          const templateSelectionId = $('#email-template-selection').val();
          return fcOptedIn
            ? { search: { q: params.term }, template_id: templateSelectionId, fc_opted_in: fcOptedIn }
            : { search: { q: params.term } };
        },
        processResults: (data) => {
          return {
            results: $.map(data, (item) => {
              if (page == 'new') {
                return {
                  id: item.payload,
                  text: `${item.full_name} (${item.email})`,
                };
              } else {
                return { id: item.id, text: item.full_name };
              }
            }),
          };
        },
        cache: true,
      },
      minimumInputLength: 1,
    });
  }

  toggleAllSubscribersAudience() {
    const allSubscriberParentTab = $('#all-subscriber-tab').parent();
    const emailClientTypeParentTab = $('#email-client-type-tab').parent();
  
    const disableObject = this.disableClickObject();
    const enableObject = this.enableClickObject();
  
    if (this.getTemlateId() == '') {
      allSubscriberParentTab.css(disableObject);
      emailClientTypeParentTab.css(disableObject);
    } else {
      allSubscriberParentTab.css(enableObject);
      emailClientTypeParentTab.css(enableObject);
    }
  }
}

Raicon.register('admin/enhanceEmails', EnhanceEmailsController);
