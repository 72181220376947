import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';

class ClientsAdvisorsController {
  constructor(){
    this.shareBtn = '.btn-share-advisor'
  }

  show() {
    handleSideMenu();
    this.triggerShareButton();
  }

  public() {
    this.triggerShareButton();
  }

  triggerShareButton(){
    const url = $(this.shareBtn).data('url');

    if (navigator.userAgent.indexOf('median') > -1) {
      $(this.shareBtn).on('click', (e) => {        
        median.share.sharePage({
          url: url
        });

        e.preventDefault();
      });
    } else if (navigator.share) {
      navigator.share({url: url});
    }
  }
}

Raicon.register('clients/advisors', ClientsAdvisorsController)
