export default class ShareButton {
  constructor() {
    this.shareBtn = '.btn-share-now';
  }

  setup() {
    this.triggerShareToOther();
  }

  triggerShareToOther() {
    if (navigator.userAgent.indexOf('median') > -1) {
      $('.btn-share-now').on('click', () => {
        const clientCode = $(this.shareBtn).data('client-code');
        const clientName = $(this.shareBtn).data('client-name');
        const shareUrl = Routes.friends_welcome_path({ code: clientCode });

        if (navigator.userAgent.includes('Android')) {
          this.medianShareToApp({
            url: "Hello, I've been using finConnect and I believe you'll enjoy it too. This app puts all your finances within your fingertip. Give it a try using this link: " + location.origin + shareUrl
          });
        } else {
          this.medianShareToApp({
            url: location.origin + shareUrl,
            text: " Hello, I've been using finConnect and I believe you'll enjoy it too. This app puts all your finances within your fingertip. Give it a try using this link"
          });
        }
      });
    }
  }

  medianShareToApp(data) {
    median.share.sharePage(data);
  }
}
