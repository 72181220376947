export const updateCalendarTaskColor = (taskSelector, taskType, status) => {
  let bgColor = '#631CD0';
  
  if(taskType == 'todo' && status == 'completed') {
    bgColor = 'rgba(99, 28, 206, 0.5)'
  }
  else if(taskType == 'to_call') {
    if(status == 'open') {
      bgColor = '#A89682';
    }
    else {
      bgColor = 'rgba(167, 149, 129, 0.5)';
    }
  }
  $(taskSelector).css('background-color', bgColor);
}
