const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);

const handleDropdown = () => {
  Array.from($('.btn-dropdown')).forEach((btn, index) => {
    $(btn).on('click', (e) => {
      if (e.target.src.includes('arrow-down')) {
        e.target.src = imagePath('./icons/arrow-up-select.svg');
        $(`tr[data-wrapper-id=${index}]`).removeClass('d-none');
      } else {
        e.target.src = imagePath('./icons/arrow-down-select.svg');
        $(`tr[data-wrapper-id=${index}]`).addClass('d-none');
      }
    });
  });
};

const handleImportOption = () => {
  Array.from($('.import-option')).forEach((option, index) => {
    $(option).on('click', (e) => {
      let number = Math.floor(index / 2);
      if (e.target.value == 'overwrite') {
        $(`[data-wrapper-overwrite=${number}]`).removeClass('d-none');
      } else {
        $(`[data-wrapper-overwrite=${number}]`).addClass('d-none');
      }
    });
  });
};

const handleDeleteDuplication = () => {
  Array.from($('.delete-duplicate-client')).forEach((btnDelete, index) => {
    $(btnDelete).on('click', () => {
      $(`tr[data-delete-id=${index}]`).remove();
      if ($('.delete-duplicate-client').length == 0) {
        $('#import-duplicate-contact').prop('disabled', true);
      }
    });
  });
};

const chooseOnlyOneContactToOverwrite = () => {
  const contactLength = $('.btn-dropdown').length;
  for (let i = 0; i < contactLength; i++) {
    const overwriteCheckboxes = $(`td[data-wrapper-overwrite=${i}]`).find('.overwrite-contact-checkbox');
    Array.from(overwriteCheckboxes).forEach((checkbox) => {
      $(checkbox).on('change', () => {
        if ($(checkbox).is(':checked')) {
          overwriteCheckboxes.prop('checked', false);
          $(checkbox).prop('checked', true);
        }
      });
    });
  }
};

const disableSubmitButtonWhenSubmitForm = () => {
  $('#import-duplicate-contact').on('click', () => {
    $('#import-duplicate-contact').addClass('d-none');
    $('#loading-button').removeClass('d-none');
  });
};

const handleImportDuplicateContacts = () => {
  handleDropdown();
  handleImportOption();
  handleDeleteDuplication();
  chooseOnlyOneContactToOverwrite();
  disableSubmitButtonWhenSubmitForm();
};

export { handleImportDuplicateContacts };
