import Raicon from 'raicon';

class ClientsLoginPreferencesController {
  constructor() {
    this.username = '#username';
    this.savePreferencesBtn = '#save-preferences-btn';
    this.loginPreferences = 'input[name="login_preferences"]:checked';
  }

  index() {
    if (navigator.userAgent.indexOf('median') > -1) {
      this.setup();
    }
  }

  saved() {}

  checkBiometricLoginEnable() {
    median.auth.status({'callbackFunction': (result) => {            
      if (result && result.hasTouchId && result.hasSecret) {
        window.location.href = Routes.clients_root_path();

        // remove secret password from local storage when biometric login is enabled
        localStorage.removeItem('secretPassword');
      } else {
        window.location.href = Routes.clients_login_preferences_path();
      }
    }});
  }

  setup() {
    $(this.savePreferencesBtn).on('click', () => {
      let loginPreferences = $(this.loginPreferences);

      if (loginPreferences.length == 0) {
        window.location.href = Routes.clients_root_path();
        return;
      }

      let secret = JSON.stringify({
        username: $(this.username).val(),
        password: localStorage.getItem('secretPassword')
      });

      loginPreferences.each((index, element) => {
        let method = $(element).val();

        if (method == 'biometric') {
          median.auth.status({'callbackFunction': (result) => {            
            if (result && result.hasTouchId) {
              median.auth.save({
                'secret': secret,
                'minimumAndroidBiometric': 'strong'
              });

              // store secret username to check if login with different user
              localStorage.setItem('secretUsername', username);
            }

            // remove secret password from local storage when biometric login is enabled
            localStorage.removeItem('secretPassword');

            window.location.href = Routes.saved_clients_login_preferences_path({ set_cookies_username: true });
          }});
        } else if (method == 'otp') {
          let targetPath = Routes.update_otp_enable_clients_login_preferences_path();

          let csrfToken = $('meta[name="csrf-token"]').attr('content');
          let data = JSON.stringify({
            authenticity_token: csrfToken,
            enable: true
          });

          $.ajax({
            type: 'PUT',
            url: targetPath,
            data: data,
            processData: false,
            contentType: 'application/json'
          }).done((data) => {
            // do nothing
          }).fail((data) => {
            // do nothing
          }).always(() => {
            window.location.href = Routes.saved_clients_login_preferences_path();
          });
        }
      });
    });
  }
}

Raicon.register('clients/loginPreferences', ClientsLoginPreferencesController);
