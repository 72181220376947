import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class ClientsProfilesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  show(){}

  update(){}
}

Raicon.register('clients/profiles', ClientsProfilesController)
